import { Box, useMediaQuery } from "@mui/material";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";

type Props = {};

export function FullScreenBottomSpacer(props: Props) {
  const { insets } = useCapacitorProvider();

  const isDesktop = useMediaQuery("(min-width:600px)");

  return <Box sx={{ height: isDesktop ? "67px" : (insets?.top || 0) + 65 + "px", width: "100%" }}></Box>;
}
