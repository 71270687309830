import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Card, Grow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import { FullScreenBottomSpacer } from "../../components/Container/FullScreenBottomSpacer";
import FullScreenContainerCenter from "../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../components/Container/FullScreenRootContainer";
import TitleContainer from "../../components/Container/TitleContainer";
import { Numpad } from "../../components/Numpad/Numpad";
import { PinForm } from "../../components/PinForm/PinForm";
import { TopNav } from "../../components/nav/TopNavigation";
import { AnyButton } from "../../gp-components/src";
import { useAuthProvider } from "../../service/auth/AuthProvider";
import { Bio, useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";
import { getBioTypeIcon, getBioTypeText, performBiometricVerification } from "../../service/capacitor/CapacitorService";
import SecureStorageService from "../../service/capacitor/SecureStorageService";
import { anyPaletteFilter } from "../../theme";
import "./Setup.css";

const enableBackupMethod = false;
const navigateToAppDelay = 500; // in milliseconds

export default function SetupPage() {
    const [bioEnabled, setBioEnabled] = useState<boolean | null>(null);
    const [pincode, setPincode] = useState("");
    const [currentDisplay, setCurrentDisplay] = useState<"bio" | "pin">("bio");

    const authProvider = useAuthProvider();
    const { setAuthBioVerified } = authProvider;
    const { setAuthPreferences, bio } = useCapacitorProvider();
    const { t } = useTranslation();

    useEffect(() => {
        if (bio?.available === false) {
            chooseBio(false);
        }
    }, [bio?.available]);

    function handleNumpadChange(newPincode: string) {
        setPincode(newPincode);
        if (newPincode.length === 5) {
            choosePincode(true, newPincode);
        }
    }

    function chooseBio(enable: boolean) {
        if (enable) {
            performBiometricVerification(t("bioAuthentication.activate") + getBioTypeText(bio?.type)).then(
                async (verified) => {
                    if (verified) {
                        setBioEnabled(true);

                        setTimeout(() => {
                            if (enableBackupMethod) {
                                setCurrentDisplay("pin");
                            } else {
                                choosePincode(false, undefined, true);
                            }
                        }, navigateToAppDelay);
                    }
                },
            );
        } else {
            setBioEnabled(false);
            setCurrentDisplay("pin");
        }
    }

    function choosePincode(enable: boolean, newPincode?: string, newBioEnabled?: boolean) {
        setTimeout(
            () => {
                savePreferences(enable, newPincode || pincode, newBioEnabled);
            },
            enable ? navigateToAppDelay : 0,
        );
    }

    function savePreferences(pincodeEnabled: boolean, newPincode: string, newBioEnabled?: boolean) {
        // don't change this order, it's important that the authProvider is re-rendered first, otherwise in PrivateRouter, the MobileAuthenticationPage is rendered
        setAuthBioVerified(true);
        setAuthPreferences({ bioEnabled: newBioEnabled || bioEnabled ? true : false, pincodeSet: pincodeEnabled });
        SecureStorageService.setPincode(authProvider, newPincode);
    }

    return (
        <FullScreenRootContainer>
            <title>{t("setup.tabTitle")}</title>
            <TopNav showBackButton={false} disableIconButtons />
            <FullScreenContainerCenter
                maxWidth="sm"
                sx={
                    currentDisplay === "bio"
                        ? {
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                          }
                        : {}
                }
            >
                {currentDisplay === "bio" ? (
                    <BioSetupComponent
                        bioEnabled={bioEnabled}
                        getBioTypeIcon={getBioTypeIcon}
                        bio={bio}
                        chooseBio={chooseBio}
                        getBioTypeText={getBioTypeText}
                    />
                ) : (
                    <PincodeSetup bioEnabled={bioEnabled} pincode={pincode} choosePincode={choosePincode} />
                )}
            </FullScreenContainerCenter>
            {currentDisplay === "pin" && (
                <Box sx={{ width: "100%" }}>
                    <Numpad actionIsDisabled handleChange={handleNumpadChange} />
                </Box>
            )}
            <PageAnalytics />
            {currentDisplay === "bio" && <FullScreenBottomSpacer />}
        </FullScreenRootContainer>
    );
}

function BioSetupComponent({
    bioEnabled,
    getBioTypeIcon,
    bio,
    chooseBio,
    getBioTypeText,
}: {
    bioEnabled: boolean | null;
    getBioTypeIcon: Function;
    bio?: Bio;
    chooseBio: Function;
    getBioTypeText: Function;
}) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <TitleContainer>
                <Typography variant="h1">{t("setup.bioTitle")}</Typography>
            </TitleContainer>
            <Card
                sx={{
                    width: "100%",
                    padding: 2,
                }}
            >
                <Typography mb={1} align="center">
                    {t("setup.bioDescription")}
                </Typography>
                <Box p={2} textAlign="center">
                    <Avatar
                        sx={{
                            height: "6.25rem",
                            width: "6.25rem",
                            margin: "auto",
                            backgroundColor:
                                theme.palette.mode === "dark" ? theme.palette.card.light : theme.palette.sandLight.main,
                        }}
                    >
                        {!bioEnabled ? (
                            <img
                                alt="biometric"
                                height="50px"
                                style={{
                                    filter:
                                        theme.palette.mode === "dark"
                                            ? anyPaletteFilter.darkMode
                                            : anyPaletteFilter.primary,
                                }}
                                src={getBioTypeIcon(bio?.type)}
                            />
                        ) : (
                            <Grow in timeout={500}>
                                <div>
                                    <FontAwesomeIcon
                                        icon={regular("circle-check")}
                                        style={{
                                            height: "64px",
                                            color:
                                                theme.palette.mode === "dark"
                                                    ? theme.palette.white.main
                                                    : theme.palette.green.main,
                                        }}
                                    />
                                </div>
                            </Grow>
                        )}
                    </Avatar>
                </Box>
                <Box my={1}>
                    <AnyButton
                        variant="contained"
                        onClick={() => {
                            chooseBio(true);
                        }}
                        disabled={bioEnabled || undefined}
                        sx={{
                            width: " 100%",
                        }}
                        anytype="green"
                    >
                        {!bioEnabled
                            ? t("setup.enableBio") + getBioTypeText(bio?.type)
                            : getBioTypeText(bio?.type) + " " + t("setup.bioEnabled")}
                    </AnyButton>
                </Box>
                <AnyButton
                    onClick={() => {
                        chooseBio(false);
                    }}
                    sx={{
                        width: "100%",
                        color: theme.palette.text.primary,
                        fontSize: "14px",
                    }}
                    anytype="transparent"
                >
                    {t("setup.skip")}
                </AnyButton>
            </Card>
        </>
    );
}

function PincodeSetup({
    bioEnabled,
    pincode,
    choosePincode,
}: {
    bioEnabled: boolean | null;
    pincode: string;
    choosePincode: Function;
}) {
    {
        const { t } = useTranslation();
        const theme = useTheme();

        return (
            <>
                <Box>
                    <Card
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            boxShadow: "none",
                            padding: 2,
                        }}
                    >
                        <Typography align="center" variant="h3">
                            {bioEnabled ? t("setup.setBackup") : t("setup.setPincode")}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "90px",
                            }}
                        >
                            {pincode.length < 5 ? (
                                <PinForm
                                    emptyStyle={{
                                        bgcolor:
                                            theme.palette.mode === "dark"
                                                ? theme.palette.card.dark
                                                : theme.palette.sandMedium.main,
                                    }}
                                    pincode={pincode}
                                />
                            ) : (
                                <Grow in timeout={500}>
                                    <div>
                                        <FontAwesomeIcon
                                            icon={regular("circle-check")}
                                            style={{
                                                height: "64px",
                                                color:
                                                    theme.palette.mode === "dark"
                                                        ? theme.palette.white.main
                                                        : theme.palette.green.main,
                                            }}
                                        />
                                    </div>
                                </Grow>
                            )}
                        </Box>
                        {bioEnabled && (
                            <AnyButton
                                anytype="transparent"
                                onClick={() => {
                                    choosePincode(false);
                                }}
                                sx={{
                                    width: "100%",
                                    color: theme.palette.text.primary,
                                    fontSize: "14px",
                                }}
                            >
                                {t("setup.skip")}
                            </AnyButton>
                        )}
                    </Card>
                </Box>
            </>
        );
    }
}
