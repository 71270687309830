import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Suspense, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageAnalytics from "../components/Analytics/PageAnalytics";
import BrandCard, { BrandCardSkeleton } from "../components/Brand/BrandCard";
import { BrandCategorySection } from "../components/Brand/BrandCategories";
import ContentContainer from "../components/Container/ContentContainer";
import PageContainer from "../components/Container/PageContainer";
import RootContainer from "../components/Container/RootContainer";
import TitleContainer from "../components/Container/TitleContainer";
import { InfoCard } from "../components/Info/InfoCard";
import { LogItem, LogItemType } from "../components/LogItem/LogItem";
import { Coupon } from "../components/coupon/CouponCard";
import { SideNavigation } from "../components/nav/SideNavigation";
import { TopNav } from "../components/nav/TopNavigation";
import { GPFooter } from "../gp-components/src";
import { AlertError, AnyAlert } from "../gp-components/src/components/Alert/AnyAlert";
import i18n from "../i18n";
import { useInAppNotifications } from "../service/InAppNotificationProvider";
import { Intersector } from "../service/IntersectionObserverHook";
import { Brand, useBrands, useGetBrandLandingPageUrl } from "../service/api/brandService";
import { useQueryGet } from "../service/api/restApiQuery";
import { hexToRgba } from "../service/theme/themeService";
import { anyPalette } from "../theme";
import { SectionHeader } from "./In-App-Notification/InAppNotificationSection";
import WelcomeToConnectedBrandDrawer from "../components/Brand/WelcomeToConnectedBrandDrawer";
import { useLocation } from "react-router";
import { WhatIsAnyIDIntroduction } from "../components/Introduction/WhatIsAnyID";
import { useToggleUserPollSubmissionNativePlatformFlag } from "../service/ClubService";
import { useBrandConfigProvider } from "../service/BrandConfigProvider";
import { Capacitor } from "@capacitor/core";

const INTERSECTION_OBSERVER_THRESHOLD = 5;

export default function HomePage() {
    const [newCoupon, setNewCoupon] = useState<Coupon>();
    const [alertError, setAlertError] = useState<AlertError | undefined>();
    const [currentIndex, setCurrentIndex] = useState(4);
    const [openWelcomeToConnectedBrandDrawer, setOpenWelcomeToConnectedBrandDrawer] = useState(false);

    const isDesktop = useMediaQuery("(min-width:600px)");
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { sections } = useInAppNotifications();
    const { state } = useLocation();

    // get the first section that is not "default"
    const section = sections.filter((x) => x.section_id !== "default").sort((a, b) => a.expires_at - b.expires_at)[0];

    const {
        brands,
        connectedBrands,
        isLoading: brandIsLoading,
        refetch: brandRefetch,
    } = useBrands({
        suspense: true,
        onError: (e: any) => {
            setAlertError({
                id: "brand",
                message: t("home.brandError"),
                code: e.response.status,
                icon: "retry",
                handleAction: brandRefetch,
            });
        },
    });

    const exclusiveBrands = useMemo(() => {
        return connectedBrands
            .sort((a) => {
                // Sort by exclusivity
                return a.exclusive ? -1 : 1;
            })
            .sort((a) => {
                // put BSO brand on top
                return a.id === "a282cde6-2cbe-48a5-8e66-948cfe6f4c76" ? -1 : 1;
            });
    }, [connectedBrands]);

    const { data: recentActivityItems } = useQueryGet<LogItemType[]>(
        ["logs"],
        "gateway",
        "/logger/logs?locale=" + i18n.languages[0],
        {
            enabled: i18n.languages[0] !== undefined && isDesktop,
            select: (result) => {
                if (!result || result.length === 0) return result;

                return result
                    .sort(
                        (a: LogItemType, b: LogItemType) =>
                            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
                    )
                    .slice(0, 2);
            },
        },
    );

    const {
        data: openCoupons,
        status: couponStatus,
        refetch: couponRefetch,
    } = useQueryGet<Coupon[]>(
        ["coupon", { status: "01" }],
        "gateway",
        "/coupon/coupon/byStatus?lang=" + i18n.languages[0] + "&status=0,1",
        {
            enabled: i18n.languages[0] !== undefined && isDesktop,
            select: (data) => {
                if (!data) return data;
                const couponsByDate = data.sort((a, b) => Number(b.created_at) - Number(a.created_at));
                return couponsByDate.slice(0, 2);
            },
            onError: (e: any) => {
                setAlertError({
                    id: "coupon",
                    message: t("home.couponError"),
                    code: e.response.status,
                    icon: "retry",
                    handleAction: couponRefetch,
                });
            },
        },
    );

    const { mutate: mutateToggleNative } = useToggleUserPollSubmissionNativePlatformFlag();
    const brandConfigs = useBrandConfigProvider();

    const { data: brandLandingPage } = useGetBrandLandingPageUrl({
        onSuccess: (data) => {
            if (Capacitor.isNativePlatform()) {
                const brandId = data?.sub_path?.split("/")[3];
                if (brandId) {
                    const pollId = brandConfigs.getByID(brandId).data?.environment?.highlighted_quiz;
                    if (data?.sub_path.includes("/club/quiz/")) {
                        if (pollId) mutateToggleNative({ pollId: pollId });
                    }
                }
            }
        },
    });

    useEffect(() => {
        if (brands?.length === 0) {
            brandRefetch();
        }
    }, [brands]);

    useEffect(() => {
        if (couponStatus === "success" && !newCoupon && openCoupons) {
            const newCoupons = openCoupons.filter((c) => c.status === "0");

            setNewCoupon(newCoupons[0]);
        }
    }, [couponStatus]);

    useEffect(() => {
        if (!!brandLandingPage) setOpenWelcomeToConnectedBrandDrawer(true);
    }, [brandLandingPage]);

    return (
        <div>
            <title>{t("home.tabTitle")}</title>
            {isDesktop ? (
                <Desktop
                    brandIsLoading={brandIsLoading}
                    couponStatus={couponStatus}
                    alertError={alertError}
                    setAlertError={setAlertError}
                    recentActivityItems={recentActivityItems}
                    exclusiveBrands={exclusiveBrands}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                />
            ) : (
                <>
                    <RootContainer>
                        <TopNav showBackButton={false} loading={brandIsLoading || couponStatus === "loading"} />
                        <PageContainer>
                            <WhatIsAnyIDIntroduction open={!!state?.fromClub} />
                            {alertError && (
                                <Box mb={2}>
                                    <AnyAlert error={alertError} handleClose={setAlertError} />
                                </Box>
                            )}
                            <Typography mb={2} variant="h3">
                                {t("home.yourBrands")}
                            </Typography>

                            {exclusiveBrands.length === 0 && !brandIsLoading && (
                                <Typography mb={2}>
                                    {t("home.notConnectedToAnyBrand", {
                                        pageName: t("bottomNav.brandOverviewExplore"),
                                    })}
                                </Typography>
                            )}

                            {section && (
                                <Box my={2} mx={-1}>
                                    <SectionHeader
                                        section={section}
                                        primaryButton={
                                            <Button
                                                onClick={() => navigate("/notificaties")}
                                                size="small"
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: "500",
                                                    borderRadius: "4px",
                                                    padding: "4px 8px",
                                                }}
                                            >
                                                {t("inAppNotifications.sectionHeader.check")}
                                                <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "4px" }} />
                                            </Button>
                                        }
                                    />
                                </Box>
                            )}

                            <Suspense fallback={<BrandCardSkeleton />}>
                                {exclusiveBrands.slice(0, currentIndex + 1).map((brand, index) => {
                                    return (
                                        <Box key={brand.id} mb={4}>
                                            {index === currentIndex && (
                                                <Intersector
                                                    callback={([entry]) => {
                                                        if (entry.isIntersecting) {
                                                            setCurrentIndex(
                                                                (prev) => (prev += INTERSECTION_OBSERVER_THRESHOLD),
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                            <BrandCard key={brand.id} {...brand} />
                                        </Box>
                                    );
                                })}
                            </Suspense>
                            <BrandCategorySection />
                        </PageContainer>
                    </RootContainer>
                </>
            )}

            {brandLandingPage && (
                <WelcomeToConnectedBrandDrawer
                    open={openWelcomeToConnectedBrandDrawer}
                    toggleOpen={() => setOpenWelcomeToConnectedBrandDrawer((prev) => !prev)}
                />
            )}
            <PageAnalytics />
        </div>
    );
}

function Desktop({
    brandIsLoading,
    couponStatus,
    alertError,
    setAlertError,
    exclusiveBrands,
    recentActivityItems,
    currentIndex,
    setCurrentIndex,
}: {
    brandIsLoading: boolean;
    couponStatus: "error" | "idle" | "loading" | "success";
    alertError: AlertError | undefined;
    setAlertError: (error: AlertError | undefined) => void;
    recentActivityItems: LogItemType[] | undefined;
    exclusiveBrands: Brand[];
    currentIndex: number;
    setCurrentIndex: (state: any) => void;
}) {
    const theme = useTheme();
    const { t } = useTranslation();
    const { state } = useLocation();

    return (
        <RootContainer>
            <TopNav showBackButton={false} loading={brandIsLoading || couponStatus === "loading"} />

            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    <WhatIsAnyIDIntroduction open={state?.fromClub} />
                    <Box mb={2}>
                        {alertError && (
                            <Grid container mb={2}>
                                <Grid item xs={6}>
                                    <AnyAlert error={alertError} handleClose={setAlertError} />
                                </Grid>
                            </Grid>
                        )}
                        <Typography variant="h1">{t("home.yourBrands")}</Typography>
                    </Box>

                    {brandIsLoading ? (
                        <BrandCardSkeleton />
                    ) : (
                        <Suspense fallback={<BrandCardSkeleton />}>
                            {exclusiveBrands.slice(0, currentIndex + 1).map((brand, index) => {
                                return (
                                    <Box key={brand.id} mb={4}>
                                        {index === currentIndex && (
                                            <Intersector
                                                callback={([entry]) => {
                                                    if (entry.isIntersecting) {
                                                        setCurrentIndex(
                                                            (prev: any) => (prev += INTERSECTION_OBSERVER_THRESHOLD),
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                        <BrandCard {...brand} margin="0" />
                                    </Box>
                                );
                            })}
                        </Suspense>
                    )}

                    <BrandCategorySection />

                    <Box
                        sx={{
                            py: 4,
                        }}
                    >
                        {recentActivityItems && recentActivityItems.length > 0 && (
                            <>
                                <TitleContainer>
                                    <Typography variant="h2">{t("recentActivity.title")}</Typography>
                                </TitleContainer>
                                <Box>
                                    <Grid container>
                                        {recentActivityItems.map((row, index) => (
                                            <Grid key={row.created_at} item xs={8}>
                                                <LogItem
                                                    key={index}
                                                    backgroundColor={anyPalette.background}
                                                    index={index}
                                                    logItem={row}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </>
                        )}
                    </Box>
                </ContentContainer>
            </PageContainer>

            {/* footer and recente activities */}
            <Box>
                <Box
                    sx={{
                        backgroundColor: hexToRgba(theme.palette.card.dark, 0.4),
                    }}
                >
                    <Container
                        sx={{
                            paddingY: 3,
                            paddingX: 0,
                        }}
                    >
                        <Box
                            sx={{
                                paddingBottom: 3,
                            }}
                        >
                            <Typography variant="h2">{t("home.title")}</Typography>
                            <Typography
                                sx={{
                                    marginTop: 2,
                                }}
                            >
                                {t("home.description")}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: 4,
                            }}
                        >
                            <InfoCard
                                sx={{
                                    width: "33%",
                                }}
                                title={t("home.safeData")}
                                description={t("home.safeDataDescription")}
                                icon={solid("shield-check")}
                            />
                            <InfoCard
                                sx={{
                                    marginY: 0,
                                    marginX: 2,
                                    width: "33%",
                                }}
                                title={t("home.onePlace")}
                                description={t("home.onePlaceDescription")}
                                icon={solid("arrow-pointer")}
                            />
                            <InfoCard
                                sx={{
                                    width: "33%",
                                }}
                                title={t("home.benefit")}
                                description={t("home.benefitDescription")}
                                icon={solid("tag")}
                            />
                        </Box>
                    </Container>
                </Box>
            </Box>
            <GPFooter />
        </RootContainer>
    );
}
