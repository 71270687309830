import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";
import { useArticlesByBrandId } from "../../service/ClubService";
import { City, UseMapsUntoldCities } from "../../service/api/MapsUntoldService";
import {
    Brand,
    ContentComponent,
    ContentComponentType,
    hasBrandEnvironment,
    useBrandById,
} from "../../service/api/brandService";
import { useCouponByIds } from "../../service/api/couponService";
import { BrandConfig } from "../../service/api/schemeValidator";
import { useBrandMediaContentProvider } from "../../service/mediaContentService";
import { Carousel } from "../Carousel/Carousel";
import BsoCalendarEventAlert from "../Club/bso/BsoCalendarEventAlert";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import { DynamicContentComponentType } from "./BrandCard";
import { BrandCardContent, BrandCardContentSkeleton, contentMediaHeight } from "./BrandCardContent";
import BrandCardMediaCarousel from "./BrandCardMediaCarousel";
import LinkDataPopup from "./LinkDataPopup";
import { useGetBsoParent } from "../../service/api/bsoService";

type Props = {
    margin?: string;
};

export default function UniqueBrandCard(brand: Props & Brand) {
    const [linkAccountPopupOpen, setLinkAccountPopupOpen] = useState(false);

    const theme = useTheme();

    const navigate = useNavigate();
    const isLargeScreen = useMediaQuery("(min-width:900px)");
    const isDesktop = useMediaQuery("(min-width:600px)");

    const { data: brandConfig } = useBrandConfigProvider().getByID(brand.id);

    const { data: brandEntity, isLoading: brandLoading } = useBrandById(brand.id, {
        enabled: hasBrandEnvironment(brandConfig),
    });

    const { GetBrandMediaContent } = useBrandMediaContentProvider();

    const data = GetBrandMediaContent({
        brandId: brand.id ?? "",
        directory: "icon",
        metadata: {
            lang: ["nl", "en"],
            type: ["image"],
        },
    });

    const handleOnClick = () => {
        if (hasBrandEnvironment(brandConfig)) {
            if (!brandLoading && !brandEntity?.connected) {
                // user is not connected to HDB
                setLinkAccountPopupOpen(true);
                return;
            }

            navigate("/club/" + brand.id, { state: { from: brand.id } });
            return;
        }

        navigate("/brand/" + brand.id);
    };

    if (!brandConfig || brandLoading) {
        return <UniqueBrandCardSkeleton />;
    }

    return (
        <Suspense fallback={<UniqueBrandCardSkeleton />}>
            <Box mx={brand.margin ?? -2} mb={!isLargeScreen ? 8 : 1} sx={{ position: "relative" }}>
                {/* logo */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "-50px",
                    }}
                >
                    <div
                        style={{
                            borderBottomLeftRadius: "50%",
                            borderBottomRightRadius: "50%",
                            border: `solid ${brand.id === "19e9244c-34e4-49d4-8b92-211f552142aa" ? 0 : "8px"} #1C1C2F`,
                            background: "#1C1C2F",
                            borderTop: "0",
                            zIndex: 3,
                        }}
                    >
                        <ImageWithFallback
                            src={data?.Url}
                            width="200px"
                            height="200px"
                            style={{
                                objectFit: "contain",
                                background: brandConfig.theme?.uniqueBrandCard?.logo?.backgroundColorLogo ?? "#1C1C2F",
                                borderRadius: "100%",
                            }}
                            containerSx={{
                                maxWidth: "100px",
                                maxHeight: "100px",
                                zIndex: 2,
                                background: "#1C1C2F",
                                borderRadius: "100%",
                            }}
                            type="circular"
                        />
                    </div>
                </Box>
                <Paper
                    sx={{
                        position: "relative",
                        p: 2,
                        borderRadius: isDesktop ? "8px" : 0,
                        backgroundColor: brandConfig.theme?.palette.backgroundColor ?? theme.palette.primary.main,
                        backgroundImage: brandConfig.theme?.palette.gradient
                            ? `radial-gradient(circle at top left, ${
                                  brandConfig.theme?.palette.gradient?.split("(")[1]
                              }`
                            : `url(${brandConfig.theme?.uniqueBrandCard?.bgImg})`,
                        backgroundPosition: "center",
                    }}
                >
                    <div style={{ cursor: "pointer" }} onClick={handleOnClick}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between ",
                                alignItems: "center",
                                cursor: "pointer",
                                mt: 6,
                            }}
                        >
                            <Typography variant="h1" color={brandConfig?.theme?.uniqueBrandCard?.palette?.textColor}>
                                {brand.name}
                            </Typography>
                            <FontAwesomeIcon
                                icon={regular("chevron-right")}
                                size="2x"
                                color={brandConfig?.theme?.uniqueBrandCard?.palette?.textColor}
                            />
                        </Box>

                        <Typography color={brandConfig?.theme?.uniqueBrandCard?.palette?.textColor} mt={1}>
                            {brand.description}
                        </Typography>
                    </div>

                    <Grid
                        container
                        justifyContent="space-between"
                        sx={{
                            [theme.breakpoints.up("md")]: {
                                mt: 2,
                            },
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                width: "100%",
                            }}
                        >
                            {brandConfig.theme?.uniqueBrandCard?.sandbox ? (
                                <Box
                                    sx={{
                                        [theme.breakpoints.up("md")]: {
                                            mx: 0,
                                            mr: 2,
                                        },
                                        display: "flex",
                                        alignItems: "center",
                                        height: "100%",
                                        mb: 2,
                                        mx: -2,
                                    }}
                                >
                                    <UniqueBrandCardSandbox {...brandConfig} id={brand.id} />
                                </Box>
                            ) : (
                                <Box mb={3} />
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="h3" color={brandConfig?.theme?.uniqueBrandCard?.palette?.textColor}>
                                {brand.slogan}
                            </Typography>

                            <Box
                                sx={{
                                    position: "relative",
                                    [theme.breakpoints.down("md")]: {
                                        height: `calc(${contentMediaHeight} + 30px)`,
                                    },
                                    height: "auto",
                                    mx: -2,
                                }}
                            >
                                <Box
                                    my={2}
                                    sx={{
                                        [theme.breakpoints.up("md")]: {
                                            position: "static",
                                            paddingRight: 2,
                                        },
                                        position: "absolute",
                                        top: 0,
                                        width: `100%`,
                                    }}
                                >
                                    <Carousel>
                                        <UniqueBrandCardContent
                                            brand_config={brandConfig}
                                            content_components={
                                                brand.content_components?.sort((a) => (a.highlighted ? -1 : 1)) ?? []
                                            }
                                            brand_id={brand.id}
                                        />
                                    </Carousel>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            {brandEntity && (
                <LinkDataPopup
                    open={linkAccountPopupOpen}
                    brand={brandEntity}
                    handleCompleted={() => {
                        setLinkAccountPopupOpen(false);
                        navigate("/club/" + brand.id, { state: { from: brand.id } });
                    }}
                    toggleOpen={() => {
                        setLinkAccountPopupOpen((prev) => !prev);
                    }}
                    handleMissingData={() => {
                        setLinkAccountPopupOpen(false);
                    }}
                />
            )}
        </Suspense>
    );
}

function UniqueBrandCardSandbox({ id, theme }: Pick<Brand, "id"> & BrandConfig) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const muiTheme = useTheme();

    if (theme?.uniqueBrandCard?.sandbox?.type === "mediaCarousel") {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <BrandCardMediaCarousel id={id} borderRadius="0" />
            </Box>
        );
    }

    if (theme?.uniqueBrandCard?.sandbox?.type === "bsoCalendarEventAlert") {
        return (
            <Box
                sx={{
                    [muiTheme.breakpoints.down("md")]: {
                        flex: 1,
                        display: "flex",
                        width: "100%",
                        m: 2,
                    },
                }}
                onClick={() => navigate("/club/" + id)}
            >
                <BsoCalendarEventAlert brandId={id} showCalendarDrawer={false} />
            </Box>
        );
    }

    return (
        <Box sx={{ position: "relative" }}>
            {theme?.uniqueBrandCard?.sandbox?.cta?.text && (
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor: theme.palette.primary,
                            color: theme.palette.textColor,
                            "&:hover": {
                                backgroundColor: theme.palette.primary,
                            },
                        }}
                        onClick={() => navigate("/brand/" + id)}
                    >
                        {t(theme?.uniqueBrandCard?.sandbox?.cta?.text)}
                    </Button>
                </Box>
            )}
            {theme?.uniqueBrandCard?.sandbox?.bgImg && (
                <div style={{ margin: "0 auto" }}>
                    <img
                        src={theme?.uniqueBrandCard.sandbox.bgImg}
                        alt="sanbox-img"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                            zIndex: 0,
                            ...(theme?.uniqueBrandCard?.sandbox?.style as {}),
                        }}
                    />
                </div>
            )}
        </Box>
    );
}

function UniqueBrandCardContent(props: {
    brand_id: string;
    content_components?: ContentComponent[];
    brand_config: BrandConfig;
}) {
    const [dynamicContentComponent, setdynamicContentComponent] = useState<DynamicContentComponentType[]>([]);

    const theme = useTheme();
    const navigate = useNavigate();

    const { data: couponsByContentComponents, isLoading: couponsByContentComponentsLoading } = useCouponByIds(
        props.content_components?.filter((content) => content.type === "coupon").map((content) => content.content_id) ??
            [],
        {
            suspense: true,
        },
    );

    const { data: brandEntity, isLoading: brandLoading } = useBrandById(props.brand_id, {
        enabled: hasBrandEnvironment(props.brand_config),
        suspense: true,
    });

    const { data: brandArticles, isLoading: brandArticlesLoading } = useArticlesByBrandId(props.brand_id, {
        suspense: true,
    });

    const { data: cities, isLoading: isCitiesLoading } = UseMapsUntoldCities({
        suspense: true,
        enabled: props.brand_id === "627b00af-fbd4-4cac-baf8-25a91533d9c4",
        select: (cities: City[]) => {
            const cityIds = props.content_components
                ?.filter((content) => content.type === "city")
                .map((content) => content.content_id);
            return cities?.filter((city) => {
                return cityIds?.includes(city.key);
            });
        },
    });

    const { refetch: refetchBsoParent } = useGetBsoParent({ enabled: false });

    const contentComponentEntitiesLoading = couponsByContentComponentsLoading || brandArticlesLoading;

    const hasContent = (): boolean => {
        if (contentComponentEntitiesLoading) return false;

        if (couponsByContentComponents && couponsByContentComponents?.length > 0) return true;

        if (brandArticles && brandArticles.length > 0) return true;

        return false;
    };

    useEffect(() => {
        // neaky refetch to always get latest BSO parent
        if (props.brand_config.type === "bso") {
            refetchBsoParent();
        }
    }, []);

    useEffect(() => {
        if (contentComponentEntitiesLoading) return;
        let contentComponentWithEntities: DynamicContentComponentType[] = [];

        if (props.content_components && couponsByContentComponents)
            contentComponentWithEntities = props.content_components.map((content, index) => {
                return {
                    contentComponent: content,
                    commonAttributes: {
                        image: couponsByContentComponents[index]?.image,
                        name: couponsByContentComponents[index]?.name,
                    },
                    entity: couponsByContentComponents[index],
                };
            });

        // concat articles
        if (brandArticles) {
            const sorted = brandArticles.sort((a, b) => b.created_at - a.created_at).slice(0, 3);
            contentComponentWithEntities = contentComponentWithEntities.concat(
                sorted.map((article) => {
                    return {
                        contentComponent: {
                            type: "article",
                            highlighted: false,
                            content_id: article.id,
                        },
                        commonAttributes: {
                            image: article.banner_image_url,
                            name: article.header,
                        },
                        entity: article,
                    };
                }),
            );
        }

        setdynamicContentComponent(contentComponentWithEntities);
    }, [couponsByContentComponentsLoading, brandArticlesLoading]);

    function handleClickContent(type: ContentComponentType, entityId: number | string) {
        if (type === "coupon") {
            navigate("/coupon/" + entityId);
            return;
        }
        if (type === "article") {
            if (hasBrandEnvironment(props.brand_config)) {
                if (!brandLoading) {
                    if (!brandEntity?.connected) {
                        // user is not connected to HDB, go to the anyid article-environment
                        navigate(`/nieuws/${props.brand_id}/artikel/${entityId}`);
                    } else {
                        // user is connected to HDB, go to the club article-environment
                        navigate(`/club/nieuws/${props.brand_id}/artikel/${entityId}`);
                    }
                }
            }
        }
    }
    if (props.brand_id === "627b00af-fbd4-4cac-baf8-25a91533d9c4") {
        return (
            <>
                {!isCitiesLoading && cities ? (
                    cities?.map((city, index) => {
                        if (!props.content_components || !props.content_components[index]) return <div></div>;

                        return (
                            <BrandCardContent
                                key={city.key}
                                {...props}
                                content_component={props.content_components[index]}
                                title={city.name}
                                imgUrl={`${city.imageUrl}`}
                                entity={city}
                                handleClick={() =>
                                    navigate("/brand/627b00af-fbd4-4cac-baf8-25a91533d9c4/trip?city=" + city?.name)
                                }
                                showIcon={false}
                                margin={
                                    index === cities.length - 1
                                        ? `0 ${theme.spacing(2)} 0 ${theme.spacing(2)}`
                                        : undefined
                                }
                            />
                        );
                    })
                ) : (
                    <Skeleton></Skeleton>
                )}
            </>
        );
    }

    if (props.brand_config.type === "bso") {
        return (
            <>
                {!hasContent() ? (
                    <BrandCardContentSkeleton />
                ) : (
                    dynamicContentComponent?.map((contentComponent, index) => {
                        return (
                            <Box key={contentComponent.contentComponent.content_id}>
                                <BrandCardContent
                                    {...props}
                                    bgColor={"#ffffff"}
                                    textSxProps={{ color: props.brand_config.theme?.palette.primary }}
                                    content_component={contentComponent.contentComponent}
                                    title={contentComponent.commonAttributes.name}
                                    imgUrl={contentComponent.commonAttributes.image}
                                    entity={contentComponent.entity}
                                    handleClick={() =>
                                        handleClickContent(
                                            contentComponent.contentComponent.type,
                                            contentComponent.contentComponent.content_id,
                                        )
                                    }
                                    margin={
                                        index === dynamicContentComponent.length - 1
                                            ? `0 ${theme.spacing(2)}`
                                            : undefined
                                    }
                                />
                            </Box>
                        );
                    })
                )}
            </>
        );
    }

    return (
        <>
            {!hasContent() ? (
                <BrandCardContentSkeleton />
            ) : (
                dynamicContentComponent?.map((contentComponent, index) => {
                    return (
                        <Box key={contentComponent.contentComponent.content_id}>
                            <BrandCardContent
                                {...props}
                                content_component={contentComponent.contentComponent}
                                title={contentComponent.commonAttributes.name}
                                imgUrl={contentComponent.commonAttributes.image}
                                entity={contentComponent.entity}
                                handleClick={() =>
                                    handleClickContent(
                                        contentComponent.contentComponent.type,
                                        contentComponent.contentComponent.content_id,
                                    )
                                }
                                margin={
                                    index === dynamicContentComponent.length - 1 ? `0 ${theme.spacing(2)}` : undefined
                                }
                            />
                        </Box>
                    );
                })
            )}
        </>
    );
}

function UniqueBrandCardSkeleton() {
    const isDesktop = useMediaQuery("(min-width:600px)");

    return (
        <Skeleton
            height={isDesktop ? 494 : 680}
            sx={{
                transform: "none",
                transformOrigin: "0",
                mb: 2,
            }}
        ></Skeleton>
    );
}
