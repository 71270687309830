import { useEffect } from "react";
import { env } from "../../env.mjs";

declare global {
  interface Window {
    analytics: any;
  }
}
const PageAnalytics = () => {
  useEffect(() => {
    // env.devEnv === "production" && window.analytics.page();
  }, []);

  return <></>;
};
export default PageAnalytics;
