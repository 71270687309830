import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import "./Carousel.css";
import { Desktop } from "../../gp-components/src";
import { hexToRgba } from "../../service/theme/themeService";

type Props = {
    children: ReactNode | JSX.Element | JSX.Element[] | undefined;
    style?: CSSProperties;
};

export function Carousel({ children, style }: Props) {
    const containerRef = useRef(null);
    const theme = useTheme();
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(true);

    const handleScrollDirection = (direction: "left" | "right") => {
        const containerEl = containerRef.current as any;
        let width = containerEl.clientWidth;

        if (direction === "left") width = width * -1;

        containerEl.scrollBy({
            left: width,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const ref = containerRef.current as any;

        if (!ref) return;
        setIsAtEnd(ref.scrollWidth === ref.offsetWidth);
    }, []);

    function handleScroll(event: any) {
        const element = event.target;
        const isAtEnd = element.scrollLeft + element.offsetWidth === element.scrollWidth;

        setIsAtEnd(isAtEnd);
        setIsAtStart(element.scrollLeft === 0);
    }

    return (
        <Box sx={{ position: "relative" }}>
            {!isAtStart && (
                <Desktop>
                    <ButtonBase
                        sx={{
                            position: "absolute",
                            left: 0,
                            backgroundColor: hexToRgba("#000000", 0.6),
                            zIndex: 1,
                            height: "100%",
                            borderRadius: `0px ${theme.shape.borderRadius}px`,
                        }}
                        onClick={() => {
                            handleScrollDirection("left");
                        }}
                    >
                        <Box sx={{ width: "50px" }}>
                            <FontAwesomeIcon icon={solid("chevron-left")} />
                        </Box>
                    </ButtonBase>
                </Desktop>
            )}
            {!isAtEnd && (
                <Desktop>
                    <ButtonBase
                        sx={{
                            position: "absolute",
                            right: 0,
                            backgroundColor: hexToRgba("#000000", 0.6),
                            zIndex: 1,
                            height: "100%",
                            borderRadius: `${theme.shape.borderRadius}px 0px`,
                        }}
                        onClick={() => {
                            handleScrollDirection("right");
                        }}
                    >
                        <Box sx={{ width: "50px" }}>
                            <FontAwesomeIcon icon={solid("chevron-right")} />
                        </Box>
                    </ButtonBase>
                </Desktop>
            )}
            <Box
                sx={{ display: "flex", overflowX: "auto", position: "relative", ...style }}
                onScroll={handleScroll}
                ref={containerRef}
                className="hidden-scroll"
            >
                {children}
            </Box>
        </Box>
    );
}
