import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
    weekStart: 1,
});

export function AppLocalizationProvider({ children }: { children: ReactNode }) {
    const { i18n } = useTranslation();

    const [locale, setLocale] = useState(i18n.language.split("-")[0]); // Initial locale

    useEffect(() => {
        const handleLanguageChange = (lng: string) => {
            setLocale(lng);
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateFormats={{
                weekday: "Monday",
            }}
            adapterLocale={locale}
        >
            {children}
        </LocalizationProvider>
    );
}

export function getLocaleDate(utcString: string, locale: string) {
    switch (locale) {
        case "en":
            return enGB(utcString);
        case "nl":
            return nlNL(utcString);
        default:
            return nlNL(utcString);
    }
}

const format: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
};

function nlNL(date: string) {
    return new Date(date).toLocaleDateString("nl-NL", format).replaceAll("/", "-");
}

function enGB(date: string) {
    return new Date(date).toLocaleDateString("en-US", format).replaceAll("/", "-");
}

export function getUniqueTimeStamp() {
    const now = new Date();
    const year = now.getFullYear();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const diff = now.getTime() - startOfYear.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const hourOfDay = now.getHours();

    return `${dayOfYear}-${hourOfDay}-${year}`;
}

export function formatUnixTimestamp(unixTimestamp: number, locale?: string, calendarFormat = false): string {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds

    const components = ["year", "month", "day", "hour", "minute"] as const;

    const formatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        hour12: false,
        minute: "numeric",
    });
    const formattedParts = formatter.formatToParts(date);
    const formattedDate: any = {};

    for (const component of components) {
        const part = formattedParts.find((part) => part.type === component);
        formattedDate[component] = part?.value;
    }

    return !calendarFormat
        ? `${formattedDate.day} ${formattedDate.month}, ${(formattedDate.hour as string).padStart(2, "0")}:${
              formattedDate.minute
          }`
        : `${formattedDate.month} ${formattedDate.year}`;
}
export function formatUnixTimestampSimple(unixTimestamp: number, locale?: string) {
    const date = new Date(unixTimestamp * 1000); // Convert Unix timestamp to milliseconds

    const components = ["year", "month", "day", "hour", "minute"] as const;

    const formatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        hour12: false,
        minute: "numeric",
    });
    const formattedParts = formatter.formatToParts(date);
    const formattedDate: Record<string, string> = {};

    for (const component of components) {
        const part = formattedParts.find((part) => part.type === component);
        if (part?.value) formattedDate[component] = part.value;
    }

    return {
        day: (formattedDate.day as string).padStart(2, "0"),
        month: formattedDate.month,
        year: formattedDate.year,
        hour: (formattedDate.hour as string).padStart(2, "0"),
        minute: formattedDate.minute,
    };
}

export function isExactlyOneDayInterval(date1Unix: number, date2Unix: number): boolean {
    const date1 = new Date(date1Unix * 1000);
    const date2 = new Date(date2Unix * 1000);

    // Calculate the difference in days
    const differenceInDays = Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);

    // Check if the difference is exactly 1
    return differenceInDays === 1;
}

// compare unix by year month and day
export function areSameDate(unixTimestamp1: number, unixTimestamp2: number) {
    const date1 = new Date(unixTimestamp1 * 1000);
    const date2 = new Date(unixTimestamp2 * 1000);

    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    return year1 === year2 && month1 === month2 && day1 === day2;
}
``;
