import { DialogProps } from "@mui/material";
import { ReactNode } from "react";
import { AnySwipableDrawer } from "../Drawer/AnySwipableDrawer";
import { AnyDialog } from "./AnyDialog";

interface Props {
    open: boolean;
    handleClose: () => void;
    onOpen: () => void;
    title?: string;
    titleIcon?: React.ReactNode;
    children?: React.ReactNode;
    header?: React.ReactNode;
    primaryButton?: React.ReactNode;
    secondaryButton?: React.ReactNode;
    dialogProps?: Omit<DialogProps, "open">;
    customHandleComponent?: ReactNode;
    handleAlignment?: string;
}

export function DynamicPopup(props: Props) {
    const {
        open,
        handleClose,
        onOpen,
        titleIcon,
        header,
        primaryButton,
        secondaryButton,
        dialogProps,
        customHandleComponent,
        handleAlignment,
        ...other
    } = props;

    return (
        <>
            <AnyDialog
                titleIcon={titleIcon}
                header={header}
                handleClose={handleClose}
                open={open}
                primaryButton={primaryButton}
                secondaryButton={secondaryButton}
                {...dialogProps}
                sx={{ display: { xs: "none", sm: "block" }, ...dialogProps?.sx }}
                {...other}
            >
                {props.children}
            </AnyDialog>
            <AnySwipableDrawer
                titleIcon={titleIcon}
                disableSwipeToOpen
                open={open}
                onClose={handleClose}
                onOpen={onOpen}
                header={header}
                sx={{ display: { xs: "block", sm: "none" }, ...dialogProps?.sx }}
                anchor="bottom"
                primaryButton={primaryButton}
                secondaryButton={secondaryButton}
                customHandleComponent={customHandleComponent}
                handleAlignment={handleAlignment}
                {...other}
            >
                {props.children}
            </AnySwipableDrawer>
        </>
    );
}
