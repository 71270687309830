//TODO: Move to service
export type Coupon = {
    id: string;
    name: string;
    description: string;
    created_at: string;
    expires_at: string;
    required_attributes: string[];
    code: string;
    status: CouponStatus;
    brand_id: string;
    image: string;
    quiz_config_id?: string;
    multi_use?: boolean;
    type: "online" | "store" | "birthday" | "giveaway";
    tag?: string;
};

export enum CouponStatus {
    New = "0",
    Available = "1",
    Used = "2",
}
