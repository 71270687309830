import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { anyPalette } from "../../../../theme";

export const AnySwitch = styled(Switch)(({ theme, sx }) => ({
    width: "44px",
    height: "24px",
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: "3px",
        boxShadow: "none",
        "&.Mui-disabled": {
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.sandDark.main,
            },
        },
        "&.Mui-checked": {
            color: theme.palette.white.main,
            "&.Mui-disabled": {
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: theme.palette.sandDark.main,
                },
            },
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.primary.main,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "unset",
        width: "18px",
        height: "18px",
        borderRadius: "20px",
        backgroundColor: "#ffffff",
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: "20px",
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.card.dark : anyPalette.sandMedium,
        boxSizing: "border-box",
    },
}));
