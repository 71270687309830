import { Box, BoxProps } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";

// The container for the description beneath the title

type Props = BoxProps & {
  hasDescription?: boolean;
  theme: Theme;
};

const TitleContainer = styled(Box)(({ hasDescription = true, theme }: Props) => {
  return {
    // desktop
    [theme.breakpoints.up("sm")]: {
      marginBottom: hasDescription ? theme.spacing(2) : theme.spacing(4),
    },
    // mobile
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  };
});

export default TitleContainer;
