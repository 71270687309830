import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import DescriptionContainer from "../../components/Container/DescriptionContainer";
import FullScreenContainerCenter from "../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../components/Container/FullScreenRootContainer";
import TitleContainer from "../../components/Container/TitleContainer";
import { AnyButton } from "../../gp-components/src";
import SafeAreaContainer from "../../components/Container/SafeAreaContainer";
import { BackButton } from "../../components/nav/BackButton";

type Props = {};

export function NoConnectionPage(props: Props) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <SafeAreaContainer
            sx={{
                position: "relative",
                ["@media (min-width:600px)"]: {
                    maxWidth: "none",
                },
            }}
        >
            <FullScreenRootContainer>
                <title>{t("noConnection.tabTitle")}</title>
                <div
                    style={{
                        position: "absolute",
                        top: "8px",
                        left: "16px",
                    }}
                >
                    {/* the page will go back in history and reload */}
                    <BackButton replace reload />
                </div>
                <FullScreenContainerCenter
                    maxWidth="sm"
                    sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: 0 }}
                >
                    <TitleContainer>
                        <Typography variant="h1">{t("noConnection.title")}</Typography>
                    </TitleContainer>
                    <DescriptionContainer>
                        <Typography>{t("noConnection.description")}</Typography>
                    </DescriptionContainer>
                    <Card
                        sx={{
                            padding: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{
                                height: "112px",
                                width: "112px",
                                marginY: 2,
                                marginX: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: "6.25rem",
                                    height: "6.25rem",
                                    backgroundColor: theme.palette.card.light,
                                    margin: "auto",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={solid("wifi-exclamation")}
                                    style={{ color: theme.palette.icon.main, height: "50px" }}
                                />
                            </Avatar>
                        </Box>
                        <AnyButton
                            onClick={() => {
                                window.location.reload();
                            }}
                            sx={{ marginTop: 2, width: " 100%" }}
                            anytype="green"
                        >
                            <Typography variant="button1">{t("noConnection.retryButton")}</Typography>
                        </AnyButton>
                    </Card>
                </FullScreenContainerCenter>
                <PageAnalytics />
            </FullScreenRootContainer>
        </SafeAreaContainer>
    );
}
