import { useMediaQuery } from "@mui/material";
import { AnyLinearProgress } from "../AnyLinearProgress";
import { useCapacitorProvider } from "../../../../../service/capacitor/CapacitorProvider";

type Props = {
  visible: boolean;
  noMargin?: boolean;
};

export default function LinearLoadingTop({ visible, noMargin }: Props) {
  const isDesktop = useMediaQuery("(min-width:600px)");
  const { insets } = useCapacitorProvider();

  let margin: string = "0px";

  if (isDesktop && !noMargin) {
    margin = "67px";
  } else if (insets?.top && !noMargin) {
    margin = insets.top - 4 + "px";
  }

  if (visible) {
    return (
      <AnyLinearProgress sx={{ position: "absolute", width: "100%", top: margin, borderRadius: 0 }} color="primary" />
    );
  }
  return <></>;
}
