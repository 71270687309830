import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, CardActionArea, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Desktop, Mobile } from "../..";
import { anyPalette } from "../../../../../theme";
import VipIcon from "../../../../icons/VipIcon";

type Props = {
  title: string;
  icon?: any;
  color?: string;
  href?: string;
  index?: number;
  textColor?: string;
  exclusive?: boolean;
  variant?: "card" | "listItem" | "dynamic";
  notificationCount?: number;
  onClick?: () => void;
};

const NavigationListItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(2),
  boxShadow: "none",

  borderRadius: theme.shape.borderRadius,

  flex: "none",
  order: 0,
  flexGrow: 0,
}));

const NavigationCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4),
  textAlign: "center",
  boxShadow: "none",

  background: theme.palette.white.main,
  borderRadius: theme.shape.borderRadius,
  justifyContent: "space-between",

  flex: "none",
  order: 0,
  flexGrow: 0,
}));

export default function GPNavigationCard(props: Props) {
  const {
    title,
    icon,
    color,
    href,
    index = 0,
    variant = "dynamic",
    textColor,
    exclusive = false,
    notificationCount = 0,
    onClick,
  } = props;
  const navigate = useNavigate();
  const theme = useTheme();

  function dynamicNavigate() {
    if (!href) return;
    const firstCharacter = Array.from(href)[0];

    if (firstCharacter === "/") {
      navigate(href);
      return;
    }

    window.location.href = href;
  }

  function renderNotificationDot() {
    return (
      <Box
        sx={{
          width: "30px",
          height: "30px",
          backgroundColor: anyPalette.softRed,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        <Typography color="white" variant="h3">
          {notificationCount > 99 ? 99 : notificationCount}
        </Typography>
      </Box>
    );
  }

  function ListItem() {
    return (
      <NavigationListItem
        borderRadius={1}
        sx={{ backgroundColor: theme.palette.card.main, height: icon ? "80px" : "56px" }}
        onClick={onClick || dynamicNavigate}
      >
        {icon && (
          <Avatar
            sx={{
              width: "53px",
              height: "53px",
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.card.light : color,
              marginRight: 1,
            }}
          >
            {icon}
          </Avatar>
        )}

        <Typography sx={{ flexGrow: 1, color: textColor }} variant={icon ? "h3" : "body1"}>
          {title}
        </Typography>

        {!icon && <FontAwesomeIcon icon={solid("chevron-right")} size="1x" />}
        {exclusive && <VipIcon width={37} fill={theme.palette.text.primary} />}
        {notificationCount > 0 && renderNotificationDot()}
      </NavigationListItem>
    );
  }

  function Card() {
    return (
      <NavigationCard
        sx={{
          backgroundColor: theme.palette.card.main,
          height: icon && "200px",
          alignItems: icon && "center",
          position: "relative",
        }}
        onClick={onClick || dynamicNavigate}
      >
        {exclusive && (
          <VipIcon
            width={37}
            fill={theme.palette.text.primary}
            style={{ position: "absolute", right: theme.spacing(2), top: theme.spacing(3) }}
          />
        )}
        <Box sx={{ position: "absolute", top: "-10px", right: "-10px" }}>
          {notificationCount > 0 && renderNotificationDot()}
        </Box>
        {icon && (
          <Avatar
            sx={{
              width: "53px",
              height: "53px",
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.card.light : color,
            }}
          >
            {icon}
          </Avatar>
        )}
        <Typography sx={{ color: textColor }} variant={icon ? "h3" : "body1"}>
          {title}
        </Typography>
      </NavigationCard>
    );
  }

  return (
    <CardActionArea sx={{ borderRadius: theme.shape.borderRadius + "px" }}>
      {variant === "dynamic" && (
        <>
          <Mobile>
            <ListItem />
          </Mobile>
          <Desktop>
            <Card />
          </Desktop>
        </>
      )}
      {variant === "listItem" && <ListItem />}
      {variant === "card" && <Card />}
    </CardActionArea>
  );
}
