import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, CardActionArea, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { anyPalette } from "../../gp-components/src/theme";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled, useTheme } from "@mui/material/styles";

type Props = {
  title: string;
  icon?: any;
  color?: string;
  href: string;
  nestedPathnames?: Array<string>;
};

const NavigationListItem = styled(CardActionArea)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: theme.spacing(1),
  boxShadow: "none",
  height: "52px",
  borderRadius: theme.shape.borderRadius,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));

export default function NavigationItem(props: Props) {
  const { title, icon, color, href, nestedPathnames = [] } = props;
  const navigate = useNavigate();
  const theme = useTheme();

  function dynamicNavigate() {
    const firstCharacter = Array.from(href)[0];

    if (firstCharacter === "/") {
      navigate(href);
      return;
    }

    window.location.href = href;
  }

  const parentPath = window.location.pathname.split("/").slice(0, 2).join("/");
  const activePath = window.location.pathname === href || nestedPathnames.includes(parentPath);

  return (
    <Box
      sx={{ borderBottom: !activePath && theme.palette.mode === "light" ? "1px solid " + anyPalette.sandMedium : "" }}
    >
      <NavigationListItem
        sx={{
          backgroundColor: activePath ? theme.palette.card.dark : "",
        }}
        onClick={dynamicNavigate}
      >
        {icon && (
          <Avatar
            sx={{
              width: "36px",
              height: "36px",
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.card.main : color,
              marginRight: 1,
            }}
          >
            {icon}
          </Avatar>
        )}
        <Typography sx={{ flexGrow: 1 }} variant={icon ? "h4" : "body1"}>
          {title}
        </Typography>
        {!icon && <FontAwesomeIcon icon={solid("chevron-right")} />}
      </NavigationListItem>
    </Box>
  );
}
