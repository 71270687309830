import { Clipboard } from "@capacitor/clipboard";
import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import { faArrowRight, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import { brandNavigationRouter } from "../../components/Brand/ActionRouter";
import ContentComponentAvatarIcon from "../../components/Brand/ContentComponentAvatarIcon";
import LinkDataPopup, { LinkDataPopupBrandProp } from "../../components/Brand/LinkDataPopup";
import ContentContainer from "../../components/Container/ContentContainer";
import PageContainer from "../../components/Container/PageContainer";
import RootContainer from "../../components/Container/RootContainer";
import { ImageWithFallback } from "../../components/Image/ImageWithFallback";
import NotificationPermissionPopup from "../../components/Notification/NotificationPermissionPopup";
import { Coupon, CouponStatus } from "../../components/coupon/CouponCard";
import RewardQuizIntroPopup from "../../components/coupon/RewardQuizIntroPopup";
import { BackButton } from "../../components/nav/BackButton";
import { SideNavigation } from "../../components/nav/SideNavigation";
import { TopNav } from "../../components/nav/TopNavigation";
import { Desktop, GPFooter, LinearLoadingTop } from "../../gp-components/src";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { logEvent, logEventWithBrandId, logEventWithCouponId } from "../../service/AnalyticsService";
import { useBrandById, useNavigateToBrand } from "../../service/api/brandService";
import { useActivateCoupon, useCouponById, useViewCoupon } from "../../service/api/couponService";
import { safeAddQueryParameter } from "../../utils/SafeAddQueryParameter";

/**
 * Main AnyId coupon card
 *
 */
export default function RewardDetailPage() {
    const [codeCopied, setCodeCopied] = useState(false);
    const [quizIntroIsOpen, setQuizIntroIsOpen] = useState(false);
    const [linkAccountPopupOpen, setLinkAccountPopupOpen] = useState(false);
    const [userIsConnected, setUserIsConnected] = useState(false);
    const [notConnectedBrand, setNotConnectedBrand] = useState<LinkDataPopupBrandProp>();
    const [navigateIsLoading, setNavigateIsLoading] = useState(false);
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);
    const [navigateError, setNavigateError] = useState<AlertError | undefined>(undefined);
    const [notificationPermissionIsOpen, setNotificationPermissionIsOpen] = useState(false);

    const params = useParams();
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const {
        data: coupon,
        status: getCouponStatus,
        refetch: couponRefetch,
    } = useCouponById(params.id, {
        suspense: true,
        onError: (e: any) => {
            setAlertError({
                id: "coupon",
                message: t("couponDetail.error"),
                code: e.response.status,
                icon: "retry",
                handleAction: couponRefetch,
            });
        },
    });

    const { data: brand, refetch: brandRefetch } = useBrandById(coupon?.brand_id, {
        suspense: true,
        onError: (e: any) => {
            setAlertError({
                id: "brand",
                message: t("brandDetail.getBrandError"),
                code: e.response.status,
                icon: "retry",
                handleAction: brandRefetch,
            });
        },
    });

    const { mutate: viewCoupon } = useViewCoupon(params.id, {
        onSuccess: () => {
            logEventWithCouponId("coupon_view", params.id!);
        },
    });
    const { mutate: activateCoupon, isLoading: couponIsActivating } = useActivateCoupon(params.id, coupon?.brand_id);

    const { navigateToBrand } = useNavigateToBrand(
        coupon?.brand_id,
        getRedirectUrl(),
        {
            onSuccess: (result) => {
                if (coupon) {
                    logEventWithBrandId("login_from_anyid", coupon.id);
                } else {
                    logEvent("login_from_anyid");
                }

                let redirectUrlWithParams = result.data.redirect_url;
                if (coupon?.code) {
                    redirectUrlWithParams = safeAddQueryParameter(redirectUrlWithParams, "coupon=" + coupon.code);
                }

                brandNavigationRouter(redirectUrlWithParams, coupon?.brand_id).then(() => {
                    setNavigateIsLoading(false);
                });
            },
            onError: (e: any) => {
                setNavigateIsLoading(false);
                setNavigateError({
                    id: "brandNavigate",
                    message: t("couponDetail.navigateError"),
                    code: e.response.status,
                });
            },
        },
        "navigate-brand",
        coupon?.id,
    );

    useEffect(() => {
        // @ts-ignore (coupon.status == "" is for backward compatibility
        if (coupon && (coupon.status === CouponStatus.New || coupon.status == "")) {
            viewCoupon({});
        }

        const permissionTimeout = setTimeout(async () => {
            if (!Capacitor.isNativePlatform() || coupon?.type === "giveaway") return;

            const permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === "prompt") {
                setNotificationPermissionIsOpen(true);
            }
        }, 2000);

        return () => {
            clearTimeout(permissionTimeout);
        };
    }, []);

    // navigate to birthday coupon page if coupon is not viewed and of type birthday
    useEffect(() => {
        if (coupon) {
            if (+coupon.status === 0 && coupon.type === "birthday") {
                navigate(`/coupon/${params.id}/birthday${location.search}`, { replace: true });
            }
        }
    }, [coupon]);

    useEffect(() => {
        if (!brand || !coupon) return;

        // is user connected to brand
        const userConnected = brand?.connected ?? false;

        if (!userConnected) {
            setNotConnectedBrand(brand);
        }

        setUserIsConnected(userConnected);
    }, [brand, coupon]);

    function getRedirectUrl() {
        const navigateToCheckoutFeature = false;
        if (!navigateToCheckoutFeature) {
            return encodeURIComponent(
                "/" + (coupon?.code === "FREEGLASS-U3CS7" ? "glazen/dare-to-drink-different-glas-33cl/" : "#"),
            );
        }

        if (!coupon?.code) return "/#";
        const anyIdCouponId = "952";

        return encodeURIComponent(`/cart.php?action=add&product_id=${anyIdCouponId}&couponcode=${coupon?.code}`);
    }

    function toggleAccountLink() {
        setLinkAccountPopupOpen((prev) => !prev);
    }

    function activateCouponClick() {
        if (coupon?.required_attributes?.filter((e) => e).length) {
            logEventWithCouponId("coupon_activate", coupon.id); // Teun: no idea when this is triggered
            setQuizIntroIsOpen(true);
        } else if (!userIsConnected) {
            setLinkAccountPopupOpen(true);
        } else {
            logEvent("coupon_unlock");
            activateCoupon({});
        }
    }

    function copyToClipboard(coupon: Coupon) {
        Clipboard.write({
            string: coupon.code,
        }).then(() => {
            if (coupon) {
                logEventWithCouponId("copy_to_clipboard", coupon.id);
            } else {
                logEvent("copy_to_clipboard");
            }
            setCodeCopied(true);
        });
    }

    return (
        <>
            <RootContainer>
                <title>{t("reward.tabTitle") + t("topNav.tabTitle")}</title>
                <LinearLoadingTop visible={couponIsActivating} />
                <TopNav loading={getCouponStatus === "loading" || navigateIsLoading} />
                <PageContainer>
                    <SideNavigation />
                    <ContentContainer>
                        <Desktop sx={{ display: "flex", width: "auto" }}>
                            <BackButton />
                        </Desktop>
                        {alertError && (
                            <Box mb={1}>
                                <AnyAlert error={alertError} handleClose={setAlertError} />
                            </Box>
                        )}

                        {getCouponStatus === "success" && coupon && (
                            <Box
                                p={2}
                                sx={{
                                    alignItems: { xs: "center", sm: "normal" },
                                    borderRadius: 1,
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    textAlign: "center",
                                    backgroundColor: theme.palette.card.main,
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        marginBottom: { xs: 2, sm: 0 },
                                        display: "grid",
                                        width: "100%",
                                    }}
                                >
                                    <ImageWithFallback
                                        src={coupon?.image}
                                        fallBackUrl={coupon?.image?.replace(/\.webp$/, ".jpg")}
                                        type="rounded"
                                        width="100%"
                                        height="353px"
                                        containerSx={{
                                            width: "100%",
                                            height: "353px",
                                            borderRadius: theme.shape.borderRadius + "px",
                                        }}
                                        alt={coupon?.name}
                                    />
                                    <Paper
                                        sx={{
                                            position: "absolute",
                                            left: theme.spacing(1.5),
                                            top: theme.spacing(1.5),
                                            borderRadius: "25px",
                                            padding: 1,
                                            display: "flex",
                                        }}
                                    >
                                        <BrandIcon width="32px" brandId={coupon?.brand_id} />

                                        <CouponDecorationIcon coupon={coupon} />
                                    </Paper>
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        textAlign: "left",
                                        marginLeft: { xs: "auto", sm: 2 },
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Box mb={2} sx={{ flexGrow: 1 }}>
                                        <Typography variant="h3">{coupon?.name}</Typography>
                                        <Typography sx={{ color: "sandMedium.main" }}>{coupon?.description}</Typography>
                                        {coupon?.code && (
                                            <Typography mb={1} variant="h3">
                                                {t("couponDetail.codePrefix") + coupon.code}
                                            </Typography>
                                        )}
                                    </Box>

                                    {coupon?.type === "store" ? (
                                        <StoreActionButtons
                                            coupon={coupon}
                                            callback={activateCouponClick}
                                            couponIsActivating={couponIsActivating}
                                        />
                                    ) : coupon?.type === "giveaway" ? (
                                        <GiveawayActionButtons
                                            coupon={coupon}
                                            callback={activateCouponClick}
                                            setNotificationPermissionIsOpen={setNotificationPermissionIsOpen}
                                            couponIsActivating={couponIsActivating}
                                        />
                                    ) : (
                                        <OnlineActionButtons
                                            coupon={coupon}
                                            useCouponCallback={() => {
                                                if (userIsConnected) {
                                                    setNavigateIsLoading(true);
                                                    navigateToBrand();
                                                } else {
                                                    toggleAccountLink();
                                                }
                                            }}
                                            activateCouponCallback={activateCouponClick}
                                            couponIsActivating={couponIsActivating}
                                            navigateIsLoading={navigateIsLoading}
                                            codeCopied={codeCopied}
                                            copyToClipboard={copyToClipboard}
                                        />
                                    )}
                                    {navigateError && (
                                        <Box mt={1}>
                                            <AnyAlert error={navigateError} handleClose={setNavigateError} />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </ContentContainer>
                </PageContainer>
                <GPFooter />
                <PageAnalytics />
            </RootContainer>
            {coupon && coupon.required_attributes?.length > 0 && (
                <RewardQuizIntroPopup
                    coupon={coupon}
                    open={quizIntroIsOpen}
                    toggleOpen={() => {
                        setQuizIntroIsOpen((prev) => !prev);
                    }}
                />
            )}
            {notConnectedBrand && (
                <LinkDataPopup
                    open={linkAccountPopupOpen}
                    toggleOpen={() => {
                        setLinkAccountPopupOpen((prev) => !prev);
                    }}
                    brand={notConnectedBrand}
                    handleMissingData={() => {
                        setLinkAccountPopupOpen(false);
                    }}
                    textVariant="coupon"
                    handleCompleted={() => {
                        setLinkAccountPopupOpen(false);
                        activateCouponClick();
                    }}
                />
            )}
            {Capacitor.isNativePlatform() && (
                <NotificationPermissionPopup
                    open={notificationPermissionIsOpen}
                    toggleOpen={() => setNotificationPermissionIsOpen((prev) => !prev)}
                    // giveaway coupon options
                    buttonType={coupon?.type === "giveaway" ? "switch" : "button"}
                    brandId={coupon?.brand_id ?? ""}
                    title={
                        coupon?.type === "giveaway"
                            ? t("couponDetail.giveaway.notificationPermissionPopup.title").toString()
                            : undefined
                    }
                    description={
                        coupon?.type === "giveaway"
                            ? t("couponDetail.giveaway.notificationPermissionPopup.description")
                            : undefined
                    }
                    buttonText={
                        coupon?.type === "giveaway"
                            ? {
                                  title: t("couponDetail.giveaway.notificationPermissionPopup.buttonText.title", {
                                      brandName: brand?.name,
                                  }),
                                  description: t(
                                      "couponDetail.giveaway.notificationPermissionPopup.buttonText.description",
                                  ),
                                  alternateDescription: t(
                                      "couponDetail.giveaway.notificationPermissionPopup.buttonText.alternateDescription",
                                  ),
                              }
                            : undefined
                    }
                />
            )}
        </>
    );
}

export function CouponDecorationIcon({ coupon }: { coupon: Coupon }) {
    return (
        <Avatar
            sx={{
                width: "32px",
                height: "32px",
                backgroundColor: "#ffffff",
                ml: 1,
                p: 1,
            }}
        >
            <ContentComponentAvatarIcon
                iconType={{
                    type: "coupon",
                    coupon: coupon,
                }}
                width={18}
                height={18}
                color="#000000"
            />
        </Avatar>
    );
}

type OnlineActionButtonsProps = {
    coupon?: Coupon;
    useCouponCallback: () => void;
    activateCouponCallback: () => void;
    codeCopied: boolean;
    couponIsActivating: boolean;
    navigateIsLoading: boolean;
    copyToClipboard: (coupon: Coupon) => void;
};
function OnlineActionButtons({
    coupon,
    useCouponCallback,
    activateCouponCallback,
    codeCopied,
    couponIsActivating,
    navigateIsLoading,
    copyToClipboard,
}: OnlineActionButtonsProps) {
    const { t } = useTranslation();

    if (coupon?.status === CouponStatus.Used) {
        return (
            <Box>
                {coupon?.code && (
                    <Button
                        onClick={() => copyToClipboard(coupon)}
                        color="white"
                        fullWidth
                        sx={{
                            marginBottom: 1,
                        }}
                    >
                        {!codeCopied ? t("couponDetail.copyToClipboard") : t("couponDetail.copiedToClipboard")}
                    </Button>
                )}

                <Button
                    fullWidth
                    onClick={useCouponCallback}
                    color="primary"
                    disabled={navigateIsLoading}
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                >
                    {t("couponDetail.useCoupon")}
                </Button>
            </Box>
        );
    }

    return (
        <Button
            disabled={couponIsActivating}
            onClick={activateCouponCallback}
            color="primary"
            sx={{
                width: "100%",
            }}
        >
            {t("couponDetail.buttonActivate")}
        </Button>
    );
}

type StoreActionButtonsProps = {
    coupon?: Coupon;
    callback: () => void;
    couponIsActivating: boolean;
};
function StoreActionButtons({ coupon, callback, couponIsActivating }: StoreActionButtonsProps) {
    const { t } = useTranslation();

    if (coupon?.status === CouponStatus.Used) {
        return (
            <Button
                disabled
                fullWidth
                endIcon={<FontAwesomeIcon icon={faCheck} />}
                sx={{ backgroundColor: "card.light" }}
            >
                {t("couponDetail.couponActivated")}
            </Button>
        );
    }

    return (
        <Button disabled={couponIsActivating} fullWidth onClick={callback}>
            {t("couponDetail.buttonActivate")}
        </Button>
    );
}

type GiveawayActionButtonsProps = {
    coupon?: Coupon;
    callback: () => void;
    setNotificationPermissionIsOpen: (open: boolean) => void;
    couponIsActivating: boolean;
};
function GiveawayActionButtons({
    coupon,
    callback,
    setNotificationPermissionIsOpen,
    couponIsActivating,
}: GiveawayActionButtonsProps) {
    const { t } = useTranslation();

    if (coupon?.status === CouponStatus.Used) {
        return (
            <Button
                disabled
                fullWidth
                endIcon={<FontAwesomeIcon icon={faCheck} />}
                sx={{ backgroundColor: "card.light" }}
            >
                {t("couponDetail.giveaway.couponActivated")}
            </Button>
        );
    }

    return (
        <Button
            disabled={couponIsActivating}
            fullWidth
            onClick={() => {
                callback();

                setTimeout(() => {
                    setNotificationPermissionIsOpen(true);
                }, 2000);
            }}
        >
            {t("couponDetail.giveaway.buttonActivate")}
        </Button>
    );
}
