import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";
import { XMLParser } from "fast-xml-parser";
import { z } from "zod";
import { env } from "../env.mjs";
import { ReactNode, createContext, useContext } from "react";

type GetMediaContentQuery = {
    brandId: string; //e.g. 627b00af-fbd4-4cac-baf8-25a91533d9c4
    directory: string; //e.g. logo
    metadata?: MediaContentSelectMetadata; //select which metadata is required
};

type MediaContentSelectMetadata = {
    type: MediaContentType[];
    lang?: MediaContentLanguage[];
};

const MediaContentTypeSchema = z.union([z.literal("image"), z.literal("video"), z.literal("vector"), z.literal("gif")]);

type MediaContentType = z.infer<typeof MediaContentTypeSchema>;

const MediaContentLanguageSchema = z.union([z.literal("en"), z.literal("nl")]);

export type MediaContentLanguage = z.infer<typeof MediaContentLanguageSchema>;

const MediaContentMetadataSchema = z.object({
    type: MediaContentTypeSchema,
    lang: MediaContentLanguageSchema.optional(),
    index: z.number().min(0).optional(),
    duration: z.number().optional(),
});

// caveats of azure blob storage
const MediaContentMetadataSchema_Capitalized = z.object({
    Type: MediaContentTypeSchema,
    Lang: MediaContentLanguageSchema.optional(),
    Index: z.number().min(0).optional(),
    Duration: z.number().optional(),
});

type MediaContentMetadata = z.infer<typeof MediaContentMetadataSchema>;

const MediaContentScheme = z.object({
    Metadata: MediaContentMetadataSchema,
    Url: z.string().url(),
    Name: z.string(),
});

const AllMediaContentScheme = z.object({
    Metadata: z.union([z.string(), MediaContentMetadataSchema]),
    Url: z.string().url(),
    Name: z.string(),
});

const AllMediaContentScheme_Capitalized = z.object({
    Metadata: z.union([z.string(), MediaContentMetadataSchema_Capitalized]),
    Url: z.string().url(),
    Name: z.string(),
});

type AllMediaContent = z.infer<typeof AllMediaContentScheme>;

export type MediaContent = z.infer<typeof MediaContentScheme>;

const ListAllMediaContentResponseSchema = z.object({
    EnumerationResults: z.object({
        Blobs: z.object({
            Blob: z.any(),
        }),
        NextMarker: z.string(),
    }),
});

const GetAllBrandMediaContent = (options?: UseQueryOptions<AllMediaContent[], unknown, AllMediaContent[]>) => {
    return useQuery<AllMediaContent[]>({
        queryKey: ["media"],
        queryFn: async (): Promise<AllMediaContent[]> => {
            return await fetchAllBrandMediaContent({});
        },
        ...options,
    });
};

type MediaQueryContext = {
    GetBrandMediaContent: (props: GetMediaContentQuery) => MediaContent | undefined;
    GetBrandMediaContentList: (props: GetMediaContentQuery) => MediaContent[] | undefined;
    isLoading: boolean;
};

const MediaQueryContext = createContext<MediaQueryContext | undefined>(undefined);

export const MediaContentProvider = ({ children }: { children?: ReactNode }) => {
    const { data, isLoading } = GetAllBrandMediaContent();

    if (!isLoading && !data) throw new Error("could not get media content");

    const GetBrandMediaContentList = ({
        brandId,
        directory,
        metadata,
    }: GetMediaContentQuery): MediaContent[] | undefined => {
        if (isLoading) return [];

        const filtered = data.filter((x) => {
            const split = x.Name.split("/");
            const last = split[split.length - 1];

            return x.Name.startsWith(directory) && last.startsWith(brandId);
        });

        const filter = (meta: MediaContentMetadata | string) => {
            if (!metadata) return true;

            if (typeof meta === "string") return false;

            const typeFilter = (type?: MediaContentType) => {
                if (!type) return false;

                return metadata.type.includes(type);
            };

            const metaFilter = (lang?: MediaContentLanguage) => {
                if (!lang) return true;

                return metadata.lang?.includes(lang);
            };

            if (!metadata.lang) return typeFilter(meta.type);

            return metaFilter(meta.lang) && typeFilter(meta.type);
        };

        return filtered
            .filter((x) => filter(x.Metadata))
            .filter((x) => {
                if (typeof x.Metadata === "string") return false;

                return x.Metadata.index !== undefined;
            })
            .sort((a, b) => {
                if (typeof a.Metadata === "string" || typeof b.Metadata === "string") return 0;

                if (a.Metadata.index === undefined || b.Metadata.index === undefined) return 0;

                return a.Metadata.index - b.Metadata.index;
            }) as MediaContent[];
    };

    const GetBrandMediaContent = ({ brandId, directory, metadata }: GetMediaContentQuery): MediaContent | undefined => {
        if (isLoading) return undefined;

        const filtered = data.filter((x) => {
            const split = x.Name.split("/");
            const last = split[split.length - 1];

            return x.Name.startsWith(directory) && last.startsWith(brandId);
        });

        const filter = (meta: MediaContentMetadata | string) => {
            if (!metadata) return true;

            if (typeof meta === "string") return false;

            const typeFilter = (type?: MediaContentType) => {
                if (!type) return false;

                return metadata.type.includes(type);
            };

            const metaFilter = (lang?: MediaContentLanguage) => {
                if (!lang) return true;

                return metadata.lang?.includes(lang);
            };

            if (!metadata.lang) return typeFilter(meta.type);

            return metaFilter(meta.lang) && typeFilter(meta.type);
        };

        return filtered.find((x) => filter(x.Metadata)) as MediaContent | undefined;
    };

    return (
        <MediaQueryContext.Provider
            value={{
                GetBrandMediaContent,
                GetBrandMediaContentList,
                isLoading,
            }}
        >
            {children}
        </MediaQueryContext.Provider>
    );
};

export const useBrandMediaContentProvider = () => {
    const context = useContext(MediaQueryContext);
    if (!context) {
        throw new Error("useMediaContent must be used in a MediaQueryProvider");
    }
    return context;
};

function parseBrandMediaContent(data: unknown[] | unknown): AllMediaContent[] {
    if (!Array.isArray(data)) {
        const res = AllMediaContentScheme.safeParse(data);
        if (!res.success) {
            console.warn("invalid content", data);
            return [];
        }

        return [res.data];
    }

    const parsedData: AllMediaContent[] = [];

    for (const element of data) {
        if (typeof element.Metadata === "string") {
            parsedData.push(element);
            continue;
        }

        const res = AllMediaContentScheme.safeParse(element);
        if (!res.success) {
            if (!AllMediaContentScheme_Capitalized.safeParse(element).success) {
                // metadata might be capitalized due to drag and drop copy behaviour
                console.warn("invalid content", element);
                continue;
            } else {
                // Convert keys to lowercase
                const lowercaseMetadata: Record<string, any> = {};
                for (const key in element.Metadata) {
                    lowercaseMetadata[key.toLowerCase()] = element.Metadata[key];
                }

                element.Metadata = lowercaseMetadata;

                parsedData.push(element);
                continue;
            }
        }
        parsedData.push(element);
    }

    return parsedData;
}

const fetchAllBrandMediaContent = async ({ marker }: { marker?: string }): Promise<AllMediaContent[]> => {
    return await axios({
        method: "get",
        params: {
            restype: "container",
            comp: "list",
            include: "metadata",
            marker: marker,
        },
        url: env.cdnUrl + "/brand/",
    }).then(async (res) => {
        const parser = new XMLParser();
        const parsedXml = parser.parse(res.data);

        const parseResponse = ListAllMediaContentResponseSchema.safeParse(parsedXml);
        if (!parseResponse.success) {
            if (env.devEnv === "development") {
                console.warn(parseResponse.error);
                console.warn(`invalid response from get all Media content`);
            }
            throw new Error(`invalid response from get all Media content`);
        }

        if (parseResponse.data.EnumerationResults.NextMarker !== "") {
            const res = await fetchAllBrandMediaContent({
                marker: parsedXml.EnumerationResults.NextMarker,
            });

            parseResponse.data.EnumerationResults.Blobs.Blob = combineElements(
                parseResponse.data.EnumerationResults.Blobs.Blob,
                res,
            );
        }

        return parseBrandMediaContent(parseResponse.data.EnumerationResults.Blobs.Blob);
    });
};

function combineElements<T>(element1: T | T[], element2: T | T[]): T[] {
    // Ensure that element1 and element2 are always arrays
    const array1 = Array.isArray(element1) ? element1 : [element1];
    const array2 = Array.isArray(element2) ? element2 : [element2];

    // Concatenate the arrays
    return array1.concat(array2);
}
