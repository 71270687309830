import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

// The container for the content and the navigation

const FullScreenContainerCenter = styled(Container)(({ theme }) => {
  return {
    // mobile
    [theme.breakpoints.down("sm")]: {
      flex: "2 1 auto",
      margin: theme.spacing(2) + " 0px",
    },
    // desktop
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  };
});

export default FullScreenContainerCenter;
