import { createContext, useContext, useState } from "react";

type bannerProviderType = {
    closed: boolean;
    closeBanner: () => void;
};

const bannerProvider = createContext<bannerProviderType | undefined>(undefined);

export const BannerProvider = ({ children }: { children: React.ReactNode }) => {
    const [closed, setClosed] = useState(false);

    const closeBanner = () => {
        setClosed(true);
    };

    return <bannerProvider.Provider value={{ closed, closeBanner }}>{children}</bannerProvider.Provider>;
};

export const useBannerProvider = () => {
    const context = useContext(bannerProvider);
    if (!context) {
        throw new Error("useBannerProvider must be used within a BannerProvider");
    }
    return context;
};
