import { lazy } from "react";
import { useClubProvider } from "../../service/ClubService";
const ClubBsoCalendarPage = lazy(() => import("./bso/calendarPage/ClubBsoCalendarPage"));
const ClubSportMatchCalendarPage = lazy(() => import("./sport/calendarPage/ClubSportMatchCalendarPage"));

export const ClubCalendarPage = () => {
    const { brand_config } = useClubProvider();

    switch (brand_config.type) {
        case "sport":
            return <ClubSportMatchCalendarPage />;
        case "bso":
            return <ClubBsoCalendarPage />;

        default:
            return <ClubSportMatchCalendarPage />;
    }
};

export default ClubCalendarPage;
