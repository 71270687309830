import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import AnyIdLogoDefault from "../../gp-components/img/AnyIdLogo.png";
import AnyIdLogoWhite from "../../gp-components/img/AnyIdLogoWhite.png";
import { useTheme } from "@mui/material/styles";
import { colorIsDark } from "../../service/theme/themeService";

type Props = {
    backgroundColor?: string;
    color?: string;
};

export function AnyIdLogo({
    backgroundColor,
    color,
    ...props
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & Props) {
    const theme = useTheme();

    if (!(color && !colorIsDark(color)) && !colorIsDark(backgroundColor || theme.palette.background.default))
        return <img src={AnyIdLogoDefault} {...props} alt="AnyID logo" />;

    return <img src={AnyIdLogoWhite} {...props} alt="AnyID logo" />;
}
