import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CardActionArea, Collapse, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";

type Props = {
  title: string;
  children?: React.ReactNode;
  defaultOpen?: boolean;
  titleVariant?: any;
  gap?: string;
};

export default function GPCollapse({ title, children, defaultOpen, titleVariant, gap }: Props) {
  const [open, setOpen] = useState(defaultOpen);
  const theme = useTheme();

  return (
    <Box borderRadius={1} sx={{ backgroundColor: theme.palette.card.main, marginBottom: gap ?? 1 }}>
      <CardActionArea
        onClick={() => {
          setOpen(!open);
        }}
        sx={{ display: "flex", padding: 2, backgroundColor: theme.palette.card.main }}
      >
        <Typography sx={{ flexGrow: 1 }} variant={titleVariant ?? "h3"}>
          {title}
        </Typography>
        {open ? <FontAwesomeIcon icon={solid("chevron-up")} /> : <FontAwesomeIcon icon={solid("chevron-down")} />}
      </CardActionArea>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2, paddingTop: 0 }}>{children}</Box>
      </Collapse>
    </Box>
  );
}
