import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { AuthContextInterface } from "../auth/AuthProvider";
import { ParsedAuthBody } from "../auth/AuthStorage.interface";
import { Capacitor } from "@capacitor/core";

export interface AuthenticationStorage {
    authBody: ParsedAuthBody;
    tokenExpiresAt: Date;
    refreshExpiresAt: Date;
}

interface PincodeStorage {
    [userId: string]: UserPin;
}

interface UserPin {
    pincode: string;
    attempts: number;
    blockedUntil?: Date;
}

export default class SecureStorageService {
    static async setAuthentication(authBody: ParsedAuthBody) {
        const tokenExpiresAt = new Date(authBody.jwt.exp * 1000);
        const refreshExpiresAt = new Date(authBody.refresh.exp * 1000);

        const authentication = {
            authBody,
            tokenExpiresAt,
            refreshExpiresAt,
        } as AuthenticationStorage;

        return await SecureStoragePlugin.set({ key: "authentication", value: JSON.stringify(authentication) });
    }

    static async getAuthentication(): Promise<AuthenticationStorage | undefined> {
        try {
            const resultString = await SecureStoragePlugin.get({ key: "authentication" });
            const authenticationStorage = JSON.parse(resultString.value) as AuthenticationStorage;

            if (
                (Capacitor.isNativePlatform() && Date.now() >= authenticationStorage.authBody.refresh.exp * 1000) ||
                (!Capacitor.isNativePlatform() && Date.now() >= authenticationStorage.authBody.jwt.exp * 1000)
            ) {
                return undefined;
            }

            return authenticationStorage;
        } catch (e) {
            console.error("cannot get authentication in secure storage.", e);

            return undefined;
        }
    }

    static async setPincode(authProvider: AuthContextInterface, pincode: string) {
        const userId = authProvider.authBody?.jwt.sub;
        if (!userId) return;

        let pincodeStorage = {} as any;
        try {
            const existingPincodeStorage = await (await SecureStoragePlugin.get({ key: "pincode" })).value;

            pincodeStorage = JSON.parse(existingPincodeStorage) as PincodeStorage;
        } catch (e) {}

        const userPincodeData = pincodeStorage[userId] || {};
        pincodeStorage[userId] = {
            ...userPincodeData,
            attempts: 0,
            pincode: pincode,
        };

        await SecureStoragePlugin.set({ key: "pincode", value: JSON.stringify(pincodeStorage) });
    }

    static async verifyPincode(
        authProvider: AuthContextInterface,
        pincodeInput: string,
    ): Promise<{ result: boolean; blockedUntil: Date | undefined }> {
        const pincodeStorage = JSON.parse((await SecureStoragePlugin.get({ key: "pincode" })).value) as PincodeStorage;
        const userId = authProvider.authBody?.jwt.sub;
        if (!userId) return { result: false, blockedUntil: undefined };

        const userPin = pincodeStorage[userId];

        if (userPin && userPin.pincode.length === 5) {
            const pincodeIsCorrect = userPin?.pincode === pincodeInput;

            return SecureStorageService.handleMaxLoginAttempts(pincodeStorage, userId, pincodeIsCorrect);
        }

        return { result: false, blockedUntil: undefined };
    }

    private static async handleMaxLoginAttempts(
        pincodeStorage: PincodeStorage,
        userId: string,
        pincodeIsCorrect: boolean,
    ) {
        const newUserPin = { ...pincodeStorage[userId] };
        if (pincodeIsCorrect && newUserPin.attempts > 0) {
            newUserPin.attempts = 0;
            newUserPin.blockedUntil = undefined;
        }
        if (!pincodeIsCorrect) {
            if (newUserPin.attempts >= 2) {
                const d = new Date();
                const attemptMultiplier = newUserPin.attempts - 1;

                d.setSeconds(d.getSeconds() + attemptMultiplier * 30);
                newUserPin.blockedUntil = d;
            }
            newUserPin.attempts = (newUserPin.attempts ?? 0) + 1;
        }

        pincodeStorage[userId] = newUserPin;
        return await SecureStoragePlugin.set({ key: "pincode", value: JSON.stringify(pincodeStorage) }).then(() => {
            return {
                result: pincodeIsCorrect,
                blockedUntil: newUserPin.blockedUntil,
            };
        });
    }

    static async setLanguage(newLanguage: string, authBody: ParsedAuthBody | undefined) {
        if (!authBody) return;
        const newJwt = authBody.jwt;
        newJwt.locale = newLanguage;
        const newAuthBody = { ...authBody, jwt: newJwt };

        return await this.setAuthentication(newAuthBody);
    }

    static async logout() {
        await SecureStoragePlugin.remove({ key: "authentication" });
    }
}
