/** A generic storage service of a user account. Right now the main use case is to store HDB season tickets */

import { UseMutationOptions, UseQueryOptions } from "react-query";
import { FetchMutateResponse } from "./fetchService";
import { FetchOptions, useQueryGet, useQueryPut } from "./restApiQuery";

export type SeasonTicketType = {
    wallet_uuid: string;
    owner?: string;
    seat?: string;
};

// the second key is the season ticket ticket number
export type UserCard = Record<string, Record<string, SeasonTicketType>>;

export function useGetUserCard(brandId?: string, options?: UseQueryOptions<any, unknown, any>) {
    return useQueryGet<UserCard>(["card", brandId], "gateway", `/vault/user/cards?brandID=${brandId}`, {
        retry: false,
        refetchOnWindowFocus: false,
        ...options,
    });
}

export function useUserSaveCard(
    brandId: string,
    options?: UseMutationOptions<FetchMutateResponse, unknown, FetchOptions, unknown>,
) {
    return useQueryPut(["card", brandId], "gateway", `/vault/user/cards`, options, {
        headers: { "Brand-Id": brandId },
    });
}
