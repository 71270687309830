import { useMemo } from "react";
import { UseMutationOptions, UseQueryOptions, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { updateUserForm } from "../../pages/data/MyDataEdit";
import { logEvent, logEventWithWizardId } from "../../service/AnalyticsService";
import { FetchOptions, useQueryGet, useQueryPut } from "../../service/api/restApiQuery";
import { Wizard, WizardFormData, WizardStep } from "./WizardForm";
import { useAuthProvider } from "../../service/auth/AuthProvider";

type WizardFormReponse = {
    config: Wizard;
    initFormData: WizardFormData;
};

export function useWizardForm(
    quizConfigId?: string,
    queryOptions?: UseQueryOptions<WizardFormReponse, unknown, WizardFormReponse>,
) {
    const searchParams = useSearchData();
    const profileInfoNames = updateUserForm.map((i) => i.name);

    return useQueryGet<WizardFormReponse>(
        ["wizardForm", quizConfigId],
        "gateway",
        "/brand/public/quiz?id=" + quizConfigId,
        {
            ...queryOptions,
            enabled: quizConfigId !== undefined,
            select: (config: any) => {
                const steps: WizardStep[] = [];
                const initFormData: WizardFormData = {};

                (config as Wizard).steps.forEach((question) => {
                    if (question.inputConfig.type === "form") {
                        question.inputConfig.formItems?.forEach((formItem) => {
                            const searchParamValue = searchParams[formItem.name];
                            if (searchParamValue) {
                                initFormData[formItem.name] = searchParamValue;
                            }
                        });
                    } else if (question.inputConfig.key !== "birth_date") {
                        const searchParamValue = searchParams[question.inputConfig.key];
                        if (searchParamValue) {
                            initFormData[question.inputConfig.key] = profileInfoNames.includes(question.inputConfig.key)
                                ? searchParamValue
                                : [searchParamValue];
                            return;
                        }
                    }

                    steps.push(question);
                });

                return {
                    config: { ...config, steps },
                    initFormData,
                };
            },
        },
    );
}

export function useSearchData<T = Record<string, string>>() {
    const location = useLocation();

    return useMemo<T>(() => Object.fromEntries(new URLSearchParams(location.search)) as T, [location.search]);
}

export function useSubmitQuiz<T>(
    key: any[],
    quizData?: WizardFormData,
    brandId?: string,
    wizardId?: string,
    queryOptions?: UseMutationOptions<T, unknown, FetchOptions, unknown>,
    url?: string,
) {
    const queryClient = useQueryClient();
    const { authBody } = useAuthProvider();

    return useQueryPut(
        key,
        "gateway",
        url ?? "/vault/user/quiz",
        {
            ...queryOptions,
            onSuccess: (data: any, variables, context) => {
                if (wizardId) logEventWithWizardId("quiz_submit", wizardId);
                else logEvent("quiz_submit");
                queryClient.invalidateQueries(["user-attributes"]);
                queryClient.invalidateQueries(["profile"]);

                if (queryOptions && queryOptions?.onSuccess) {
                    queryOptions.onSuccess(data, variables, context);
                }
            },
        },
        {
            body: { ...quizData, email: authBody?.jwt.email }, //add email
            headers: {
                "Content-Type": "application/json",
                BrandId: brandId || "",
            },
        },
    );
}
