import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { WizardFormData } from "../components/WizardForm/WizardForm";

type QuizState = Record<string, WizardFormData>;

interface QuizContext {
    quiz?: QuizState;
    setQuiz: Dispatch<SetStateAction<QuizState | undefined>>;
}

const quizContext = createContext<QuizContext | undefined>(undefined);

export const QuizProvider = ({ children }: { children?: ReactNode }) => {
    const [quizState, setQuizState] = useState<QuizState | undefined>(undefined);

    return (
        <quizContext.Provider
            value={{
                quiz: quizState,
                setQuiz: setQuizState,
            }}
        >
            {children}
        </quizContext.Provider>
    );
};

export const useQuizProvider = () => {
    const context = useContext(quizContext);
    if (!context) throw new Error(context);

    return context;
};
