import { Box, Menu, MenuItem } from "@mui/material";
import { env } from "../../env.mjs";
import { useState } from "react";
import { useDynamicThemeProvider } from "../../service/DynamicThemeProvider";
import i18n from "../../i18n.js";
import { useQueryPut } from "../../service/api/restApiQuery";
import SecureStorageService from "../../service/capacitor/SecureStorageService";
import { useAuthProvider } from "../../service/auth/AuthProvider";
import { Preferences } from "@capacitor/preferences";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { useQueryClient } from "react-query";

type Props = {};

export function AnyDevTools(props: Props) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { toggleColorMode } = useDynamicThemeProvider();
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();

    const { mutate: setLanguage } = useQueryPut(["user-locale"], "gateway", "/vault/user/locale/", {
        onSuccess: async (result, data) => {
            const newLanguage = data.queryParams || i18n.languages[0];

            i18n.changeLanguage(newLanguage);
            await SecureStorageService.setLanguage(newLanguage, authProvider.authBody);

            queryClient.invalidateQueries({ queryKey: ["coupon"] });
            queryClient.invalidateQueries({ queryKey: ["user-brands"] });
            queryClient.invalidateQueries({ queryKey: ["brand"] });
            queryClient.invalidateQueries({ queryKey: ["article"] });
            queryClient.invalidateQueries({ queryKey: ["logs"] });
        },
    });

    if (env.devEnv !== "development") return <></>;

    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function removePreferences() {
        Preferences.remove({
            key: "authPreferences",
        });
        SecureStoragePlugin.remove({ key: "pincode" });
        Preferences.remove({ key: "syncUpFcmToken" });
    }

    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                sx={{ zIndex: 9999 }}
            >
                <MenuItem
                    onClick={() => {
                        removePreferences();
                        handleClose();
                    }}
                >
                    Clear user preferences
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setLanguage({ queryParams: i18n.languages[0] === "en" ? "nl" : "en" });
                        handleClose();
                    }}
                >
                    Toggle Language
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        toggleColorMode();
                        handleClose();
                    }}
                >
                    Toggle Theme
                </MenuItem>
            </Menu>
            <Box
                onClick={handleClick}
                sx={{
                    width: "30px",
                    height: "30px",
                    position: "fixed",
                    backgroundColor: "red",
                    bottom: "0.5em",
                    right: "0.5em",
                    zIndex: 9998,
                }}
            />
        </>
    );
}
