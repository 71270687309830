import { PaletteMode } from "@mui/material";
import { PaletteOptions, Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import { anyPalette } from "../../theme";
import { deepMergeObjects } from "./themeService";
import { nlNL, enUS } from "@mui/x-date-pickers";
import { i18n } from "i18next";
import { getBrandThemeOptions } from "./BrandThemes";
import { getClubTheme } from "./ClubTheme";
import { BrandConfig } from "../api/schemeValidator";

type ThemeType = "brand" | "club" | "onboarding";
export function getDynamicTheme(
    mode: PaletteMode,
    i18n: i18n,
    brandId?: string,
    variant?: ThemeType,
    brandConfig?: BrandConfig,
    overrideTheme?: Theme,
) {
    let brandThemeOptions;

    if (brandId) {
        if (variant === "club" && brandConfig) brandThemeOptions = getClubTheme(brandId, brandConfig);
        else if (variant === "onboarding") brandThemeOptions = getBrandThemeOptions(brandId, overrideTheme);
        else brandThemeOptions = getBrandThemeOptions(brandId);

        mode = brandThemeOptions?.palette?.mode || "dark";
    }

    let themeOptions = getDefaultTheme(mode);

    if (brandId) {
        themeOptions = deepMergeObjects(themeOptions, brandThemeOptions);
    }

    let theme = createTheme(themeOptions, i18n.languages[0] === "en" ? enUS : nlNL);
    const themedComponents = getThemedComponents(theme);
    theme = deepMergeObjects(themedComponents, theme);

    return theme;
}

const lightPalette: PaletteOptions = {
    mode: "light",
    primary: {
        main: "#54AB9A",
        dark: "#3a776b",
        light: "#76bbae",
        contrastText: "#ffffff",
    },
    secondary: {
        main: "#025497",
        dark: "#013a69",
        light: "#3476ab",
        contrastText: "#ffffff",
    },
    text: {
        primary: "#000000",
        secondary: anyPalette.sandDark,
        tertiair: anyPalette.darkGray,
    },
    card: {
        main: "#ffffff",
        dark: "#ffffff",
    },
    icon: {
        main: "#ffffff",
    },
    background: {
        default: "#EFEDE8",
        paper: "#ffffff",
    },
};

const darkPalette: PaletteOptions = {
    mode: "dark",
    primary: {
        main: "#54AB9A",
        contrastText: "#ffffff",
    },
    secondary: {
        main: "#EA789D",
        contrastText: "#ffffff",
    },
    text: {
        primary: "#ffffff",
        secondary: anyPalette.sandLight,
        tertiair: "#ffffff",
    },
    card: {
        main: "#645F8F",
        dark: "#443F6F",
        light: "#8682AE",
    },
    icon: {
        main: "#ffffff",
    },
    background: {
        default: "#1C1C2F",
        gradient: "#3D3872",
        paper: "#645F8F",
    },
    inAppNotificationCard: {
        main: "#ffffff",
        contrastText: "#000000",
    },
};

const getThemedComponents = (theme: Theme) => {
    return {
        components: {
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        borderRadius: theme.shape.borderRadius,
                        "& .MuiButtonBase-root": {
                            borderRadius: theme.shape.borderRadius,
                            fontFamily: fonts.poppins,
                            fontWeight: 500,
                        },
                        "& .MuiButtonBase-root.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                        },
                        "& .MuiPickersCalendarHeader-label": {
                            fontFamily: fonts.poppins,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.text.primary,
                            borderRadius: "4px",
                            padding: "5px",
                        },
                        "& .MuiDayCalendar-weekDayLabel": {
                            fontWeight: 500,
                            fontFamily: fonts.poppins,
                            color: theme.palette.text.primary,
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    variant: "contained",
                },
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                        textTransform: "none",
                        fontFamily: fonts.poppins,
                        borderRadius: "6px",
                        padding: "8px 15px",
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        formHeader: "p",
                        h7: "p",
                        h8: "p",
                        h9: "p",
                        h10: "p",
                    },
                },
                styleOverrides: {
                    root: {
                        userSelect: "none",
                    },
                },
            },
            MuiInputBase: {
                defaultProps: {
                    size: "small",
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: "-9px",
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "& .MuiInputBase-root": {
                            height: "44px",
                            backgroundColor: theme.palette.mode === "dark" ? "#8682AE" : "#ffffff",
                        },
                        "& .MuiFormHelperText-root": {
                            margin: "4px 0px 0px 0px",
                        },
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiCard: {
                defaultProps: {
                    elevation: 0,
                },
            },
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        fontSize: "1em",
                        fontWeight: 400,
                    },
                },
            },
            MuiStepIcon: {
                styleOverrides: {
                    text: {
                        fontSize: "16px",
                    },
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        transform: "none",
                        transformOrigin: "0",
                    },
                },
            },
        },
    } as ThemeOptions;
};

export const getDefaultTheme = (mode: PaletteMode) => {
    return {
        label: {
            margin: "0px",
        },
        spacing: 8,
        shape: {
            borderRadius: 6,
        },
        typography: {
            h1: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "700" : "600",
                lineHeight: "inherit",
                color: "inherit",
                fontSize: "26px",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "2.25rem",
                },
            },
            h2: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "700" : "600",
                lineHeight: "inherit",
                fontSize: "22px",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "1.75rem",
                },
            },
            h3: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "600" : "500",
                lineHeight: "inherit",
                fontSize: "18px",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "20px",
                },
            },
            h4: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "700" : "600",
                fontSize: "14px",
                lineHeight: "inherit",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            h5: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "700" : "600",
                textTransform: "uppercase",
                letterSpacing: "0.09em",
                lineHeight: "inherit",
                fontSize: "1rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0,875rem",
                },
            },
            h6: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "600" : "500",
                lineHeight: "inherit",
                fontSize: "1rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            h7: {
                fontFamily: fonts.poppins,
                fontWeight: "400",
                lineHeight: "24px",
                fontSize: "0.9rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            h8: {
                fontFamily: fonts.poppins,
                fontWeight: "400",
                lineHeight: "inherit",
                fontSize: "0.8rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            h9: {
                fontFamily: fonts.poppins,
                fontWeight: "400",
                lineHeight: "inherit",
                fontSize: "0.7rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            h10: {
                fontFamily: fonts.poppins,
                fontWeight: "400",
                lineHeight: "inherit",
                fontSize: "0.6rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            body1: {
                fontFamily: fonts.roboto,
                fontSize: "1rem",
                userselect: "text",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            formHeader: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "600" : "500",
                lineHeight: "inherit",
                fontSize: "0.875rem",
            },
            // used for the back button
            subtitle1: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "700" : "600",
                lineHeight: "inherit",
                fontSize: "0.75rem",
            },
            subtitle2: {
                fontFamily: fonts.poppins,
                fontWeight: mode === "dark" ? "600" : "500",
                lineHeight: "inherit",
                fontSize: "0.875rem",
            },
            iconText: {
                fontFamily: fonts.poppins,
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "0.75rem",
                lineHeight: "10px",
            },
            buttonText: {
                fontFamily: fonts.poppins,
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.75rem",
                lineHeight: "10px",
            },
            button1: {
                fontFamily: fonts.poppins,
                fontWeight: 600,
                fontSize: "1rem",
                // desktop
                "@media (min-width:600px)": {
                    fontSize: "0.875rem",
                },
            },
            button: {
                fontSize: "1rem",
            },
            caption: {
                fontFamily: fonts.poppins,
                fontSize: "0.625rem",
                fontWeight: 600,
            },
        },
        palette: {
            green: {
                main: "#54AB9A",
            },
            blue: {
                main: "#025497",
            },
            lightBlue: {
                main: "#078FAD",
            },
            orange: {
                main: "#F7A34F",
            },
            purple: {
                main: "#7E65A3",
            },
            rose: {
                main: "#EA789D",
            },
            white: {
                main: "#ffffff",
                dark: "#b2b2b2",
                light: "#ffffff",
                contrastText: "#222323",
            },
            black: {
                main: "#000000",
            },
            sandLight: {
                main: "#EFEDE8",
            },
            sandMedium: {
                main: "#DDDBD7",
            },
            sandDark: {
                main: "#A7A39A",
            },
            error: {
                main: "#f44336",
            },
            ...(mode === "dark" ? darkPalette : lightPalette),
        },
    } as ThemeOptions;
};

export const fonts = {
    roboto: `"Roboto", sans-serif`,
    poppins: `"Poppins", sans-serif`,
};
