import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationOptions, UseQueryOptions, useQueryClient } from "react-query";
import { normalizeLanguage } from "./ConfigTranslator";
import { FetchOptions, useQueryDelete, useQueryGet, useQueryPost, useQueryPut } from "./api/restApiQuery";
import { useAuthProvider } from "./auth/AuthProvider";
import { AxiosError } from "axios";

export type Section = {
    section_id: string;
    section_name: string;
    image_url: string;
    start_date: number;
    expires_at: number;
    notifications: InAppNotification[];
};

export type InAppNotification = {
    id: string;
    brand_name: string;
    title: string;
    description: string;
    image_url: string;
    created_at: number;
    visible_at: number;
    brand_id: string;
    parent_id?: string;
    navigate_url: string;
    variant: "anyid" | "brand";
    status: "first_loaded" | "seen" | "clicked" | "deleted";
} & (ResourceCategories | OtherCategories);

type ResourceCategories = {
    category: "brand_action" | "mvp_poll" | "poll" | "coupon" | "article" | "navigate";
    resource_id: string;
};

type OtherCategories = {
    category: "brand_updates" | "platform-related";
};

export function useGetAllInAppNotifications(brandId?: string, options?: UseQueryOptions<Section[], AxiosError>) {
    const { i18n } = useTranslation();
    const { authBody } = useAuthProvider();
    const lang = normalizeLanguage(i18n.language, "nl");

    return useQueryGet<Section[]>(
        ["in-app-notifications", lang, brandId],
        "gateway",
        `/in-app-notification/in-app-notification?lang=${lang}${brandId ? `&brand_id=${brandId}` : ""}`,
        { enabled: !!authBody?.accessToken, suspense: !!brandId },
    );
}

/**
 * set all notifications as seen in the local cache (optimistic update) without refetching data
 */
export function useSetSeenInAppNotificationItems(
    ids: string[],
    options?: UseMutationOptions<any, unknown, FetchOptions, unknown>,
) {
    const queryClient = useQueryClient();

    return useQueryPost(
        ["in-app-notifications"],
        "gateway",
        "/in-app-notification/in-app-notification",
        {
            onMutate: async () => {
                const previousData = queryClient.getQueryData<Section[]>(["in-app-notifications"]);

                queryClient.setQueryData(["in-app-notifications"], (_previousConsent: unknown) => {
                    const previousData = !!_previousConsent ? (_previousConsent as Section[]) : [];
                    return previousData.map((section) => {
                        return {
                            ...section,
                            notifications: section.notifications.map((notification) => {
                                return {
                                    ...notification,
                                    status: "seen",
                                };
                            }),
                        };
                    });
                });

                return { previousData };
            },
            ...options,
        },
        {
            body: {
                in_app_notification_ids: ids,
            },
        },
    );
}

export function useClickInAppNotificationItem(
    id?: string,
    options?: UseMutationOptions<any, unknown, FetchOptions, unknown>,
) {
    return useQueryPut(["in-app-notification", id], "gateway", "/in-app-notification/in-app-notification", options);
}

/**
 * Set the in app notification item status into "deleted". it does not delete the entity
 */
export function useDeleteInAppNotificationItem(
    inAppNotificationId?: string,
    options?: UseMutationOptions<any, unknown, FetchOptions, unknown>,
) {
    const queryClient = useQueryClient();

    return useQueryDelete(
        ["in-app-notification", inAppNotificationId],
        "gateway",
        "/in-app-notification/in-app-notification",
        {
            // optimistic update. We update first update the UI, then wait the API returns the expected data.
            onMutate: async () => {
                await queryClient.cancelQueries(["in-app-notifications", inAppNotificationId]);

                const previousData = queryClient.getQueryData<Section[]>(["in-app-notifications"]);

                queryClient.setQueryData(["in-app-notifications"], (_previousConsent: unknown) => {
                    const previousData = !!_previousConsent ? (_previousConsent as Section[]) : [];

                    return previousData.map((section) => {
                        const found = !!section.notifications.find(
                            (notification) => notification.id === inAppNotificationId,
                        );
                        if (found) {
                            return {
                                ...section,
                                notifications: section.notifications.map((notification) => {
                                    if (notification.id === inAppNotificationId) {
                                        return {
                                            ...notification,
                                            status: "deleted",
                                        };
                                    }
                                    return notification;
                                }),
                            };
                        } else return section;
                    });
                });

                return { previousData };
            },
            ...options,
        },
    );
}

export const useCountdown = (targetDate: number) => {
    const countDownDate = new Date(targetDate * 1000).getTime();

    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
};
