import { Box, BoxProps, Grow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { anyPalette } from "../../theme";

type Props = {
    pincode: string;
    wrongPincode?: boolean;
    sx?: any;
    emptyStyle?: BoxProps;
};

export function PinForm(props: Props) {
    const { sx } = props;

    return (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1, ...sx }}>
            <RenderDot {...props} index={0} />
            <RenderDot {...props} index={1} />
            <RenderDot {...props} index={2} />
            <RenderDot {...props} index={3} />
            <RenderDot {...props} index={4} />
        </Box>
    );
}

function RenderDot({ pincode, wrongPincode = false, emptyStyle, index }: Props & { index: number }) {
    const theme = useTheme();

    const length = pincode.length;

    if (length > index) {
        // render filled dot
        return (
            <Grow in timeout={200}>
                <Box
                    sx={{
                        backgroundColor: wrongPincode ? anyPalette.softRed : anyPalette.secondary,
                        borderRadius: "50%",
                        width: "32px",
                        height: "32px",
                    }}
                />
            </Grow>
        );
    }
    if (length === index) {
        // render next dot
        return (
            <Box
                sx={{
                    backgroundColor: theme.palette.card.main,
                    borderRadius: "50%",
                    width: "32px",
                    height: "32px",
                    border:
                        "2px solid " + (theme.palette.mode === "light" ? anyPalette.primary : anyPalette.sandMedium),
                    transition: "all ease-in 0.2s",
                }}
            />
        );
    }
    // render empty dot
    return (
        <Box
            sx={{
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                width: "32px",
                height: "32px",
                ...emptyStyle,
            }}
        />
    );
}
