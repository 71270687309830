import { Capacitor } from "@capacitor/core";

export function navigateNewTab(url: string) {
  return new Promise((resolve) => {
    if (Capacitor.isNativePlatform()) {
      window.location.href = url;
      resolve("success");
    } else {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;

      if (!newWindow) {
        resolve("error");
        return;
      }

      const loadListener = () => {
        newWindow.removeEventListener("load", loadListener);
        resolve("success");
      };

      newWindow.addEventListener("load", loadListener);
    }
  });
}
