import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
    fa1,
    fa2,
    faBasketball,
    faBeerMug,
    faBell,
    faBellSlash,
    faBookOpen,
    faBoxingGlove,
    faBullseye,
    faCalendar,
    faCardsBlank,
    faCartShopping,
    faCircle,
    faCoffee,
    faCompass,
    faEllipsis,
    faEnvelope,
    faFaceLaughSquint,
    faFieldHockey,
    faFish,
    faFootballBall,
    faFutbol,
    faGamepad,
    faGamepadModern,
    faGift,
    faGlobe,
    faGolfBall,
    faGrill,
    faHandsClapping,
    faHeart,
    faHockeyPuck,
    faHorse,
    faHotel,
    faIdBadge,
    faLock,
    faMagnifyingGlass,
    faNewspaper,
    faPaperPlane,
    faPartyHorn,
    faPersonBiking,
    faPersonRunning,
    faPersonWalkingLuggage,
    faQuestion,
    faRacquet,
    faSealQuestion,
    faShop,
    faShoppingBag,
    faShuttlecock,
    faSpa,
    faSwimmer,
    faTennisBall,
    faThumbsDown,
    faThumbsUp,
    faTicket,
    faTrophy,
    faUmbrellaBeach,
    faUser,
    faUserSlash,
    faUsers,
    faVolleyballBall,
    faMeat,
    faLeaf,
    faSeedling,
    faSalad,
    faPotFood,
    faBadgePercent,
    faSnowflake,
    faBasketballBall,
    faLightbulbOn,
    faHandHoldingHeart,
    faStar,
    faDumbbell,
    faGraduationCap,
    faBookOpenCover,
    faChildReaching,
    faRulerHorizontal,
    faCalendarDays,
    faBeerFoam,
    faHome,
    faInbox,
    faClock,
    faIslandTreePalm,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faHome as farHome,
    faCalendar as fasCalendar,
    faInbox as fasInbox,
    faFerrisWheel as farFerrisWheel,
} from "@fortawesome/pro-regular-svg-icons";
import { ReactNode } from "react";

// Map of string icon names to their corresponding FontAwesome icon definitions
const iconMap: { [key: string]: IconDefinition } = {
    coffee: faCoffee,
    heart: faHeart,
    envelope: faEnvelope,
    "gamepad-modern": faGamepadModern,
    ticket: faTicket,
    "shopping-bag": faShoppingBag,
    hotel: faHotel,
    globe: faGlobe,
    spa: faSpa,
    1: fa1,
    2: fa2,
    question: faQuestion,
    futbol: faFutbol,
    "field-hockey": faFieldHockey,
    basketball: faBasketball,
    racquet: faRacquet,
    "person-running": faPersonRunning,
    "boxing-glove": faBoxingGlove,
    "person-biking": faPersonBiking,
    "thumbs-up": faThumbsUp,
    "thumbs-down": faThumbsDown,
    "face-laugh-squint": faFaceLaughSquint,
    "party-horn": faPartyHorn,
    "beer-mug": faBeerMug,
    gift: faGift,
    grill: faGrill,
    user: faUser,
    "football-ball": faFootballBall,
    circle: faCircle,
    bullseye: faBullseye,
    shuttlecock: faShuttlecock,
    "volleyball-ball": faVolleyballBall,
    swimmer: faSwimmer,
    horse: faHorse,
    "hockey-puch": faHockeyPuck,
    "golf-ball": faGolfBall,
    fish: faFish,
    "tennis-ball": faTennisBall,
    shop: faShop,
    "umbrella-beach": faUmbrellaBeach,
    "book-open": faBookOpen,
    "seal-question": faSealQuestion,
    "person-walking-luggage": faPersonWalkingLuggage,
    "paper-plane": faPaperPlane,
    compass: faCompass,
    "magnify-glass": faMagnifyingGlass,
    ellipsis: faEllipsis,
    "hands-clapping": faHandsClapping,
    calendar: faCalendar,
    users: faUsers,
    gamepad: faGamepad,
    "id-badge": faIdBadge,
    lock: faLock,
    "shopping-cart": faCartShopping,
    "cards-blank": faCardsBlank,
    bell: faBell,
    "bell-slash": faBellSlash,
    "user-slash": faUserSlash,
    trophy: faTrophy,
    newspaper: faNewspaper,
    meat: faMeat,
    leaf: faLeaf,
    seedling: faSeedling,
    salad: faSalad,
    "pot-food": faPotFood,
    "badge-percent": faBadgePercent,
    snowflake: faSnowflake,
    "basketball-ball": faBasketballBall,
    "lightbulb-on": faLightbulbOn,
    "hand-holding-heart": faHandHoldingHeart,
    star: faStar,
    dumbbell: faDumbbell,
    "graduation-cap": faGraduationCap,
    "book-open-cover": faBookOpenCover,
    "child-reaching": faChildReaching,
    "ruler-horizontal": faRulerHorizontal,
    "calendar-days": faCalendarDays,
    "Beer-foam": faBeerFoam,
    home: faHome,
    inbox: faInbox,
    farHome: farHome,
    fasCalendar,
    fasInbox,
    "island-tree-palm": faIslandTreePalm,
    clock: faClock,
    ferrisWheel: farFerrisWheel,
};

let libraryLoaded = false;

/**
 * Load font awesome icon so they can be rendered dynamically from database configuration (eg. brand configuration)
 */
export const LoadIcons = () => {
    if (libraryLoaded) return;
    Object.keys(iconMap).forEach((iconName) => {
        library.add(iconMap[iconName]);
    });
    libraryLoaded = true;
};

/**
 * Load dynamic fontawesome icons from database configuration.
 * It triggers the parent Suspense.
 */
export default function FontAwesomeIconWrapper({ children }: { children: ReactNode }) {
    const asyncLoadIcons = async () => {
        await new Promise((resolve) => {
            LoadIcons();
            resolve("success");
        });
    };

    asyncLoadIcons();

    return <>{children}</>;
}
