import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Article } from "../../service/ClubService";
import { Coupon } from "../coupon/CouponCard";
import { faPartyHorn, faShoppingCart, faStore, faTrophy, faGift, faNewspaper } from "@fortawesome/pro-solid-svg-icons";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";

type CouponContentComponentProp = {
    type: "coupon";
    coupon: Coupon;
};

type ArticleContentComponentProp = {
    type: "article";
    article: Article;
};

export type ContentComponentProp =
    | CouponContentComponentProp
    | any
    | ArticleContentComponentProp
    | {
          type: "city";
      };

type IconStyleType = {
    width?: number;
    height?: number;
    color?: string;
};

export default function ContentComponentAvatarIcon({
    iconType,
    width,
    height,
    color,
}: {
    iconType: ContentComponentProp;
} & IconStyleType) {
    switch (iconType.type) {
        case "article":
            if (iconType.article !== undefined) {
                return <ArticleIcon article={iconType.article} width={width} height={height} color={color} />;
            }
            break;
    
        case "coupon":
            if (iconType.coupon !== undefined) {
                return <CouponIcon coupon={iconType.coupon} width={width} height={height} color={color} />;
            }
            break;
    }

    return null;
}

function CouponIcon({
    coupon,
    width,
    height,
    color,
}: {
    coupon: Coupon;
} & IconStyleType) {
    const { brandTheme } = useBrandConfigProvider().getByID(coupon?.brand_id);

    const iconStyle = {
        color: color ?? brandTheme?.palette.tertiairTextColor ?? brandTheme?.palette.textColor,
        width,
        height,
    };

    if (coupon.type === "giveaway") return <FontAwesomeIcon style={iconStyle} icon={faTrophy} />;
    if (coupon.type === "birthday") return <FontAwesomeIcon style={iconStyle} icon={faPartyHorn} />;
    if (coupon.type == "store") return <FontAwesomeIcon style={iconStyle} icon={faStore} />;
    if (coupon.type == "online" && coupon.required_attributes?.length > 0)
        return <FontAwesomeIcon style={iconStyle} icon={faShoppingCart} />;

    return <FontAwesomeIcon style={iconStyle} icon={faGift} />;
}

function ArticleIcon({
    article,
    width,
    height,
    color,
}: {
    article: Article;
} & IconStyleType) {
    const { brandTheme } = useBrandConfigProvider().getByID(article.brand_id);

    const iconStyle = {
        color: color ?? brandTheme?.palette.tertiairTextColor ?? brandTheme?.palette.textColor,
        width,
        height,
    };
    return <FontAwesomeIcon icon={faNewspaper} style={iconStyle} />;
}
