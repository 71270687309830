import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

import { NavigateFunction } from "react-router-dom";
import { safeAddQueryParameter } from "../../utils/SafeAddQueryParameter";
import CryptoJS from "crypto-js";
import { env } from "../../env.mjs";

export function brandNavigationRouter(url: string, brandId?: string, navigate?: NavigateFunction) {
    return new Promise((resolve, reject) => {
        let baseUrl = env.customerPortalUrl || window.location.origin;
        let encryptedCredentials: CryptoJS.lib.CipherParams | null = null;
        let parsedUrl: string | undefined;

        if (isRelativePath(url) && navigate) {
            navigate(new URL(url).pathname);
            resolve("success");
            return;
        }

        // bigcommerce. The url is already encrypted. The partner's webshop will decreypt
        if (url.includes("/login/token")) {
            parsedUrl = getBigCommerceRedirectUrl(url);
        } else if (url.startsWith("https://")) {
            try {
                openNewTab(url);
                resolve("success");
            } catch {
                reject("error");
            }
        } else if (url.includes("/woocommerce-redirect")) {
            parsedUrl = getWooCommerceRedirectUrl(url);
        }
        //  shopify. it requires a form submission hack to do sso, in iOS it's blocked, so we open an intermediate page first
        else if (url.includes("/shopify-redirect")) {
            if (Capacitor.getPlatform() === "ios") {
                encryptedCredentials = getShopifyRedirectParams(url) || "";

                if (!encryptedCredentials) return;

                const searchParams = new URLSearchParams({
                    credentials: encryptedCredentials.toString(),
                });

                Browser.open({ url: `${baseUrl}/brand/inloggen/${brandId}?${searchParams.toString()}` })
                    .then(() => {
                        resolve("success");
                    })
                    .catch(() => {
                        reject("error");
                    });
            } else {
                const form = directShopifyLogin(url);
                form.submit();
                resolve("success");
            }
        }
        if (!parsedUrl) return;

        try {
            openNewTab(parsedUrl);
            resolve("success");
        } catch {
            reject("error");
        }
    });
}

function openNewTab(parsedUrl: string) {
    const target = Capacitor.getPlatform() === "ios" ? "_self" : "_blank";
    window.open(parsedUrl, target);
}

function isRelativePath(url: string): boolean {
    // in dev-app and production, the webview runs on localhost. But localhost is being used when developing locally
    if (!Capacitor.isNativePlatform() && window.location.hostname === "localhost") {
        if (url.includes("https://mijn-dev.any-id.nl/")) return true;
    }

    if (url.includes(env.customerPortalUrl)) return true;

    return false;
}

function getBigCommerceRedirectUrl(redirectPath: string) {
    return redirectPath;
}

export function getWooCommerceRedirectUrl(redirectPath: string): string {
    const searchParams = retrieveQueryParams(redirectPath);

    const paramsToAdd = [
        { name: "user", value: searchParams.get("email") || "" },
        { name: "pw", value: searchParams.get("password") || "" },
        { name: "coupon", value: searchParams.get("coupon") },
        { name: "redirect_url", value: searchParams.get("redirect_url") },
    ];

    const newParams = new URLSearchParams();
    for (const { name, value } of paramsToAdd) {
        if (value) {
            newParams.append(name, value);
        }
    }

    return searchParams.get("login_url") + "?" + newParams.toString();
}

export function getShopifyRedirectParams(redirectPath: string) {
    const params = retrieveQueryParams(redirectPath);
    const paramsAsObject = Object.fromEntries(params.entries());

    const loginUrl = params.get("login_url") || "";
    const redirectUrl = params.get("redirect_url") || "";

    if (redirectUrl) {
        paramsAsObject.login_url = safeAddQueryParameter(loginUrl, `return_url=${redirectUrl}`);
    }

    return CryptoJS.AES.encrypt(JSON.stringify(paramsAsObject), env.authenticationEncryptionSecret);
}

export function retrieveQueryParams(url: string) {
    const index = url.indexOf("?");
    if (index === -1) {
        throw new Error('URL does not contain "?", unable to retrieve parameters.');
    }

    return new URLSearchParams(url.substring(index + 1));
}

export function directShopifyLogin(redirectPath: string) {
    const params = retrieveQueryParams(redirectPath);

    let login_url = params.get("login_url") || "";
    const redirect_url = params.get("redirect_url");

    if (redirect_url) login_url = safeAddQueryParameter(login_url, "return_url=" + redirect_url);

    const valuesToAdd = [
        { name: "customer[email]", value: params.get("email") || "" },
        { name: "customer[password]", value: params.get("password") || "" },
        { name: "form_type", value: "customer_login" },
        { name: "checkout_url", value: redirect_url ?? "" },
    ];

    const formId = "shopify-redirect-form";

    const form = document.createElement("form");
    form.id = formId;
    form.action = login_url;
    form.method = "post";
    form.style.display = "none";

    // in mobile, the browser will make a normal post request without sending form data. So we don't set target and the OS will open the browser app.
    if (!Capacitor.isNativePlatform()) form.target = "_blank";

    for (const { name, value } of valuesToAdd) {
        const formInput = document.createElement("input");
        formInput.type = "hidden";
        formInput.name = name;
        formInput.value = value;

        form.appendChild(formInput);
    }

    window.document.body.appendChild(form);

    return form;
}
