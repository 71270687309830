import { createContext, ReactNode, useState, useContext } from "react";

type AnimationContext = {
    showHeroAnimation: boolean;
    setShowHeroAnimation: (state: boolean) => void;
    showMvpAnimation: boolean;
    setShowMvpAnimation: (state: boolean) => void;
};

const animationContext = createContext<AnimationContext | undefined>(undefined);

export const AnimationProvider = ({ children }: { children?: ReactNode }) => {
    const [showHeroAnimation, setShowHeroAnimation] = useState(true);
    const [showMvpAnimation, setShowMvpAnimation] = useState(true);

    return (
        <animationContext.Provider
            value={{
                showHeroAnimation,
                setShowHeroAnimation,
                showMvpAnimation,
                setShowMvpAnimation,
            }}
        >
            {children}
        </animationContext.Provider>
    );
};

export const useAnimationProvider = () => {
    const context = useContext(animationContext);
    if (!context) {
        throw Error("must use useAnimationProvider in animation context provider");
    }
    return context;
};
