import { faAngleRight, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Grid, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FadeContainer from "../../components/Container/FadeContainer";
import { useClubProvider } from "../../service/ClubService";
import { useCouponsByBrandId } from "../../service/api/couponService";
import { Coupon } from "../../components/coupon/CouponCard";
import { CouponDialog } from "../../components/Club/CouponDialog";
import { logEventWithCouponId } from "../../service/AnalyticsService";
import { ImageWithFallback } from "../../components/Image/ImageWithFallback";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { PartnerHighlight } from "../../components/Club/PartnerHighlight";

export default function ClubPartnersPage() {
    const { brand_config, brand_id } = useClubProvider();

    const navigate = useNavigate();

    useEffect(() => {
        if (brand_config.type === "bso") {
            navigate(`/club/bso/gifts/${brand_id}`);
        }
    }, [brand_config]);

    return (
        <FadeContainer short>
            <title>{t("club.tabTitle.partners", { brandName: brand_config.name })}</title>
            <PartnerOfTheWeek />
            <AllRewards />
            <FindMoreBrandsSection />
        </FadeContainer>
    );
}

const PartnerOfTheWeek = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { brand_config } = useClubProvider();

    return (
        <>
            <div
                style={{
                    fontSize: "20px",
                    margin: theme.spacing(2, 0),
                }}
            >
                {t("club.partners.week").toUpperCase()}
            </div>

            <PartnerHighlight
                detailed
                coupon_id={brand_config.environment?.partner.coupon_id!}
                brand_id={brand_config.environment?.partner.brand_id!}
                logo_background={brand_config.environment?.partner.logo_background}
            />
        </>
    );
};

const AllRewards = () => {
    const { brand_id } = useClubProvider();
    const theme = useTheme();

    const { data: coupons } = useCouponsByBrandId(brand_id, {
        suspense: true,
    });

    return (
        <div style={{ marginTop: theme.spacing(4) }}>
            <div
                style={{
                    fontSize: "20px",
                    marginBottom: theme.spacing(2),
                }}
            >
                {t("club.partners.more").toUpperCase()}
            </div>
            {coupons && (
                <Grid container spacing={{ xs: theme.spacing(2), md: 3 }}>
                    {coupons.map((coupon) => {
                        return (
                            <Grid item xs={6} key={coupon.id} height={"100%"}>
                                <Reward coupon={coupon} brandId={brand_id} />
                            </Grid>
                        );
                    }, [])}
                </Grid>
            )}
        </div>
    );
};

type RewardProps = {
    coupon: Coupon;
    brandId: string;
};
const Reward = ({ coupon, brandId }: RewardProps) => {
    const { brand_config: brandConfig } = useClubProvider();
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    return (
        <div>
            <CouponDialog
                coupon={coupon}
                brandId={brandId}
                dialog={{
                    open: open,
                    onClose: () => {
                        setOpen(false);
                    },
                }}
            />
            <Card
                sx={{
                    backgroundColor: "brandConfig.environment?.colors.text",
                    borderRadius: "0px",
                    height: "270px",
                }}
                onClick={() => {
                    logEventWithCouponId("coupon_view", coupon.id); // assuming coupon.id is the correct attribute for couponId
                    setOpen(true);
                }}
            >
                <CardActionArea>
                    <CardMedia
                        sx={{
                            height: "100%",
                        }}
                    >
                        <RewardImage brandID={coupon.brand_id} image={coupon.image} />
                    </CardMedia>
                    <CardContent
                        sx={{
                            backgroundColor: brandConfig.environment?.colors.text,
                            padding: theme.spacing(1),
                        }}
                    >
                        <div
                            style={{
                                color: "#000000",
                                fontFamily: brandConfig.environment?.fonts.secondary,
                                fontSize: "16px",
                                fontWeight: 500,
                            }}
                        >
                            {coupon.name}
                        </div>
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            style={{
                                margin: 8,
                            }}
                        />
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};

const RewardImage = ({ brandID, image }: { brandID: string; image: string }) => {
    return (
        <div
            style={{
                position: "relative",
                overflow: "hidden",
            }}
        >
            <ImageWithFallback
                className="background-shadow"
                src={image}
                fallBackUrl={image?.replace(/\.webp$/, ".jpg")}
                type="rectangular"
                containerSx={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    objectPosition: "center",
                    display: "block",
                }}
            />
            <div
                style={{
                    position: "absolute",
                    inset: 16,
                    border: "0.55px solid white",
                    borderTop: "none",
                    boxSizing: "border-box",
                }}
            >
                <BrandIcon brandId={brandID} shape="rectangular" />
            </div>
        </div>
    );
};

function FindMoreBrandsSection() {
    const { brand_config } = useClubProvider();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Box mt={2}>
            <Typography variant="h4" mb={1}>
                {t("club.partners.findMoreBrands", { brandName: brand_config.name })}
            </Typography>
            <Button
                fullWidth
                endIcon={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "1em" }} />}
                onClick={() => {
                    navigate("/aangesloten-merken");
                    window.scroll(0, 0);
                }}
            >
                {t("club.partners.findMore")}
            </Button>
        </Box>
    );
}
