import { SxProps, useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag } from "@fortawesome/pro-solid-svg-icons";
import { ImageWithFallback, ImageWithFallbackShape } from "../../../../components/Image/ImageWithFallback";
import { useMemo } from "react";
import { useBrandMediaContentProvider } from "../../../../service/mediaContentService";
import { useBrandConfigProvider } from "../../../../service/BrandConfigProvider";

type BrandIconProps = {
    brandId?: string;
    width?: string;
    sx?: SxProps;
    shape?: ImageWithFallbackShape;
    objectFit?: "contain"|"cover"|"fill"|"none"|"scale-down";
};

export const BrandIcon = ({ brandId, width = "53px", sx, shape,objectFit }: BrandIconProps) => {
    const theme = useTheme();
    const { brandTheme } = useBrandConfigProvider().getByID(brandId ?? "");

    const { GetBrandMediaContent } = useBrandMediaContentProvider();

    const data = GetBrandMediaContent({
        brandId: brandId ?? "",
        directory: "icon",
        metadata: {
            lang: ["nl", "en"],
            type: ["image"],
        },
    });

    const src = useMemo(() => {
        if (!data) return;

        return data.Url;
    }, [data]);

    return (
        <ImageWithFallback
            src={src}
            fallBackSlotIcon={<FontAwesomeIcon style={{ color: theme.palette.icon.main }} icon={faShoppingBag} />}
            containerSx={{
                width,
                height: width,
                backgroundColor: data ? brandTheme?.palette.backgroundColorLogo : theme.palette.card.dark,
                ...sx,
            }}
            alt="brand-logo"
            type={shape}
            objectFit={objectFit}
        />
    );
};
