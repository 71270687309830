import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainContent } from "../../components/Container/MainContent";
import { AnyDevTools } from "../../components/Development/AnyDevTools";
import { ErrorFallback } from "../../components/Error/Error";
import i18n from "../../i18n";
import { AppUrlListener } from "../../pages/auth/native/AppUrlListener";
import { CapacitorProvider } from "../capacitor/CapacitorProvider";
import SecureStorageService from "../capacitor/SecureStorageService";
import { AuthBodyWeb, ParsedAuthBody } from "./AuthStorage.interface";
import { parseAuthBody, parseJwt } from "./AuthStorageService";
import { keycloakTokenCall, loginKeycloakWeb } from "./OAuthService";
import PrivateRouter, { isPublicPage } from "./PrivateRouter";

export interface AuthContextInterface {
    authBody: ParsedAuthBody | undefined;
    setAuthBody: (authBody: ParsedAuthBody) => Promise<void>;
    authLoading: boolean;
    tokenExpiresAt: Date;
    refreshExpiresAt: Date;
    authBioVerified: boolean;
    setAuthBioVerified: (newValue: boolean) => void;
    setAuthLoading: (newValue: boolean) => void;
    emptyCache: () => void;
    handleCallbackResponse: (response: any, redirectUrl?: string) => void;
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

export function AuthProvider({ children }: any) {
    const [authBody, setAuthBody] = useState<ParsedAuthBody | undefined>();
    const [authLoading, setAuthLoading] = useState(true);
    const [tokenExpiresAt, setTokenExpiresAt] = useState(new Date(0));
    const [refreshExpiresAt, setRefreshExpiresAt] = useState(new Date(0));
    const [authBioVerified, setAuthBioVerified] = useState(!Capacitor.isNativePlatform() ? true : false); // web platform don't need to verify with bio authentication

    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/callback") {
            dispatchCallback();
        } else {
            initAuthBody();
        }
    }, []);

    async function setBody(parsedAuthBody: ParsedAuthBody, init: boolean = false) {
        if (!init) await SecureStorageService.setAuthentication(parsedAuthBody);
        setTokenExpiresAt(new Date((parsedAuthBody.jwt.exp || 0) * 1000));
        setRefreshExpiresAt(new Date((parsedAuthBody.refresh.exp || 0) * 1000));
        setAuthBody(parsedAuthBody);
    }

    function emptyCache() {
        setTokenExpiresAt(new Date(0));
        setRefreshExpiresAt(new Date(0));
        setAuthBody(undefined);
        SecureStorageService.logout();
    }

    async function initAuthBody() {
        try {
            const storedAuthentication = await SecureStorageService.getAuthentication();
            if (storedAuthentication) {
                await setBody(storedAuthentication.authBody, true);
                await i18n.changeLanguage(storedAuthentication.authBody.jwt.locale);
                setAuthLoading(false);
                return;
            }
        } catch (e) {
            console.error("Unable to retrieve init authBody", e);
        } finally {
            setAuthLoading(false);
        }

        if (Capacitor.isNativePlatform() || isPublicPage()) {
            setAuthLoading(false);
        } else {
            loginKeycloakWeb();
        }
    }

    function dispatchCallback() {
        keycloakTokenCall()
            .then((response) => {
                handleCallbackResponse(response);
            })
            .catch(() => {
                window.location.href = "/home";
            });
    }

    async function handleCallbackResponse(response: any): Promise<void> {
        const authBody = {
            ...response,
            jwt: parseJwt(response.access_token),
        } as AuthBodyWeb;

        await setBody(parseAuthBody(authBody));

        const result = await Preferences.get({ key: "callbackRedirectPath" });

        if (result.value) {
            await Preferences.remove({ key: "callbackRedirectPath" });
            const targetPageUrl = result.value && new URLSearchParams(result.value.split("?")[1]).get("target-url");
            setAuthLoading(false);
            navigate(targetPageUrl || result.value || "/home", { replace: true });
            return;
        }

        setAuthLoading(false);
        navigate("/home");
    }

    return (
        <AuthContext.Provider
            value={{
                authBody,
                setAuthBody: setBody,
                authLoading,
                setAuthLoading,
                tokenExpiresAt,
                refreshExpiresAt,
                authBioVerified,
                setAuthBioVerified,
                emptyCache,
                handleCallbackResponse,
            }}
        >
            <CapacitorProvider>
                <ErrorFallback>
                    <AppUrlListener />
                    <AnyDevTools />
                    <MainContent>
                        <PrivateRouter>{children}</PrivateRouter>
                    </MainContent>
                </ErrorFallback>
            </CapacitorProvider>
        </AuthContext.Provider>
    );
}

export function useAuthProvider() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuthProvider must only be called in AuthProvider");
    }
    return context;
}
