import { useRef, useEffect } from "react";

export const Intersector = ({ callback }: { callback: IntersectionObserverCallback }) => {
    const observerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            callback,
            {
                threshold: 1,
            }, // Adjust the threshold as needed
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [observerRef.current]);

    return <div ref={observerRef}></div>;
};
