import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, ButtonBase } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
    href?: string | any;
    replace?: boolean;
    reload?: boolean;
};

export function BackButton(props: Props) {
    const { href } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <ButtonBase
            onClick={() => {
                navigate(href ?? -1, { replace: props.replace });

                // the reload behaviour is only used by error pages (Error page and NoConnection page)
                // we need to tell to react to go back in history, wait, and then reload the page
                if (props.reload) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }
            }}
            sx={{
                p: 1,
                mb: 2,
                display: "flex",
                alignItems: "center",
                backgroundColor: theme.palette.card.main,
                borderRadius: theme.shape.borderRadius + "px",
            }}
        >
            <FontAwesomeIcon style={{ marginRight: theme.spacing(1) }} icon={solid("chevron-left")} size="1x" />
            <Typography variant="subtitle1">{t("topNav.back")}</Typography>
        </ButtonBase>
    );
}
