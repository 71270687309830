type AnalyticsIdentifier =
    | "click_promotion"
    | "copy_to_clipboard"
    | "coupon_activate"
    | "coupon_close"
    | "coupon_unlock"
    | "coupon_view"
    | "delete_anyid"
    | "edit_data"
    | "login_from_anyid"
    | "navigate_to_brand"
    | "quiz_submit"
    | "quiz_submit_step"
    | "sign_up"
    | "share_data"
    | "sign_up_for_brand"
    | "link_season_ticket"
    | "toggle_consent";

type PromotionDescription = "to_app_store" | "to_play_store";

export function logEvent(identifier: AnalyticsIdentifier) {
    window.gtag("event", identifier);
}

export function logEventWithBrandId(identifier: AnalyticsIdentifier, brandId: string) {
    window.gtag("event", identifier, { brand_id: brandId });
}

export function logEventWithCouponId(identifier: AnalyticsIdentifier, couponId: string) {
    window.gtag("event", identifier, { coupon_id: couponId });
}

export function logEventWithWizardId(identifier: AnalyticsIdentifier, wizardId: string, stepIndex?: number) {
    window.gtag("event", identifier, { wizard_id: wizardId, step_index: stepIndex });
}

export function logEventWithDescription(identifier: AnalyticsIdentifier, promotionDescription: PromotionDescription) {
    window.gtag("event", identifier, { description: promotionDescription });
}
