import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import axios from "axios";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import qs from "qs";
import { useQueryClient } from "react-query";
import { env } from "../../env.mjs";
import { useQueryGet } from "../api/restApiQuery";
import { AuthContextInterface, useAuthProvider } from "./AuthProvider";
import { AuthBodyWeb, ParsedAuthBody } from "./AuthStorage.interface";
import { parseAuthBody, parseJwt, parseRefreshToken } from "./AuthStorageService";
import { OAuth2AuthenticateOptions, OAuth2Client, OAuth2RefreshTokenOptions } from "./capacitor-oauth2/dist/esm";
import { useNavigate } from "react-router-dom";

const accessTokenEndpoint = `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/token`;
export const resetCredentialUrl = `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/auth?client_id=${env.keycloakClientId}&redirect_uri=${window.location.href}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;

// config object for byteowl/OAuth2ClientPlugin plugin
// note: in iOS/Android, the /token call is made in native code (swift/java) which doesn't have Same-origin policy.
// On web token call is handled in the AnyID/JS app.
// The /code call is always done by the IDP page (`${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/auth`)
// On android < 10 versions the OS is outdated (it returns handshake error) and only supports up to TLS 1.2, so Keycloak must have lower TLS than other services.
export const oAuth2Options = {
    appId: env.keycloakClientId,
    authorizationBaseUrl: `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/auth`,
    accessTokenEndpoint: `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/token`,
    resourceUrl: `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/userinfo`,
    pkceEnabled: true,
    scope: "openid",
    web: {
        responseType: "code",
        pkceEnabled: true,
        redirectUrl: window.location.origin + "/callback",
        windowTarget: "_self",
    },
    android: {
        responseType: "code",
        pkceEnabled: true,
        redirectUrl: env.bundleId + ":/",
    },
    ios: {
        pkceEnabled: true,
        responseType: "code",
        redirectUrl: env.bundleId + ":/",
    },
} as OAuth2AuthenticateOptions;

export async function loginKeycloakNative(
    authProvider: AuthContextInterface,
    startPage?: "login" | "register",
): Promise<ParsedAuthBody | undefined> {
    const { setAuthBody, setAuthLoading } = authProvider;

    const options = { ...oAuth2Options };
    if (startPage === "register") {
        options.authorizationBaseUrl = `${env.keycloakUrl}/realms/${env.keycloakRealm}/protocol/openid-connect/registrations`;
    }

    await Preferences.set({
        key: "callbackRedirectPath",
        value: window.location.pathname + window.location.search,
    });

    setAuthLoading(true);

    let accessTokenEndpointResult = null;

    try {
        // ios: use the OAuth2Client to get auth code and access token
        accessTokenEndpointResult = await OAuth2Client.authenticate(options);

        const authBody = {
            ...accessTokenEndpointResult,
            jwt: parseJwt(accessTokenEndpointResult.access_token),
        } as AuthBodyWeb;

        const parsedAuthBody = parseAuthBody(authBody);
        await setAuthBody(parsedAuthBody);

        setAuthLoading(false);
        return parsedAuthBody;
    } catch (error) {
        console.error("Keycloak native login failed", error);
        setAuthLoading(false);
        return undefined;
    }
}

export async function loginKeycloakWeb() {
    await Preferences.set({
        key: "callbackRedirectPath",
        value: window.location.pathname + window.location.search,
    });

    return await OAuth2Client.authenticate(oAuth2Options);
}

const oAuth2RefreshTokenOptions = {
    appId: env.keycloakClientId,
    accessTokenEndpoint: `${env.keycloakUrl}/realms/GlobeProtocol/protocol/openid-connect/token`,
    // refreshToken must be set at refreshTokenKeycloak
} as OAuth2RefreshTokenOptions;

export async function refreshToken(authProvider: AuthContextInterface, refreshToken?: string) {
    const token = refreshToken || authProvider.authBody?.refreshToken;
    if (!Capacitor.isNativePlatform() || !token) throw new Error("Refresh token: No token available");

    const response = await OAuth2Client.refreshToken({
        ...oAuth2RefreshTokenOptions,
        refreshToken: token,
    }).then(async (result) => {
        const parsedAuthBody = parseRefreshToken(result);
        authProvider.setAuthBody(parsedAuthBody);

        return parsedAuthBody;
    });

    return response;
}

export function useLogoutKeycloak() {
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return () => {
        authProvider.setAuthLoading(true);
        axios({
            method: "post",
            url: env.keycloakUrl + "/realms/GlobeProtocol/protocol/openid-connect/logout",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: qs.stringify({
                client_id: env.keycloakClientId,
                refresh_token: authProvider.authBody?.refreshToken,
            }),
        }).finally(() => {
            queryClient.invalidateQueries();
            authProvider.emptyCache();
            navigate("/");
            authProvider.setAuthLoading(false);
        });
    };
}

export async function keycloakTokenCall() {
    const code = new URLSearchParams(window.location.search).get("code");

    if (!code) throw new Error("keycloakTokenCall: no code search param found in url");

    // retrieve PKCE code to verify
    const verifierOutOfSession = await (await SecureStoragePlugin.get({ key: "codeVerifier" })).value;
    if (!verifierOutOfSession) {
        throw new Error("keycloakTokenCall: no codeVerifier found in storage");
    }

    const res = await axios({
        method: "post",
        url: accessTokenEndpoint,
        headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
            code: code,
            grant_type: "authorization_code",
            client_id: oAuth2Options.appId,
            // despite not being used, redirect uri must match the one used in authorization request
            redirect_uri: window.location.origin + "/callback",
            code_verifier: verifierOutOfSession,
        }),
    }).then((res) => {
        return res.data;
    });

    return res;
}

export function useTokenHealthCheck() {
    const { authLoading, authBody } = useAuthProvider();

    useQueryGet(["user-info"], "keycloak", "/realms/GlobeProtocol/protocol/openid-connect/userinfo", {
        enabled: !authLoading && authBody !== undefined && Capacitor.isNativePlatform(),
        onError: (e: any) => {
            console.error("Token health check failed", e);
        },
    });
}
