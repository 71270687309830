import { Capacitor } from "@capacitor/core";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDown,
    faAngleUp,
    faArrowUpRightFromSquare,
    faBell,
    faBellSlash,
    faRightFromBracket,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress, Collapse, Drawer, Skeleton, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddURLParams, useAction } from "../../service/ActionProvider";
import { useAnimationProvider } from "../../service/AnimationProvider";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";
import { isPollActive, useClubProvider, usePollByBrandConfiguration } from "../../service/ClubService";
import { useGetAllInAppNotifications } from "../../service/InAppNotificationService";
import { useBrandById } from "../../service/api/brandService";
import { useGetBsoParent } from "../../service/api/bsoService";
import { Action, NavActionItemType } from "../../service/api/schemeValidator";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";
import {
    requestPushNotificationOSPermission,
    useGetNotificationConsentByBrandId,
    useNativePushNotificationPermission,
    useUpdateBrandNotificationConsent,
} from "../../service/capacitor/PushNotificationService";
import { MediaContentLanguage, useBrandMediaContentProvider } from "../../service/mediaContentService";
import ClubSeasonTicket from "../Club/ClubSeasonTicket";
import BrandNotificationSettingItem from "../InAppNotification/InAppNotificationSettingItem";
import { useSearchData } from "../WizardForm/WizardFormService";

export const ClubBottomNav = () => {
    const { openMoreMenu } = useSearchData<{
        openMoreMenu: boolean;
    }>();

    const [mobileKeyboardHeight, setMobileKeyboardHeight] = useState(0);

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { brandId } = useParams();
    const { t, i18n } = useTranslation();

    const { getAction, state } = useAction(brandId!);
    const { data: brand_config } = useBrandConfigProvider().getByID(brandId!);
    const { showHeroAnimation } = useAnimationProvider();

    const [firstOpenMoreMenu, setFirstOpenMoreMenu] = useState(openMoreMenu);

    const { GetBrandMediaContent } = useBrandMediaContentProvider();
    const media = GetBrandMediaContent({
        brandId: brandId ?? "",
        directory: "icon",
        metadata: {
            lang: [i18n.language as MediaContentLanguage],
            type: ["image", "vector", "video"],
        },
    });

    //The check for brand_config._id is required so its properly loaded
    if (brand_config && brand_config._id && !brand_config.environment) {
        navigate("/brand/" + brandId);
    }

    // animate only at club homepage and only once
    const shouldAnimate =
        (brand_config?.environment?.heroAnimation ?? true) &&
        window.location.pathname === `/club/${brandId}` &&
        showHeroAnimation;

    function keyboardWillShow(ev: any) {
        const keyboardHeight = ev.keyboardHeight;
        setMobileKeyboardHeight(keyboardHeight);
    }
    function keyboardWillHide() {
        setMobileKeyboardHeight(0);
    }

    // side effect to open for first time the drawer (eg. after linking ticket)
    useEffect(() => {
        if (!shouldAnimate && firstOpenMoreMenu) {
            getAction({ type: "toggle", key: "more" })();
            setFirstOpenMoreMenu((prev) => !prev);
        }
    }, [shouldAnimate, firstOpenMoreMenu]);

    useEffect(() => {
        window.addEventListener("keyboardWillShow", (ev: any) => {
            keyboardWillShow(ev);
        });

        window.addEventListener("keyboardWillHide", () => {
            keyboardWillHide();
        });

        return () => {
            window.removeEventListener("keyboardDidShow", keyboardWillShow);
            window.removeEventListener("keyboardWillHide", keyboardWillHide);
        };
    }, []);
    // if it is quiz page then return null
    if (!Capacitor.isNativePlatform()) {
        if (location.pathname.includes(`/public/club/quiz/`) || location.pathname.includes(`/club/quiz/`)) {
            return null;
        }
    }

    return (
        <motion.div
            initial={shouldAnimate ? { y: "90px" } : { y: "0" }}
            animate={shouldAnimate ? { y: 0, transition: { delay: 1.5, type: "tween" } } : { y: 0 }}
            style={{
                position: "fixed",
                bottom: `${-mobileKeyboardHeight}px`,
                left: 0,
                right: 0,
                borderTop: `1px solid ${brand_config?.environment?.bottomNav.colors.text}4d`,
                fontFamily: brand_config?.environment?.bottomNav.fonts.primary,
                color: brand_config?.environment?.bottomNav.colors.text,
                backgroundColor: brand_config?.environment?.bottomNav.colors.primary,
                zIndex: 100,
            }}
            transition={{
                type: "just",
            }}
        >
            <div
                style={{
                    display: "grid",
                    padding: theme.spacing(2, 2),
                    gridTemplateColumns: brand_config?.environment?.bottomNav.items.map(() => "1fr").join(" "),
                    textAlign: "center",
                    overflow: "hidden",
                }}
            >
                {brand_config?.environment &&
                    brand_config?.environment.bottomNav.items
                        .filter((x) => x.enabled)
                        .map((navItem) => {
                            return (
                                <NavButton
                                    key={navItem.title}
                                    navItem={navItem}
                                    text={t(navItem.title).toUpperCase()}
                                    onClick={getAction(navItem.action)}
                                    selected={
                                        navItem.action.type === "navigate"
                                            ? location.pathname.includes(
                                                  AddURLParams(navItem.action.url, {
                                                      brandId: brandId ?? "",
                                                  }),
                                              )
                                            : false
                                    }
                                />
                            );
                        })}
            </div>
            <Drawer anchor="right" onClose={getAction({ type: "toggle", key: "more" })} open={state.more}>
                <MoreDrawer logo={media ? media.Url : ""} />
            </Drawer>
        </motion.div>
    );
};

export default ClubBottomNav;

type NavButtonProps = {
    text: string;
    navItem: NavActionItemType;
    onClick: () => any;
    selected: boolean;
};

const NavButton = (props: NavButtonProps) => {
    const { brand_config } = useClubProvider();

    if (brand_config.type === "sport" || brand_config.type === "club") {
        return <HdbNavButton {...props} />;
    }

    return <BsoNavButton {...props} />;
};

const HdbNavButton = ({ text, navItem, onClick, selected }: NavButtonProps) => {
    const { brandId } = useParams();
    const { data: brand_config } = useBrandConfigProvider().getByID(brandId!);

    const { i18n } = useTranslation();

    const { GetBrandMediaContent } = useBrandMediaContentProvider();
    const media = GetBrandMediaContent({
        brandId: brandId ?? "",
        directory: "icon",
        metadata: {
            lang: [i18n.language as MediaContentLanguage],
            type: ["image", "vector", "video"],
        },
    });

    return (
        <div
            onClick={onClick}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    borderRadius: "100px",
                    width: "31px",
                    height: "31px",
                    backgroundColor: brand_config?.environment?.bottomNav.colors.text,
                    color: selected
                        ? brand_config?.environment?.bottomNav.colors.secondary
                        : brand_config?.environment?.bottomNav.colors.primary,
                    boxShadow: selected
                        ? `0px 0px 30px 20px ${brand_config?.environment?.bottomNav.colors.secondary}`
                        : "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {navItem.icon === "logo" ? (
                    <img src={media ? media.Url : ""} width={"31px"} />
                ) : (
                    <FontAwesomeIcon icon={{ iconName: navItem.icon as IconName, prefix: "fas" }} size="xs" />
                )}
            </div>
            <div style={{ marginTop: "4px", fontSize: "12px" }}>{text}</div>
        </div>
    );
};

const BsoNavButton = ({ text, navItem, onClick, selected }: NavButtonProps) => {
    const { brand_id } = useClubProvider();
    const { data: bsoParent } = useGetBsoParent();

    const bsos = useMemo(() => {
        if (!bsoParent) return undefined;

        const hash = new Map<string, string>();

        hash.set(brand_id, "exists");

        bsoParent.children.forEach((ch) => {
            ch.bso_ids.forEach((id) => hash.set(id, "exists"));
        });

        return Array.from(hash, (map) => map[0]).join(",");
    }, [bsoParent]);

    const { data: sections } = useGetAllInAppNotifications(bsos ? bsos : brand_id);

    const badgeCounter = useMemo(() => {
        let badgeCounter = 0;
        if (!sections) return badgeCounter;
        for (const section of sections) {
            for (const notification of section.notifications) {
                if (notification.status === "first_loaded") {
                    badgeCounter++;
                }
            }
        }

        return badgeCounter;
    }, [sections]);

    const theme = useTheme();

    return (
        <div
            onClick={onClick}
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                {navItem.title === "club.nav.messages" && badgeCounter > 0 && (
                    <div
                        style={{
                            position: "absolute",
                            borderRadius: "100%",
                            background: theme.palette.tertiair.main,
                            transform: "translate(8px, -8px)",
                            fontSize: "10px",
                            lineHeight: "18px",
                            color: theme.palette.tertiair.contrastText,
                            top: 0,
                            right: -4,
                            width: "17px",
                            height: "17px",
                        }}
                    >
                        {badgeCounter > 9 ? "9+" : badgeCounter}
                    </div>
                )}
                <FontAwesomeIcon icon={{ iconName: navItem.icon as IconName, prefix: selected ? "fas" : "far" }} />
            </div>
            <Typography variant="h9" fontWeight={700} mt={0.5}>
                {text}
            </Typography>
        </div>
    );
};

const MoreDrawer = ({ logo }: { logo: string }) => {
    const { insets } = useCapacitorProvider();
    const theme = useTheme();
    const navigate = useNavigate();
    const { brandId } = useParams();

    const { userCards } = useClubProvider();

    const { data: brand_config } = useBrandConfigProvider().getByID(brandId!);

    const { data: notificationConsent, isLoading: notificationConsentLoading } =
        useGetNotificationConsentByBrandId(brandId);
    const { mutateAsync: updateConsent } = useUpdateBrandNotificationConsent(brandId ?? "");

    const { enqueueSnackbar } = useSnackbar();

    const hasNativePushNotificationPermission = useNativePushNotificationPermission([
        notificationConsentLoading,
        notificationConsent,
    ]);

    const queryClient = useQueryClient();
    const { getAction } = useAction(brandId ?? "");

    const { t } = useTranslation();

    if (!brand_config?.environment) return <></>;

    return (
        <div
            style={{
                width: "60vw",
                height: "100%",
                backgroundColor: brand_config?.environment?.bottomNav.colors.primary,
                fontFamily: brand_config?.environment?.bottomNav.fonts.primary,
                paddingTop: insets?.top,
                paddingBottom: insets?.bottom,
            }}
        >
            <MoreLogo url={logo} />
            {userCards && (
                <Box mx={2}>
                    <ClubSeasonTicket />
                </Box>
            )}

            <div>
                {brand_config?.environment?.sideNav?.items
                    .filter((x) => x.enabled)
                    .map((item) => {
                        if ("action" in item) {
                            return (
                                <MoreItem
                                    key={item.title}
                                    title={item.title}
                                    icon={<FontAwesomeIcon icon={{ iconName: item.icon as IconName, prefix: "fas" }} />}
                                    description={item.description}
                                    action={item.action}
                                    onClick={getAction(item.action)}
                                />
                            );
                        }
                        return (
                            <MoreItem
                                key={item.title}
                                title={item.title}
                                icon={<FontAwesomeIcon icon={{ iconName: item.icon as IconName, prefix: "fas" }} />}
                                collapseItems={item.collapseItems.map((colItem) => {
                                    return {
                                        icon: colItem.icon,
                                        title: colItem.title,
                                        onClick: getAction(colItem.action),
                                    };
                                })}
                            />
                        );
                    })}

                {/* notification button */}
                {Capacitor.isNativePlatform() && !notificationConsentLoading && (
                    <Box
                        my={-3}
                        onClick={(e) => {
                            if (!hasNativePushNotificationPermission) {
                                e.preventDefault();
                                requestPushNotificationOSPermission(
                                    async () => {
                                        await updateConsent({
                                            body: {
                                                brand_id: brandId,
                                                consent: true,
                                            },
                                        });
                                        queryClient.invalidateQueries({
                                            queryKey: ["notification-consent", brandId],
                                        });
                                    },
                                    () =>
                                        enqueueSnackbar({
                                            variant: "warning",
                                            message: t("notificationPermissionPopup.noNativePermission"),
                                        }),
                                );
                            }
                        }}
                    >
                        <BrandNotificationSettingItem
                            title={t("club.nav.notifications").toUpperCase()}
                            brandId={brandId ?? ""}
                            enabled={!!notificationConsent?.consent && !!hasNativePushNotificationPermission}
                            disabledBgColor="#717171"
                            mainContent={
                                <MoreItem
                                    key={"notification-menu-button"}
                                    title={t("club.nav.notifications")}
                                    icon={
                                        <FontAwesomeIcon
                                            icon={
                                                hasNativePushNotificationPermission && notificationConsent?.consent
                                                    ? faBell
                                                    : faBellSlash
                                            }
                                        />
                                    }
                                />
                            }
                        />
                    </Box>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: theme.spacing(6),
                }}
            >
                <Button
                    sx={{
                        backgroundColor: brand_config?.environment?.bottomNav.colors.secondary,
                        borderRadius: "0px",
                        fontFamily: brand_config?.environment?.bottomNav.fonts.primary,
                        width: "90%",
                        "&:hover": {
                            backgroundColor: brand_config?.environment?.bottomNav.colors.secondary,
                        },
                    }}
                    onClick={() => navigate("/home")}
                >
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <div style={{ marginLeft: theme.spacing(1), textTransform: "uppercase", fontSize: "13px" }}>
                        {t("club.backAnyID")}
                    </div>
                </Button>
            </div>
        </div>
    );
};

const MoreLogo = ({ url }: { url: string }) => {
    const theme = useTheme();

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
            }}
        >
            <img src={url} style={{ width: "60px" }} />
        </div>
    );
};

type BaseMoreItemProps = {
    icon: ReactNode;
    title: string;
    description?: string;
    size?: "lg" | "sm";
    action?: Action;
};

type MoreItemActionProps = BaseMoreItemProps & {
    onClick: () => void | Promise<unknown>;
};

type MoreItemCollapse = BaseMoreItemProps & {
    collapseItems: MoreItemActionProps[];
};

const MoreItem = (props: BaseMoreItemProps | MoreItemCollapse | MoreItemActionProps) => {
    const { icon, title, description, size, action } = props;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false); // for action type "brand_action"

    const theme = useTheme();
    const { t } = useTranslation();

    const { brand_id, brand_config } = useClubProvider();
    const { data: poll, isLoading: isLoadingPoll } = usePollByBrandConfiguration(brand_config, brand_id);

    const isMvpItem = title === "club.nav.mvp";
    const isMvpActive = !isLoadingPoll && poll && isPollActive(poll);

    return (isMvpItem ? isMvpActive : true) ? (
        <div
            onClick={
                "onClick" in props
                    ? () => {
                          if (action?.type === "brand_action") {
                              setLoading(true);
                              (props.onClick as () => Promise<unknown>)()
                                  .catch((err) => console.error(err))
                                  .finally(() => setLoading(false));
                          } else {
                              props.onClick();
                          }
                      }
                    : undefined
            }
            style={{
                display: "flex",
                flexDirection: "column",
                margin: `${size === "sm" ? "0px" : theme.spacing(2)} 0px`,
                cursor: "pointer",
            }}
        >
            <div
                style={{
                    display: "flex",
                    fontSize: size === "sm" ? "14px" : undefined,
                    fontFamily: size === "sm" ? brand_config.environment?.bottomNav.fonts.secondary : undefined,
                    marginBottom: theme.spacing(1),
                }}
            >
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            height: "24px",
                            width: theme.spacing(6),
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            size={"24px"}
                            sx={{
                                color: "#ffffff",
                            }}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            height: "collapseItems" in props ? "24px" : "",
                            alignItems: "collapseItems" in props ? "start" : "center",
                            justifyContent: "center",
                            width: theme.spacing(6),
                        }}
                    >
                        {icon}
                    </div>
                )}

                <div
                    style={{
                        margin: theme.spacing(0, 1),
                    }}
                >
                    <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={"collapseItems" in props ? () => setOpen(!open) : undefined}
                    >
                        <p style={{ margin: theme.spacing(0), userSelect: "none" }}>{t(title).toUpperCase()}</p>
                        {"collapseItems" in props && <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />}
                    </div>
                    <div
                        style={{
                            fontFamily: brand_config.environment?.bottomNav.fonts.secondary,
                            opacity: 0.7,
                        }}
                    >
                        {isMvpItem && isMvpActive ? <MvpDescription /> : description && t(description).toUpperCase()}
                    </div>
                </div>

                {props.action?.type === "external-navigate" && (
                    <div
                        style={{
                            display: "flex",
                            height: "collapseItems" in props ? "24px" : "",
                            alignItems: "center",
                            justifyContent: "center",
                            width: theme.spacing(6),
                            gap: theme.spacing(2),
                            marginLeft: "auto",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            {<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                        </div>
                    </div>
                )}
            </div>
            {"collapseItems" in props && (
                <Collapse in={open}>
                    <CollapseContent items={props.collapseItems} />
                </Collapse>
            )}
        </div>
    ) : (
        <></>
    );
};

const CollapseContent = ({ items }: { items: MoreItemActionProps[] }) => {
    const theme = useTheme();

    return (
        <div style={{ marginLeft: theme.spacing(2) }}>
            {items.map((item) => {
                return (
                    <MoreItem
                        key={item.title}
                        {...item}
                        icon={<FontAwesomeIcon icon={{ iconName: item.icon as IconName, prefix: "fas" }} />}
                        size="sm"
                    />
                );
            })}
        </div>
    );
};

function MvpDescription() {
    const { brand_config } = useClubProvider();
    const { t } = useTranslation();

    const { data: mvpPartnerBrand, isLoading: mvpPartnerBrandLoading } = useBrandById(
        brand_config.environment?.mvpPartner?.brand_id,
    );

    return (
        <>
            {!mvpPartnerBrandLoading ? (
                t("club.nav.mvpDescription", { brandName: mvpPartnerBrand?.name }).toUpperCase()
            ) : (
                <Skeleton />
            )}
        </>
    );
}
