import { useEffect, useState } from "react";
import { loginKeycloakWeb } from "../../service/auth/OAuthService";
import LoadingPage from "../error/Loading";
import { ErrorDialog } from "../../components/Error/ErrorDialog";
import { useTranslation } from "react-i18next";

type Props = {};

export default function RedirectIdpPage(props: Props) {
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        loginKeycloakWeb().catch(() => {
            setIsError(true);
        });
    }, []);

    if (isError) {
        return <ErrorDialog open action="retry" description={t("redirectIdp.errorMessage")} />;
    }

    return <LoadingPage />;
}
