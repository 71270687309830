import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Desktop = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default Desktop;
