import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import {
    registerPushNotifications,
    requestPushNotificationOSPermission,
    useGetNotificationConsentByBrandId,
    useNativePushNotificationPermission,
} from "../../service/capacitor/PushNotificationService";
import BrandNotificationSettingItem from "../InAppNotification/InAppNotificationSettingItem";

type ButtonText = {
    title: string;
    description: string;
    alternateDescription?: string;
};

type Props = {
    open: boolean;
    toggleOpen: () => void;

    iconSlot?: ReactNode;

    buttonType?: "button" | "switch";

    primaryButtonSx?: SxProps;
    secondaryButtonSx?: SxProps;

    // optionals
    brandId?: string;
    title?: string;
    description?: string;
    descriptionAlignment?: "center" | "left";
    buttonText?: ButtonText;

    handleEnableCallback?: () => void;
};

/**
 * Simple Notification popup used in coupon page.
 *
 * @param buttonType By default it is "button", DOES NOT update the Backend brand-consent. If set to "switch" it toggles brand-consent
 */
const NotificationPermissionPopup: FC<Props> = ({ open, toggleOpen, iconSlot, buttonType = "button", ...props }) => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useTranslation();

    const hasNativePushNotificationPermission = useNativePushNotificationPermission([open]);

    const { data: notificationConsent } = useGetNotificationConsentByBrandId(props.brandId, {
        enabled: !!props.brandId && buttonType === "switch",
    });

    function handleEnable() {
        registerPushNotifications().then((permissionGranted) => {
            if (permissionGranted) {
                // we don't update in BE the brand_consent to true because user has to first
                // connect to the brand (which in turn enables the consent)
                toggleOpen();

                if (props.handleEnableCallback) {
                    props.handleEnableCallback();
                }
            } else {
                console.error("Push notification permission not granted");

                enqueueSnackbar({
                    message: t("notificationPermissionPopup.noNativePermission").toString(),
                    variant: "warning",
                });
            }
        });
    }

    return (
        <DynamicPopup
            dialogProps={{ fullWidth: true }}
            open={open}
            onOpen={toggleOpen}
            handleClose={toggleOpen}
            primaryButton={
                buttonType === "button" && (
                    <Button fullWidth variant="contained" sx={props.primaryButtonSx} onClick={handleEnable}>
                        {t("notificationPermissionPopup.enableButton")}
                    </Button>
                )
            }
            secondaryButton={
                buttonType === "button" && (
                    <Button
                        sx={{ color: "white.main", ...props.secondaryButtonSx }}
                        fullWidth
                        variant="text"
                        onClick={toggleOpen}
                    >
                        {t("notificationPermissionPopup.laterButton")}
                    </Button>
                )
            }
        >
            <div>
                <Box p={2} textAlign="center">
                    {iconSlot ? (
                        iconSlot
                    ) : (
                        <Avatar
                            sx={{
                                height: "6.25rem",
                                width: "6.25rem",
                                margin: "auto",
                                backgroundColor: theme.palette.mode === "dark" ? "card.light" : "sandLight.main",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={solid("bell")}
                                style={{
                                    height: "64px",
                                    color:
                                        theme.palette.mode === "dark"
                                            ? theme.palette.white.main
                                            : theme.palette.green.main,
                                }}
                            />
                        </Avatar>
                    )}
                </Box>
                <Typography align="center" variant="h3" mb={1}>
                    {props.title ?? t("notificationPermissionPopup.title")}
                </Typography>
                <Typography
                    mb={2}
                    textAlign={props.descriptionAlignment ?? "center"}
                    sx={{
                        opacity: 0.7,
                        fontWeight: 400,
                        fontSize: "18px",
                    }}
                >
                    {props.description ?? t("notificationPermissionPopup.description")}
                </Typography>
            </div>

            {buttonType === "switch" && props.brandId && props.buttonText && (
                <div
                    onClick={(e) => {
                        if (!hasNativePushNotificationPermission) {
                            e.preventDefault();
                            requestPushNotificationOSPermission(
                                () => {},
                                () =>
                                    enqueueSnackbar({
                                        message: t("notificationPermissionPopup.noNativePermission"),
                                        variant: "warning",
                                    }),
                            );
                        }
                    }}
                >
                    <BrandNotificationSettingItem
                        brandId={props.brandId}
                        enabled={notificationConsent?.consent || false}
                        title={props.buttonText.title}
                        description={
                            notificationConsent?.consent
                                ? props.buttonText.description
                                : props.buttonText.alternateDescription
                        }
                    />
                </div>
            )}
        </DynamicPopup>
    );
};

export default NotificationPermissionPopup;
