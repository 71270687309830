import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {};

const GPDivider = styled(Box)<Props>(({ theme }) => ({
  height: "1px",
  backgroundColor: theme.palette.sandMedium.main,
  width: "auto",
}));
export default GPDivider;
