export function emailIsCorrect(email: string) {
    const validEmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email && email.match(validEmailRegex) ? true : false;
}

export function phoneNumberIsCorrect(phoneNumber: string) {
    // allow empty string, instead of changing dynamic form
    if (phoneNumber === "") return true;

    const validPhoneNumberRegex = /^(?:\+|00)?[1-9]\d{1,14}$/;

    return phoneNumber && phoneNumber.match(validPhoneNumberRegex) ? true : false;
}

export function passwordIsCorrect(password: string) {
    const validPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

    return password && password.match(validPasswordRegex) ? true : false;
}

export function postalCodeIsCorrect(postalCode: string) {
    const postalCodeRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

    return postalCode && postalCode.match(postalCodeRegex) ? true : false;
}

export function nameIsCorrect(name: string) {
    const firstAndLastNameRegex =
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    return name && name.match(firstAndLastNameRegex) ? true : false;
}

export function validatorMapper(type: "given_name" | "family_name"): (value: any, form: any) => boolean {
    if (["given_name", "family_name"].includes(type)) {
        return nameIsCorrect;
    }
    if (["email"].includes(type)) {
        return emailIsCorrect;
    }

    return nameIsCorrect;
}
