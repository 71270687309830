import { useTranslation } from "react-i18next";
import { UseMutationOptions, UseQueryOptions, useQueryClient } from "react-query";
import { Coupon, CouponStatus } from "../../components/coupon/CouponCard";
import { FetchOptions, usePrefetchQuery, useQueryGet, useQueryPut } from "./restApiQuery";
import { AxiosResponse } from "axios";
import { normalizeLanguage } from "../ConfigTranslator";

export function useCouponsByBrandId(brandId?: string, queryOptions?: UseQueryOptions<Coupon[], unknown, Coupon[]>) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");

    return useQueryGet<Coupon[]>(
        ["coupon", "brand", brandId],
        "gateway",
        `/coupon/coupon/getTarget?lang=${lang}&brand-id=${brandId}`,
        {
            enabled: i18n.language !== undefined && brandId !== undefined,
            // put used coupons at the end of the list
            select: (data) => {
                if (!data) return data;
                return data.sort((a, b) => {
                    if (a.status === b.status) {
                        return parseInt(b.created_at) - parseInt(a.created_at);
                    }

                    return a.status === CouponStatus.Used ? 1 : -1;
                });
            },
            ...queryOptions,
        },
    );
}

export function usePrefetchCouponsByBrandId(brandId?: string) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");

    return usePrefetchQuery(
        ["coupon", "brand", brandId],
        "gateway",
        `/coupon/coupon/getTarget?lang=${lang}&brand-id=${brandId}`,
    );
}

export function useCouponById(id?: string, queryOptions?: UseQueryOptions<Coupon, unknown, Coupon>) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");

    return useQueryGet<Coupon>(["coupon", id], "gateway", `/coupon/coupon/byId?ids=${id}&lang=${lang}`, {
        select: (data: unknown) => {
            const _coupon = (data as Coupon[])[0];
            const coupon: Coupon = {
                ..._coupon,
                // @ts-ignore
                status: _coupon?.status == "" ? CouponStatus.New : _coupon?.status,
            };

            return coupon;
        },
        ...queryOptions,
    });
}

export function useCouponByIds(id: string[], queryOptions?: UseQueryOptions<Coupon[], unknown, Coupon[]>) {
    const { i18n } = useTranslation();
    const queryClient = useQueryClient();
    const lang = normalizeLanguage(i18n.language, "nl");

    const sanitazedIds: string = Array.isArray(id) ? id.join(",") : id;

    return useQueryGet<Coupon[]>(["coupon", sanitazedIds], "gateway", `/coupon/coupon/byId?ids=${id}&lang=${lang}`, {
        enabled: id.length > 0,
        onSuccess: (data) => {
            // some content_components can be undefined
            if (data === null) return;

            data.forEach((coupon) => {
                // the Backend returns an empty string instead to indicate the coupon has never being seen by user
                const sanitazedCoupon = {
                    ...coupon,
                    // @ts-ignore
                    status: coupon?.status == "" ? CouponStatus.New : coupon?.status,
                };

                queryClient.setQueryData(["coupon", coupon.id], [sanitazedCoupon]);
            });

            if (queryOptions && queryOptions.onSuccess) {
                queryOptions.onSuccess(data);
            }
        },
        ...queryOptions,
    });
}

export function useViewCoupon(
    id?: string,
    options?: UseMutationOptions<AxiosResponse<Coupon>, unknown, FetchOptions, unknown>,
) {
    const queryClient = useQueryClient();

    return useQueryPut<AxiosResponse<Coupon>>(["coupon", id], "gateway", `/coupon/coupon/view?couponIds=${id}`, {
        // optimistic update. No refetch as it doesn't mutate any other fields
        onMutate: () => {
            const previousData = queryClient.getQueryData<Coupon>(["coupon"]);

            queryClient.setQueryData(["coupon", id], (_previousCoupon: unknown) => {
                const previousData = !!_previousCoupon ? (_previousCoupon as Coupon[])[0] : [];

                return [
                    {
                        ...previousData,
                        status: CouponStatus.Available,
                    },
                ];
            });

            return { previousData };
        },
        ...options,
    });
}

export function useActivateCoupon(
    id?: string,
    brandId = "",
    options?: UseMutationOptions<AxiosResponse<Coupon>, unknown, FetchOptions, unknown>,
    invalidate = true,
) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");
    const queryClient = useQueryClient();

    return useQueryPut<AxiosResponse<Coupon>>(
        ["coupon", id],
        "gateway",
        `/coupon/coupon/activate?couponid=${id}&lang=${lang}`,
        {
            ...options,
            onSuccess: (result: any, variables, context) => {
                queryClient.invalidateQueries(["logs"]);
                queryClient.invalidateQueries(["user-consent"]);

                if (invalidate) {
                    queryClient.invalidateQueries(["coupon"]);

                    queryClient.setQueryData(["coupon", id], result.data);
                }

                if (options && options?.onSuccess) {
                    options.onSuccess(result, variables, context);
                }
            },
        },
        { headers: { BrandId: brandId } },
    );
}

/**
 * Specific flow for BSO sponsor coupons. It is used in article page
 */
export function useGetCouponsByArticleSponsor(brandId?: string, sponsorBrandId?: string) {
    return useCouponsByBrandId(sponsorBrandId, {
        enabled: !!brandId && !!sponsorBrandId,
    });
}
