import { useEffect, useState } from "react";
import shield from "../../assets/img/icons/shield.svg";
import FullScreenRootContainer from "../../components/Container/FullScreenRootContainer";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import { TopNav } from "../../components/nav/TopNavigation";
import { useAuthProvider } from "../../service/auth/AuthProvider";
import "./loading.css";
import { Box, Grow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
    disableIconButtons?: boolean;
    description?: string;
    zIndex?: number;
};

export default function LoadingPage({ disableIconButtons = false, description, zIndex }: Props) {
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const authProvider = useAuthProvider();
    const { t } = useTranslation();

    useEffect(() => {
        const timeout = setTimeout(function () {
            setShowRefreshButton(true);
        }, 4000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <FullScreenRootContainer
            sx={{
                backgroundColor: "#3D3872",
                zIndex,
            }}
        >
            <TopNav showBackButton={false} showLogoutButton disableIconButtons={disableIconButtons} />
            <div
                style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "absolute",
                }}
            >
                <Box className="pulse">
                    <img width="64px" src={shield} alt="any-id" />
                </Box>
            </div>
            {description && (
                <Grow in timeout={500}>
                    <Typography mt={3} textAlign="center" variant="subtitle1">
                        {description}
                    </Typography>
                </Grow>
            )}
            {showRefreshButton && (
                <Typography
                    variant="buttonText"
                    align="center"
                    sx={{
                        marginTop: 3,
                        position: "absolute",
                        width: "100%",
                        bottom: "100px",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        authProvider.emptyCache();
                        window.location.href = window.location.origin;
                    }}
                >
                    {t("loading.refreshPage")}
                </Typography>
            )}
            <PageAnalytics />
        </FullScreenRootContainer>
    );
}
