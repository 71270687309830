import { Schema, z } from "zod";
import { TranslatableConfigTextSchema } from "../ConfigTranslator";

const ActionButtonSchema = z.object({
    text: z.string(),
    icon: z.string().optional(),
    color: z.string().optional(),
    textColor: z.string().optional(),
    localRedirect: z.boolean().optional(),
    style: z.object({}).optional(),
});

export type ActionButtonTheme = z.infer<typeof ActionButtonSchema>;

const BrandPalette = z.object({
    primary: z.string(),
    secondary: z.string(),
    tertiair: z.string(),
    tertiairTextColor: z.string().optional(),
    gradient: z.string().optional(),
    backgroundColor: z.string().optional(),
    backgroundColorLogo: z.string().optional(),
    textColor: z.string().optional(),
});

const UniqueBrandCardScheme = z.object({
    bgImg: z.string().optional(),
    logo: z
        .object({
            backgroundColorLogo: z.string().optional(),
        })
        .optional(),
    sandbox: z
        .object({
            type: z
                .union([z.literal("mediaCarousel"), z.literal("bsoCalendarEventAlert"), z.literal("img")])
                .optional(),
            bgImg: z.string().optional(),
            style: z.unknown().optional(),
            cta: z
                .object({
                    text: z.string(),
                })
                .optional(),
        })
        .optional(),
    palette: z
        .object({
            textColor: z.string().optional(),
        })
        .optional(),
});

const BrandThemeSchema = z.object({
    palette: BrandPalette,
    actionButton1: ActionButtonSchema,
    actionButton2: ActionButtonSchema.optional(),
    headerActionButton: ActionButtonSchema.optional(),
    fontFamily: z.string().optional(),
    carousel: z.string().optional(),
    uniqueBrandCard: UniqueBrandCardScheme.optional(),
    detailed_description: TranslatableConfigTextSchema.optional(),
});

export type BrandTheme = z.infer<typeof BrandThemeSchema>;

const ColorObject = z.object({
    primary: z.string(),
    secondary: z.string(),
    text: z.string(),
});

const FontObject = z.object({
    primary: z.string(),
    secondary: z.string(),
});

const NavigateAction = z.object({
    type: z.literal("navigate"),
    url: z.string(),
    callback: z.function().optional(),
});

const MoreAction = z.object({
    type: z.literal("toggle"),
    key: z.string(),
});

const NotificationAction = z.object({
    type: z.literal("notification"),
    enabled: z.boolean(),
});

const BrandAction = z.object({
    type: z.literal("brand_action"),
    key: z.string(),
    brand_id: z.string().optional(),
    callback: z.function().optional(),
});

const FollowAction = z.object({
    type: z.literal("follow"),
    enabled: z.boolean(),
});

const ExternalNavigateAction = z.object({
    type: z.literal("external-navigate"),
    url: z.string(),
});

const CouponAction = z.object({
    type: z.literal("coupon"),
    coupon_id: z.string(),
    url: z.string(),
    brand_id: z.string(),
    callback: z.function().optional(),
});

const MvpPollAction = z.object({
    type: z.literal("mvp_poll"),
    poll_id: z.string(),
    url: z.string(),
    brand_id: z.string(),
    callback: z.function().optional(),
});

const PollAction = z.object({
    type: z.literal("poll"),
    poll_id: z.string(),
    url: z.string(),
    brand_id: z.string(),
    callback: z.function().optional(),
});

const NoAction = z.object({
    type: z.literal("none"),
});

const Actions = z.union([
    NavigateAction,
    MoreAction,
    NotificationAction,
    BrandAction,
    FollowAction,
    ExternalNavigateAction,
    CouponAction,
    MvpPollAction,
    PollAction,
    NoAction,
]);

export type Action = z.infer<typeof Actions>;

export type ActionKey = Action["type"];

const NavActionItem = z.object({
    icon: z.string(),
    title: z.string(),
    description: z.string().optional(),
    action: Actions,
    enabled: z.boolean(),
});

const NavCollapseItem = z.object({
    icon: z.string(),
    title: z.string(),
    collapseItems: z.array(NavActionItem),
    enabled: z.boolean(),
});

const NavItem = z.union([NavActionItem, NavCollapseItem]);

const DynamicSideNav = z.object({
    items: z.array(NavItem),
});

const DynamicBottomNav = z.object({
    colors: ColorObject,
    fonts: FontObject,
    items: z.array(NavActionItem),
});

export type NavActionItemType = z.infer<typeof NavActionItem>;

const Partner = z.object({
    brand_id: z.string(),
    coupon_id: z.string(),
    logo_background: z.string().optional(),
});

const PollSchema = z.object({
    currentActivePollId: z.string(),
});

const BrandEnvironment = z.object({
    partner: Partner,
    mvpPartner: Partner.optional(),
    poll: PollSchema.optional(),
    bottomNav: DynamicBottomNav,
    sideNav: DynamicSideNav.optional(),
    colors: ColorObject,
    fonts: FontObject,
    highlighted_quiz: z.string().optional(),
    heroAnimation: z.boolean().optional().default(true),
});

const BlurredBannerSchema = z.object({
    imgUrl: z.string(),
    redirectUrl: z.string(),
    titleText: TranslatableConfigTextSchema,
    subtitleText: TranslatableConfigTextSchema,
});

export type BlurredBannerConfig = z.infer<typeof BlurredBannerSchema>;

const BrandOptionalComponentsSchema = z.object({
    blurredBanner1: BlurredBannerSchema.optional(),
    blurredBanner2: BlurredBannerSchema.optional(),
});

// TODO: remove club which was added to backward compatibility
const ClubTypeSchema = z
    .union([z.literal("club"), z.literal("sport"), z.literal("bso"), z.literal("webshop")])
    .optional();

export const BrandConfigSchema = z.object({
    name: z.string(),
    _id: z.string(),
    type: ClubTypeSchema,
    optionalComponents: BrandOptionalComponentsSchema.optional(),
    requiredCreateAccount: z.array(z.string()),
    requiredCheckout: z.array(z.string()),
    optionalCreateAccount: z.array(z.string()).optional(),
    optionalCheckout: z.array(z.string()).optional(),
    hasRewards: z.boolean().optional(),
    theme: BrandThemeSchema.optional(),
    quiz: z
        .object({
            onboarding: z.string().optional(),
            requiredCreateAccount: z.string().optional(),
        })
        .optional(),
    quiz_config_id: z.string().optional(),
    environment: BrandEnvironment.optional(),
});

export type BrandConfig = z.infer<typeof BrandConfigSchema>;

export function validateScheme<T>(scheme: Schema<T>, data: any) {
    const validationResult = scheme.safeParse(data);

    if (!validationResult.success) {
        throw new Error(validationResult.error.message);
    }

    return validationResult.data;
}

export function validateBrandConfigScheme<T>(scheme: Schema<T>, data: any) {
    const validationResult = scheme.safeParse(data);

    if (!validationResult.success) {
        console.error(validationResult.error.message, validationResult);
    }

    // @ts-ignore
    return validationResult.data;
}
