import {
    faBook,
    faCalendarDays,
    faCalendarStar,
    faChildReaching,
    faClock,
    faExternalLink,
    faSchool,
    faVanShuttle,
    faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BsoCard from "../../../../../components/Club/bso/BsoCard";
import { DynamicPopup } from "../../../../../gp-components/src/components/Dialog/DynamicPopup";
import { useClubProvider } from "../../../../../service/ClubService";
import { BsoCalendarEvent, useChildColorLegend, useGetBsoParent } from "../../../../../service/api/bsoService";
import { formatUnixTimestampSimple, isExactlyOneDayInterval } from "../../../../../service/dateService";

type Props = {
    open: boolean;
    toggleOpen: () => void;
    events: BsoCalendarEvent[];
    handleCompleted?: () => void;
};

export default function ClubBsoCalendarDrawer({ open, toggleOpen, events }: Props) {
    const { t } = useTranslation();

    return (
        <DynamicPopup
            open={open}
            onOpen={toggleOpen}
            handleClose={toggleOpen}
            primaryButton={<Button />}
            customHandleComponent={
                <Box px={1.5} pt={0.5}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: "black", width: "20px", height: "20px" }}
                        onClick={toggleOpen}
                    />
                </Box>
            }
            handleAlignment="auto"
        >
            <Stack gap={4} mt={2} divider={<Divider sx={{ backgroundColor: "#000000", opacity: "50%" }} />}>
                {events.map((event, idx) => (
                    <Stack gap={4} key={`${event.title}_${event.type}_${idx}`}>
                        <Title event={event} />

                        <InfoGraphicSection event={event} />

                        {event.type === "trip" ? (
                            <Typography variant="h6">{event.description}</Typography>
                        ) : (
                            <>
                                <div>
                                    <Typography variant="h6" fontWeight={700}>
                                        {t("club.bso.agenda.eventDrawer.link.title")}
                                    </Typography>
                                    <Button
                                        sx={{ backgroundColor: "#fff", mt: 1 }}
                                        endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                                        href="https://portaal.novict.nl/SGYVaT7DuOw/"
                                    >
                                        {t("club.bso.agenda.eventDrawer.link.link")}
                                    </Button>
                                </div>
                                <BsoDayOffTripsCard />
                            </>
                        )}
                    </Stack>
                ))}
            </Stack>
        </DynamicPopup>
    );
}

export function bsoCalendarIconType(event: BsoCalendarEvent) {
    if (event.type === "study-day") return faBook;
    else if (event.type === "trip") return faVanShuttle;

    // event.type === "vacation"
    return faCalendarStar;
}

type TitleProps = {
    event: BsoCalendarEvent;
};
function Title({ event }: TitleProps) {
    const theme = useTheme();

    const colorLegend = useChildColorLegend();

    return (
        <Typography variant="h4" fontWeight={700}>
            <FontAwesomeIcon
                icon={bsoCalendarIconType(event)}
                color={colorLegend(event)}
                style={{ marginRight: theme.spacing(1) }}
            />
            {event.title}
        </Typography>
    );
}

type InfoGraphicSectionProps = {
    event: BsoCalendarEvent;
};
function InfoGraphicSection({ event }: InfoGraphicSectionProps) {
    const { data: bsoParent } = useGetBsoParent({ suspense: true });

    const { i18n } = useTranslation();
    const theme = useTheme();

    const formattedStartDate = formatUnixTimestampSimple(event.start_date, i18n.language);
    const formattedEndDate = formatUnixTimestampSimple(event.end_date, i18n.language);

    const calendarDay = `${formattedStartDate.day} ${formattedStartDate.month}`;
    const colorLegend = useChildColorLegend();

    const color = colorLegend(event);

    const iconStyle = { marginRight: theme.spacing(1), width: "20px" };

    function getChildNames(childIds: string[]) {
        const names = childIds.map((childId) => bsoParent?.children.find((child) => child.id === childId)?.first_name);

        return names?.join(" & ");
    }

    if (event.type === "study-day") {
        return (
            <Stack gap={1}>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faChildReaching} color={color} style={iconStyle} />
                    {getChildNames(event.children)}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faClock} color={color} style={iconStyle} />
                    {`${formattedStartDate.hour}:${formattedStartDate.minute}`}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faCalendarDays} color={color} style={iconStyle} />
                    {calendarDay}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faSchool} color={color} style={iconStyle} />
                    {event.location}
                </Typography>
            </Stack>
        );
    } else if (event.type === "trip") {
        return (
            <Stack gap={1}>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faChildReaching} color={color} style={iconStyle} />
                    {getChildNames(event.children)}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faClock} color={color} style={iconStyle} />
                    {`${formattedStartDate.hour}:${formattedStartDate.minute} - ${formattedEndDate.hour}:${formattedEndDate.minute}`}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faCalendarDays} color={color} style={iconStyle} />
                    {calendarDay}
                </Typography>
                <Typography variant="h7">
                    <FontAwesomeIcon icon={faSchool} color={color} style={iconStyle} />
                    {event.location}
                </Typography>
            </Stack>
        );
    }

    // event.type == "vacation"
    return (
        <Stack gap={1}>
            <Typography variant="h7">
                <FontAwesomeIcon icon={faChildReaching} color={color} style={iconStyle} />
                {getChildNames(event.children)}
            </Typography>
            <Typography variant="h7">
                <FontAwesomeIcon icon={faCalendarDays} color={color} style={iconStyle} />
                {isExactlyOneDayInterval(event.start_date, event.end_date)
                    ? `${formattedStartDate.day} ${formattedStartDate.month}`
                    : `${formattedStartDate.day} ${formattedStartDate.month} - ${formattedEndDate.day} ${formattedEndDate.month}`}
            </Typography>
            <Typography variant="h7">
                <FontAwesomeIcon icon={faSchool} color={color} style={iconStyle} />
                {event.location}
            </Typography>
        </Stack>
    );
}

function BsoDayOffTripsCard() {
    const { brand_id } = useClubProvider();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <BsoCard>
            <Typography variant="h6">{t("club.bso.agenda.eventDrawer.dayOff.title")}</Typography>
            <Button
                sx={{
                    mt: 2,
                }}
                onClick={() => {
                    navigate(`/club/bso/gifts/${brand_id}`);
                }}
            >
                {t("club.bso.agenda.eventDrawer.dayOff.button")}
            </Button>
        </BsoCard>
    );
}
