import { AuthBodyNative, AuthBodyWeb, ParsedAuthBody, RefreshBody } from "./AuthStorage.interface";
import { AuthContextInterface } from "./AuthProvider";

export function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function isNativeAuthBody(authBody: AuthBodyWeb | AuthBodyNative): authBody is AuthBodyNative {
  return (authBody as AuthBodyNative).access_token_response !== undefined;
}

export function parseAuthBody(authBody: AuthBodyWeb | AuthBodyNative): ParsedAuthBody {
  let refreshToken: string;
  if (isNativeAuthBody(authBody)) {
    refreshToken = authBody.access_token_response.refresh_token;
  } else {
    refreshToken = authBody.refresh_token;
  }
  const jwt = parseJwt(authBody.access_token);
  const refresh = parseJwt(refreshToken);

  return { accessToken: authBody.access_token, refreshToken, jwt, refresh };
}

export function parseRefreshToken(refreshBody: RefreshBody): ParsedAuthBody {
  return {
    accessToken: refreshBody.access_token,
    refreshToken: refreshBody.refresh_token,
    jwt: parseJwt(refreshBody.access_token),
    refresh: parseJwt(refreshBody.refresh_token),
  };
}

export function getUpdatedJwt(authProvider: AuthContextInterface, fieldsToUpdate: Object): ParsedAuthBody {
  const updatedAuthBody = { ...authProvider.authBody } as ParsedAuthBody;

  if (updatedAuthBody.jwt) {
    updatedAuthBody.jwt = { ...updatedAuthBody.jwt, ...fieldsToUpdate };
  }

  return updatedAuthBody;
}
