import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// The container for the content without the navigation
const ContentContainer = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.up("sm")]: {
      width: "65%",
      marginBottom: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  };
});

export default ContentContainer;
