import { Avatar, Box, Skeleton, SxProps } from "@mui/material";
import { Property } from "csstype";
import { ReactNode, SyntheticEvent, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import fallbackImage from "../../assets/img/fallback.png";

export type ImageWithFallbackShape = "text" | "circular" | "rectangular" | "rounded";

type ImageWithFallbackProps = {
    src: string | undefined;
    fallBackUrl?: string;
    type?: ImageWithFallbackShape;
    width?: string;
    height?: string;
    containerSx?: SxProps;
    fallBackSlotIcon?: ReactNode;
    alt?: string;
    objectFit?: Property.ObjectFit;
    onLoadCallback?: () => void;
} & Partial<JSX.IntrinsicElements["img"]>;

/**
 *
 * @param type skeleton type
 * @returns An img with lazy loading animaiton and height to preserve UI spacing
 */
export const ImageWithFallback = (props: ImageWithFallbackProps) => {
    const {
        src,
        srcSet,
        fallBackUrl,
        width = "53px",
        height = width,
        style,
        containerSx,
        type = "circular",
        alt = src,
        objectFit = "cover",
        onLoadCallback,
    } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [triedFallbackAttempt, setTriedFallbackAttempt] = useState<number>(0);

    function parse(): "circular" | "rounded" | "square" {
        if (type === "rectangular" || type === "text") return "square";

        return type;
    }
    const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        setIsLoading(false);

        if (triedFallbackAttempt >= 3) {
            // Set hard-coded fallback URL if the third fallback attempt fails
            e.currentTarget.src = fallbackImage;
            return;
        }

        // First attempt to use the provided fallback URL
        if (fallBackUrl) {
            e.currentTarget.src = fallBackUrl;
            setTriedFallbackAttempt((prev) => prev + 1);
        }
    };

    return (
        <Avatar
            sx={{
                position: "relative",
                width: width,
                height: height,
                backgroundColor: "transparent",
                ...containerSx,
            }}
            variant={parse()}
        >
            {src ? (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {isLoading && <Fallback {...props} />}
                    <LazyLoadImage
                        src={src}
                        srcSet={srcSet}
                        width={"100%"}
                        height={"100%"}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: objectFit,
                            transition: "all ease 0.1s",
                            opacity: isLoading ? 0 : 1,
                            ...style,
                        }}
                        onLoad={() => {
                            setIsLoading(false);
                            if (onLoadCallback) {
                                onLoadCallback();
                            }
                        }}
                        onError={handleImageError}
                        alt={""}
                    />
                </div>
            ) : (
                <Fallback {...props} />
            )}
        </Avatar>
    );
};

function Fallback({ fallBackSlotIcon, width, height = width, type, containerSx }: ImageWithFallbackProps) {
    if (fallBackSlotIcon)
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                {fallBackSlotIcon}
            </Box>
        );

    return (
        <Box
            sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
            }}
        >
            <Skeleton
                sx={{ transform: "none", transformOrigin: "0", ...containerSx }}
                width={width}
                height={height}
                variant={type}
            />
        </Box>
    );
}
