import styled from "@emotion/styled";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDown, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Button, Skeleton, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SideButton } from "../../gp-components/src/components/Button/SideButton";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import { Brand, useBrands } from "../../service/api/brandService";
import {
    useGetNotificationConsentByBrandIds,
    useNativePushNotificationPermission,
} from "../../service/capacitor/PushNotificationService";
import NotificationPermissionPopup from "../Notification/NotificationPermissionPopup";
import BrandNotificationSettingItem from "./InAppNotificationSettingItem";

export default function InAppNotificationOptionsButton() {
    const [open, setOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const { t } = useTranslation();
    const theme = useTheme();

    const { connectedBrands, status } = useBrands();
    const hasNativePushNotificationPermission = useNativePushNotificationPermission([openDrawer]);

    if (status === "loading") return null;

    return (
        <div
            style={{
                width: "100%",
                position: "relative",
            }}
        >
            <SideButton
                sx={{ marginLeft: "auto", fontFamily: "Poppins", backgroundColor: "#57527D" }}
                onClick={() => {
                    setOpen((prev) => !prev);
                }}
            >
                {t("inAppNotifications.options")}
                <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{
                        marginLeft: "4px",
                    }}
                />
            </SideButton>

            <Backdrop open={open} onClick={() => setOpen(false)} sx={{ zIndex: 99 }} />
            <div
                style={{
                    position: "absolute",
                    marginTop: theme.spacing(1),
                    right: 0,
                    boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 24px",
                    borderRadius: theme.shape.borderRadius,
                    zIndex: 100,
                    transition: "all ease 0.2s",
                    opacity: open ? 1 : 0,
                }}
            >
                <div
                    style={{
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        padding: theme.spacing(1),
                        fontWeight: 400,
                        fontSize: "13px",
                        borderRadius: theme.shape.borderRadius,
                    }}
                    onClick={() => {
                        if (!open) return;
                        setOpenDrawer(true);
                        setOpen(false);
                    }}
                >
                    <FontAwesomeIcon
                        //@ts-ignore for some reason the faCog type is fucked up, but the icon still loads. Probably because it's importing from "regular"
                        icon={faCog}
                        style={{
                            marginRight: "4px",
                        }}
                    />
                    {t("inAppNotifications.settings.title")}
                </div>
            </div>

            {hasNativePushNotificationPermission ? (
                <PushNotificationSettingsPopup
                    open={openDrawer}
                    setOpen={setOpenDrawer}
                    connectedBrands={connectedBrands}
                />
            ) : (
                // user hasn't granted OS permission
                <NotificationPermissionPopup
                    open={openDrawer}
                    toggleOpen={() => {
                        setOpenDrawer((prev) => !prev);
                    }}
                />
            )}
        </div>
    );
}

const PushNotificationSettingsPopup = ({
    open,
    setOpen,
    connectedBrands,
}: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    connectedBrands: Brand[];
}) => {
    const { t } = useTranslation();
    const { data: notificationConsents, status } = useGetNotificationConsentByBrandIds(
        connectedBrands.map((brand) => brand.id),
    );

    return (
        <DynamicPopup
            open={open}
            onOpen={() => {}}
            handleClose={() => {
                setOpen(false);
            }}
        >
            <div
                style={{
                    gap: "8px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        fontWeight: 700,
                        fontSize: "25px",
                    }}
                >
                    {t("inAppNotifications.settings.title")}
                </div>
                {connectedBrands.length > 0 ? (
                    <>
                        <span>{t("inAppNotifications.settings.description")}</span>
                        {status === "success" ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                    margin: "16px -16px 0 -16px",
                                }}
                            >
                                {connectedBrands.map((brand, index) => {
                                    return (
                                        <div
                                            key={brand.id}
                                            style={{
                                                backgroundColor: index % 2 === 1 ? "#ffffff19" : undefined,
                                                paddingLeft: "16px",
                                                paddingRight: "8px",
                                            }}
                                        >
                                            <BrandNotificationSettingItem
                                                title={brand.name}
                                                brandId={brand.id}
                                                enabled={
                                                    notificationConsents?.find(
                                                        (consent) => consent.brand_id === brand.id,
                                                    )?.consent ?? false
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <>
                                <BrandNotificationSettingItemSkeleton />
                                <BrandNotificationSettingItemSkeleton />
                                <BrandNotificationSettingItemSkeleton />
                            </>
                        )}
                    </>
                ) : (
                    // user is connected to no brands
                    <NotConnectedToAnyBrand />
                )}
            </div>
        </DynamicPopup>
    );
};

const BrandNotificationSettingItemSkeleton = styled(Skeleton)({
    margin: "4px 0",
    transform: "none",
    borderRadius: "16px",
    transformOrigin: "0",
});

const NotConnectedToAnyBrand = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <span>{t("home.notConnectedToAnyBrand", { pageName: t("bottomNav.brandOverviewExplore") })}</span>
            <Button
                fullWidth
                endIcon={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "1em" }} />}
                onClick={() => {
                    navigate("/aangesloten-merken");
                    window.scroll(0, 0);
                }}
                sx={{ mt: 2 }}
            >
                {t("club.partners.findMore")}
            </Button>
        </>
    );
};
