import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface CountdownTimerProps {
    countdownGoal: number;
    onCountdownEnd?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ countdownGoal, onCountdownEnd }) => {
    const [timeLeft, setTimeLeft] = useState<string>("");

    useEffect(() => {
        const interval = setInterval(() => {
            const now = dayjs().unix();
            const difference = countdownGoal - now;

            if (difference <= -1 && onCountdownEnd) {
                onCountdownEnd();
                clearInterval(interval);
            } else {
                const hours = Math.floor(difference / 3600);
                const minutes = Math.floor((difference % 3600) / 60);
                const seconds = difference % 60;

                const formatNumber = (num: number): string => `0${num}`.slice(-2);

                setTimeLeft(`${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdownGoal]);

    return <Box>{timeLeft}</Box>;
};

export default CountdownTimer;
