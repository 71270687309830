import { Box, useMediaQuery } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import BrandCard, { BrandCardSkeleton } from "../../components/Brand/BrandCard";
import { BrandCategorySection } from "../../components/Brand/BrandCategories";
import BrandDetailHeader from "../../components/Brand/BrandDetailHeader";
import BrandOverviewSearchBar from "../../components/Brand/BrandOverviewSearchBar";
import ContentContainer from "../../components/Container/ContentContainer";
import PageContainer from "../../components/Container/PageContainer";
import RootContainer from "../../components/Container/RootContainer";
import { SideNavigation } from "../../components/nav/SideNavigation";
import { TopNav } from "../../components/nav/TopNavigation";
import { env } from "../../env.mjs";
import { GPFooter } from "../../gp-components/src";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { Intersector } from "../../service/IntersectionObserverHook";
import { Brand, useBrands } from "../../service/api/brandService";
import { categories } from "../../service/api/categoryService";

const INTERSECTION_OBSERVER_THRESHOLD = 5;

export default function BrandOverviewPage() {
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);
    const [URLSearchParams, _] = useSearchParams();
    const [currentIndex, setCurrentIndex] = useState(4);

    const isDesktop = useMediaQuery("(min-width:600px)");
    const { t } = useTranslation();
    const selectedCategory = URLSearchParams.get("category");

    const {
        notConnectedBrands,
        isLoading: brandsAreLoading,
        refetch: brandRefetch,
    } = useBrands({
        onError: (e: any) => {
            setAlertError({
                id: "brand",
                message: t("home.brandError"),
                code: e.response.status,
                icon: "retry",
                handleAction: brandRefetch,
            });
        },
        suspense: true,
    });

    const [filteredBrands, setFilteredBrands] = useState<Brand[]>(notConnectedBrands);

    useEffect(() => {
        if (brandsAreLoading) return;

        if (selectedCategory) {
            const filteredCategories = notConnectedBrands.filter((item) => {
                return item?.categories?.some((category) => category.Id === selectedCategory);
            });
            setFilteredBrands(filteredCategories);
        } else {
            setFilteredBrands(notConnectedBrands);
        }
    }, [selectedCategory, brandsAreLoading]);

    return (
        <RootContainer>
            <TopNav
                showBackButton={!!selectedCategory}
                loading={brandsAreLoading}
                sx={{ position: "absolute", top: 0, zIndex: 1, width: "100%" }}
            />
            <title>{t("brandOverviewExplore.tabTitle")}</title>
            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    {!selectedCategory ? (
                        <>
                            <Box mb={2}>
                                <BrandOverviewSearchBar />
                            </Box>

                            <Box mb={4}>
                                <BrandCategorySection />
                            </Box>
                        </>
                    ) : (
                        <Box mb={2} mx={-2} mt={-2}>
                            <BrandDetailHeader
                                title={t(
                                    "category." + categories.find((category) => category.Id === selectedCategory)?.Name,
                                )}
                                id="1"
                                heroImgUrl={`${env.cdnUrl}/category/${selectedCategory}_3x.webp`}
                                showLogo={false}
                                gradient={false}
                                maxHeight="0"
                            />
                        </Box>
                    )}

                    {alertError && (
                        <Box mb={1}>
                            <AnyAlert error={alertError} handleClose={setAlertError} />
                        </Box>
                    )}

                    <Suspense
                        fallback={
                            <>
                                <BrandCardSkeleton />
                                <BrandCardSkeleton />
                                <BrandCardSkeleton />
                            </>
                        }
                    >
                        {/* custom virtualized list for long DOM elements. We use the currentIndex and intersection observer to render as the user scrolls */}
                        {filteredBrands.slice(0, currentIndex + 1).map((brand, index) => {
                            return (
                                <Box key={brand.id} mb={4}>
                                    {index === currentIndex && (
                                        <Intersector
                                            callback={([entry]) => {
                                                if (entry.isIntersecting) {
                                                    setCurrentIndex(
                                                        (prev) => (prev += INTERSECTION_OBSERVER_THRESHOLD),
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                    <BrandCard {...brand} margin={isDesktop ? "2" : undefined}></BrandCard>
                                </Box>
                            );
                        })}
                    </Suspense>
                </ContentContainer>
            </PageContainer>
            <PageAnalytics />
            <GPFooter />
        </RootContainer>
    );
}
