import { Box } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { CSSProperties, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTokenHealthCheck } from "../../service/auth/OAuthService";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";

type Props = {
    children: JSX.Element;
};

type RadialGradientProps = {
    color?: string;
    gradient?: string;
} & JSX.IntrinsicElements["div"];

export function RadialGradient({ color, gradient, style }: RadialGradientProps) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                background:
                    gradient ||
                    `radial-gradient(circle, ${color || theme.palette.background.gradient} 10%, transparent 50%)`,
                position: "fixed",
                top: "-95%",
                left: "-95%",
                height: "210%",
                width: "210%",
                zIndex: "-1",
                ...style,
            }}
        />
    );
}

export function useBackground(brandId?: string, style?: CSSProperties, brandTheme?: Theme) {
    const theme = useTheme();
    const { data: brandConfig } = useBrandConfigProvider().getByID(brandId ?? "");

    useEffect(() => {
        document.body.style.backgroundColor =
            brandTheme?.palette.background.default ||
            brandConfig?.theme?.palette.backgroundColor ||
            theme.palette.background.default;

        return () => {
            document.body.style.backgroundColor = theme.palette.background.default;
        };
    }, [brandTheme?.palette.background.default, brandConfig?.theme?.palette.backgroundColor]);

    if (theme.palette.mode === "dark" && !brandId && !brandConfig) {
        return <RadialGradient style={style} />;
    }

    if (brandConfig?.theme?.palette.gradient) {
        return <RadialGradient gradient={brandConfig?.theme?.palette.gradient} style={style} />;
    }
}

export function MainContent(props: Props) {
    useTokenHealthCheck();

    const location = useLocation();

    const showGradient = useMemo(() => {
        return (
            !["/home", "/aangesloten-merken", "/welkom/", "/club/"].find((path) => location.pathname.includes(path)) &&
            !(window.location.pathname === "/")
        );
    }, [location.pathname]);

    return (
        <>
            {showGradient && <RadialGradient />}
            {props.children}
        </>
    );
}
