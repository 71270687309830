import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";
import { getBioTypeIcon } from "../../service/capacitor/CapacitorService";
import { anyPalette, anyPaletteFilter } from "../../theme";

type Props = {
    handleChange: (value: string) => void;
    performAction?: (type: string) => void;
    maxLength?: number;
    sx?: any;
    actionIsDisabled?: boolean;
    disabled?: boolean;
};

type ClearCacheHandle = {
    clearCache: () => void;
};

export type NumpadClearCacheHandle = React.ElementRef<typeof Numpad>;

export const Numpad = forwardRef<ClearCacheHandle, Props>(
    ({ handleChange, performAction, sx, maxLength = 5, actionIsDisabled = false, disabled = false }: Props, ref) => {
        const [pincodeCache, setPincodeCache] = useState("");
        const { bio, insets } = useCapacitorProvider();
        const theme = useTheme();

        const items = useMemo(
            () => [
                {
                    display: <Typography variant="h1">1</Typography>,
                    value: "1",
                },
                {
                    display: <Typography variant="h1">2</Typography>,
                    value: "2",
                },
                {
                    display: <Typography variant="h1">3</Typography>,
                    value: "3",
                },
                {
                    display: <Typography variant="h1">4</Typography>,
                    value: "4",
                },
                {
                    display: <Typography variant="h1">5</Typography>,
                    value: "5",
                },
                {
                    display: <Typography variant="h1">6</Typography>,
                    value: "6",
                },
                {
                    display: <Typography variant="h1">7</Typography>,
                    value: "7",
                },
                {
                    display: <Typography variant="h1">8</Typography>,
                    value: "8",
                },
                {
                    display: <Typography variant="h1">9</Typography>,
                    value: "9",
                },
                {
                    display: (
                        <img
                            style={{
                                filter:
                                    theme.palette.mode === "dark"
                                        ? anyPaletteFilter.darkMode
                                        : anyPaletteFilter.primary,
                            }}
                            alt="biometric type"
                            width="36px"
                            src={getBioTypeIcon(bio?.type)}
                        />
                    ),
                    value: "fingerprint",
                },
                {
                    display: <Typography variant="h1">0</Typography>,
                    value: "0",
                },
                {
                    display: (
                        <FontAwesomeIcon
                            icon={solid("delete-left")}
                            style={{
                                height: "25px",
                            }}
                        />
                    ),
                    value: "delete",
                },
            ],
            [theme.palette.mode],
        );

        useImperativeHandle(ref, () => ({
            clearCache() {
                setPincodeCache("");
            },
        }));

        const handleNumpadClick = (value: string) => {
            if (disabled) return;
            let newPincode = pincodeCache;

            if (value === "delete") {
                if (newPincode.length === maxLength) {
                    newPincode = "";
                } else {
                    newPincode = pincodeCache.substring(0, pincodeCache.length - 1);
                }
            } else if (value === "fingerprint") {
                if (!actionIsDisabled && performAction) performAction("fingerprint");
                return;
            } else {
                newPincode += value;
            }

            if (newPincode.length > maxLength) {
                return;
            }

            setPincodeCache(newPincode);
            handleChange(newPincode);
        };

        return (
            <Grid
                {...sx}
                container
                sx={{
                    width: "100%",
                    backgroundColor:
                        theme.palette.mode === "light" ? theme.palette.card.main : theme.palette.background.default,
                    paddingBottom: insets?.bottom ? `${insets.bottom}px` : "",
                }}
            >
                {items.map((item, index) => {
                    return (
                        <Grid
                            key={item.value}
                            item
                            xs={4}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                border: theme.palette.mode === "light" ? "1px solid " + anyPalette.sandLight : "",
                                borderTop: theme.palette.mode === "light" && index < 3 ? "none" : "",
                            }}
                        >
                            {(item.value !== "fingerprint" || !actionIsDisabled) && (
                                <Button
                                    variant="text"
                                    className="button"
                                    onTouchEndCapture={(e) => {
                                        e.preventDefault();
                                        handleNumpadClick(item.value);
                                    }}
                                    style={{
                                        justifyContent: "center",
                                        display: "flex",
                                        width: "100%",
                                        height: "100%",
                                        maxHeight: "7vh",
                                        alignItems: "center",
                                        userSelect: "none",
                                        color: disabled
                                            ? anyPalette.sandMedium
                                            : theme.palette.mode === "dark"
                                            ? theme.palette.text.primary
                                            : theme.palette.secondary.main,
                                    }}
                                >
                                    {item.display}
                                </Button>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        );
    },
);
