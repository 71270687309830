import { Capacitor } from "@capacitor/core";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import ContentContainer from "../../components/Container/ContentContainer";
import PageContainer from "../../components/Container/PageContainer";
import RootContainer from "../../components/Container/RootContainer";
import TitleContainer from "../../components/Container/TitleContainer";
import GPDivider from "../../components/Divider/GPDivider";
import { SideNavigation } from "../../components/nav/SideNavigation";
import { TopNav } from "../../components/nav/TopNavigation";
import { GPFooter, GPNavigationCard } from "../../gp-components/src";
import { useLogoutKeycloak } from "../../service/auth/OAuthService";
import { anyPalette } from "../../theme";

type Props = {};

export default function SettingsPage(props: Props) {
    const { t } = useTranslation();
    const logout = useLogoutKeycloak();
    const theme = useTheme();

    return (
        <RootContainer>
            <title>{t("settings.tabTitle")}</title>
            <TopNav />
            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    <TitleContainer>
                        <Typography variant="h1">{t("settings.title")} </Typography>
                    </TitleContainer>
                    <Box
                        mb={1}
                        borderRadius={1}
                        sx={{
                            backgroundColor: theme.palette.card.main,
                        }}
                    >
                        <GPNavigationCard href="/taal" index={0} title={t("userMenu.language")} variant="listItem" />
                        {Capacitor.isNativePlatform() && (
                            <>
                                <Box sx={{ paddingX: 2 }}>
                                    <GPDivider />
                                </Box>
                                <GPNavigationCard
                                    href="/beveiliging"
                                    index={1}
                                    title={t("userMenu.security")}
                                    variant="listItem"
                                />
                            </>
                        )}
                    </Box>
                    <Box
                        mb={1}
                        borderRadius={1}
                        sx={{
                            backgroundColor: theme.palette.card.main,
                        }}
                    >
                        <GPNavigationCard
                            index={2}
                            title={t("userMenu.anyId")}
                            href="https://any-id.nl/consument/"
                            variant="listItem"
                        />
                        <Box sx={{ marginY: 0, marginX: 2 }}>
                            <GPDivider />
                        </Box>
                        <GPNavigationCard
                            index={3}
                            title={t("userMenu.privacy")}
                            href="/privacy-statement"
                            variant="listItem"
                        />
                        <Box sx={{ marginY: 0, marginX: 2 }}>
                            <GPDivider />
                        </Box>
                        <GPNavigationCard
                            index={4}
                            title={t("account.buttonDel")}
                            textColor={theme.palette.mode === "light" ? anyPalette.lightRed : "#FF7878"}
                            href="/account-verwijderen"
                            variant="listItem"
                        />
                    </Box>
                    <GPNavigationCard onClick={logout} index={5} title={t("userMenu.logout")} variant="listItem" />
                </ContentContainer>
            </PageContainer>
            <GPFooter />
            <PageAnalytics />
        </RootContainer>
    );
}
