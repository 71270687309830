import { Avatar, Box, CardActionArea, Skeleton, Stack, Typography } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { ContentComponent } from "../../service/api/brandService";
import { BrandConfig } from "../../service/api/schemeValidator";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import ContentComponentAvatarIcon from "./ContentComponentAvatarIcon";

export const contentMediaHeight = "133px";

type Props = {
    title: string;
    imgUrl: string;
    handleClick: () => void;
    showIcon?: boolean;
    brand_config: BrandConfig;
    content_component: ContentComponent;
    margin?: string;
    ml?: number;
    mr?: number;
    bgColor?: string;
    textSxProps?: SxProps;
    entity: unknown;
};

const baseContentWidth = "130px";

export function BrandCardContent({
    title,
    brand_config: { theme: brandTheme },
    imgUrl,
    content_component: { highlighted, type },
    handleClick,
    showIcon = true,
    margin,
    ml = 2,
    mr = 0,
    bgColor,
    textSxProps,
    entity,
}: Props) {
    const theme = useTheme();

    const contentWidth = highlighted || type === "article" ? "176px" : baseContentWidth;

    return (
        <>
            <CardActionArea
                onClick={handleClick}
                sx={{
                    width: contentWidth,
                    minWidth: contentWidth,
                    height: "200px",
                    borderRadius: "8px",
                    display: "flex",
                    zIndex: 0,
                    backgroundColor:
                        bgColor ||
                        brandTheme?.palette.tertiair ||
                        brandTheme?.palette.primary ||
                        theme.palette.card.main,
                    ml: ml,
                    mr: mr,
                    margin: margin,
                    // rounds corners even for imgs
                    overflow: "hidden",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Box
                        sx={{
                            height: contentMediaHeight,
                        }}
                    >
                        <ImageWithFallback
                            src={type === "coupon" ? imgUrl.replace(/\.webp$/, "_200px.webp") : imgUrl}
                            fallBackUrl={imgUrl.replace(/\.webp$/, ".jpg")}
                            draggable={false}
                            width="100%"
                            height="100%"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                filter: "brightness(0.95)",
                            }}
                            type="rectangular"
                            alt={imgUrl}
                        />
                    </Box>

                    {showIcon && (
                        <Box sx={{ position: "absolute", margin: 1, width: "100%" }}>
                            <Avatar
                                sx={{
                                    width: "33px",
                                    height: "33px",
                                    fontSize: "14px",
                                    p: "10px",
                                    backgroundColor: bgColor ?? brandTheme?.palette.tertiair ?? theme.palette.card.main,
                                }}
                            >
                                <ContentComponentAvatarIcon
                                    // @ts-ignore
                                    color={textSxProps?.color}
                                    iconType={{
                                        type: type,
                                        article: entity as any,
                                        coupon: entity as any,
                                    }}
                                />
                            </Avatar>
                        </Box>
                    )}

                    <Box sx={{ width: "100%", display: "flex", alignItems: "flex-end", p: 1, pb: 1.5 }}>
                        <Typography
                            sx={{
                                color: brandTheme?.palette.tertiairTextColor ?? brandTheme?.palette.textColor,
                                zIndex: -1,
                                maxHeight: "66px",
                                display: "-webkit-box",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                fontWeight: 600,
                                ...textSxProps,
                            }}
                            variant="subtitle1"
                        >
                            {title || <Skeleton />}
                        </Typography>
                    </Box>
                </Box>
            </CardActionArea>
        </>
    );
}

export function BrandCardContentSkeleton() {
    return (
        <Stack direction={"row"} gap={2} ml={2}>
            <Skeleton height={200} width={baseContentWidth} sx={{ transform: "none", transformOrigin: "0", mb: 2 }} />
            <Skeleton height={200} width={baseContentWidth} sx={{ transform: "none", transformOrigin: "0", mb: 2 }} />
            <Skeleton height={200} width={baseContentWidth} sx={{ transform: "none", transformOrigin: "0", mb: 2 }} />
        </Stack>
    );
}
