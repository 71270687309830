import { Tab } from "@mui/material";
import { styled } from "@mui/material/styles";
import { fonts } from "../../../../../service/theme/AnyTheme";

type TabProps = {};

const GPTab = styled(Tab)<TabProps>(({ theme }) => ({
  fontFamily: fonts.poppins,
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "capitalize",
  borderBottom: "1px solid" + theme.palette.sandMedium.main,
  color: theme.palette.sandDark.main,
  padding: 0,
}));
export default GPTab;
