import React, { ReactNode, useMemo } from "react";
import { Section, useGetAllInAppNotifications } from "./InAppNotificationService";
import { useParams } from "react-router-dom";

type inAppNotificationContext = {
    sections: Section[];
    hasUnopenedNotifications: boolean;
};

const InAppNotificationContext = React.createContext<inAppNotificationContext | undefined>(undefined);

export const InAppNotificationProvider = ({
    children,
    environment,
}: {
    environment: "anyid" | "club";
    children: ReactNode;
}) => {
    const { brandId } = useParams();

    const { data } = useGetAllInAppNotifications(environment === "club" ? brandId : undefined);

    const hasUnopenedNotifications = useMemo(() => {
        if (!data) return false;

        for (const section of data) {
            for (const notification of section.notifications) {
                if (notification.status === "first_loaded") {
                    return true;
                }
            }
        }

        return false;
    }, [data]);

    return (
        <InAppNotificationContext.Provider
            value={{
                sections: data ?? [],
                hasUnopenedNotifications,
            }}
        >
            {children}
        </InAppNotificationContext.Provider>
    );
};

export const useInAppNotifications = () => {
    const context = React.useContext(InAppNotificationContext);
    if (!context) {
        throw new Error("You must use useInAppNotifications inside an InAppNotificationProvider");
    }
    return context;
};
