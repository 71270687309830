import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, BoxProps, Grow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AnyButton } from "../Button/AnyButton";

type Props = {
    anytype?: "success" | "error";
    error?: AlertError;
    handleClose?: (newValue: undefined) => void;
};

export type AlertError = {
    id:
        | "brand"
        | "coupon"
        | "interest"
        | "profile"
        | "log"
        | "link"
        | "brandNavigate"
        | "login"
        | "register"
        | "userConsent"
        | "rewardQuiz";
    message: string;
    code?: number;
    icon?: "retry" | "navigate" | "unlock";
    handleAction?: () => void;
};

export function AnyAlert({ anytype = "success", error, handleClose, ...other }: BoxProps & Props) {
    const theme = useTheme();

    function DynamicIcon() {
        if (error) {
            return (
                <FontAwesomeIcon
                    style={{ color: theme.palette.rose.main, height: "20px" }}
                    icon={regular("circle-exclamation")}
                />
            );
        }
        if (anytype === "success") {
            return (
                <FontAwesomeIcon
                    style={{ backgroundColor: theme.palette.green.main, height: "20px" }}
                    icon={regular("circle-check")}
                />
            );
        }

        return <></>;
    }

    return (
        <Box>
            <Grow in timeout={500}>
                <Box
                    p={1}
                    borderRadius={1}
                    {...other}
                    sx={{
                        backgroundColor: theme.palette.white.main,
                        color: "#000000",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        ...other.sx,
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box mr={1} sx={{ display: "flex" }}>
                            <DynamicIcon />
                        </Box>
                        {error && (
                            <Typography variant="subtitle2" sx={{ color: theme.palette.sandDark.main }}>
                                {error.message}
                                {error.code && (
                                    <span style={{ color: theme.palette.sandDark.main }}>{" #" + error.code}</span>
                                )}
                            </Typography>
                        )}
                    </Box>
                    {error?.icon && (
                        <AnyButton
                            sx={{ marginLeft: theme.spacing(1) }}
                            anytype="rose"
                            onClick={() => {
                                if (error.handleAction) error.handleAction();
                                if (handleClose) handleClose(undefined);
                            }}
                        >
                            {error.icon === "retry" && <FontAwesomeIcon icon={solid("rotate-right")} />}
                            {error.icon === "navigate" && (
                                <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} />
                            )}
                            {error.icon === "unlock" && <FontAwesomeIcon icon={solid("unlock")} />}
                        </AnyButton>
                    )}
                </Box>
            </Grow>
        </Box>
    );
}
