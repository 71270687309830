import { FCM } from "@capacitor-community/fcm";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Preferences } from "@capacitor/preferences";
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import axios from "axios";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { useEffect, useState } from "react";
import { UseMutationOptions, UseQueryOptions } from "react-query";
import { NavigateFunction } from "react-router-dom";
import { navigateNewTab } from "../../components/nav/NavigateNewTab";
import { env } from "../../env.mjs";
import { FetchOptions, useQueryGet, useQueryPost, useQueryPut } from "../api/restApiQuery";
import { ParsedAuthBody } from "../auth/AuthStorage.interface";

export type BrandNotificationConsent = {
    brand_id: string;
    consent: boolean;
    id: string;
};

export function useGetNotificationConsentByBrandId(
    brandId?: string,
    options?: UseQueryOptions<BrandNotificationConsent, unknown, BrandNotificationConsent>,
) {
    return useQueryGet<BrandNotificationConsent>(
        ["notification-consent", brandId],
        "gateway",
        "/notification/user/consent?brand-id=" + brandId,
        {
            enabled: brandId !== undefined && Capacitor.isNativePlatform(),
            select: (data: any) => {
                if (!data) return data;

                const selectedData = data as BrandNotificationConsent[];
                return selectedData[0];
            },
            ...options,
        },
    );
}

export function useGetNotificationConsentByBrandIds(
    brandId?: string[],
    options?: UseQueryOptions<BrandNotificationConsent[], unknown, BrandNotificationConsent[]>,
) {
    return useQueryGet<BrandNotificationConsent[]>(
        ["notification-consents"],
        "gateway",
        "/notification/user/consent?brand-id=" + brandId?.join(","),
        {
            enabled: brandId !== undefined && Capacitor.isNativePlatform(),
            ...options,
        },
    );
}

export function useCreateBrandNotificationConsent(
    brandId: string,
    options?: UseMutationOptions<BrandNotificationConsent[], unknown, FetchOptions, unknown>,
) {
    return useQueryPost<BrandNotificationConsent[]>(
        ["notification-consent", brandId],
        "gateway",
        "/notification/user/consent",
        options,
    );
}

export function useUpdateBrandNotificationConsent(
    brandId: string,
    options?: UseMutationOptions<BrandNotificationConsent, unknown, FetchOptions, unknown>,
) {
    return useQueryPut<BrandNotificationConsent>(
        ["notification-consent", brandId],
        "gateway",
        "/notification/user/consent",
        options,
    );
}

/**
 * Utility function to request push notification permission. If user has already closed the OS permission popup, it will
 * redirect into the native settings page.
 */
export async function requestPushNotificationOSPermission(onSuccessCallback: () => void, onErrorCallback: () => void) {
    try {
        // when user wants to follow but has not granted push notification permission yet, we need to request it
        const permissionGranted = await registerPushNotifications();

        if (permissionGranted) {
            onSuccessCallback();
        } else {
            await openNativeNotificationSettings();

            // Check permission again after returning from settings;
            // the user might close settings without enabling the permission
            const permissionGranted = await registerPushNotifications();

            if (permissionGranted) {
                onSuccessCallback();
            }
        }
    } catch (err) {
        console.error(err);
        onErrorCallback();
    }
}

/**
 * Open the relative platform system settings for enabling notifications.
 * In iOS if you go to app permission list it doesn't show notifications, so
 * call this function only after you've already requested notification permission and user
 * has denied it (clicking permission popup outside is considered as denying).
 *
 * @returns Promis with operation status
 */
export function openNativeNotificationSettings() {
    return NativeSettings.open({
        optionAndroid: AndroidSettings.AppNotification,
        optionIOS: IOSSettings.App,
    });
}
/**
 * Return if user has enbaled native notification permission. It listens also when user
 * resumes the app.
 * @param dependencyList list of variables to observe changes
 * @returns
 */
export function useNativePushNotificationPermission(dependencyList: unknown[]) {
    const [hasNotificationEnabled, setHasNotificationEnabled] = useState<boolean | undefined>();

    function updateNotificationPermissionStatus() {
        if (!Capacitor.isNativePlatform()) return;

        hasNativePushNotificationPermission()
            .then((value) => {
                const hasNativePushNotificationPermissionEnabled = value;

                setHasNotificationEnabled(hasNativePushNotificationPermissionEnabled);
            })
            .catch((err) => console.error(err));
    }

    useEffect(() => {
        updateNotificationPermissionStatus();
    }, dependencyList);

    useEffect(() => {
        // Add an event listener for the onResume event
        const onResumeListener = App.addListener("resume", () => {
            // This event is triggered when the app is resumed
            updateNotificationPermissionStatus();
        });

        // Clean up the event listeners when the component unmounts
        return () => {
            onResumeListener.remove();
        };
    }, []);

    return hasNotificationEnabled;
}

/**
 * return true if user has gave native permission
 */
export async function hasNativePushNotificationPermission(): Promise<boolean> {
    let permStatus = await PushNotifications.checkPermissions();

    return permStatus.receive === "granted";
}

// main function to call to enable native notification permission
export async function registerPushNotifications(): Promise<boolean> {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === "granted") {
        // this triggers the function subscribeToPushNotification()
        await PushNotifications.register();

        return true;
    }

    return false;
}

export function addPushNotificationListeners(navigateFunction: NavigateFunction, authBody: ParsedAuthBody) {
    if (!Capacitor.isNativePlatform()) return;

    // feels more like init setup code
    PushNotifications.checkPermissions().then((res) => {
        if (res.receive === "granted") {
            subscribeToPushNotification(authBody);
        }
    });

    //
    PushNotifications.addListener("registration", async (token: Token) => {
        const permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === "granted") {
            subscribeToPushNotification(authBody, token.value);
        }
    });

    PushNotifications.addListener("registrationError", (error: any) => {
        console.log("registrationError: ", error);
    });

    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
        console.log("notification: ", notification);
    });

    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
        const data = notification.notification.data;

        pushNotificationRouter(data, navigateFunction);
    });
}

function pushNotificationRouter(notification: any, navigate: NavigateFunction) {
    const { externalUrl, couponId, navigateUrl } = notification;

    if (externalUrl) {
        navigateNewTab(notification.externalUrl);
        return;
    }

    if (navigateUrl) {
        navigate(navigateUrl);
        return;
    }

    if (couponId) {
        navigate("/coupon/" + notification.couponId);
        return;
    }
}

type FcmTokenPreference = {
    isTokenSet: boolean;
    createdAt: Date;
    updatedAt: Date;
};

type FcmTokenEntity = {
    id: string;
    token: string;
    deviceId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
};

async function subscribeToPushNotification(authBody: ParsedAuthBody, fcmToken?: string) {
    try {
        let shouldUpdateFcmToken = false;
        const backendFcmToken = await getFcmToken(authBody);

        // if backend fcm token is null or different than the local one -> update
        if (!backendFcmToken?.token || backendFcmToken.token !== (fcmToken || (await FCM.getToken()).token)) {
            console.log("update FCM token");
            shouldUpdateFcmToken = true;
        }

        Preferences.get({ key: "syncUpFcmToken" }).then(async (_result) => {
            if (_result.value) {
                const result: FcmTokenPreference = parseToFcmTokenPreference(_result.value);
                const sinceDate = result.updatedAt;
                const futureDate = new Date(sinceDate);
                futureDate.setMonth(sinceDate.getMonth() + 2);

                if (shouldUpdateFcmToken || !result.isTokenSet || !(sinceDate.getTime() < futureDate.getTime())) {
                    const token = fcmToken || (await FCM.getToken()).token;
                    postOrUpdateFcmToken(token, authBody, result);
                }
            } else {
                const token = fcmToken || (await FCM.getToken()).token;
                postOrUpdateFcmToken(token, authBody);
            }
        });
    } catch (e) {
        // try to update
        const token = fcmToken || (await FCM.getToken()).token;
        postOrUpdateFcmToken(token, authBody);
        console.error(e);
    }
}

function parseToFcmTokenPreference(text: string): FcmTokenPreference {
    const rawJson: FcmTokenPreference = JSON.parse(text);

    return {
        isTokenSet: rawJson.isTokenSet,
        createdAt: new Date(rawJson.createdAt),
        updatedAt: new Date(rawJson.updatedAt),
    };
}

async function getFcmToken(authBody: ParsedAuthBody) {
    const deviceId = (await Device.getId()).identifier;

    const body: Partial<FcmTokenEntity> = {
        deviceId,
        userId: authBody?.jwt.sub,
    };
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authBody.accessToken}`,
    };
    const res = await axios({
        method: "post",
        url: env.gatewayUrl + "/notification/user/token",
        headers,
        data: body,
    });

    return res.data as {
        id: string;
        token?: string;
        deviceId: string;
        userId: string;
        createdAt: Date;
        updatedAt: Date;
    };
}

async function postOrUpdateFcmToken(
    localFcmToken: string,
    authBody: ParsedAuthBody,
    prevFcmTokenPreference?: FcmTokenPreference,
) {
    const deviceId = (await Device.getId()).identifier;

    const body: Partial<FcmTokenEntity> = {
        deviceId,
        userId: authBody?.jwt.sub,
    };
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authBody.accessToken}`,
    };

    let isTokenSet = false;

    try {
        const res = await axios({
            method: "post",
            url: env.gatewayUrl + "/notification/user/token",
            headers,
            data: body,
        });

        if ((res.data as FcmTokenEntity).token !== localFcmToken) {
            // update token
            body.token = localFcmToken;
            await axios({
                method: "put",
                url: env.gatewayUrl + "/notification/user/token",
                headers,
                data: body,
            });

            isTokenSet = true;
        } else {
            isTokenSet = true;
        }
    } catch (err: any) {
        if (err.response.status === 404) {
            // create token
            try {
                body.token = localFcmToken;
                await axios({
                    method: "post",
                    url: env.gatewayUrl + "/notification/user/token/create",
                    headers,
                    data: body,
                });
                isTokenSet = true;
            } catch (err) {
                console.warn(err);
            }
        }
    } finally {
        const newPreference: FcmTokenPreference = {
            isTokenSet: isTokenSet,
            updatedAt: new Date(),
            createdAt: prevFcmTokenPreference?.createdAt || new Date(),
        };
        Preferences.set({ key: "syncUpFcmToken", value: JSON.stringify(newPreference) });
    }
}
