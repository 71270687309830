import { UseMutationOptions, UseQueryOptions, useQuery } from "react-query";
import parentCategories from "./../../assets/maps-untold/categories.json";
import { FetchOptions, useQueryDelete, useQueryGet, useQueryPut } from "./restApiQuery";
import { AxiosResponse } from "axios";
import { z } from "zod";
import { validateScheme } from "./schemeValidator";
import { env } from "../../env.mjs";

export function useGetUserHotspots(options?: UseQueryOptions<Record<string, { hotspot_mapping: Hotspot }>>) {
    return useQueryGet<Record<string, { hotspot_mapping: Hotspot; rating?: number }>>(
        ["hotspots"],
        "gateway",
        "/vault/user/hotspot",
        {
            ...options,
        },
    );
}

export function useSaveHotspot(
    hotspot: Hotspot,
    options?: UseMutationOptions<AxiosResponse<Hotspot>, unknown, FetchOptions, unknown>,
) {
    const sanitazedHotspot: Hotspot = {
        ...hotspot,
        imageurl: hotspot.imageurl ?? "",
    };

    return useQueryPut<AxiosResponse<Hotspot>>(
        ["hotspots", hotspot.reco_id],
        "gateway",
        "/vault/user/hotspot",
        { ...options },
        {
            headers: {
                hotspotId: hotspot.reco_id,
            },
            body: sanitazedHotspot,
        },
    );
}

export function useRateHotspot(
    hotspot: Hotspot,
    options?: UseMutationOptions<AxiosResponse<Hotspot>, unknown, FetchOptions, unknown>,
) {
    return useQueryPut<AxiosResponse<Hotspot>>(
        ["hotspots", hotspot.reco_id],
        "gateway",
        "/vault/user/hotspot",
        { ...options },
        {
            headers: {
                hotspotId: hotspot.reco_id,
            },
        },
    );
}

export function useDeleteHotspot(
    hotspot: Hotspot,
    options?: UseMutationOptions<AxiosResponse<Hotspot>, unknown, FetchOptions, unknown>,
) {
    return useQueryDelete<AxiosResponse<Hotspot>>(
        ["hotspots", hotspot.reco_id],
        "gateway",
        "/vault/user/hotspot",
        { ...options },
        {
            headers: {
                hotspotId: hotspot.reco_id,
            },
            body: hotspot,
        },
    );
}

export function useMapsUntoldHotspotRecommandations(hotspotId: string, category: string, city: string) {
    return useQuery(["hotspot", "mapsuntold", hotspotId, category, city], async () =>
        getRecommendations(hotspotId, category, city),
    );
}

export type MapsUntoldCategory = {
    text: string;
    value: string;
    img: string;
};
export const mapsUntoldCategories: MapsUntoldCategory[] = [
    {
        text: "hotspot.category.culture",
        value: "Cultuur",
        img: "https://mapsuntold.io/wp-content/uploads/2023/03/Cultuur-scaled-1.png",
    },
    {
        text: "hotspot.category.eat",
        value: "Eten & Drinken",
        img: "https://mapsuntold.io/wp-content/uploads/2023/03/Eten-_-Drinken-scaled-1.png",
    },
    {
        text: "hotspot.category.activity",
        value: "Activiteiten",
        img: "https://mapsuntold.io/wp-content/uploads/2023/03/Activiteiten-scaled-1.png",
    },
    {
        text: "hotspot.category.nightlife",
        value: "Nachtleven",
        img: "https://mapsuntold.io/wp-content/uploads/2023/04/MapsUntold_IconSet_Scaled-04.png",
    },
    {
        text: "hotspot.category.nature",
        value: "Natuur",
        img: "https://mapsuntold.io/wp-content/uploads/2023/04/MapsUntold_IconSet_Scaled-05.png",
    },
];

export type City = z.infer<typeof CitySchema>;

const CitySchema = z.object({
    name: z.string(),
    key: z.string(),
    imageUrl: z.string().url(),
});

const CitiesSchema = z.array(CitySchema);

const fetchCities = async () => {
    return await fetch(`${env.cdnUrl}/brand/other/627b00af-fbd4-4cac-baf8-25a91533d9c4/city/cities.json`).then(
        async (res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json().then((json) => validateScheme(CitiesSchema, json));
        },
    );
};

export const UseMapsUntoldCities = (options?: any) => {
    return useQuery(["cities"], fetchCities, options);
};

export type HotspotAutoCompleteResult = {
    searchquery: string;
    _id: string;
    title: string;
    category: string;
    city: string;
};

export type Hotspot = {
    reco_id: string;
    imageurl: string;
    title: string;
    score: number;
    category: string;
    city: string;
    address: string;
    website: string;
    rating?: number;
};

type HotspotWithCategory = {
    recos: Hotspot[];
    subcategory: string;
};

type ParentCategory = {
    parentcategory: string;
    subcategories: Subcategory[];
    alternatives: any[]; // The alternatives array is not used in the given JSON, so its type is set to 'any[]'
    images: {
        dark: string;
        light: string;
    };
    texts: {
        categorydescription: string;
        categoryplaceholder: string;
        categoryrecodescription: string;
    };
};
type Subcategory = {
    subcategory: string;
    childcategories: string[];
};

const requestOptions = {
    method: "GET",
};

type GetHotspotAutocompleteReturn = {
    hotspotAutoCompleteResult: HotspotAutoCompleteResult[];
    allSuggestions: HotspotAutoCompleteResult[];
};

const autoCompleteScope = 2;

/** Get recommendations (Hotspots) beased on favorite hotspots and locations
 *
 * Returns:
 *  - A unique list of locations (we take the first place if there are multiples with same name but different addresses)
 *  - List of all locations; it can be used to find the original object and retrieve its ID
 */
export async function getHotspotAutocomplete(
    city: City,
    value: string,
    filterByCity = false,
): Promise<GetHotspotAutocompleteReturn> {
    const results = await fetch(
        `https://api-mapsuntold.nl/autocomplete?term=${value}&searchscope=${autoCompleteScope}`,
        requestOptions,
    )
        .then((response) => {
            // Check if the response status indicates success (200-299) before reading the body
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json(); // or response.text() if the response is in plain text format
        })
        .then((result: HotspotAutoCompleteResult[]) => {
            if (result.length > 0) {
                // get unique
                const uniqueTitles = new Map<string, HotspotAutoCompleteResult>();
                result.forEach((item) => {
                    const key = `${item.title}-${item.category}-${item.city}`;
                    uniqueTitles.set(key, item);
                });
                // Convert the Map values back to an array
                const uniqueList: HotspotAutoCompleteResult[] = Array.from(uniqueTitles.values());

                // sort by city
                const itemsWithMatchingCity: HotspotAutoCompleteResult[] = [];
                const itemsWithDifferentCity: HotspotAutoCompleteResult[] = [];

                uniqueList.forEach((item: HotspotAutoCompleteResult) => {
                    if (item.city === city.name) {
                        itemsWithMatchingCity.push(item);
                    } else {
                        itemsWithDifferentCity.push(item);
                    }
                });

                // Concatenate the arrays so that items with matching city come first
                const sortedItems = filterByCity
                    ? itemsWithMatchingCity
                    : [...itemsWithMatchingCity, ...itemsWithDifferentCity];

                const value: GetHotspotAutocompleteReturn = {
                    allSuggestions: result,

                    hotspotAutoCompleteResult: sortedItems,
                };
                return value;
            }
        })
        .catch((error) => {
            console.error(error);
            return {
                allSuggestions: [],
                hotspotAutoCompleteResult: [],
            };
        });

    if (!results)
        return {
            allSuggestions: [],
            hotspotAutoCompleteResult: [],
        };

    return results;
}

function getSubcategoriesByParentCategory(data: ParentCategory[], parentCategory: string): Subcategory[] {
    const category = data.find((category) => category.parentcategory === parentCategory);
    return category ? category.subcategories : [];
}

interface IndexMap {
    [key: string]: string;
}
const indexMap: IndexMap = {
    Cultuur: "0",
    "Eten & Drinken": "1",
    Activiteiten: "2",
    Nachtleven: "3",
    Natuur: "4",
};
const mapsUntoldClientId = "anyid";
async function getRecommendations(hotspotId: string, category: string, city: string): Promise<Hotspot[]> {
    const parsedCity = city.toLowerCase().replace(" ", "");
    const results = await fetch(
        `https://api-mapsuntold.nl/recommendations?favoritesid=arrayitem&favoritesid=${hotspotId}${indexMap[category]}&city=${parsedCity}&client=${mapsUntoldClientId}`,
        requestOptions,
    )
        .then((response) => {
            return response.json();
        })
        .then(async (result) => {
            const recommendations = result[0].recos;
            let allRecommendationsWithCategory: Hotspot[] = [];
            let seenRecoIds = new Set();

            recommendations.forEach((recommendationCategory: HotspotWithCategory) => {
                recommendationCategory.recos.forEach((recommendation: Hotspot) => {
                    // Check if the recommendation ID is not in the set and the subcategories match
                    if (
                        !seenRecoIds.has(recommendation.reco_id) &&
                        getSubcategoriesByParentCategory(parentCategories, category)?.some((subcategory: Subcategory) =>
                            subcategory.childcategories.includes(recommendation.category),
                        )
                    ) {
                        seenRecoIds.add(recommendation.reco_id);
                        allRecommendationsWithCategory.push(recommendation);
                    }
                });
            });

            allRecommendationsWithCategory.sort((a, b) => b.score - a.score);

            const allRecommendationsWithCategoryWithCity = allRecommendationsWithCategory.map((item) => {
                return { ...item, city };
            });

            return allRecommendationsWithCategoryWithCity;
        })
        .catch((error) => {
            console.error("error", error);
            throw error;
        });

    return results;
}
