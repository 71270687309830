import { Paper, styled } from "@mui/material";
import { ReactNode } from "react";
import Bird from "../../../assets/club/bso/bso-bird.svg";

type BsoCardProps = {
    children: ReactNode;
};
export default function BsoCard({ children }: BsoCardProps) {
    return (
        <Card>
            {children}
            <img src={Bird} alt="Bird" style={{ position: "absolute", bottom: "-10px", right: "8px" }} />
        </Card>
    );
}
const Card = styled(Paper)(({ theme }) => ({
    marginX: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: "2px 6px 24px 0px #0000001A",
    position: "relative",
}));
