import { Box, Button, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import { AnyLinearProgress } from "../../gp-components/src/components/Progress/AnyLinearProgress";
import { useBrandById, useBrands, useNavigateToBrand } from "../../service/api/brandService";
import { Coupon } from "./CouponCard";
import { useQueryGet } from "../../service/api/restApiQuery";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { ImageWithFallback } from "../Image/ImageWithFallback";

type Props = {
    open: boolean;
    toggleOpen: () => void;
    coupon: Coupon;
};

const RewardQuizIntroPopup: FC<Props> = ({ open, toggleOpen, coupon }) => {
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);

    const theme = useTheme();
    const { t } = useTranslation();
    const { connectedBrands } = useBrands();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { data: brand, refetch: brandRefetch } = useBrandById(coupon.brand_id, {
        suspense: true,
        onError: (e: any) => {
            setAlertError({
                id: "brand",
                message: t("brandDetail.getBrandError"),
                code: e.response.status,
                icon: "retry",
                handleAction: brandRefetch,
            });
        },
    });

    const isConnectedToBrand = useMemo(
        () => connectedBrands.findIndex((b) => b.id === coupon.brand_id) !== -1,
        [connectedBrands, coupon.brand_id],
    );

    const { navigateToBrandAsync: linkUser, isLoading: isLinking } = useNavigateToBrand(
        coupon.brand_id,
        undefined,
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ["user-brands"] });
                queryClient.invalidateQueries({ queryKey: ["brand", "detail", coupon.brand_id] });
            },
        },
        undefined,
        undefined,
        // user must consent to proceed to the quiz
        "true",
    );

    const {
        data: attributes,
        isLoading: isAttributesLoading,
        refetch: profileRefetch,
    } = useQueryGet<{ [key: string]: string[] }>(["user-attributes"], "gateway", "/vault/user/attributes", {
        onError: (e: any) => {
            setAlertError({
                id: "interest",
                message: t("errors.getDataFailed"),
                code: e.response.code,
                icon: "retry",
                handleAction: profileRefetch,
            });
        },
    });

    async function startQuiz() {
        // either connect and/or set marketing consent to true
        await linkUser();
        navigate("/quiz/" + coupon.id);
    }

    function pascalToSnake(pascalCase: string): string {
        const snakeCase = pascalCase.replace(/([A-Z])/g, "_$1").toLowerCase();
        return snakeCase.slice(1); // Remove the leading underscore
    }

    useEffect(() => {
        if (!attributes || !coupon || !open) return;

        let alreadyCompleted = true;

        coupon?.required_attributes.forEach((attribute) => {
            const requiredAttribute = attributes[pascalToSnake(attribute)];

            if (!requiredAttribute || requiredAttribute.length <= 1) {
                alreadyCompleted = false;
                return;
            }
        });

        if (alreadyCompleted) activateReward();
    }, [attributes, coupon, open]);

    const activateReward = () => {
        navigate("/coupon/" + coupon.id);
    };

    if (isAttributesLoading) {
        return <></>;
    }

    return (
        <>
            <DynamicPopup dialogProps={{ fullWidth: true }} open={open} onOpen={toggleOpen} handleClose={toggleOpen}>
                <Stack gap={1}>
                    <Box sx={{ width: { xs: "100%", sm: "70%", alignSelf: "center", mx: "auto" } }}>
                        <ImageWithFallback
                            src={coupon.image}
                            fallBackUrl={coupon.image.replace(/\.webp$/, ".jpg")}
                            height="275px"
                            width="100%"
                            containerSx={{
                                width: "100%",
                                maxHeight: "275px",
                                objectFit: "cover",
                                borderRadius: theme.shape.borderRadius + "px",
                            }}
                            type="rounded"
                            alt={coupon.name}
                        />
                    </Box>
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontFamily: "Poppins",
                            fontWeight: 600,
                        }}
                    >
                        {t("rewardQuizIntroPopup.title")}
                    </Typography>

                    <Typography sx={{ opacity: "0.75" }}>{t("rewardQuizIntroPopup.body")}</Typography>

                    <Stepper activeStep={isConnectedToBrand ? 1 : 0} orientation="vertical">
                        <Step>
                            <StepLabel>{t("rewardQuizIntroPopup.step1Title", { brandName: brand?.name })}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>{t("rewardQuizIntroPopup.step2Title")}</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("rewardQuizIntroPopup.step3Title", {
                                            couponName: `<strong>${coupon.name}</strong>`,
                                        }),
                                    }}
                                ></span>
                            </StepLabel>
                        </Step>
                    </Stepper>

                    <Typography sx={{ opacity: "0.75", mb: 0.5 }}>{t("rewardQuizIntroPopup.legalTerms")}</Typography>

                    <Box display="flex">
                        {isConnectedToBrand ? (
                            <Button fullWidth variant="contained" onClick={startQuiz}>
                                {t("rewardQuizIntroPopup.startButton")}
                            </Button>
                        ) : (
                            <Button
                                disabled={isLinking}
                                fullWidth
                                variant="contained"
                                onClick={async () => {
                                    await linkUser();
                                    startQuiz();
                                }}
                            >
                                {t("rewardQuizIntroPopup.connectButton")}
                            </Button>
                        )}
                    </Box>

                    {isLinking && <AnyLinearProgress />}
                </Stack>
            </DynamicPopup>
            {alertError && (
                <Box mt={1}>
                    <AnyAlert error={alertError} handleClose={setAlertError} />
                </Box>
            )}
        </>
    );
};

export default RewardQuizIntroPopup;
