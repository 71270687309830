import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, LinearProgress, Stack, SxProps, useMediaQuery, useTheme } from "@mui/material";
import { CSSProperties, Dispatch, SetStateAction, memo, useEffect, useRef, useState } from "react";
import { MediaContent, useBrandMediaContentProvider } from "../../service/mediaContentService";
import { ImageWithFallback } from "../Image/ImageWithFallback";

type BrandCardMediaCarouselProps = {
    id: string;
    borderRadius?: string;
    isUniqueBrandCard?: boolean;
};

export default function BrandCardMediaCarousel({ id, borderRadius, isUniqueBrandCard }: BrandCardMediaCarouselProps) {
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(false);

    const mediaContentContainerRef = useRef(null);

    const theme = useTheme();
    const { GetBrandMediaContentList: GetMediaContentList } = useBrandMediaContentProvider();

    const carouselMedia = GetMediaContentList({
        brandId: id,
        directory: "carousel",
        metadata: { type: ["image", "vector", "video", "gif"] },
    });

    const [isMediaLoaded, setisMediaLoaded] = useState<Map<number, boolean>>(
        new Map(carouselMedia?.map((item) => [item.Metadata.index!, false])),
    );

    // check if media content is visible in client screen
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 1,
            }, // Adjust the threshold as needed
        );

        if (mediaContentContainerRef.current) {
            observer.observe(mediaContentContainerRef.current);
        }

        return () => {
            if (mediaContentContainerRef.current) {
                observer.unobserve(mediaContentContainerRef.current);
            }
        };
    }, [mediaContentContainerRef.current]);

    const brandHasMultipleMedia = carouselMedia?.length && carouselMedia?.length > 1;

    return (
        <Box ref={mediaContentContainerRef} sx={{ height: "300px", width: "100%", flex: 1, position: "relative" }}>
            {carouselMedia?.map((media) => {
                return (
                    <div
                        key={media.Url}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            height: "100%",
                            width: "100%",
                            display: media.Metadata.index === currentMediaIndex ? "block" : "none",
                            transition: "all ease 0.1s",
                        }}
                    >
                        <MediaContentContainer
                            media={media}
                            isVisible={isVisible && media.Metadata.index === currentMediaIndex}
                            borderRadius={borderRadius}
                            isUniqueBrandCard={isUniqueBrandCard}
                            setMediaIsLoaded={setisMediaLoaded}
                        />
                    </div>
                );
            })}

            {brandHasMultipleMedia && (
                // media buttons
                <MediaNavigationButtons
                    setCurrentMediaIndex={setCurrentMediaIndex}
                    mediaLength={carouselMedia?.length ?? 1}
                />
            )}

            {brandHasMultipleMedia && (
                <>
                    {/* timeline */}
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: theme.spacing(2),
                            zIndex: 100,
                            width: "100%",
                            px: 2,
                        }}
                    >
                        <Stack mt={1} direction={"row"} gap={1}>
                            {carouselMedia?.map((media, index) => {
                                return (
                                    <MediaTimeline
                                        key={media.Url}
                                        media={carouselMedia}
                                        active={currentMediaIndex === index}
                                        completed={index < currentMediaIndex}
                                        currentMediaIndex={currentMediaIndex}
                                        setCurrentMediaIndex={setCurrentMediaIndex}
                                        isVisible={isVisible && (isMediaLoaded.get(index) ?? false)}
                                    />
                                );
                            })}
                        </Stack>
                    </Box>
                </>
            )}
        </Box>
    );
}

type MediaContentContainerProps = {
    media: MediaContent;
    isVisible: boolean;
    borderRadius?: string;
    isUniqueBrandCard?: boolean;
} & setIsMediaLoadedCallbackType;

function MediaContentContainer(props: MediaContentContainerProps) {
    const { media: _media, borderRadius } = props;
    const isDesktop = useMediaQuery("(min-width:600px)");

    const style = { height: "100%", width: "100%", borderRadius: borderRadius ?? (isDesktop ? " 8px 8px 0 0" : 0) };

    if (_media.Metadata.type === "video") {
        return <VideoComponent {...props} media={_media} />;
    }

    return (
        <ImageWithFallback
            loading="lazy"
            src={_media.Url}
            width="100%"
            height="100%"
            type="rectangular"
            onLoadCallback={() => {
                setIsMediaLoadedCallback(props.media.Metadata.index!, {
                    setMediaIsLoaded: props.setMediaIsLoaded,
                });
            }}
            containerSx={{ ...style, objectFit: "cover" }}
        />
    );
}

export function VideoComponent({
    media,
    isVisible,
    isUniqueBrandCard,
    borderRadius,
    setMediaIsLoaded,
}: MediaContentContainerProps) {
    const videoElementRef = useRef<HTMLVideoElement>(null);

    const isDesktop = useMediaQuery("(min-width:600px)");
    const style = { height: "100%", width: "100%", borderRadius: borderRadius ?? (isDesktop ? " 8px 8px 0 0" : 0) };

    useEffect(() => {
        if (!videoElementRef.current) return;
        try {
            if (isVisible) {
                const isPlaying =
                    videoElementRef.current.currentTime > 0 &&
                    !videoElementRef.current.paused &&
                    !videoElementRef.current.ended &&
                    videoElementRef.current.readyState > videoElementRef.current.HAVE_CURRENT_DATA;
                if (!isPlaying) {
                    videoElementRef.current.play();
                }
            } else {
                videoElementRef.current.currentTime = 0;
                videoElementRef.current.pause();
            }
        } catch (e) {
            console.warn(e);
        }

        return () => {
            if (videoElementRef.current) {
                videoElementRef.current.pause();
            }
        };
    }, [isVisible]);

    let videoUrl = media.Url;

    return (
        <video
            ref={videoElementRef}
            preload="none"
            muted
            autoPlay
            playsInline
            loop={isUniqueBrandCard}
            style={{ ...style, objectFit: "cover", transition: "all ease 0.1s" }}
            poster="noposter"
            onCanPlay={() => {
                setIsMediaLoadedCallback(media.Metadata.index!, {
                    setMediaIsLoaded,
                });
            }}
        >
            <source src={videoUrl}></source>
        </video>
    );
}

export function wrapAroundIndex(index: number, range: number) {
    return ((index % range) + range) % range;
}

type MediaNavigationButtonsProps = {
    mediaLength: number;
    setCurrentMediaIndex: Dispatch<SetStateAction<number>>;
};

const buttonIconStyle: CSSProperties = {
    padding: "10px",
    fontSize: "18px",
    cursor: "pointer",
    color: "white",
};

const iconWrapperStyle: CSSProperties = {
    borderRadius: "50%",
    backgroundColor: "#00000099",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "38px",
    width: "38px",
};

const containerStyle: SxProps = {
    position: "absolute",
    justifyContent: "flex-start",
    width: "50%",
    height: "100%",
    top: 0,
    left: 0,
    "&:hover": {
        backgroundColor: "transparent",
    },
    cursor: "inherit",
};
function _MediaNavigationButtons({ setCurrentMediaIndex, mediaLength }: MediaNavigationButtonsProps) {
    return (
        <>
            <Button
                sx={containerStyle}
                variant="text"
                disableRipple
                onClick={() => {
                    setCurrentMediaIndex((prevIndex) => wrapAroundIndex(prevIndex - 1, mediaLength));
                }}
            >
                <div style={iconWrapperStyle}>
                    <FontAwesomeIcon icon={solid("arrow-left")} style={buttonIconStyle} />
                </div>
            </Button>
            <Button
                sx={{
                    ...containerStyle,
                    justifyContent: "flex-end",
                    left: "unset",
                    right: 0,
                }}
                variant="text"
                disableRipple
                onClick={() => setCurrentMediaIndex((prevIndex) => wrapAroundIndex(prevIndex + 1, mediaLength))}
            >
                <div style={iconWrapperStyle}>
                    <FontAwesomeIcon icon={solid("arrow-right")} style={buttonIconStyle} />
                </div>
            </Button>
        </>
    );
}

const MediaNavigationButtons = memo(_MediaNavigationButtons);

type setIsMediaLoadedCallbackType = {
    setMediaIsLoaded: Dispatch<SetStateAction<Map<number, boolean>>>;
};

function setIsMediaLoadedCallback(index: number, { setMediaIsLoaded }: setIsMediaLoadedCallbackType) {
    setMediaIsLoaded((prev) => {
        const newMap = new Map(prev);
        newMap.set(index, true);
        return newMap;
    });
}

const imgDuration = 3 * 1000;
type MediaTimelineProps = {
    media: MediaContent[];
    active: boolean;
    completed: boolean;
    currentMediaIndex: number;
    isVisible: boolean;
    setCurrentMediaIndex: (value: number) => void;
};

function MediaTimeline({
    active,
    completed,
    currentMediaIndex,
    media,
    setCurrentMediaIndex,
    isVisible,
}: MediaTimelineProps) {
    const [progress, setProgress] = useState(!active ? (completed ? 100 : 0) : 0);

    const theme = useTheme();

    function getMediaDuration() {
        return ["gif", "video"].includes(media[currentMediaIndex].Metadata.type)
            ? media[currentMediaIndex].Metadata.duration ?? imgDuration
            : imgDuration;
    }

    useEffect(() => {
        if (!active) {
            setProgress(completed ? 100 : 0);
            return;
        }

        const interval = 20; // Update every 20 milliseconds
        const duration = getMediaDuration(); // Total duration in milliseconds
        const steps = duration / interval;
        let step = 0;

        let timer: NodeJS.Timer;

        if (isVisible) {
            timer = setInterval(() => {
                step++;
                let newProgress = (step / steps) * 100;

                setProgress(newProgress);

                if (step >= steps) {
                    clearInterval(timer);
                    setCurrentMediaIndex(wrapAroundIndex(currentMediaIndex + 1, media.length));
                }
            }, interval);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isVisible, active, completed]);

    return (
        <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
                width: "100%",
                boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
                "& .MuiLinearProgress-bar": {
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    // remove animation when going back
                    transition: progress === 100 ? "none" : "width 0.5s linear",
                },
                borderRadius: "8px",
                backgroundColor: theme.palette.sandDark.main,
            }}
        />
    );
}
