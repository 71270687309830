import { faChevronRight, faNewspaper } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { type Article, useClubProvider, Poll } from "../../service/ClubService";
import { MediaContentLanguage } from "../../service/mediaContentService";
import { useTranslation } from "react-i18next";
import { Button, Skeleton, useTheme } from "@mui/material";
import { useWizardForm } from "../WizardForm/WizardFormService";
import { useConfigTranslator } from "../../service/ConfigTranslator";

export type Quiz = {
    title: string;
    created_at: number;
    banner_image_url: string;
    id: string;
};

export const ArticleSection = ({ articles, limited, quiz }: { articles: Article[]; limited: boolean; quiz?: Poll }) => {
    const { brand_config: brandConfig, brand_id: brandId } = useClubProvider();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <>
            <div
                style={{
                    display: "flex",
                }}
            >
                <div style={{ textTransform: "uppercase" }}>{t("club.article.news")}</div>
            </div>
            <div>
                {quiz && quiz.duration > 0 && !quiz?.metadata.voting_winner && <QuizItem {...quiz} />}

                {articles.map((article) => {
                    return <ArticleItem {...article} key={article.id} />;
                })}
            </div>
            {limited && (
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        fontFamily: brandConfig.environment?.fonts.secondary,
                        fontSize: "18px",
                        marginBottom: "16px",
                    }}
                    onClick={() => {
                        navigate("/club/nieuws/" + brandId);
                    }}
                >
                    {t("club.article.viewAllArticles").toUpperCase()}
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{
                            marginLeft: "8px",
                        }}
                    />
                </div>
            )}
        </>
    );
};

const QuizItem = ({ id }: Poll) => {
    const { brand_id } = useClubProvider();
    const theme = useTheme();
    const navigate = useNavigate();
    const { data: wizard } = useWizardForm(id);
    const { translateConfig } = useConfigTranslator();
    const { t } = useTranslation();

    if (!wizard) {
        return <Skeleton />;
    }

    return (
        <div
            style={{
                display: "flex",
                border: "1px solid #ffffff80",
                margin: theme.spacing(1, 0),
                padding: "8px",
            }}
            onClick={() => {
                navigate("/club/quiz/" + brand_id);
            }}
        >
            <div style={{ width: "40%" }}>
                <img
                    src={wizard?.config.header_image}
                    style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        paddingRight: theme.spacing(1),
                    }}
                    alt="quiz"
                />
            </div>
            <div style={{ width: "60%", fontSize: "15px", display: "flex", flexDirection: "column" }}>
                {wizard.config.preview && <div>{translateConfig(wizard.config.preview).toUpperCase()}</div>}

                <Button
                    size="small"
                    sx={{ mt: "auto", width: "fit-content", fontWeight: 400 }}
                    endIcon={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "1em" }} />}
                >
                    {t("club.quiz.play").toUpperCase()}
                </Button>
            </div>
        </div>
    );
};

const ArticleItem = ({ header: title, banner_image_url, id }: Article) => {
    const { brand_id } = useClubProvider();
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div
            style={{
                display: "flex",
                border: "1px solid #ffffff80",
                margin: theme.spacing(1) + " 0px",
                padding: "8px",
            }}
            onClick={() => {
                navigate("/club/nieuws/" + brand_id + "/artikel/" + id);
                window.scroll(0, 0);
            }}
        >
            <div style={{ width: "40%" }}>
                <img
                    src={banner_image_url}
                    style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        paddingRight: theme.spacing(1),
                    }}
                    alt="article"
                />
            </div>
            <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
                <div
                    style={{
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                    }}
                >
                    {title.toUpperCase()}
                </div>
                <div
                    style={{
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1, fontWeight: 400 }}
                        endIcon={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: "1em" }} />}
                    >
                        {t("club.article.more").toUpperCase()}
                    </Button>
                    <FontAwesomeIcon icon={faNewspaper} />
                </div>
            </div>
        </div>
    );
};

export const DateSection = ({ unix }: { unix: number }) => {
    const { i18n } = useTranslation();
    const { brand_config: brandConfig } = useClubProvider();
    const theme = useTheme();

    const date = new Date(unix * 1000);

    return (
        <div
            style={{
                backgroundColor: brandConfig.environment?.colors.text,
                color: brandConfig.environment?.colors.primary,
                fontSize: "12px",
                padding: theme.spacing(1) + " 0px",
                display: "block",
                width: "95px",
                textAlign: "center",
            }}
        >
            {`${date.getUTCDate()}  ${getMonthAbbreviation(
                date.getUTCMonth(),
                i18n.language.split("-")[0] as MediaContentLanguage,
            )}. ${date.getUTCFullYear()}`}
        </div>
    );
};

// TODO: #1596 do not rely on language to get the months, use the class object of Date like it's done in the calendar page
// it breaks if there's a layout like en-US
function getMonthAbbreviation(monthNumber: number, language: "nl" | "en"): string {
    const monthsAbbreviations = {
        en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        nl: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    };

    const monthsAbbreviation = monthsAbbreviations[language];

    return monthsAbbreviation[monthNumber].toUpperCase();
}
