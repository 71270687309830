export type BrandCategory = {
    Id: string;
    Name: string;
};

export const categories: BrandCategory[] = [
    { Id: "d6348d9b-3005-4ac0-81e9-24978b7e15a7", Name: "leisure" },
    { Id: "bcb852a9-5c97-47ed-afec-855ffafe94e0", Name: "fashion" },
    { Id: "41ff1e96-60e3-46fc-9529-d6182c1c7c8b", Name: "food" },
    { Id: "ca1db169-4511-458f-a1c1-e28107e529e6", Name: "sports" },
];
