import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CardActionArea, Container, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Desktop } from "..";

import GpLogo from "../../../img/GpLogo.png";
import GpLogoWhite from "../../../img/GpLogoWhite.png";

import { useTheme } from "@mui/material/styles";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnyIdLogo } from "../../../../components/Logo/AnyIdLogo";

type Props = {};

export default function GPFooter(props: Props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  function LinkButton(text: string, href: string, icon?: any) {
    return (
      <CardActionArea sx={{ width: "auto", padding: 1, borderRadius: "6px" }}>
        <Link
          underline="none"
          sx={{ color: theme.palette.text.tertiair, display: "flex", alignItems: "center" }}
          href={href}
          target="_blank"
        >
          <FontAwesomeIcon style={{ marginRight: theme.spacing(1) }} icon={icon ? icon : solid("chevron-right")} />
          <Typography>{text}</Typography>
        </Link>
      </CardActionArea>
    );
  }

  function getUrl(href: "forConsumers" | "forBusiness" | "contact" | "home" | "privacy") {
    const urls = {
      forConsumers: {
        nl: "https://any-id.nl/consument/",
        en: "https://any-id.nl/consument/",
      },
      forBusiness: {
        nl: "https://any-id.nl/",
        en: "https://any-id.nl/",
      },
      contact: {
        nl: "https://any-id.nl/neem-contact-op/",
        en: "https://any-id.nl/neem-contact-op/",
      },
      home: {
        nl: "https://any-id.nl/",
        en: "https://any-id.nl/",
      },
      privacy: {
        nl: "https://any-id.nl/privacy-policy/",
        en: "https://any-id.nl/privacy-policy/",
      },
    } as any;

    return urls[href][i18n.languages[0]];
  }

  return (
    <Desktop
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "transparent" : theme.palette.sandMedium.main,
        borderTop: theme.palette.mode === "dark" ? "1px solid #C0C0C0" : "0px 2px 0px rgba(0, 0, 0, 0.05)",
        paddingTop: 3,
        paddingBottom: 4,
        marginTop: 4,
        width: "100%",
        flex: "0 1 auto",
      }}
    >
      <Container>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
          <AnyIdLogo height="24px" alt="any-logo" />
          <Typography sx={{ marginX: 1 }} variant="h3">
            {t("footer.productOf")}
          </Typography>
          <img height="24px" src={theme.palette.mode === "dark" ? GpLogoWhite : GpLogo} alt="gp-logo" />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            {LinkButton(t("footer.forConsumers"), getUrl("forConsumers"))}
            {LinkButton(t("footer.forBusiness"), getUrl("forBusiness"))}
            {LinkButton(t("footer.aboutAnyID"), getUrl("contact"))}
          </Box>
          <Box>
            {LinkButton(t("userMenu.anyId"), getUrl("forConsumers"), solid("lock-keyhole"))}
            {LinkButton(t("userMenu.privacy"), getUrl("privacy"), solid("user-circle"))}
            {LinkButton(t("footer.requestBusiness"), getUrl("contact"), solid("suitcase"))}
          </Box>

          <Box
            sx={{
              marginRight: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: theme.palette.text.tertiair,
            }}
          >
            <Typography>Globe Protocol</Typography>
            <Typography>Schimmelt 32</Typography>
            <Typography>5611 ZX Eindhoven</Typography>
            <Link
              sx={{ textDecorationColor: theme.palette.text.tertiair }}
              onClick={() => {
                window.location.href = "mailto:info@globeprotocol.com";
              }}
            >
              <Typography sx={{ color: theme.palette.text.tertiair, cursor: "pointer" }}>
                info@globeprotocol.com
              </Typography>
            </Link>
          </Box>
        </Box>
      </Container>
    </Desktop>
  );
}
