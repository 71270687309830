import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import { StatusBar, Style } from "@capacitor/status-bar";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getDynamicTheme } from "./theme/AnyTheme";
import { useTranslation } from "react-i18next";

interface ThemeContextInterface {
    toggleColorMode: () => void;
}

const ColorModeContext = React.createContext<ThemeContextInterface | undefined>(undefined);

export function DynamicThemeProvider({ children }: any) {
    const { i18n } = useTranslation();
    const [theme, setTheme] = useState(getDynamicTheme("dark", i18n));

    const toggleMode = useCallback(() => {
        const newMode = theme.palette.mode === "light" ? "dark" : "light";

        Preferences.set({ key: "theme", value: newMode });
        if (Capacitor.isNativePlatform()) {
            StatusBar.setStyle({ style: newMode === "dark" ? Style.Dark : Style.Light });
        }

        setTheme(getDynamicTheme(newMode, i18n));
    }, [theme]);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            StatusBar.setStyle({ style: Style.Dark });
        }
    }, []);

    return (
        <ColorModeContext.Provider
            value={{
                toggleColorMode: toggleMode,
            }}
        >
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export function useDynamicThemeProvider() {
    const context = useContext(ColorModeContext);
    if (!context) {
        throw new Error("useDynamicThemeProvider must only be called in DynamicThemeProvider");
    }
    return context;
}
