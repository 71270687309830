export function hexToRgba(hex: string, opacity: number): string {
    const hexValue = hex.replace("#", "");
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
/**
 * Checks whether the font color should be light or dark given hexColor
 * @returns true for dark color, false for light
 */
export function colorIsDark(hexColor: string): boolean {
    if (hexColor.startsWith("#")) {
        hexColor = hexColor.slice(1);
    }

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    return brightness < 128;
}

export function deepMergeObjects(target: any = {}, source: any = {}): any {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            if (
                source[key] &&
                typeof source[key] === "object" &&
                target.hasOwnProperty(key) &&
                typeof target[key] === "object"
            ) {
                // If both the target and source properties are objects, recursively merge them
                deepMergeObjects(target[key], source[key]);
            } else {
                // Otherwise, simply assign the value to the target property
                target[key] = source[key];
            }
        }
    }
    return target;
}
