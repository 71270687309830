import { useClubProvider } from "../../service/ClubService";
import { motion } from "framer-motion";
import { RadialGradient } from "../../components/Container/MainContent";
import HdbIcon from "./../../assets/club/animation/hdb-icon.png";
import HdbLogoText from "./../../assets/club/animation/hdb-logo-text.png";

export default function HeroAnimation({ onClose }: { onClose: () => void }) {
    const { brand_config } = useClubProvider();

    return (
        <motion.div
            exit={{ opacity: 0, display: "none", transitionEnd: { display: "none" } }}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 100,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                }}
            >
                {/* top container */}
                <motion.div
                    style={{
                        width: "102%",
                        height: "100%",
                        flex: 1,
                        backgroundColor: brand_config.environment?.colors.primary,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                    }}
                    animate={{ x: "-100vw", transition: { ease: "easeOut", duration: 1, delay: 1 } }}
                >
                    <RadialGradient
                        gradient={brand_config.theme?.palette.gradient}
                        style={{ height: "170%", left: "-105%" }}
                    />
                    <motion.div
                        initial={{ x: "-100vw" }}
                        animate={{ x: 0, transition: { ease: "easeIn", duration: 0.3 } }}
                    >
                        <img src={HdbIcon} style={{ width: "124px", height: "auto" }} />
                    </motion.div>
                </motion.div>

                {/* bottom container */}
                <motion.div
                    animate={{ x: "100vw", transition: { ease: "easeOut", duration: 1, delay: 1 } }}
                    style={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                        backgroundColor: brand_config.environment?.colors.primary,
                        display: "flex",
                        justifyContent: "center",
                    }}
                    onAnimationComplete={() => onClose()}
                >
                    <motion.div
                        initial={{ x: "100vw" }}
                        animate={{ x: 0, transition: { ease: "easeIn", duration: 0.3, delay: 0.3 } }}
                    >
                        <img src={HdbLogoText} />
                    </motion.div>
                </motion.div>
            </div>
            <div></div>
        </motion.div>
    );
}
