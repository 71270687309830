import { ThemeOptions } from "@mui/material";
import { BrandConfig } from "../api/schemeValidator";
import { getBrandThemeOptions } from "./BrandThemes";

/**
 * Get the club environment theme. It's based on the BranTheme/getBrandThemeOptions() with more aggressive theming
 * @param brandId
 * @param brandConfig
 * @returns
 */
export function getClubTheme(brandId: string, brandConfig: BrandConfig): ThemeOptions {
    const baseTheme = getBrandThemeOptions(brandId);

    if (!brandConfig.environment) throw new Error(`Invalid brand config with brand id ${brandId} in club environment`);

    const clubThemes = {
        // Heroes den Bosch
        "19e9244c-34e4-49d4-8b92-211f552142aa": {
            ...baseTheme,
            palette: {
                ...baseTheme.palette,
                card: {
                    ...baseTheme.palette?.card,
                    main: brandConfig.environment?.colors.primary,
                    dark: brandConfig.environment?.colors.primary,
                },
            },
            typography: {
                ...baseTheme.typography,
                fontFamily: `${brandConfig.environment.fonts.primary}`,
                h4: {
                    color: brandConfig.environment.colors.text,
                    fontSize: "1.1em",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                },
                h6: {
                    fontFamily: `${brandConfig.environment.fonts.primary}`,
                },
                body1: {
                    fontFamily: `${brandConfig.environment.fonts.primary}`,
                },
                subtitle1: {
                    fontFamily: `${brandConfig.environment.fonts.primary}`,
                },
            },
            components: {
                ...baseTheme.components,
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            fontFamily: brandConfig.environment.fonts.secondary, // futura condensed for headers, futur
                            color: brandConfig.environment.colors.text,
                        },
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                        variant: "contained",
                    },
                    styleOverrides: {
                        root: {
                            fontFamily: brandConfig.environment.fonts.primary,
                            textTransform: "uppercase",
                            borderRadius: 0,
                            backgroundColor: brandConfig.environment.colors.secondary,
                        },
                        outlined: {
                            backgroundColor: "transparent",
                            color: brandConfig.environment.colors.text,
                            border: `2px solid ${brandConfig.environment?.colors.text}`,
                            p: 1,
                            "&:hover": {
                                border: `2px solid ${brandConfig.environment?.colors.text}`,
                                backgroundColor: "transparent",
                            },
                        },
                        text: {
                            padding: 0,
                            minWidth: 0,
                            backgroundColor: "transparent",
                            color: brandConfig.environment.colors.text,
                        },
                    },
                },
            },
        },
        // BSO demo brand
        "a282cde6-2cbe-48a5-8e66-948cfe6f4c76": {
            ...baseTheme,
            palette: {
                ...baseTheme.palette,
                card: {
                    ...baseTheme.palette?.card,
                    main: brandConfig.environment?.colors.secondary,
                    dark: brandConfig.environment?.colors.secondary,
                },
                inAppNotificationCard: {
                    main: brandConfig.environment?.colors.secondary,
                },
            },
            components: {
                ...baseTheme.components,
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            fontFamily: brandConfig.environment.fonts.secondary, // futura condensed for headers, futur
                            color: brandConfig.environment.colors.text,
                        },
                    },
                },
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                    },
                    styleOverrides: {
                        root: {
                            fontFamily: "Aleo",
                            fontWeight: 400,
                            color: brandConfig.environment.colors.text,
                            backgroundColor: brandConfig.environment.colors.secondary,
                            ":hover": {
                                backgroundColor: brandConfig.environment.colors.secondary,
                            },
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            padding: "16px",
                        },
                    },
                },
            },
        },
    } as Record<string, ThemeOptions>;

    return clubThemes[brandId];
}
