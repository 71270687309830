import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBase, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { brandNavigationRouter } from "../../Brand/ActionRouter";
import { useNavigate } from "react-router-dom";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { UsefulLink } from "../../../service/api/bsoService";
import { env } from "../../../env.mjs";

type BsoUsefulLinkProps = {
    link: UsefulLink;
    showDescription?: boolean;
};

export default function BsoUsefulLink({ link, showDescription = true }: BsoUsefulLinkProps) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <ButtonBase
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.5,
                mx: "auto",
                justifyContent: "flex-start",
                cursor: "pointer",
            }}
            onClick={() => brandNavigationRouter(link.link, undefined, navigate)}
        >
            <Box
                sx={{
                    borderRadius: "99999px",
                    backgroundColor: theme.palette.secondary.main,
                    height: "140px",
                    width: "140px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "2px 6px 24px 0px #0000001A",
                    p: 4,
                }}
            >
                <img
                    style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "auto",
                        overflow: "hidden",
                    }}
                    alt={link.title}
                    src={link.logo_url}
                />
            </Box>

            <Box
                sx={{
                    display: "flex",
                    color: theme.palette.primary.main,
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "5px",
                    marginTop: "4px",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 500,
                        textAlign: "center",
                    }}
                >
                    {link.title}
                </Typography>

                {link.link.includes("https") && !link.link.includes(env.customerPortalUrl) && (
                    <FontAwesomeIcon fontSize="18px" icon={faArrowUpRightFromSquare} style={{ marginTop: "-3px" }} />
                )}
            </Box>

            {showDescription && (
                <Typography
                    variant="h8"
                    sx={{
                        fontWeight: 300,
                        fontStyle: "italic",
                        color: theme.palette.text.secondary,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {link.description}
                </Typography>
            )}
        </ButtonBase>
    );
}
