import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Dialog, DialogActions, DialogProps, DialogTitle, IconButton, Slide, Typography } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";

export interface AnyDialogProps extends DialogProps {
  handleClose?: () => void;
  titleIcon?: React.ReactNode;
  header?: React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
}

export function AnyDialog(props: AnyDialogProps) {
  const { handleClose, title, titleIcon, header, primaryButton, secondaryButton, ...other } = props;
  const theme = useTheme();

  return (
    <Dialog
      scroll="body"
      TransitionComponent={TransitionSlide}
      style={{
        backgroundColor: "rgba(34, 35, 35, 0.7)",
        backdropFilter: "blur(2.5px)",
      }}
      PaperProps={{
        sx: {
          borderRadius: theme.shape.borderRadius + "px",
          backgroundColor: "card.dark",
          backgroundImage: "inherit",
        },
      }}
      {...other}
      onClose={handleClose}
    >
      <DialogTitle color="inherit" sx={{ p: 2, pb: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "flex-start" }}>
            {titleIcon && <Box sx={{ marginRight: 2, display: "flex", alignItems: "center" }}>{titleIcon}</Box>}
            {title && <Typography variant="h3">{title}</Typography>}
            {header}
          </Box>
          {handleClose && (
            <IconButton onClick={handleClose}>
              <FontAwesomeIcon icon={solid("xmark")} />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <Box px={2} py={1}>
        {props.children}
      </Box>
      {(primaryButton || secondaryButton) && (
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          {primaryButton}
          {secondaryButton}
        </DialogActions>
      )}
    </Dialog>
  );
}

const TransitionSlide = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
