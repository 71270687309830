import { Clipboard } from "@capacitor/clipboard";
import { faArrowRight, faCheck, faCopy, faLockOpen, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Dialog, DialogProps, Paper, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { logEventWithBrandId, logEventWithCouponId } from "../../service/AnalyticsService";
import { useClubProvider } from "../../service/ClubService";
import { useNavigateToBrand } from "../../service/api/brandService";
import { useActivateCoupon, useViewCoupon } from "../../service/api/couponService";
import { safeAddQueryParameter } from "../../utils/SafeAddQueryParameter";
import { brandNavigationRouter } from "../Brand/ActionRouter";
import { Coupon, CouponStatus } from "../coupon/CouponCard";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { CouponDecorationIcon } from "../../pages/reward/RewardDetail";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";

/**
 * Club Coupon card design. This is re-used in the dialog version in Partners page
 * @returns
 */
export const CouponDisplay = (
    coupon: Coupon & {
        closeDisplay?: () => void;
        disabled?: boolean;
    },
) => {
    const theme = useTheme();

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "12px",
                padding: theme.spacing(2),
                color: "#000000",
                fontFamily: "Poppins",
                height: "fit-content",
                position: "relative",
            }}
        >
            <Paper
                sx={{
                    position: "absolute",
                    margin: theme.spacing(2),
                    borderRadius: "25px",
                    padding: 1,
                    display: "flex",
                    zIndex: 10,
                }}
            >
                <BrandIcon width="32px" brandId={coupon?.brand_id} />

                <CouponDecorationIcon coupon={coupon} />
            </Paper>

            {coupon.closeDisplay !== undefined && (
                <div
                    style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        backgroundColor: "#ffffffcc",
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        margin: theme.spacing(4),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 8px 24px",
                        zIndex: 100,
                    }}
                    onClick={() => {
                        if (coupon.closeDisplay) coupon.closeDisplay();
                    }}
                >
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                            height: "20px",
                        }}
                    />
                </div>
            )}
            <CouponImage image={coupon.image} />
            <CouponText name={coupon.name} description={coupon.description} />
            <CouponCodeDisplay {...coupon} disabled={coupon.disabled} />
        </div>
    );
};

/**
 * Club Coupon card used in partners page. It uses the same component in CouponDisplay
 * @returns
 */
export const CouponDialog = ({ dialog, coupon, brandId }: { dialog: DialogProps; coupon: Coupon; brandId: string }) => {
    const queryClient = useQueryClient();
    const theme = useTheme();

    return (
        <div>
            <Dialog
                {...dialog}
                PaperProps={{
                    sx: {
                        margin: 0,
                        background: "transparent",
                        width: "100%",
                        px: theme.spacing(2),
                    },
                }}
                onClose={(event, reason) => {
                    logEventWithCouponId("coupon_close", coupon.id);
                    queryClient.invalidateQueries(["coupon", "brand", brandId]);
                    if (dialog.onClose) dialog.onClose(event, reason);
                }}
            >
                <CouponDisplay
                    {...coupon}
                    closeDisplay={() => {
                        logEventWithCouponId("coupon_close", coupon.id);
                        queryClient.invalidateQueries(["coupon", "brand", brandId]);
                        if (dialog.onClose) dialog.onClose({}, "escapeKeyDown");
                    }}
                />
            </Dialog>
        </div>
    );
};

const CouponImage = ({ image }: { image: string }) => {
    return (
        <ImageWithFallback
            src={image}
            fallBackUrl={image?.replace(/\.webp$/, ".jpg")}
            containerSx={{
                width: "100%",
                height: "100%",
                maxHeight: "348px",
                aspectRatio: "1",
                borderRadius: "8px",
                verticalAlign: "middle",
                objectFit: "cover",
                zIndex: 0,
            }}
            type="rounded"
            alt="Coupon image"
        />
    );
};

const CouponText = ({ name, description }: { name: string; description: string }) => {
    const theme = useTheme();

    return (
        <div
            style={{
                margin: theme.spacing(2, 0),
            }}
        >
            <div
                style={{
                    fontSize: "18px",
                    fontWeight: "600",
                }}
            >
                {name}
            </div>
            <div
                style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    opacity: 0.7,
                }}
            >
                {description}
            </div>
        </div>
    );
};

const CouponCodeDisplay = ({
    code,
    id,
    brand_id,
    type,
    status,
    disabled = false,
}: Pick<Coupon, "code" | "brand_id" | "id" | "type" | "status"> & { disabled?: boolean }) => {
    const [navigateIsLoading, setNavigateIsLoading] = useState(false);
    const { brandTheme: sponsor_brand_config } = useBrandConfigProvider().getByID(brand_id);
    const { brand_config } = useClubProvider();
    const theme = useTheme();
    const { t } = useTranslation();
    const [opened, setOpened] = useState(status === CouponStatus.Used);

    const { mutate: viewCoupon } = useViewCoupon(id, {
        onSuccess: () => {
            logEventWithCouponId("coupon_view", id);
        },
    });
    const { mutate: activateCoupon, data: activatedCoupon } = useActivateCoupon(
        id,
        brand_id,
        {
            onSuccess() {
                logEventWithCouponId("coupon_activate", id);
                setOpened(true);
            },
        },
        false,
    );

    const { navigateToBrand } = useNavigateToBrand(
        brand_id,
        undefined,
        {
            onSuccess: (result) => {
                logEventWithBrandId("login_from_anyid", brand_id);
                if (activatedCoupon) {
                    logEventWithCouponId("navigate_to_brand", activatedCoupon.data.id);
                }

                let redirectUrlWithParams = safeAddQueryParameter(
                    result.data.redirect_url,
                    "coupon=" + (activatedCoupon ? activatedCoupon.data.code : code),
                );

                brandNavigationRouter(redirectUrlWithParams, brand_id).then(() => {
                    setNavigateIsLoading(false);
                });
            },
        },
        "navigate-brand",
        undefined,
    );

    useEffect(() => {
        // @ts-ignore (status == "" is for backward compatibility
        if (status === CouponStatus.New || status == "") {
            viewCoupon({});
        } else if (status === CouponStatus.Used) {
            setOpened(true);
        }
    }, [status]);

    function hasCode() {
        if (type === "giveaway") return false;

        if (!opened) return true;

        const codeValue = activatedCoupon ? activatedCoupon.data.code : code;

        return codeValue.trim() !== "";
    }

    function ButtonText() {
        if (navigateIsLoading) {
            return (
                <>
                    <CircularProgress
                        sx={{ color: theme.palette.primary.contrastText, my: "auto", height: "100%" }}
                        size={"26px"}
                    />
                </>
            );
        }

        if (type === "online") {
            if (opened)
                return (
                    <>
                        {t("club.coupon.store")}
                        <FontAwesomeIcon
                            icon={faArrowRight}
                            style={{
                                marginLeft: "4px",
                            }}
                        />
                    </>
                );

            return (
                <>
                    {t("club.coupon.open")}
                    <FontAwesomeIcon
                        icon={faLockOpen}
                        style={{
                            marginLeft: "4px",
                        }}
                    />
                </>
            );
        }

        if (type === "giveaway") {
            if (opened)
                return (
                    <>
                        {t("couponDetail.giveaway.couponActivated")}
                        <FontAwesomeIcon
                            icon={faCheck}
                            style={{
                                marginLeft: "4px",
                            }}
                        />
                    </>
                );

            return <>{t("couponDetail.giveaway.buttonActivate")}</>;
        }

        if (opened) return <>{t("club.coupon.rewardActivated")}</>;
        else return <>{t("club.coupon.activateReward")}</>;
    }

    return (
        <div>
            {/* despite coupon is type "store" we show the code. if coupon is not activated, then we cannot know beforehand its code */}
            {hasCode() && (
                <div
                    style={{
                        backgroundColor: "#00000016",
                        borderRadius: "4px",
                        padding: "8px",
                        display: "flex",
                    }}
                >
                    {opened ? (
                        <CouponCodeContent code={activatedCoupon ? activatedCoupon.data.code : code} type={type} />
                    ) : (
                        <div
                            style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#000000",
                                margin: "4px 0px",
                            }}
                        >
                            {t("club.coupon.reveal")}
                        </div>
                    )}
                </div>
            )}
            <Button
                variant="contained"
                disabled={disabled || (["store", "giveaway"].includes(type) && opened)}
                style={{
                    fontWeight: "400",
                    backgroundColor:
                        ["store", "giveaway"].includes(type) && opened
                            ? sponsor_brand_config?.palette.secondary
                            : brand_config.environment?.colors.secondary,
                    color: type === "store" && opened ? "#4D4D4D" : brand_config.environment?.colors.text,
                    width: "100%",
                    borderRadius: "4px",
                    marginTop: theme.spacing(1),
                }}
                onClick={() => {
                    if (["store", "giveaway"].includes(type) && opened) return;

                    if (opened) {
                        navigateToBrand();
                        setNavigateIsLoading(true);
                    } else activateCoupon({});
                }}
            >
                <ButtonText />
            </Button>
        </div>
    );
};

const CouponCodeContent = ({ code, type }: Pick<Coupon, "code" | "type">) => {
    const { brand_config } = useClubProvider();
    const { t } = useTranslation();

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        Clipboard.write({
            string: code,
        }).then(() => {
            setCopied(true);
        });
    };
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "17px",
                        color: "#000000",
                        opacity: 0.7,
                        fontFamily: "Roboto",
                    }}
                >
                    {t("club.coupon.code")}
                </div>
                <div
                    style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "17px",
                        color: "#000000",
                    }}
                >
                    {code}
                </div>
            </div>
            {type !== "store" && (
                <div
                    style={{
                        backgroundColor: brand_config.environment?.colors.primary,
                        color: brand_config.environment?.colors.text,
                        marginLeft: "auto",
                        fontWeight: 600,
                        borderRadius: "4px",
                        padding: "10px 16px",
                    }}
                    onClick={copyToClipboard}
                >
                    {copied ? t("club.coupon.copied") : t("club.coupon.copy")}
                    <FontAwesomeIcon icon={copied ? faCheck : faCopy} style={{ marginLeft: "4px" }} />
                </div>
            )}
        </>
    );
};
