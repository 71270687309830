import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import { useBrandById, useGetBrandLandingPageUrl } from "../../service/api/brandService";
import { useQueryClient } from "react-query";

type Props = {
    open: boolean;
    toggleOpen: () => void;
};
export default function WelcomeToConnectedBrandDrawer({ open, toggleOpen }: Props) {
    const { data: brandLandingPage, isLoading: brandLandingPageIsLoading } = useGetBrandLandingPageUrl();
    const { data: brand, isLoading: brandIsLoading } = useBrandById(brandLandingPage?.brand_id);

    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const navigate = useNavigate();

    function handlePrimaryButtonClick() {
        if (brandLandingPage) {
            navigate(brandLandingPage?.sub_path);
            queryClient.invalidateQueries({ queryKey: ["brand", "landing-page"] });
        }
    }

    return (
        <DynamicPopup open={open} onOpen={toggleOpen} handleClose={toggleOpen}>
            <Stack gap={4} mt={5} mb={3} alignItems={"center"} width={"100%"}>
                {!brandLandingPageIsLoading && !brandIsLoading && brandLandingPage && brand ? (
                    <>
                        <BrandIcon brandId={brandLandingPage.brand_id} width="96px" />
                        <Typography fontSize={"20px"} fontWeight={600} lineHeight={"30px"} textAlign={"center"}>
                            {t("home.welcomeToConnectedBrandDrawer.title", { brandName: brand.name })}
                        </Typography>
                        <Button onClick={handlePrimaryButtonClick} fullWidth>
                            {t("home.welcomeToConnectedBrandDrawer.buttonText")}
                        </Button>
                    </>
                ) : (
                    <div style={{ height: "350px" }}>
                        <CircularProgress />
                    </div>
                )}
            </Stack>
        </DynamicPopup>
    );
}
