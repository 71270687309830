import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Img } from "react-suspense-img";
import Bird from "../../../assets/club/bso/bso-bird.svg";
import Butterfly from "../../../assets/club/bso/bso-butterfly.svg";
import CouponCardBanner from "../../../assets/club/bso/bso-home-kadootjes-banner.png";
import { BsoArticleList } from "../../../components/Club/bso/BsoArticleList";
import BsoCalendarEventAlert from "../../../components/Club/bso/BsoCalendarEventAlert";
import BsoUsefulLink from "../../../components/Club/bso/BsoUsefulLink";
import FadeContainer from "../../../components/Container/FadeContainer";
import NotificationPermissionPopup from "../../../components/Notification/NotificationPermissionPopup";
import { useClubProvider } from "../../../service/ClubService";
import { useGetBsoParent, useGetUsefulLinks } from "../../../service/api/bsoService";
import { useCapacitorProvider } from "../../../service/capacitor/CapacitorProvider";
import { useBrandMediaContentProvider } from "../../../service/mediaContentService";
import { useQuery, useQueryClient } from "react-query";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";

export default function ClubBsoHomePage() {
    const { brand_config, brand_id } = useClubProvider();

    const { data: highlightedUsefulLinks } = useGetUsefulLinks(brand_id, true);

    const theme = useTheme();
    const { brandId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { GetBrandMediaContent } = useBrandMediaContentProvider();
    const { insets } = useCapacitorProvider();

    const { data: bsoParent } = useGetBsoParent({ suspense: true });

    const brandLogo = GetBrandMediaContent({
        brandId: brand_id ?? "",
        directory: "logo",
    });

    const bsos = useMemo(() => {
        if (!bsoParent) return undefined;

        const hash = new Map<string, string>();
        bsoParent.children.forEach((ch) => {
            ch.bso_ids.forEach((id) => hash.set(id, "exists"));
        });

        return Array.from(hash, (map) => map[0]).join(",");
    }, [bsoParent]);

    return (
        <FadeContainer short>
            <title>{t("club.tabTitle.home", { brandName: brand_config.name })}</title>

            {Capacitor.isNativePlatform() && brandLogo?.Url && <BsoNotificationPermissionPopup logo={brandLogo.Url} />}

            <div
                style={{
                    backgroundColor: theme.palette.background.paper,
                    marginTop: insets?.top ? -insets.top + "px" : "-16px",
                    padding: "16px",
                    paddingTop: insets?.top ? insets.top + "px" : "16px",
                    marginLeft: "-16px",
                    marginRight: "-16px",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    display: "flex",
                    boxShadow: "2px 4px 16px rgb(0 0 0 / 0.15)",
                }}
            >
                <Button
                    sx={{
                        bgcolor: theme.palette.green.main,
                        color: theme.palette.white.main,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "16px",
                        display: "flex",
                        gap: "8px",
                        "&:hover": {
                            bgcolor: theme.palette.green.main,
                        },
                    }}
                    onClick={() => {
                        navigate("/", {
                            state: {
                                fromClub: true,
                            },
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Home
                </Button>

                <Button
                    sx={{
                        marginLeft: "auto",
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.white.main,
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "16px",
                        display: "flex",
                        gap: "4px",
                        "&:hover": {
                            bgcolor: theme.palette.primary.main,
                        },
                    }}
                    onClick={() => {
                        navigate(`/club/bso/mijn-gegevens/${brand_id}`);
                    }}
                >
                    {t("settings.title")}
                </Button>
            </div>
            <Stack
                gap={"32px"}
                sx={{
                    marginBottom: "-16px",
                }}
            >
                <div>{brandLogo?.Url && <Img src={brandLogo.Url} height="auto" width="100%" alt="logo" />}</div>

                {brandId && <BsoCalendarEventAlert brandId={brandId} />}

                <Grid container spacing={4}>
                    {highlightedUsefulLinks?.map((link) => {
                        return (
                            <Grid key={link.id} item xs={6}>
                                <BsoUsefulLink link={link} showDescription={false} />
                            </Grid>
                        );
                    })}
                </Grid>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        mx: -2,
                        py: 1.5,
                        px: 2,
                        gap: 2,
                        bgcolor: theme.palette.background.paper,
                    }}
                >
                    <Box color={theme.palette.primary.main}>
                        <Typography variant="h6" fontWeight={700}>
                            {t("club.bso.home.usefulLinksBanner.title")}
                        </Typography>
                        <Typography variant="h8" fontWeight={400}>
                            {t("club.bso.home.usefulLinksBanner.description")}
                        </Typography>
                    </Box>

                    <Box>
                        <Button
                            sx={{
                                py: 1,
                                px: 1.5,
                                borderRadius: 0.5,
                                height: "40px",
                                backgroundColor: "white !important",
                            }}
                            onClick={() => navigate(`/club/bso/useful-links/${brand_id}`)}
                        >
                            <Typography variant="h7">{t("club.bso.home.usefulLinksBanner.button")}</Typography>
                            <FontAwesomeIcon icon={faChevronRight} fontSize="16px" style={{ marginLeft: "3px" }} />
                        </Button>
                    </Box>
                    <img src={Bird} alt="Bird" style={{ position: "absolute", bottom: "-13px", right: "12px" }} />
                </Box>

                {bsos && <BsoArticleList brand_id={brandId!} bsos={bsos} />}

                <Box position={"relative"} mb={insets?.bottom ? -insets.bottom + "px" : "-20px"}>
                    <img
                        src={Butterfly}
                        alt="Butterfly"
                        style={{ height: "41px", position: "absolute", zIndex: "1", right: "9px", top: "-20px" }}
                    />

                    <Box
                        sx={{
                            mx: -2,
                            height: "140px",
                            py: 1.5,
                            px: 2,
                            backgroundImage: `url(${CouponCardBanner})`,
                            backgroundSize: "cover",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            position: "relative",
                            mx: -2,
                            py: 1.5,
                            px: 2,
                            gap: 2,
                            bgcolor: theme.palette.background.paper,
                        }}
                    >
                        <Box color={theme.palette.primary.main}>
                            <Typography variant="h6" fontWeight={700}>
                                {t("club.bso.home.presentsBanner.title", { brandName: brand_config.name })}
                            </Typography>
                            <Typography variant="h8" fontWeight={400}>
                                {t("club.bso.home.presentsBanner.description")}
                            </Typography>
                        </Box>

                        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
                            <Button
                                sx={{
                                    py: 1,
                                    px: 1.5,
                                    borderRadius: 0.5,
                                    height: "40px",
                                    backgroundColor: "white !important",
                                }}
                                onClick={() => navigate(`/club/bso/gifts/${brand_id}`)}
                            >
                                <Typography variant="h7">{t("club.bso.home.presentsBanner.button")}</Typography>
                                <FontAwesomeIcon icon={faChevronRight} fontSize="16px" style={{ marginLeft: "3px" }} />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </FadeContainer>
    );
}

type BsoNotificationPermissionPopupProps = {
    logo: string;
};
const key = "seen_bso_notification_permission_popup";
const BsoNotificationPermissionPopup = ({ logo }: BsoNotificationPermissionPopupProps) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const theme = useTheme();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data: seen_intro, isLoading } = useQuery({
        queryKey: [key],
        queryFn: () => Preferences.get({ key: key }),
    });

    useEffect(() => {
        if (!isLoading) setOpenDrawer(seen_intro?.value !== "true");
    }, [seen_intro?.value, isLoading]);

    function closePopupState() {
        Preferences.set({
            key: key,
            value: "true",
        });
        queryClient.invalidateQueries({ queryKey: key });
    }

    return (
        <NotificationPermissionPopup
            open={openDrawer}
            toggleOpen={() => {
                setOpenDrawer((prev) => !prev);
                closePopupState();
            }}
            iconSlot={<img src={logo} alt="logo" style={{ width: "100%", height: "auto" }} />}
            title={""}
            description={t("club.bso.home.notificationPermissionPopup.description")}
            descriptionAlignment="left"
            buttonText={{
                description: t("notificationPermissionPopup.enableButton"),
                title: t("notificationPermissionPopup.enableButton"),
            }}
            primaryButtonSx={{
                bgcolor: theme.palette.tertiair.main,
                color: theme.palette.tertiair.contrastText,
            }}
            secondaryButtonSx={{
                color: undefined,
            }}
            handleEnableCallback={() => {
                closePopupState();
            }}
        />
    );
};
