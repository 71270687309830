import { SnackbarProvider } from "notistack";
import { lazy } from "react";
import { Outlet, RouteObject, RouterProvider, ScrollRestoration, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { BrandOverviewSearchBarPage } from "./components/Brand/BrandOverviewSearchBar";
import { BottomNav } from "./components/nav/BottomNavigation";
import { env } from "./env.mjs";
import { DownloadAppPage } from "./pages/Download";
import HomePage from "./pages/Home";
import InAppNotificationPage from "./pages/In-App-Notification/InAppNotificationPage";
import SettingsPage from "./pages/account/Settings";
import BrandOverviewPage from "./pages/brand/BrandOverview";
import ClubCalendarPage from "./pages/club/ClubCalendarPage";
import ClubHomePage from "./pages/club/HomePage";
import ClubPartnersPage from "./pages/club/PartnersPage";
import ClubTeamPage from "./pages/club/TeamPage";
import { BannerProvider } from "./service/BannerProvider";
import { BrandConfigProvider } from "./service/BrandConfigProvider";
import { DynamicThemeProvider } from "./service/DynamicThemeProvider";
import { InAppNotificationProvider } from "./service/InAppNotificationProvider";
import { AuthProvider } from "./service/auth/AuthProvider";
import { BrandSearchProvider } from "./service/auth/BrandSearchProvider";
import { AppLocalizationProvider } from "./service/dateService";
import { MediaContentProvider } from "./service/mediaContentService";

const BsoFillMissingParentDataPage = lazy(() => import("./pages/data/BsoFillMissingParentDataPage"));
const BsoParentDataOverview = lazy(() => import("./components/Club/bso/BsoParentDataOverviewPage"));

// code splitting - lazy load pages that are less used
const CompleteAccountPage = lazy(() => import("./pages/account/CompleteAccount"));
const DeleteAccountPage = lazy(() => import("./pages/account/DeleteAccount"));
const LanguagePage = lazy(() => import("./pages/account/Language"));
const PrivacyStatementPage = lazy(() => import("./pages/account/PrivacyStatement"));
const SecurityPage = lazy(() => import("./pages/account/Security"));
const MyDataEditPage = lazy(() => import("./pages/data/MyDataEdit"));
const DataOverviewPage = lazy(() => import("./pages/data/DataOverview"));
const ChangePincodePage = lazy(() => import("./pages/auth/native/ChangePincode"));
const MyDataPage = lazy(() => import("./pages/data/MyData"));
const RecentActivityPage = lazy(() => import("./pages/RecentActivity"));

const BrandDetailPage = lazy(() => import("./pages/brand/BrandDetail"));
const BrandCategoriesPage = lazy(() => import("./components/Brand/BrandCategories"));
const MobilePromotionPage = lazy(() => import("./pages/MobilePromotion"));
const BrandConfiguratorPage = lazy(() => import("./pages/development/BrandConfigurator"));
const RegisterLinkPage = lazy(() => import("./pages/linking/register/RegisterLink"));
const RegisterLinkTestPage = lazy(() => import("./pages/development/RegisterLinkTest"));
const BsoOnboardingPage = lazy(() => import("./pages/linking/bso/BsoOnboardingPage"));
const BrandNavigationPage = lazy(() => import("./pages/brand/BrandNavigation"));
const RedirectBrandPage = lazy(() => import("./pages/routing/RedirectBrand"));
const AuthenticatorPage = lazy(() => import("./pages/auth/native/authenticator/Authenticator"));
const BrandConsentPage = lazy(() => import("./pages/brand/BrandConsent"));
const InterestsPage = lazy(() => import("./pages/data/MyInterests"));

const NotFoundPage = lazy(() => import("./pages/error/NotFound"));
const ClosedPage = lazy(() => import("./pages/Closed/ClosedPage"));

const RewardDetailPage = lazy(() => import("./pages/reward/RewardDetail"));
const RewardQuizPage = lazy(() => import("./pages/reward/RewardQuiz"));
const RewardQuizStartRedirect = lazy(() => import("./pages/reward/RewardQuizStartRedirect"));
const RedirectNativeStorePage = lazy(() => import("./pages/routing/RedirectNativeStore"));
const BirthdayCouponPage = lazy(() => import("./pages/reward/BirthdatCoupon/BirthdayCoupon"));

const TripPage = lazy(() => import("./pages/Trip/Trip"));
const TripRecommendationPage = lazy(() => import("./pages/Trip/TripRecommendation"));
const TripsPage = lazy(() => import("./pages/Trip/Trips"));
const LoginLinkPage = lazy(() => import("./pages/linking/LoginLink"));
const TransferAccountPage = lazy(() => import("./pages/linking/TransferAccount"));
const RegisterLinkDTDDEmployeePage = lazy(() => import("./pages/linking/register/RegisterLinkDTDDEmployee"));
const RegisterUserGroupLinkPage = lazy(() => import("./pages/linking/register/RegisterUserGroupLink"));
const RegisterUserGroupBrewDogLinkPage = lazy(() => import("./pages/linking/register/RegisterUserGroupLink_BrewDog"));
const BrandThemedLandingPage = lazy(() => import("./pages/BrandThemedLandingPage"));

const NewsPage = lazy(() => import("./pages/club/NewsPage"));
const ClubArticlePage = lazy(() => import("./pages/club/ArticlePage"));
const PlayerPage = lazy(() => import("./pages/club/PlayerPage"));

const ClubProvider = lazy(() => import("./service/ClubService"));
const ClubMvpVoterPage = lazy(() => import("./pages/club/ClubMvpVoter"));
const ClubMvpRewardPage = lazy(() => import("./pages/club/ClubMvpReward"));
const ClubMvpResultPage = lazy(() => import("./pages/club/ClubMvpResult"));
const PartnerPage = lazy(() => import("./pages/club/PartnerPage"));
const ClubCouponPage = lazy(() => import("./pages/club/CouponPage"));
const ClubMyData = lazy(() => import("./pages/club/MyData"));
const ClubMyDataEditPage = lazy(() => import("./pages/club/MyDataEdit"));
const ArticlePage = lazy(() => import("./pages/News/ArticlePage"));
const ClubQuizPage = lazy(() => import("./pages/club/QuizPage"));
const PublicClubQuizPage = lazy(() => import("./pages/club/PublicQuizPage"));
const SyncClubSeasonTicket = lazy(() => import("./pages/club/SyncClubSeasonTicket"));
const UsefulLinksPage = lazy(() => import("./pages/club/bso/usefulLinksPage/UsefulLinksPage"));
const BsoInAppNotificationPage = lazy(() => import("./pages/club/bso/BsoInAppNotificationPage"));
const ClubBsoGiftsPage = lazy(() => import("./pages/club/bso/giftsPage/ClubBsoGiftsPage"));

const AddIosPinnedShortcutPage = lazy(() => import("./pages/appShortcut/AddIosPinnedShortcutPage"));

const CommonProviders = () => {
    return (
        <DynamicThemeProvider>
            <BrandSearchProvider>
                <AuthProvider>
                    <MediaContentProvider>
                        <SnackbarProvider>
                            <AppLocalizationProvider>
                                <BrandConfigProvider>
                                    <BannerProvider>
                                        <>
                                            {/* scroll to the top page on route navigation */}
                                            <ScrollRestoration />
                                            <div className="noSelect">
                                                <Outlet />
                                            </div>
                                        </>
                                    </BannerProvider>
                                </BrandConfigProvider>
                            </AppLocalizationProvider>
                        </SnackbarProvider>
                    </MediaContentProvider>
                </AuthProvider>
            </BrandSearchProvider>
        </DynamicThemeProvider>
    );
};

const MainLayout = () => {
    return (
        <>
            <InAppNotificationProvider environment="anyid">
                <Outlet />
                <BottomNav />
            </InAppNotificationProvider>
        </>
    );
};

const MainRoutes: RouteObject[] = [
    { index: true, Component: HomePage },
    { path: "/home", Component: HomePage },
    { path: "/overzicht-gegevens", Component: DataOverviewPage },
    { path: "/mijn-gegevens", Component: MyDataPage },
    { path: "/mijn-gegevens/bewerken", Component: MyDataEditPage },
    { path: "/mijn-interesses", Component: InterestsPage },
    { path: "/recent-gedeeld", Component: RecentActivityPage },
    { path: "/coupon/:id", Component: RewardDetailPage },
    { path: "/coupon/:id/birthday", Component: BirthdayCouponPage },
    { path: "/notificaties", Component: InAppNotificationPage },
];

const BrandRoutes: RouteObject[] = [
    { path: "/brand/:brandId", Component: BrandDetailPage },
    { path: "/member/:brandId", Component: BrandDetailPage },
    { path: "/gedeelde-gegevens/:brandId", Component: BrandConsentPage },
    { path: "/aangesloten-merken", Component: BrandOverviewPage },

    { path: "/aangesloten-merken/zoeken", Component: BrandOverviewSearchBarPage },
    { path: "/aangesloten-merken/categorie", Component: BrandCategoriesPage },
];

const QuizRoutes: RouteObject[] = [
    { path: "/welkom/start/:couponId/:brandId", Component: RewardQuizStartRedirect },
    { path: "/quiz/:couponId", Component: RewardQuizPage },
];

const AccountRoutes: RouteObject[] = [
    { path: "/instellingen", Component: SettingsPage },
    { path: "/taal", Component: LanguagePage },
    { path: "/beveiliging", Component: SecurityPage },
    { path: "/privacy-statement", Component: PrivacyStatementPage },
    { path: "/account-verwijderen", Component: DeleteAccountPage },
    { path: "/pincode-wijzigen", Component: ChangePincodePage },
    { path: "/compleet-account", Component: CompleteAccountPage },
];

const PublicRoutes: RouteObject[] = [
    { path: "/authenticator/:brandId", Component: AuthenticatorPage },
    { path: "/welkom/:brandId", Component: RegisterLinkPage },
    { path: "/welkom/:brandId/variant/:wizardId", Component: RegisterLinkPage },
    { path: "/bso/welkom/:brandId", Component: BsoOnboardingPage },
    { path: "/login-link/:brandId", Component: LoginLinkPage },
    { path: "/update-account/:brandId", Component: TransferAccountPage },
    { path: "/sync-season-ticket/:brandId", Component: SyncClubSeasonTicket },
    { path: "/user-group-link/:brandId/brewdog", Component: RegisterUserGroupBrewDogLinkPage },
    { path: "/user-group-link/:brandId", Component: RegisterUserGroupLinkPage },
    { path: "/brand/inloggen/:brandId", Component: BrandNavigationPage },
    { path: "/brand-landing/:brandId", Component: BrandThemedLandingPage },
    { path: "/brand-landing", Component: BrandThemedLandingPage },
    // for testing club with just brandId (static qr-code)
    { path: "public/club/quiz/:brandId/", Component: PublicClubQuizPage },
];

const SpecificBrandRoutes: RouteObject[] = [
    { path: "/medewerker-link/863d6a76-a591-4531-9e50-9609c64678c5", Component: RegisterLinkDTDDEmployeePage },
    { path: "/brand/627b00af-fbd4-4cac-baf8-25a91533d9c4/trip", Component: TripPage },
    { path: "/brand/627b00af-fbd4-4cac-baf8-25a91533d9c4/trip/:hotspotId", Component: TripRecommendationPage },
    { path: "/brand/:brandId/trips", Component: TripsPage },
    { path: "/redirect/:brandId", Component: RedirectBrandPage },
];

const NativeRoutes: RouteObject[] = [
    { path: "/mobile", Component: MobilePromotionPage },
    { path: "/app/:url", Component: RedirectNativeStorePage },
    { path: "/download", Component: DownloadAppPage },
];

const DevRoutes: RouteObject[] = [
    { path: "/dev/welkom", Component: BrandConfiguratorPage },
    { path: "/dev/welkom/:brandId", Component: RegisterLinkTestPage },
    { path: "/dev/welkom/:brandId/variant/:wizardId", Component: RegisterLinkTestPage },
];

const ExtraRoutes: RouteObject[] = [
    { path: "/gesloten", Component: ClosedPage },
    { path: "/bso/mijn-gegevens/:brandId", Component: BsoFillMissingParentDataPage },
    { path: "*", Component: NotFoundPage },
    { path: "/health-check", Component: () => <>running</> },
    { path: "/add-pinned-shortcut", Component: AddIosPinnedShortcutPage },
];

const ArticleRoutes: RouteObject[] = [{ path: "/nieuws/:brandId/artikel/:articleId", Component: ArticlePage }];

const AppRoutes: RouteObject[] = [
    ...MainRoutes,
    ...BrandRoutes,
    ...QuizRoutes,
    ...ArticleRoutes,
    ...AccountRoutes,
    ...PublicRoutes,
    ...SpecificBrandRoutes,
    ...NativeRoutes,
    ...(env.devEnv === "development" ? DevRoutes : []),
    ...ExtraRoutes,
];

const ClubEnvironment = () => {
    return (
        <ClubProvider>
            <Outlet />
        </ClubProvider>
    );
};

const ClubRoutes: RouteObject[] = [
    { path: "/club/calender/:brandId", Component: ClubCalendarPage },
    { path: "/club/nieuws/:brandId/artikel/:articleId", Component: ClubArticlePage },
    { path: "/club/nieuws/:brandId", Component: NewsPage },
    { path: "/club/partners/:brandId/coupon/:couponId", Component: ClubCouponPage },
    { path: "/club/partners/:brandId/partner/:partnerId", Component: PartnerPage },
    { path: "/club/partners/:brandId", Component: ClubPartnersPage },
    { path: "/club/team/:brandId/speler/:playerId", Component: PlayerPage },
    { path: "/club/team/:brandId", Component: ClubTeamPage },
    { path: "/club/quiz/:brandId/", Component: ClubQuizPage },
    { path: "/club/quiz/:brandId/variant/:variantId", Component: ClubQuizPage },

    { path: "/club/mijn-gegevens/:brandId", Component: ClubMyData },
    { path: "/club/mijn-gegevens/:brandId/bewerken", Component: ClubMyDataEditPage },
    { path: "/club/:brandId", Component: ClubHomePage },
    { path: "/club/mvp/voter/:brandId", Component: ClubMvpVoterPage },
    { path: "/club/mvp/reward/:brandId", Component: ClubMvpRewardPage },
    { path: "/club/mvp/result/:brandId", Component: ClubMvpResultPage },
    { path: "/club/bso/useful-links/:brandId", Component: UsefulLinksPage },
    { path: "/club/bso/mijn-gegevens/:brandId", Component: BsoParentDataOverview },
    { path: "/club/bso/notificaties/:brandId", Component: BsoInAppNotificationPage },
    { path: "/club/bso/gifts/:brandId", Component: ClubBsoGiftsPage },
];

const pages: RouteObject[] = [
    { Component: MainLayout, children: AppRoutes },
    { Component: ClubEnvironment, children: ClubRoutes },
];

const browserRouter = createBrowserRouter([{ Component: CommonProviders, children: pages }]);

function App() {
    return <RouterProvider router={browserRouter} />;
}

export default App;
