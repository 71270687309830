import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { env } from "../../../env.mjs";
import { useGetBrandConfigs } from "../../../service/api/brandService";

export function AppUrlListener() {
    const navigate = useNavigate();
    const { data: brandConfig, status: brandConfigStatus } = useGetBrandConfigs();

    useEffect(() => {
        if (brandConfigStatus !== "success") {
            return;
        }

        App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
            const url = new URL(event.url);
            const searchParams = url.searchParams;
            let pathName = url.pathname;

            // allow users to use biometrics to authenticate in a webshop
            if (pathName.includes("/login-link")) {
                const brandId = pathName.split("/")[2];
                navigate("/authenticator/" + brandId);
                return;
            }

            const brandId = pathName.split("/")[2];

            if (pathName.includes("/welkom") && Capacitor.isNativePlatform()) {
                if (searchParams.get("season_ticket") && searchParams.get("wallet_uuid")) {
                    navigate(`/sync-season-ticket/${brandId}?${searchParams.toString()}`);
                    return;
                }

                if (brandId === "19e9244c-34e4-49d4-8b92-211f552142aa") {
                    navigate("/club/" + brandId, { state: { from: brandId } });
                    return;
                }

                if (brandConfig[brandId].type === "bso") {
                    navigate(`${pathName}?${searchParams.toString()}`);
                    return;
                } else {
                    navigate("/brand/" + brandId);
                    return;
                }
            }

            if (pathName.includes("/mobile")) {
                const action = searchParams.get("action");

                if (action === "auth") {
                    handleAuthenticator(searchParams);
                }
            }

            if (pathName.includes("/app") && Capacitor.isNativePlatform()) {
                pathName = pathName.replace("/app", "");
            }

            navigate(`${pathName}?${searchParams.toString()}`);
        });

        //
        App.addListener("appStateChange", async () => {
            if (!Capacitor.isNativePlatform()) return;

            const channel = ["development", "release"].includes(env.devEnv) ? "release" : "production";
            CapacitorUpdater.setChannel({ channel: channel });
            CapacitorUpdater.notifyAppReady();
        });
    }, [brandConfigStatus]);

    function handleAuthenticator(searchParams: URLSearchParams) {
        navigate("/authenticator/" + searchParams.get("brand_id"), {
            state: { redirectUri: searchParams.get("redirect_uri") },
        });
    }

    return null;
}
