import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grow, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { AnyButton } from "../../gp-components/src";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import { AnyLinearProgress } from "../../gp-components/src/components/Progress/AnyLinearProgress";
import { logEventWithBrandId } from "../../service/AnalyticsService";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";
import { Brand, useNavigateToBrand } from "../../service/api/brandService";
import { BulletList } from "../List/BulletList";

export type LinkDataPopupBrandProp = Pick<Brand, "id" | "name">;

type Props = {
    open: boolean;
    toggleOpen: () => void;
    brand: LinkDataPopupBrandProp;
    handleMissingData: () => void;
    textVariant?: "login" | "coupon" | "link";
    handleCompleted?: () => void;
};

// This displays the popup to connect to a brand with a consent screen in between
export default function LinkDataPopup(props: Props) {
    const { open, toggleOpen, brand, handleMissingData, textVariant = "login", handleCompleted } = props;
    const [actionCompleted, setActionCompleted] = useState(false);
    const [navigateIsLoading, setNavigateIsLoading] = useState(false);
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);

    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const theme = useTheme();
    const navigate = useNavigate();
    const { data: brandConfig } = useBrandConfigProvider().getByID(brand.id);

    const { navigateToBrand: linkUser, isLoading: linkUserLoading } = useNavigateToBrand(
        brand.id,
        undefined,
        {
            onSuccess: (result) => {
                if (result.status !== 200) {
                    handleMissingData();
                    return;
                }
                logEventWithBrandId("sign_up_for_brand", brand.id);
                setActionCompleted(true);
                queryClient.invalidateQueries({ queryKey: ["user-brands"] });
                queryClient.invalidateQueries({ queryKey: ["brand", "detail", brand.id] });
            },
            onError: (e: any) => {
                let errorMessage = t("missingDataPopup.updateUserError");
                if (e.response.status === 401) {
                    errorMessage = t("connectedBrands.instructions");
                }

                setAlertError({
                    id: "link",
                    message: errorMessage,
                    code: e.response.status,
                    icon: "retry",
                    handleAction: linkUser,
                });
            },
        },
        "link-user",
    );

    useEffect(() => {
        if (textVariant === "link") {
            setActionCompleted(true);
        }
    }, []);

    if (!brandConfig) return <></>;

    function ActionCompletedContent() {
        let description = t("missingDataPopup.completedDescriptionLogin", { brandName: brand.name });

        if (brandConfig?.requiredCreateAccount.length === 0) {
            description = t("missingDataPopup.completedDescriptionLoginNoData", { brandName: brand.name });
        }

        if (textVariant === "coupon") {
            description = t("missingDataPopup.completedDescriptionCoupon", { brandName: brand.name });
        } else if (textVariant === "link") {
            description = t("missingDataPopup.completedDescriptionLink", { brandName: brand.name });
        }

        const brand_description = t("missingDataPopup." + brand.id);
        if (brand_description !== "missingDataPopup." + brand.id) {
            description = brand_description;
        }

        return (
            <>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Grow in timeout={500}>
                        <div>
                            <FontAwesomeIcon
                                icon={regular("circle-check")}
                                style={{
                                    height: "64px",
                                    color:
                                        theme.palette.mode === "dark"
                                            ? theme.palette.white.main
                                            : theme.palette.green.main,
                                }}
                            />
                        </div>
                    </Grow>
                </Box>
                <Typography mt={3}>{description}</Typography>
                {navigateIsLoading && <LinearProgress sx={{ marginTop: theme.spacing(1) }} />}
            </>
        );
    }

    const LinkDataContent = () => {
        const linkText = (() => {
            if (brandConfig.requiredCreateAccount.length <= 0)
                return t("connectedBrands.linkTextPlatformless", { brandName: brand.name });

            if (textVariant === "coupon")
                return t("connectedBrands.linkTextCoupon") + brand.name + t("connectedBrands.linkTextCoupon2");

            return t("connectedBrands.linkTextLogin") + brand.name + t("connectedBrands.linkTextLogin2");
        })();

        return (
            <div>
                <Typography>{linkText}</Typography>

                {brandConfig.requiredCreateAccount.length > 0 && (
                    <>
                        <Box mt={1}>
                            <BulletList>
                                {brandConfig?.requiredCreateAccount.map((requiredDataItem: string) => {
                                    return (
                                        <Typography key={requiredDataItem} fontSize="16px" variant="h6">
                                            {t("profileInfo." + requiredDataItem)}
                                        </Typography>
                                    );
                                })}
                            </BulletList>
                        </Box>

                        {brand.id !== "863d6a76-a591-4531-9e50-9609c64678c5" && (
                            <Typography
                                sx={{ color: theme.palette.text.secondary }}
                                color={"secondary"}
                                variant="subtitle1"
                                pt={2}
                            >
                                {t("missingDataPopup.emailPrivacy", { brandName: brand.name })}
                            </Typography>
                        )}

                        <Typography
                            sx={{ color: theme.palette.text.secondary }}
                            color={"secondary"}
                            variant="subtitle1"
                            pt={1}
                        >
                            {t("missingDataPopup.agreement", { brandName: brand.name })}
                        </Typography>
                    </>
                )}

                {linkUserLoading && <AnyLinearProgress sx={{ marginTop: theme.spacing(2) }} />}

                {alertError && (
                    <Box mt={1}>
                        <AnyAlert error={alertError} handleClose={setAlertError} />
                    </Box>
                )}
            </div>
        );
    };

    function SubmitButton() {
        if (!actionCompleted) {
            return (
                <AnyButton disabled={linkUserLoading} sx={{ width: "100%" }} onClick={linkUser} anytype="green">
                    {t("connectedBrands.buttonOk")}
                </AnyButton>
            );
        }
        if (textVariant === "link" || textVariant === "login") {
            return (
                <AnyButton
                    onClick={() => {
                        if (handleCompleted) {
                            // clear "link" state
                            navigate(window.location.pathname, {});
                            handleCompleted();
                        }
                    }}
                    sx={{ width: "100%" }}
                    anytype="green"
                >
                    {t("missingDataPopup.close")}
                </AnyButton>
            );
        }
        return (
            <AnyButton onClick={handleCompleted} sx={{ width: "100%" }} anytype="green">
                {t("missingDataPopup.activateCoupon")}
            </AnyButton>
        );
    }

    return (
        <>
            <DynamicPopup
                open={open}
                onOpen={toggleOpen}
                handleClose={() => {
                    toggleOpen();
                }}
                title={
                    !actionCompleted
                        ? t("connectedBrands.linkTitle") + brand.name + "?"
                        : t("missingDataPopup.completedTitle")
                }
                primaryButton={<SubmitButton />}
            >
                {!actionCompleted ? LinkDataContent() : ActionCompletedContent()}
            </DynamicPopup>
        </>
    );
}
