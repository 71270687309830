import { Box, Button, IconButton, Paper, Stack, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MainContent } from "../Container/MainContent";
import ContentContainer from "../Container/ContentContainer";
import PageContainer from "../Container/PageContainer";
import RootContainer from "../Container/RootContainer";
import { SideNavigation } from "../nav/SideNavigation";
import { TopNav } from "../nav/TopNavigation";
import { Brand, useBrands } from "../../service/api/brandService";
import { useNavigate } from "react-router-dom";
import { useBrandSearchProvider } from "../../service/auth/BrandSearchProvider";
import { BrandCardHeader } from "./BrandCardHeader";

/**
 * Show a overlay (like in IG) with a list of results (like the brandcard header) to click on.
 */
export function BrandOverviewSearchBarPage() {
    const [filteredBrands, setfilteredBrands] = useState<Brand[]>([]);

    const { t } = useTranslation();
    const { searchQuery, setSearchQuery } = useBrandSearchProvider();
    const theme = useTheme();

    const { brands, isLoading: brandsAreLoading } = useBrands();

    const navigate = useNavigate();

    const handleSearchBlur = () => {
        setSearchQuery("");
        navigate("/aangesloten-merken");
    };

    const handleInputChange = (newInputValue: string) => {
        setSearchQuery(newInputValue);

        if (newInputValue) {
            const newBrands = brands
                .filter((brand) => {
                    if (brand.name.toLowerCase().includes(newInputValue.toLowerCase())) return true;
                    if (brand?.tags?.some((tag) => tag.toLowerCase().includes(newInputValue.toLowerCase())))
                        return true;

                    return false;
                })
                // Sort brands by the index of the input
                .sort((a, b) => {
                    const indexA = a.name.toLowerCase().indexOf(newInputValue.toLowerCase());
                    const indexB = b.name.toLowerCase().indexOf(newInputValue.toLowerCase());

                    if (indexA === -1 && indexB === -1) {
                        return 0;
                    } else if (indexA === -1) {
                        return 1;
                    } else if (indexB === -1) {
                        return -1;
                    } else {
                        return indexA - indexB;
                    }
                });

            setfilteredBrands(newBrands);
        } else {
            setfilteredBrands([]);
        }
    };

    function clearSearchQuery() {
        handleInputChange("");
    }

    useEffect(() => {
        if (searchQuery !== "") handleInputChange(searchQuery);
    }, []);

    return (
        <Paper
            sx={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                background: theme.palette.background.default,
                zIndex: 1000,
                overflowY: "auto" /* Allow scrolling within the overlay */,
            }}
        >
            <MainContent>
                <RootContainer
                    sx={{
                        [theme.breakpoints.down("sm")]: {
                            marginBottom: theme.spacing(0),
                            pb: 0,
                        },
                    }}
                >
                    <TopNav showBackButton={false} loading={brandsAreLoading} />
                    <PageContainer>
                        <SideNavigation />
                        <ContentContainer>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                {/* real one */}
                                <TextField
                                    defaultValue={searchQuery}
                                    value={searchQuery}
                                    fullWidth
                                    autoFocus
                                    onChange={(e) => handleInputChange(e.target.value)}
                                    placeholder={t("search.search")}
                                    InputProps={{
                                        startAdornment: (
                                            <FontAwesomeIcon icon={regular("search")} style={{ marginRight: "8px" }} />
                                        ),
                                        endAdornment: searchQuery && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    width: "22px",
                                                    height: "22px",
                                                    ml: "8px",
                                                    aspectRatio: "1 / 1",
                                                    backgroundColor: theme.palette.sandDark.main,

                                                    borderRadius: "100%",
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={regular("xmark")}
                                                    style={{ color: "#ffffff" }}
                                                    onClick={clearSearchQuery}
                                                />
                                            </Box>
                                        ),
                                        autoComplete: "off",
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                                            background: "white",
                                            color: `${theme.palette.black.main} !important`,
                                        },
                                    }}
                                />
                                <Button variant="text" onClick={handleSearchBlur} size="small">
                                    {t("editData.cancelButton")}
                                </Button>
                            </Box>

                            {/* results */}
                            <Stack ml={-2}>
                                {filteredBrands.map((brand) => {
                                    return <BrandCardHeader key={brand.id} {...brand} showBackground={false} />;
                                })}
                            </Stack>
                        </ContentContainer>
                    </PageContainer>
                </RootContainer>
            </MainContent>
        </Paper>
    );
}

// fake button to open search overlay
export default function BrandOverviewSearchBar() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    const handleSearchFocus = () => {
        navigate("/aangesloten-merken/zoeken");
    };

    return (
        <>
            <TextField
                fullWidth
                onFocus={handleSearchFocus}
                placeholder={t("search.search")}
                InputProps={{
                    startAdornment: <FontAwesomeIcon icon={regular("search")} style={{ marginRight: "8px" }} />,
                    readOnly: true,
                }}
                autoComplete="none"
                sx={{
                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                        background: "white",
                        color: theme.palette.grey[900],
                    },
                }}
            />
        </>
    );
}
