import { useEffect } from "react";
import shield from "../../assets/img/icons/shield.svg";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import FullScreenRootContainer from "../../components/Container/FullScreenRootContainer";
import "./loading.css";

export default function LoadingLitePage() {
    return (
        <FullScreenRootContainer
            sx={{
                backgroundColor: "#3D3872",
            }}
        >
            <div
                style={{
                    top: "50%",
                    left: "50%",
                    marginLeft: "-50px",
                    marginTop: "-50px",
                    position: "absolute",
                }}
            >
                <div className="pulse">
                    <img width="64px" src={shield} alt="any-id" />
                </div>
            </div>
            <PageAnalytics />
        </FullScreenRootContainer>
    );
}
