import { Box, SwipeableDrawer, SwipeableDrawerProps, Typography, useTheme } from "@mui/material";
import { anyPalette } from "../../../../theme";
import { useCapacitorProvider } from "../../../../service/capacitor/CapacitorProvider";
import { ReactNode } from "react";

interface AnySwipableDrawerProps extends SwipeableDrawerProps {
    title?: string;
    titleIcon?: React.ReactNode;
    header?: React.ReactNode;
    primaryButton?: React.ReactNode;
    secondaryButton?: React.ReactNode;
    customHandleComponent?: ReactNode;
    handleAlignment?: string;
}

export function AnySwipableDrawer(props: AnySwipableDrawerProps) {
    const {
        title,
        titleIcon,
        header,
        primaryButton,
        secondaryButton,
        customHandleComponent,
        handleAlignment,
        ...other
    } = props;
    const { insets } = useCapacitorProvider();
    const theme = useTheme();

    return (
        <SwipeableDrawer
            style={{
                backgroundColor: "rgba(34, 35, 35, 0.7)",
                backdropFilter: "blur(2.5px)",
            }}
            PaperProps={{
                sx: {
                    maxHeight: "90vh",
                    paddingBottom: insets?.bottom ? insets.bottom + "px" : 0,
                    display: { xs: "flex", sm: "none" },
                    borderRadius: "16px 16px 0px 0px",
                    backgroundColor: "card.dark",
                    backgroundImage: "inherit",
                },
            }}
            {...other}
        >
            <Box
                sx={{
                    position: "sticky",
                    top: 0,
                    py: 1.5,
                    alignSelf: handleAlignment,
                    backgroundColor: "card.dark",
                    width: "100%",
                    zIndex: 1,
                }}
            >
                {customHandleComponent ? (
                    customHandleComponent
                ) : (
                    <Box
                        sx={{
                            backgroundColor: theme.palette.primary.contrastText,
                            opacity: 0.8,
                            borderRadius: "3px",
                            width: "50px",
                            height: "6px",
                            mx: "auto",
                        }}
                    />
                )}
            </Box>

            {/* content */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "90vh",
                }}
                p={2}
                pt={0}
            >
                {(header || titleIcon || title) && (
                    <Box mb={1} sx={{ display: "flex", alignItems: "center" }}>
                        {titleIcon && (
                            <Box mr={2} sx={{ display: "flex", alignItems: "center" }}>
                                {titleIcon}
                            </Box>
                        )}
                        {title && <Typography variant="h2">{title}</Typography>}
                        {header}
                    </Box>
                )}
                <Box sx={{ flex: "1" }}>{props.children}</Box>
                {(primaryButton || secondaryButton) && (
                    <Box
                        mt={2}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {primaryButton}
                        {secondaryButton && <Box sx={{ height: "10px" }} />}
                        {secondaryButton}
                    </Box>
                )}
            </Box>
        </SwipeableDrawer>
    );
}
