import { AppUpdate, AppUpdateAvailability } from "@capawesome/capacitor-app-update";
import { BiometryType, NativeBiometric } from "capacitor-native-biometric";
import { t } from "i18next";
import eye from "../../assets/img/icons/eye.svg";
import faceId from "../../assets/img/icons/face-id.svg";
import fingerprint from "../../assets/img/icons/fingerprint.svg";
import { env } from "../../env.mjs";
import { Semaphore } from "../api/Semaphore";

const bioVerificationSemaphore = new Semaphore(1);

export async function performBiometricVerification(message?: string) {
    try {
        await bioVerificationSemaphore.acquire();
    } catch (e) {
        return;
    }

    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) {
        throw new Error("Biometric is unavailable");
    }

    const verified = await NativeBiometric.verifyIdentity({
        title: message || t("bioAuthentication.promptBio"),
    })
        .then(() => true)
        .catch(() => false)
        .finally(() => {
            bioVerificationSemaphore.release();
        });

    return verified;
}

export function getBioTypeIcon(type: BiometryType | undefined) {
    if (!type) return faceId;

    if ([BiometryType.FACE_AUTHENTICATION, BiometryType.FACE_ID].includes(type)) return faceId;

    if ([BiometryType.FINGERPRINT, BiometryType.TOUCH_ID].includes(type)) {
        return fingerprint;
    }
    if (type === BiometryType.IRIS_AUTHENTICATION) return eye;

    return faceId;
}

export function getBioTypeText(type: BiometryType | undefined) {
    if (!type) return t("bioAuthentication.FACE_ID");
    return t("bioAuthentication." + BiometryType[type]);
}

export function getMobileOperatingSystem(window: any) {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export async function checkAndPerformAppUpdate() {
    if (["development"].includes(env.devEnv)) return false;
    const appUpdateInfo = await AppUpdate.getAppUpdateInfo({ country: "NL" });

    if (appUpdateInfo?.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        return true;
    }
    if (appUpdateInfo.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
    } else {
        await AppUpdate.openAppStore({ country: "NL" });
    }

    return false;
}
