import { Box, Card, CardActionArea, Grow, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { getLocaleDate } from "../../service/dateService";
import "./style.css";

export type LogItemType = {
    name: string;
    scopeName: string;
    description: string;
    created_at: string; // utc (zulu)
    brand_id: string;
};

type props = {
    logItem: LogItemType;
    index: number;
    backgroundColor?: string;
};

export function LogItem({ logItem, index }: props) {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Grow in={true} timeout={(index % 8) * 200}>
            <Box mb={1}>
                <Card>
                    <CardActionArea onClick={() => navigate(`/gedeelde-gegevens/${logItem.brand_id}`)}>
                        <Box
                            borderRadius={1}
                            sx={{
                                backgroundColor: theme.palette.card.main,
                                display: "flex",
                                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                            }}
                        >
                            <Box mr={2} sx={{ display: "flex", alignItems: "center" }}>
                                <BrandIcon brandId={logItem.brand_id} />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Typography
                                    variant="subtitle1"
                                    fontFamily={"Roboto"}
                                    sx={{ color: theme.palette.text.secondary }}
                                >
                                    {getLocaleDate(logItem.created_at, i18n.languages[0])}
                                </Typography>
                                <Typography variant="h3">{logItem.name}</Typography>
                                <Typography>{logItem.description}</Typography>
                            </Box>
                        </Box>
                    </CardActionArea>
                </Card>
            </Box>
        </Grow>
    );
}
