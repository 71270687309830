import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

// The container for the content and the navigation

const PageContainer = styled(Container)(({ theme }) => {
    return {
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "space-between",
            flex: "1 1 auto",
            marginTop: "90px",
        },
    };
});

export default PageContainer;
