import { Avatar, Box, BoxProps, Typography } from "@mui/material";
import { anyPalette } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

type Props = {
  title: string;
  description: string;
  color?: string;
  icon: IconDefinition;
  sx?: BoxProps;
};

export function InfoCard({ title, description, color, icon, sx }: Props) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      <Avatar sx={{ backgroundColor: color || anyPalette.green, height: "38px", width: "38px", marginBottom: 1 }}>
        <FontAwesomeIcon style={{ color: theme.palette.white.main }} icon={icon} />
      </Avatar>
      <Typography mb={1} variant="h3">
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  );
}
