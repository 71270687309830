import { Button } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { anyPalette } from "../../theme";
import { fonts } from "../../../../service/theme/AnyTheme";

type Props = {
  anytype?: string;
};

const baseStyle = {
  fontFamily: fonts.poppins,
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "24px",
  width: "fit-content",
  textTransform: "none",
  color: "#ffffff",
  boxShadow: "none",
  padding: "5px 10px",
  height: "44px",
  "&:hover": {
    opacity: "0.8",
    boxShadow: "none",
  },
  "&:disabled": {
    backgroundColor: anyPalette.sandDark,
  },
  borderRadius: "4px",
};

function getButtonStyle(type: string, theme: Theme) {
  const themes = {
    blue: {
      backgroundColor: theme.palette.blue.main,
    },
    green: {
      backgroundColor: theme.palette.green.main,
    },
    rose: {
      backgroundColor: theme.palette.rose.main,
    },
    orange: {
      backgroundColor: theme.palette.orange.main,
    },
    white: {
      backgroundColor: theme.palette.white.main,
      color: theme.palette.black.main,
    },
    transparent: {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
    },
    grey: {
      backgroundColor: theme.palette.sandMedium.main,
      color: theme.palette.black.main,
    },
    red: {
      backgroundColor: "#EC3323",
    },
  };

  const currentTheme = (themes as any)[type];

  const styling = {
    ...baseStyle,
    ...currentTheme,
  };

  styling["&:hover"] = {
    ...baseStyle["&:hover"],
    ...styling["&:hover"],
    backgroundColor: styling.backgroundColor,
  };

  return styling;
}

export const AnyButton = styled(Button)<Props>(({ anytype = "blue", theme }) => getButtonStyle(anytype, theme));
