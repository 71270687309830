import { Typography, useTheme } from "@mui/material";
import { useQueryClient } from "react-query";
import { AnySwitch } from "../../gp-components/src/components/Switch/AnySwitch";
import {
    BrandNotificationConsent,
    useCreateBrandNotificationConsent,
    useUpdateBrandNotificationConsent,
} from "../../service/capacitor/PushNotificationService";
import { ReactNode } from "react";

type BrandNotificationSettingItemProps = {
    title: string | ReactNode;
    description?: string;
    brandId: string;
    enabled: boolean;
    mainContent?: ReactNode;
    disabledBgColor?: string;
    enabledBgColor?: string;
    disabledThumbColor?: string;
    enabledThumbColor?: string;
};

export default function BrandNotificationSettingItem({
    title,
    description,
    brandId,
    enabled,
    disabledBgColor,
    mainContent,
    ...props
}: BrandNotificationSettingItemProps) {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const { mutate: createConsent } = useCreateBrandNotificationConsent(brandId);
    const { mutate: updateConsent } = useUpdateBrandNotificationConsent(brandId, {
        // optimistic update. We update first update the UI, then wait the API returns the expected data.
        // if they don't match or an error is returned, we revert back to the previous state.
        onMutate: async (newConsent) => {
            await queryClient.cancelQueries(["notification-consents"]);

            const previousConsent = queryClient.getQueryData<BrandNotificationConsent[]>([
                "notification-consent",
                brandId,
            ]);

            // set new consent for both cache keys:
            queryClient.setQueryData(["notification-consents"], (_previousConsent: unknown) => {
                const previousConsent = !!_previousConsent ? (_previousConsent as BrandNotificationConsent[]) : [];
                const index = previousConsent?.findIndex((entity) => entity.brand_id === brandId);

                if (index !== -1) {
                    previousConsent[index] = {
                        ...previousConsent[index],
                        consent: !previousConsent[index].consent,
                    };
                } else {
                    // notification consent doesn't exist, so we need to create in the server
                    const newConsent = {
                        id: "temp-brand",
                        brand_id: brandId,
                        consent: !enabled,
                    };
                    previousConsent.push(newConsent);
                    createConsent({
                        body: newConsent,
                    });
                }
                return previousConsent;
            });
            queryClient.setQueryData(["notification-consent", brandId], newConsent);

            // Return a context with the previous and new consent
            return { previousConsent, newConsent };
        },
        // If the mutation fails, use the context we returned above
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(
                ["notification-consents"],
                // @ts-ignore
                context.body?.newConsent,
            );
        },
        // Always refetch after error or success:
        onSettled: (newTodo) => {
            queryClient.invalidateQueries({ queryKey: ["notification-consents"] });
            queryClient.invalidateQueries({ queryKey: ["notification-consent", brandId] });
        },
    });

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    margin: "8px 0",
                }}
            >
                {mainContent ? (
                    <>{mainContent}</>
                ) : (
                    <>
                        {title}
                        {description && (
                            <Typography
                                variant="body2"
                                mt={0.25}
                                sx={{
                                    opacity: 0.7,
                                    fontWeight: 400,
                                }}
                            >
                                {description}
                            </Typography>
                        )}
                    </>
                )}
            </div>
            <div
                style={{
                    marginLeft: "auto",
                    paddingLeft: "8px",
                    marginRight: "8px",
                }}
            >
                <AnySwitch
                    checked={enabled}
                    onChange={() => {
                        updateConsent({
                            body: {
                                brand_id: brandId,
                                consent: !enabled,
                            },
                        });
                    }}
                    sx={{
                        "& .MuiSwitch-thumb": {
                            backgroundColor: props.disabledThumbColor,
                        },
                        "& .MuiSwitch-switchBase": {
                            "&.Mui-checked": {
                                color: theme.palette.white.main,
                                "&.Mui-disabled": {
                                    "& + .MuiSwitch-track": {
                                        backgroundColor: theme.palette.sandDark.main,
                                    },
                                },
                                "& .MuiSwitch-thumb": {
                                    backgroundColor: props.enabledThumbColor,
                                },
                                "& + .MuiSwitch-track": {
                                    backgroundColor: props.enabledBgColor ?? theme.palette.primary.main,
                                },
                            },
                        },
                        "& .MuiSwitch-track": {
                            backgroundColor: disabledBgColor ?? theme.palette.card.main,
                        },
                    }}
                ></AnySwitch>
            </div>
        </div>
    );
}
