import { useTranslation } from "react-i18next";
import { anyPalette } from "../../theme";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { Desktop } from "../../gp-components/src";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { useBrands } from "../../service/api/brandService";
import NavigationItem from "./NavigationItem";

export function SideNavigation() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Desktop sx={{ width: "25%", marginRight: 2 }}>
            <NavigationItem
                title={t("home.myData")}
                color={anyPalette.primary}
                icon={
                    <FontAwesomeIcon
                        icon={solid("id-badge")}
                        style={{ width: "20px", color: theme.palette.icon.main }}
                    />
                }
                href="/overzicht-gegevens"
                nestedPathnames={["/mijn-gegevens", "/mijn-interesses"]}
            />
            <NavigationItem
                title={t("home.brandOverviewExplore")}
                color={anyPalette.secondary}
                icon={
                    <FontAwesomeIcon
                        icon={solid("compass")}
                        style={{ width: "20px", color: theme.palette.icon.main }}
                    />
                }
                href="/aangesloten-merken"
                nestedPathnames={["/aangesloten-merk", "/aangesloten-merk"]}
            />
        </Desktop>
    );
}
