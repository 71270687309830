import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { useTheme } from "@mui/material/styles";
import { BiometryType, NativeBiometric } from "capacitor-native-biometric";
import { SafeArea } from "capacitor-plugin-safe-area";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuthProvider } from "../auth/AuthProvider";
import { checkAndPerformAppUpdate } from "./CapacitorService";
import PreferencesService, { AuthPreferences } from "./PreferencesService";

interface SafeAreaType {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export interface Bio {
    available: boolean;
    type: BiometryType;
}

export interface CapacitorContextInterface {
    bioVerified: boolean;
    setBioVerified: (isVerified: boolean) => void;
    insets: SafeAreaType | undefined;
    bio: Bio | undefined;
    authPreferences: AuthPreferences | null | undefined;
    setAuthPreferences: (preferences: AuthPreferences) => void;
    capLoading: boolean;
    isLatestAppVersion: boolean;
}

const CapacitorContext = createContext<CapacitorContextInterface>({
    bioVerified: false,
    insets: undefined,
    setBioVerified: () => {},
    bio: undefined,
    authPreferences: undefined,
    setAuthPreferences: () => {},
    capLoading: true,
    isLatestAppVersion: true,
});

export function CapacitorProvider({ children }: any) {
    const [capLoading, setCapLoading] = useState(Capacitor.isNativePlatform());
    const [bioVerified, setBioVerified] = useState(!Capacitor.isNativePlatform());
    const [insets, setInsets] = useState<SafeAreaType | undefined>();
    const [bio, setBio] = useState<Bio | undefined>();
    const [authPreferences, setAuthPreferences] = useState<AuthPreferences | null | undefined>(undefined);
    const [isLatestAppVersion, setIsLatestAppVersion] = useState(true);

    const authProvider = useAuthProvider();
    const theme = useTheme();

    async function initCapacitor() {
        const promises = [];

        try {
            promises.push(checkAndPerformAppUpdate().then(setIsLatestAppVersion));
        } catch {}

        try {
            promises.push(
                SafeArea.getSafeAreaInsets().then((safeAreaResult) => setInsets(safeAreaResult.insets || insets)),
            );
        } catch {}

        try {
            promises.push(
                NativeBiometric.isAvailable().then((availableBioResult) =>
                    setBio(
                        {
                            type: availableBioResult.biometryType,
                            available: availableBioResult.isAvailable,
                        } || bio,
                    ),
                ),
            );
        } catch {}

        try {
            promises.push(
                StatusBar.setStyle({
                    style: theme.palette.mode === "dark" ? Style.Dark : Style.Light,
                }),
            );
        } catch {}

        try {
            if (Capacitor.getPlatform() === "android") {
                promises.push(StatusBar.setOverlaysWebView({ overlay: true }));
            }
        } catch {}

        try {
            promises.push(PreferencesService.getAuth(authProvider).then((pref) => setAuthPreferences(pref)));
        } catch {}

        await Promise.all(promises);

        setCapLoading(false);
    }

    useEffect(() => {
        if (authProvider.authLoading) return;

        if (Capacitor.isNativePlatform()) {
            initCapacitor();
        }
    }, [authProvider.authLoading]);

    function setPreferences(preferences: AuthPreferences) {
        setAuthPreferences(preferences);
        PreferencesService.setAuth(authProvider, preferences);
    }

    return (
        <CapacitorContext.Provider
            value={{
                bioVerified,
                setBioVerified,
                insets,
                bio,
                authPreferences,
                setAuthPreferences: setPreferences,
                capLoading,
                isLatestAppVersion,
            }}
        >
            {children}
        </CapacitorContext.Provider>
    );
}

export function useCapacitorProvider() {
    return useContext(CapacitorContext);
}
