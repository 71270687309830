export type Theme = {
    background: string;
    primary: string;
    secondary: string;
    lightGray: string;
    green: string;
    orange: string;
    purple: string;
    lightBlue: string;
    lightYellow: string;
    lightRed: string;
    darkYellow: string;
    darkRed: string;
    darkGray: string;
    sandDark: string;
    sandMedium: string;
    sandLight: string;
    softRed: string;
};

export const anyPalette: Theme = {
    primary: "#025497",
    secondary: "#54AB9A",
    lightGray: "#ececec",
    green: "#54AB9A",
    orange: "#F7A34F",
    purple: "#7E65A3",
    lightBlue: "#497fb5",
    lightYellow: "#FFFBCC",
    lightRed: "#EC3323",
    darkYellow: "#c2bc76",
    darkRed: "#8b0000",
    darkGray: "#222323",
    sandDark: "#A7A39A",
    sandMedium: "#DDDBD7",
    sandLight: "#EFEDE8",
    background: "#efede8",
    softRed: "#FF7878",
};

export const anyPaletteFilter = {
    primary: "invert(20%) sepia(96%) saturate(1699%) hue-rotate(190deg) brightness(93%) contrast(98%)",
    darkMode:
        "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(17%) hue-rotate(291deg) brightness(104%) contrast(104%)", //convert into white
    sandMedium: "invert(99%) sepia(4%) saturate(2000%) hue-rotate(336deg) brightness(120%) contrast(72%)",
};
