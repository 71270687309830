import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import LoadingLitePage from "./pages/error/LoadingLite";
import { AnimationProvider } from "./service/AnimationProvider";
import { QuizProvider } from "./service/QuizProvider";
import { ScriptProvider } from "./service/ScriptProvider";
import { defaultStaleTime } from "./service/api/restApiQuery";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: defaultStaleTime,
        },
    },
});

ReactDOM.render(
    <Suspense fallback={<LoadingLitePage />}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ScriptProvider />
                {/* The quiz provider needs to be on top of all the application because the quiz data needs to be cached in the entire application */}
                <QuizProvider>
                    <AnimationProvider>
                        <App />
                    </AnimationProvider>
                </QuizProvider>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </React.StrictMode>
    </Suspense>,
    document.getElementById("root"),
);
