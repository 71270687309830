import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArticleSection } from "../../../components/Club/ArticleSection";
import { BackToAnyIDButton } from "../../../components/Club/BackToAnyIDButton";
import CountdownTimer from "../../../components/Club/CountdownTimer";
import HeroAnimation from "../../../components/Club/HeroAnimation";
import { ClubLogo } from "../../../components/Club/Logo";
import FadeContainer from "../../../components/Container/FadeContainer";
import { useAnimationProvider } from "../../../service/AnimationProvider";
import {
    useClubProvider,
    useGetPollById,
    usePollByBrandConfiguration,
    isPollAboutToStart,
    convertPollToArticle,
    Article,
    Poll,
    isPollActive,
} from "../../../service/ClubService";
import { env } from "../../../env.mjs";

export default function ClubSportHomePage() {
    const { articles, brand_config, brand_id } = useClubProvider();
    const { showHeroAnimation, setShowHeroAnimation } = useAnimationProvider();
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { data: quiz } = useGetPollById(brand_config.environment?.highlighted_quiz);

    const { data: poll, isLoading: isLoadingPoll } = usePollByBrandConfiguration(brand_config, brand_id);

    const isThereMvpPoll = poll && isPollAboutToStart(poll);

    const { highlighted_article, newest_articles } = useMemo(() => {
        const sorted_articles = [...articles].sort((a, b) => b.created_at - a.created_at);

        const highlighted_article = isThereMvpPoll ? convertPollToArticle(poll, t) : sorted_articles.shift();

        return {
            highlighted_article,
            newest_articles: quiz?.active ? sorted_articles.slice(0, 2) : sorted_articles.slice(0, 3),
        };
    }, [articles, quiz, poll, isThereMvpPoll]);

    useEffect(() => {
        const scroll = searchParams.get("scroll");
        if (scroll && !showHeroAnimation) {
            const elem = document.getElementById(scroll);
            elem?.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [searchParams, showHeroAnimation]);

    return (
        <FadeContainer short>
            <title>{t("club.tabTitle.home", { brandName: brand_config.name })}</title>
            <AnimatePresence>
                {showHeroAnimation && <HeroAnimation onClose={() => setShowHeroAnimation(false)} />}
            </AnimatePresence>

            <BackToAnyIDButton />

            <ClubLogo />

            {isLoadingPoll ? (
                <HighlightedItemSkeleton />
            ) : (
                highlighted_article && (
                    <div style={{ margin: "0 -16px" }}>
                        <HighlightedItem
                            item={highlighted_article}
                            type={isThereMvpPoll ? "mvp-poll" : "article"}
                            poll={poll}
                        />
                    </div>
                )
            )}
            <ArticleSection articles={newest_articles} limited={true} quiz={quiz?.active ? quiz : undefined} />
            <PlayOfTheWeek />
        </FadeContainer>
    );
}

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type HighlightedItemProps = {
    item: WithOptional<Article, "brand_id">;
    type: "article" | "mvp-poll";
    poll?: Poll;
};

// can be either article or mpv poll
const HighlightedItem = (props: HighlightedItemProps) => {
    const { header, banner_image_url, id } = props.item;
    const { brand_id } = useClubProvider();
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isPollActiveState, setIsPollActiveState] = useState(
        props.type === "mvp-poll" ? props.poll && isPollActive(props.poll) : false,
    );

    const goTo = () => {
        if (props.type === "article") {
            navigate("/club/nieuws/" + brand_id + "/artikel/" + id);
            window.scroll(0, 0);
            return;
        }
        if (props.type === "mvp-poll") {
            if (isPollActiveState) navigate("/club/mvp/voter/" + brand_id);
            return;
        }
    };

    return (
        <div
            style={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(1),
            }}
            onClick={goTo}
        >
            <div style={{ position: "relative", display: "flex", height: "160px", justifyContent: "end" }}>
                <img
                    src={banner_image_url}
                    alt="banner"
                    style={{
                        position: "absolute",
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                    }}
                />
                <Button
                    disabled={props.type === "mvp-poll" && !isPollActiveState}
                    size="small"
                    sx={{ mt: "auto", width: "fit-content", mb: 2, mr: 2, fontWeight: 400 }}
                >
                    {props.type === "mvp-poll"
                        ? t("club.mvp.vote_button").toUpperCase()
                        : t("club.article.more").toUpperCase()}

                    <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{
                            marginLeft: "8px",
                        }}
                    />
                </Button>
            </div>
            <div
                style={{
                    margin: theme.spacing(1) + " " + theme.spacing(2),
                }}
            >
                <div
                    style={{
                        fontSize: "18px",
                        marginBottom: theme.spacing(1),
                    }}
                >
                    {header.toUpperCase()}
                </div>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(2),
                    }}
                >
                    {!isPollActiveState && props.type === "mvp-poll" && props.poll && (
                        <CountdownTimer
                            countdownGoal={props.poll.start_date}
                            onCountdownEnd={() => setIsPollActiveState(true)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const HighlightedItemSkeleton = () => {
    return <Skeleton sx={{ my: 2, transform: "none", transformOrigin: "0" }} height={400} />;
};

const PlayOfTheWeek = () => {
    const { brand_config: brandConfig, brand_id } = useClubProvider();
    const theme = useTheme();

    const sponsor = env.cdnUrl + "/brand/other/" + brand_id + "/partner_of_the_match.png";
    const text = "PLAY OF THE GAME";

    const playOfTheWeekSrc = env.cdnUrl + `/brand/mvp/${brand_id}/play-of-the-game.mp4`;
    const video_ref = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5, // Adjust the threshold as needed
        };

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                try {
                    if (entry.isIntersecting) {
                        // Video is visible, start playing
                        video_ref.current?.play();
                    } else {
                        // Video is not visible, pause
                        video_ref.current?.play().then(() => {
                            video_ref.current?.pause();
                        });
                    }
                } catch (e) {
                    console.warn(e);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (video_ref.current) {
            observer.observe(video_ref.current);
        }

        return () => {
            if (video_ref.current) observer.disconnect();
        };
    }, [window]);

    return (
        <div style={{ paddingTop: theme.spacing(2) }} id="play_of_the_game">
            <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={sponsor} style={{ marginBottom: "-1px", zIndex: -1 }} alt="sponsor" />
            </div>
            <div
                style={{
                    padding: theme.spacing(1),
                    border: "1px solid #ffffff80",
                    backgroundColor: brandConfig.environment?.colors.primary,
                }}
            >
                <video
                    ref={video_ref}
                    src={playOfTheWeekSrc}
                    muted
                    autoPlay
                    playsInline
                    loop
                    poster="noposter"
                    style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                    }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "clamp(22px, 23px + 2vw, 40px)",
                    lineHeight: "30px",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: brandConfig.environment?.colors.primary,
                        zIndex: -1,
                        marginTop: -2,
                    }}
                >
                    {text}
                </div>
                <div
                    className="outline-text shadow"
                    style={{
                        marginTop: "-2px",
                        display: "flex",
                        justifyContent: "center",
                        WebkitMaskImage: "linear-gradient(transparent 55%, black 55%)",
                        maskImage: "linear-gradient(transparent 55%, black 55%)",
                    }}
                >
                    {text}
                </div>
            </div>
        </div>
    );
};
