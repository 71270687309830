import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const BackToAnyIDButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Button
            style={{
                fontSize: "14px",
                fontWeight: 400,
            }}
            onClick={() => {
                navigate("/");
            }}
        >
            {t("club.backAnyID").toUpperCase()}
        </Button>
    );
};
