import { faBell, faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AppBar,
    Box,
    Button,
    CardActionArea,
    CircularProgress,
    Container,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    useMediaQuery,
    useScrollTrigger,
} from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SideButton } from "../../gp-components/src/components/Button/SideButton";
import { useAuthProvider } from "../../service/auth/AuthProvider";
import { useLogoutKeycloak } from "../../service/auth/OAuthService";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";
import { anyPalette } from "../../theme";
import { AnyIdLogo } from "../Logo/AnyIdLogo";
import { NavigationItem, isCurrentPath } from "./BottomNavigation";
import "./TopNavigation.css";
// these icons for some reason are not liked by typescript
import { faArrowRight, faCircleUser, faClose, faGear } from "@fortawesome/pro-regular-svg-icons";
import { useBannerProvider } from "../../service/BannerProvider";

type Props = {
    href?: string;
    clickable?: boolean;
    showBackButton?: boolean;
    showLogoutButton?: boolean;
    showOnlyLogo?: boolean;
    disableIconButtons?: boolean;
    height?: string;
    sx?: SxProps;
    loading?: boolean;
    hideHeader?: boolean;
};

export function TopNav(props: Props) {
    const isDesktop = useMediaQuery("(min-width:600px)");

    if (isDesktop) {
        return <DesktopHeader {...props} />;
    } else {
        return <MobileHeader {...props} />;
    }
}

function HideOnScroll(props: any) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function InsetGradient() {
    const { insets } = useCapacitorProvider();
    const theme = useTheme();

    if (insets?.top) {
        return (
            <Box
                sx={{
                    position: "fixed",
                    background:
                        theme.palette.mode === "dark"
                            ? "linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 0.4) 50% , rgba(0, 0, 0, 0.7) 100%)"
                            : "",
                    top: 0,
                    width: "100%",
                    zIndex: 10,
                    height: insets?.top + "px",
                }}
            />
        );
    }

    return <></>;
}

function TopNavigationItem({ navigationItem }: { navigationItem: NavigationItem }) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <CardActionArea
            key={navigationItem.title}
            onClick={() => navigate(navigationItem.paths[0])}
            className="toolbar-button"
            sx={{
                borderRadius: 1,
            }}
        >
            <FontAwesomeIcon
                icon={navigationItem.icon}
                style={{
                    color: isCurrentPath(navigationItem.paths) ? anyPalette.green : theme.palette.text.tertiair,
                }}
            />
            <Typography
                sx={{
                    marginLeft: 1,
                    color: isCurrentPath(navigationItem.paths) ? anyPalette.green : theme.palette.text.tertiair,
                }}
                variant="subtitle1"
            >
                {navigationItem.title}
            </Typography>
        </CardActionArea>
    );
}

function MobileHeader({
    showBackButton = true,
    showOnlyLogo,
    sx,
    href,
    loading,
    disableIconButtons,
    showLogoutButton,
    clickable = true,
    hideHeader = false,
}: Props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { insets } = useCapacitorProvider();
    const logoutKeycloak = useLogoutKeycloak();
    const { t } = useTranslation();

    const { closed, closeBanner } = useBannerProvider();

    if (showBackButton) {
        return (
            <Box
                sx={{
                    paddingTop: insets?.top ? `${insets.top}px` : "16px",
                    ...sx,
                }}
            >
                <InsetGradient />
                <Container
                    sx={{
                        height: "42px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        flexGrow: 1,
                    }}
                >
                    <Box sx={{ display: "flex" }}>
                        <SideButton
                            onClick={() => {
                                if (clickable) navigate((href as any) ?? -1);
                            }}
                        >
                            <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faChevronLeft} size="1x" />
                            <Typography variant="subtitle1">{t("topNav.back")}</Typography>
                        </SideButton>
                        {loading && (
                            <CircularProgress
                                size="25px"
                                sx={{ color: theme.palette.white.main, marginLeft: theme.spacing(1) }}
                            />
                        )}
                    </Box>
                </Container>
            </Box>
        );
    }

    return (
        <>
            <InsetGradient />
            {/* Like tbh this is actually really bad, this should be a prop not determined by pathname */}
            {showOnlyLogo ||
            ["/", "/home", "/aangesloten-merken", "/notificaties", "/aangesloten-merken/zoeken", "/download"].includes(
                window.location.pathname,
            ) ? (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        margin: `${insets?.top ? insets.top + "px" : theme.spacing(2)} ${theme.spacing(
                            2,
                        )} ${theme.spacing(2)} ${theme.spacing(2)}`,
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                        <AnyIdLogo
                            onClick={() => {
                                if (clickable) navigate("/home");
                            }}
                            width="87px"
                            height="25px"
                            alt="any-id"
                            className={clickable ? "hover-pointer" : undefined}
                        />
                        {loading && (
                            <CircularProgress
                                size="25px"
                                sx={{ color: theme.palette.white.main, marginLeft: theme.spacing(1) }}
                            />
                        )}
                    </Box>
                    {!disableIconButtons &&
                        ["/", "/home", "/aangesloten-merken", "/notificaties", "/download"].includes(
                            window.location.pathname,
                        ) && (
                            <>
                                <IconButton
                                    sx={{
                                        mr: theme.spacing(1),
                                    }}
                                    onClick={() => {
                                        navigate("/overzicht-gegevens");
                                    }}
                                >
                                    <FontAwesomeIcon
                                        // @ts-ignore
                                        icon={faCircleUser}
                                        style={{ color: theme.palette.text.primary, height: "1rem" }}
                                    />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        navigate("/instellingen");
                                    }}
                                >
                                    <FontAwesomeIcon
                                        // @ts-ignore
                                        icon={faGear}
                                        style={{ color: theme.palette.text.primary, height: "1rem" }}
                                    />
                                </IconButton>
                            </>
                        )}
                </Box>
            ) : (
                <div style={{ height: insets?.top ? `${insets.top}px` : "0px" }}></div>
            )}

            {showLogoutButton && (
                <SideButton
                    onClick={logoutKeycloak}
                    sx={{
                        position: "absolute",
                        top: insets?.top ? `${insets.top}px` : theme.spacing(2),
                        right: theme.spacing(2),
                    }}
                >
                    <Typography variant="subtitle1">{t("topHeader.logout")}</Typography>
                    <FontAwesomeIcon style={{ marginLeft: "8px" }} icon={faChevronRight} size="1x" />
                </SideButton>
            )}
            {!hideHeader && !closed && (
                <div
                    style={{
                        minHeight: "50px",
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        position: "sticky",
                        width: "100vw",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 16px",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            maxWidth: "1200px",
                            display: "flex",
                            gap: "16px",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            onClick={() => {
                                closeBanner();
                            }}
                        >
                            <FontAwesomeIcon icon={faClose} size="sm" />
                        </div>
                        <div>
                            <div
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                }}
                            >
                                {t("banner.mobile1")}
                            </div>
                            <div
                                style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: "12px",
                                }}
                            >
                                {t("banner.mobile2")}
                            </div>
                        </div>
                        <div
                            style={{
                                marginLeft: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                closeBanner();
                                navigate("/download");
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowRight} size="sm" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function DesktopHeader(props: Props) {
    const { showLogoutButton = false, disableIconButtons = false, loading = false, showOnlyLogo = false } = props;

    const navigate = useNavigate();
    const theme = useTheme();
    const logoutKeycloak = useLogoutKeycloak();
    const { t } = useTranslation();
    const { authBody, authBioVerified } = useAuthProvider();
    const { insets } = useCapacitorProvider();

    const { closed, closeBanner } = useBannerProvider();

    const navigationItems = [
        {
            paths: ["/notificaties"],
            icon: faBell,
            title: t("bottomNav.notifications"),
        },
        {
            paths: ["/instellingen", "/taal", "/account-verwijderen"],
            icon: faGear,
            title: t("bottomNav.settings"),
        },
    ] as unknown as NavigationItem[];

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        background: theme.palette.mode === "dark" ? theme.palette.card.dark : "#ffffff",
                        boxShadow:
                            theme.palette.mode === "dark" ? "0px 1px 0px #C0C0C0" : "0px 2px 0px rgba(0, 0, 0, 0.05)",
                    }}
                    position="sticky"
                >
                    <Toolbar
                        disableGutters
                        sx={{
                            paddingTop: insets?.top ? `${insets.top}px` : "",
                            height: insets?.top ? `${insets.top + 67}px` : "67px",
                        }}
                    >
                        <Container sx={{ alignItems: "center", display: "flex" }}>
                            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                                <AnyIdLogo
                                    onClick={() => {
                                        if (!disableIconButtons && authBioVerified && authBody?.accessToken) {
                                            navigate("/");
                                        }
                                    }}
                                    height="37px"
                                    alt="any-id"
                                    className="hover-pointer"
                                />
                                {loading && (
                                    <CircularProgress
                                        size="25px"
                                        sx={{ color: theme.palette.white.main, marginLeft: theme.spacing(2) }}
                                    />
                                )}
                            </Box>
                            {!showOnlyLogo &&
                                !disableIconButtons &&
                                authBioVerified &&
                                authBody?.accessToken &&
                                navigationItems.map((item) => (
                                    <TopNavigationItem key={item.title} navigationItem={item} />
                                ))}
                            {!showOnlyLogo && showLogoutButton && (
                                <SideButton
                                    onClick={logoutKeycloak}
                                    sx={{
                                        position: "absolute",
                                        top: insets?.top ? `${insets.top}px` : "82px",
                                        right: "15px",
                                        color: "#000000",
                                    }}
                                >
                                    <Typography variant="buttonText">{t("topHeader.logout")}</Typography>
                                    <FontAwesomeIcon style={{ marginLeft: "8px" }} icon={faChevronRight} size="1x" />
                                </SideButton>
                            )}
                        </Container>
                    </Toolbar>
                    {!props.hideHeader && !closed && (
                        <div
                            style={{
                                minHeight: "40px",
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                position: "sticky",
                                width: "100vw",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 16px",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: "1200px",
                                    display: "flex",
                                    gap: "16px",
                                    width: "100%",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                    }}
                                >
                                    {t("banner.desktop")}
                                </div>
                                <Button
                                    size="small"
                                    sx={{
                                        borderRadius: "4px",
                                        fontSize: "10px",
                                        padding: "8px 10px",
                                        minWidth: "130px",
                                    }}
                                    onClick={() => {
                                        closeBanner();
                                        navigate("/download");
                                    }}
                                >
                                    {t("banner.button")}
                                    <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: "8px" }} size={"sm"} />
                                </Button>
                                <div
                                    style={{
                                        marginLeft: "auto",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => {
                                        closeBanner();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faClose} />
                                </div>
                            </div>
                        </div>
                    )}
                </AppBar>
            </HideOnScroll>
        </>
    );
}
