import { useTranslation } from "react-i18next";
import { useClubProvider } from "../../service/ClubService";
import { MediaContentLanguage, useBrandMediaContentProvider } from "../../service/mediaContentService";
import { useTheme } from "@mui/material";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import { normalizeLanguage } from "../../service/ConfigTranslator";

export const ClubLogo = () => {
    const { i18n } = useTranslation();
    const { brand_id: brandId } = useClubProvider();
    const theme = useTheme();

    const { GetBrandMediaContent } = useBrandMediaContentProvider();

    const logo = GetBrandMediaContent({
        brandId: brandId ?? "",
        directory: "logo",
        metadata: {
            lang: [normalizeLanguage(i18n.language, "nl") as MediaContentLanguage],
            type: ["image", "vector", "video"],
        },
    });

    return (
        <div
            style={{
                height: "170px",
                margin: theme.spacing(2),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {logo && (
                <ImageWithFallback
                    src={logo.Url}
                    width={"173px"}
                    height={"170px"}
                    type="rounded"
                    style={{ objectFit: "contain" }}
                />
            )}
        </div>
    );
};
