import { Preferences } from "@capacitor/preferences";
import { AuthContextInterface } from "../auth/AuthProvider";

interface PreferenceStorage {
  [userId: string]: AuthPreferences;
}

export interface AuthPreferences {
  bioEnabled: undefined | boolean;
  pincodeSet: undefined | boolean;
}

export default class PreferencesService {
  static async setAuth(authProvider: AuthContextInterface, preference: AuthPreferences) {
    const userId = authProvider.authBody?.jwt.sub;
    if (!userId) return null;

    const store = await Preferences.get({ key: "authPreferences" });
    const storage = store.value ? (JSON.parse(store.value) as PreferenceStorage) : {};
    storage[userId] = preference;

    await Preferences.set({
      key: "authPreferences",
      value: JSON.stringify(storage),
    });
  }

  static async getAuth(authProvider: AuthContextInterface): Promise<AuthPreferences | null> {
    const userId = authProvider.authBody?.jwt.sub;
    if (!userId) return null;

    const store = await Preferences.get({ key: "authPreferences" });
    const storage = store.value ? (JSON.parse(store.value) as PreferenceStorage) : {};

    return storage[userId] || null;
  }
}
