import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

type Props = {
  password: string;
  focussed: boolean;
  error: boolean;
  textColor?: string;
};

export function PasswordRequirements(props: Props) {
  const { password, focussed, error } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  if (!focussed && !error) return <></>;

  function checkUppercase(str: string) {
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)) {
        return true;
      }
    }
    return false;
  }

  function checkColor(checkType: "charLength" | "capital" | "lowerCase" | "digit") {
    const textColor = props.textColor || theme.palette.text.primary;
    let checked = false;
    if (!password) return textColor;

    if (checkType === "charLength") checked = password.length >= 6;
    if (checkType === "capital") checked = checkUppercase(password);
    if (checkType === "lowerCase") checked = password.toUpperCase() !== password;
    if (checkType === "digit") checked = /\d/.test(password);

    return checked ? theme.palette.success.main : textColor;
  }

  return (
    <Typography
      variant="subtitle1"
      sx={{
        textAlign: "left",
        marginTop: 0.5,
      }}
    >
      <span style={{ color: checkColor("capital") }}>1 {t("passwordRequirements.capital")}</span>
      <span style={{ color: checkColor("lowerCase") }}> • 1 {t("passwordRequirements.lowerCase")}</span>
      <span style={{ color: checkColor("charLength") }}> • 6 {t("passwordRequirements.characters")}</span>
      <span style={{ color: checkColor("digit") }}> • 1 {t("passwordRequirements.digit")}</span>
    </Typography>
  );
}
