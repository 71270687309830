export const countries = [
  {
    //   id: "1",
    //   country: "Afghanistan",
    //   country_iso2: "AF",
    //   country_iso3: "AFG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/1/states",
    //       "#text": "/countries/1/states",
    //     },
    //   },
    // },
    // {
    //   id: "2",
    //   country: "Albania",
    //   country_iso2: "AL",
    //   country_iso3: "ALB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/2/states",
    //       "#text": "/countries/2/states",
    //     },
    //   },
    // },
    // {
    //   id: "3",
    //   country: "Algeria",
    //   country_iso2: "DZ",
    //   country_iso3: "DZA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/3/states",
    //       "#text": "/countries/3/states",
    //     },
    //   },
    // },
    // {
    //   id: "4",
    //   country: "American Samoa",
    //   country_iso2: "AS",
    //   country_iso3: "ASM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/4/states",
    //       "#text": "/countries/4/states",
    //     },
    //   },
    // },
    // {
    //   id: "5",
    //   country: "Andorra",
    //   country_iso2: "AD",
    //   country_iso3: "AND",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/5/states",
    //       "#text": "/countries/5/states",
    //     },
    //   },
    // },
    // {
    //   id: "6",
    //   country: "Angola",
    //   country_iso2: "AO",
    //   country_iso3: "AGO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/6/states",
    //       "#text": "/countries/6/states",
    //     },
    //   },
    // },
    // {
    //   id: "7",
    //   country: "Anguilla",
    //   country_iso2: "AI",
    //   country_iso3: "AIA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/7/states",
    //       "#text": "/countries/7/states",
    //     },
    //   },
    // },
    // {
    //   id: "8",
    //   country: "Antarctica",
    //   country_iso2: "AQ",
    //   country_iso3: "",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/8/states",
    //       "#text": "/countries/8/states",
    //     },
    //   },
    // },
    // {
    //   id: "9",
    //   country: "Antigua and Barbuda",
    //   country_iso2: "AG",
    //   country_iso3: "ATG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/9/states",
    //       "#text": "/countries/9/states",
    //     },
    //   },
    // },
    // {
    //   id: "10",
    //   country: "Argentina",
    //   country_iso2: "AR",
    //   country_iso3: "ARG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/10/states",
    //       "#text": "/countries/10/states",
    //     },
    //   },
    // },
    // {
    //   id: "11",
    //   country: "Armenia",
    //   country_iso2: "AM",
    //   country_iso3: "ARM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/11/states",
    //       "#text": "/countries/11/states",
    //     },
    //   },
    // },
    // {
    //   id: "12",
    //   country: "Aruba",
    //   country_iso2: "AW",
    //   country_iso3: "ABW",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/12/states",
    //       "#text": "/countries/12/states",
    //     },
    //   },
    // },
    // {
    //   id: "13",
    //   country: "Australia",
    //   country_iso2: "AU",
    //   country_iso3: "AUS",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/13/states",
    //       "#text": "/countries/13/states",
    //     },
    //   },
    // },
    // {
    //   id: "14",
    //   country: "Austria",
    //   country_iso2: "AT",
    //   country_iso3: "AUT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/14/states",
    //       "#text": "/countries/14/states",
    //     },
    //   },
    // },
    // {
    //   id: "15",
    //   country: "Azerbaijan",
    //   country_iso2: "AZ",
    //   country_iso3: "AZE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/15/states",
    //       "#text": "/countries/15/states",
    //     },
    //   },
    // },
    // {
    //   id: "16",
    //   country: "Bahamas",
    //   country_iso2: "BS",
    //   country_iso3: "BHS",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/16/states",
    //       "#text": "/countries/16/states",
    //     },
    //   },
    // },
    // {
    //   id: "17",
    //   country: "Bahrain",
    //   country_iso2: "BH",
    //   country_iso3: "BHR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/17/states",
    //       "#text": "/countries/17/states",
    //     },
    //   },
    // },
    // {
    //   id: "18",
    //   country: "Bangladesh",
    //   country_iso2: "BD",
    //   country_iso3: "BGD",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/18/states",
    //       "#text": "/countries/18/states",
    //     },
    //   },
    // },
    // {
    //   id: "19",
    //   country: "Barbados",
    //   country_iso2: "BB",
    //   country_iso3: "BRB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/19/states",
    //       "#text": "/countries/19/states",
    //     },
    //   },
    // },
    // {
    //   id: "20",
    //   country: "Belarus",
    //   country_iso2: "BY",
    //   country_iso3: "BLR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/20/states",
    //       "#text": "/countries/20/states",
    //     },
    //   },
    // },
    // {
    //   id: "21",
    //   country: "Belgium",
    //   country_iso2: "BE",
    //   country_iso3: "BEL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/21/states",
    //       "#text": "/countries/21/states",
    //     },
    //   },
    // },
    // {
    //   id: "22",
    //   country: "Belize",
    //   country_iso2: "BZ",
    //   country_iso3: "BLZ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/22/states",
    //       "#text": "/countries/22/states",
    //     },
    //   },
    // },
    // {
    //   id: "23",
    //   country: "Benin",
    //   country_iso2: "BJ",
    //   country_iso3: "BEN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/23/states",
    //       "#text": "/countries/23/states",
    //     },
    //   },
    // },
    // {
    //   id: "24",
    //   country: "Bermuda",
    //   country_iso2: "BM",
    //   country_iso3: "BMU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/24/states",
    //       "#text": "/countries/24/states",
    //     },
    //   },
    // },
    // {
    //   id: "25",
    //   country: "Bhutan",
    //   country_iso2: "BT",
    //   country_iso3: "BTN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/25/states",
    //       "#text": "/countries/25/states",
    //     },
    //   },
    // },
    // {
    //   id: "26",
    //   country: "Bolivia",
    //   country_iso2: "BO",
    //   country_iso3: "BOL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/26/states",
    //       "#text": "/countries/26/states",
    //     },
    //   },
    // },
    // {
    //   id: "27",
    //   country: "Bosnia and Herzegovina",
    //   country_iso2: "BA",
    //   country_iso3: "BIH",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/27/states",
    //       "#text": "/countries/27/states",
    //     },
    //   },
    // },
    // {
    //   id: "28",
    //   country: "Botswana",
    //   country_iso2: "BW",
    //   country_iso3: "BWA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/28/states",
    //       "#text": "/countries/28/states",
    //     },
    //   },
    // },
    // {
    //   id: "29",
    //   country: "Bouvet Island",
    //   country_iso2: "BV",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/29/states",
    //       "#text": "/countries/29/states",
    //     },
    //   },
    // },
    // {
    //   id: "30",
    //   country: "Brazil",
    //   country_iso2: "BR",
    //   country_iso3: "BRA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/30/states",
    //       "#text": "/countries/30/states",
    //     },
    //   },
    // },
    // {
    //   id: "31",
    //   country: "British Indian Ocean Territory",
    //   country_iso2: "IO",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/31/states",
    //       "#text": "/countries/31/states",
    //     },
    //   },
    // },
    // {
    //   id: "32",
    //   country: "Brunei Darussalam",
    //   country_iso2: "BN",
    //   country_iso3: "BRN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/32/states",
    //       "#text": "/countries/32/states",
    //     },
    //   },
    // },
    // {
    //   id: "33",
    //   country: "Bulgaria",
    //   country_iso2: "BG",
    //   country_iso3: "BGR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/33/states",
    //       "#text": "/countries/33/states",
    //     },
    //   },
    // },
    // {
    //   id: "34",
    //   country: "Burkina Faso",
    //   country_iso2: "BF",
    //   country_iso3: "BFA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/34/states",
    //       "#text": "/countries/34/states",
    //     },
    //   },
    // },
    // {
    //   id: "35",
    //   country: "Burundi",
    //   country_iso2: "BI",
    //   country_iso3: "BDI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/35/states",
    //       "#text": "/countries/35/states",
    //     },
    //   },
    // },
    // {
    //   id: "36",
    //   country: "Cambodia",
    //   country_iso2: "KH",
    //   country_iso3: "KHM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/36/states",
    //       "#text": "/countries/36/states",
    //     },
    //   },
    // },
    // {
    //   id: "37",
    //   country: "Cameroon",
    //   country_iso2: "CM",
    //   country_iso3: "CMR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/37/states",
    //       "#text": "/countries/37/states",
    //     },
    //   },
    // },
    // {
    //   id: "38",
    //   country: "Canada",
    //   country_iso2: "CA",
    //   country_iso3: "CAN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/38/states",
    //       "#text": "/countries/38/states",
    //     },
    //   },
    // },
    // {
    //   id: "39",
    //   country: "Cape Verde",
    //   country_iso2: "CV",
    //   country_iso3: "CPV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/39/states",
    //       "#text": "/countries/39/states",
    //     },
    //   },
    // },
    // {
    //   id: "40",
    //   country: "Cayman Islands",
    //   country_iso2: "KY",
    //   country_iso3: "CYM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/40/states",
    //       "#text": "/countries/40/states",
    //     },
    //   },
    // },
    // {
    //   id: "41",
    //   country: "Central African Republic",
    //   country_iso2: "CF",
    //   country_iso3: "CAF",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/41/states",
    //       "#text": "/countries/41/states",
    //     },
    //   },
    // },
    // {
    //   id: "42",
    //   country: "Chad",
    //   country_iso2: "TD",
    //   country_iso3: "TCD",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/42/states",
    //       "#text": "/countries/42/states",
    //     },
    //   },
    // },
    // {
    //   id: "43",
    //   country: "Chile",
    //   country_iso2: "CL",
    //   country_iso3: "CHL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/43/states",
    //       "#text": "/countries/43/states",
    //     },
    //   },
    // },
    // {
    //   id: "44",
    //   country: "China",
    //   country_iso2: "CN",
    //   country_iso3: "CHN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/44/states",
    //       "#text": "/countries/44/states",
    //     },
    //   },
    // },
    // {
    //   id: "45",
    //   country: "Christmas Island",
    //   country_iso2: "CX",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/45/states",
    //       "#text": "/countries/45/states",
    //     },
    //   },
    // },
    // {
    //   id: "46",
    //   country: "Cocos (Keeling) Islands",
    //   country_iso2: "CC",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/46/states",
    //       "#text": "/countries/46/states",
    //     },
    //   },
    // },
    // {
    //   id: "47",
    //   country: "Colombia",
    //   country_iso2: "CO",
    //   country_iso3: "COL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/47/states",
    //       "#text": "/countries/47/states",
    //     },
    //   },
    // },
    // {
    //   id: "48",
    //   country: "Comoros",
    //   country_iso2: "KM",
    //   country_iso3: "COM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/48/states",
    //       "#text": "/countries/48/states",
    //     },
    //   },
    // },
    // {
    //   id: "49",
    //   country: "Congo",
    //   country_iso2: "CG",
    //   country_iso3: "COG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/49/states",
    //       "#text": "/countries/49/states",
    //     },
    //   },
    // },
    // {
    //   id: "50",
    //   country: "Congo, the Democratic Republic of the",
    //   country_iso2: "CD",
    //   country_iso3: "COD",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/50/states",
    //       "#text": "/countries/50/states",
    //     },
    //   },
    // },
    // {
    //   id: "51",
    //   country: "Cook Islands",
    //   country_iso2: "CK",
    //   country_iso3: "COK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/51/states",
    //       "#text": "/countries/51/states",
    //     },
    //   },
    // },
    // {
    //   id: "52",
    //   country: "Costa Rica",
    //   country_iso2: "CR",
    //   country_iso3: "CRI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/52/states",
    //       "#text": "/countries/52/states",
    //     },
    //   },
    // },
    // {
    //   id: "54",
    //   country: "Croatia",
    //   country_iso2: "HR",
    //   country_iso3: "HRV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/54/states",
    //       "#text": "/countries/54/states",
    //     },
    //   },
    // },
    // {
    //   id: "56",
    //   country: "Cyprus",
    //   country_iso2: "CY",
    //   country_iso3: "CYP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/56/states",
    //       "#text": "/countries/56/states",
    //     },
    //   },
    // },
    // {
    //   id: "57",
    //   country: "Czech Republic",
    //   country_iso2: "CZ",
    //   country_iso3: "CZE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/57/states",
    //       "#text": "/countries/57/states",
    //     },
    //   },
    // },
    // {
    //   id: "58",
    //   country: "Denmark",
    //   country_iso2: "DK",
    //   country_iso3: "DNK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/58/states",
    //       "#text": "/countries/58/states",
    //     },
    //   },
    // },
    // {
    //   id: "59",
    //   country: "Djibouti",
    //   country_iso2: "DJ",
    //   country_iso3: "DJI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/59/states",
    //       "#text": "/countries/59/states",
    //     },
    //   },
    // },
    // {
    //   id: "60",
    //   country: "Dominica",
    //   country_iso2: "DM",
    //   country_iso3: "DMA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/60/states",
    //       "#text": "/countries/60/states",
    //     },
    //   },
    // },
    // {
    //   id: "61",
    //   country: "Dominican Republic",
    //   country_iso2: "DO",
    //   country_iso3: "DOM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/61/states",
    //       "#text": "/countries/61/states",
    //     },
    //   },
    // },
    // {
    //   id: "62",
    //   country: "Ecuador",
    //   country_iso2: "EC",
    //   country_iso3: "ECU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/62/states",
    //       "#text": "/countries/62/states",
    //     },
    //   },
    // },
    // {
    //   id: "63",
    //   country: "Egypt",
    //   country_iso2: "EG",
    //   country_iso3: "EGY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/63/states",
    //       "#text": "/countries/63/states",
    //     },
    //   },
    // },
    // {
    //   id: "64",
    //   country: "El Salvador",
    //   country_iso2: "SV",
    //   country_iso3: "SLV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/64/states",
    //       "#text": "/countries/64/states",
    //     },
    //   },
    // },
    // {
    //   id: "65",
    //   country: "Equatorial Guinea",
    //   country_iso2: "GQ",
    //   country_iso3: "GNQ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/65/states",
    //       "#text": "/countries/65/states",
    //     },
    //   },
    // },
    // {
    //   id: "66",
    //   country: "Eritrea",
    //   country_iso2: "ER",
    //   country_iso3: "ERI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/66/states",
    //       "#text": "/countries/66/states",
    //     },
    //   },
    // },
    // {
    //   id: "67",
    //   country: "Estonia",
    //   country_iso2: "EE",
    //   country_iso3: "EST",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/67/states",
    //       "#text": "/countries/67/states",
    //     },
    //   },
    // },
    // {
    //   id: "68",
    //   country: "Ethiopia",
    //   country_iso2: "ET",
    //   country_iso3: "ETH",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/68/states",
    //       "#text": "/countries/68/states",
    //     },
    //   },
    // },
    // {
    //   id: "69",
    //   country: "Falkland Islands (Malvinas)",
    //   country_iso2: "FK",
    //   country_iso3: "FLK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/69/states",
    //       "#text": "/countries/69/states",
    //     },
    //   },
    // },
    // {
    //   id: "70",
    //   country: "Faroe Islands",
    //   country_iso2: "FO",
    //   country_iso3: "FRO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/70/states",
    //       "#text": "/countries/70/states",
    //     },
    //   },
    // },
    // {
    //   id: "71",
    //   country: "Fiji",
    //   country_iso2: "FJ",
    //   country_iso3: "FJI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/71/states",
    //       "#text": "/countries/71/states",
    //     },
    //   },
    // },
    // {
    //   id: "72",
    //   country: "Finland",
    //   country_iso2: "FI",
    //   country_iso3: "FIN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/72/states",
    //       "#text": "/countries/72/states",
    //     },
    //   },
    // },
    // {
    //   id: "73",
    //   country: "France",
    //   country_iso2: "FR",
    //   country_iso3: "FRA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/73/states",
    //       "#text": "/countries/73/states",
    //     },
    //   },
    // },
    // {
    //   id: "74",
    //   country: "French Guiana",
    //   country_iso2: "GF",
    //   country_iso3: "GUF",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/74/states",
    //       "#text": "/countries/74/states",
    //     },
    //   },
    // },
    // {
    //   id: "75",
    //   country: "French Polynesia",
    //   country_iso2: "PF",
    //   country_iso3: "PYF",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/75/states",
    //       "#text": "/countries/75/states",
    //     },
    //   },
    // },
    // {
    //   id: "76",
    //   country: "French Southern Territories",
    //   country_iso2: "TF",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/76/states",
    //       "#text": "/countries/76/states",
    //     },
    //   },
    // },
    // {
    //   id: "77",
    //   country: "Gabon",
    //   country_iso2: "GA",
    //   country_iso3: "GAB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/77/states",
    //       "#text": "/countries/77/states",
    //     },
    //   },
    // },
    // {
    //   id: "78",
    //   country: "Gambia",
    //   country_iso2: "GM",
    //   country_iso3: "GMB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/78/states",
    //       "#text": "/countries/78/states",
    //     },
    //   },
    // },
    // {
    //   id: "79",
    //   country: "Georgia",
    //   country_iso2: "GE",
    //   country_iso3: "GEO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/79/states",
    //       "#text": "/countries/79/states",
    //     },
    //   },
    // },
    // {
    //   id: "80",
    //   country: "Germany",
    //   country_iso2: "DE",
    //   country_iso3: "DEU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/80/states",
    //       "#text": "/countries/80/states",
    //     },
    //   },
    // },
    // {
    //   id: "81",
    //   country: "Ghana",
    //   country_iso2: "GH",
    //   country_iso3: "GHA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/81/states",
    //       "#text": "/countries/81/states",
    //     },
    //   },
    // },
    // {
    //   id: "82",
    //   country: "Gibraltar",
    //   country_iso2: "GI",
    //   country_iso3: "GIB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/82/states",
    //       "#text": "/countries/82/states",
    //     },
    //   },
    // },
    // {
    //   id: "83",
    //   country: "Greece",
    //   country_iso2: "GR",
    //   country_iso3: "GRC",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/83/states",
    //       "#text": "/countries/83/states",
    //     },
    //   },
    // },
    // {
    //   id: "84",
    //   country: "Greenland",
    //   country_iso2: "GL",
    //   country_iso3: "GRL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/84/states",
    //       "#text": "/countries/84/states",
    //     },
    //   },
    // },
    // {
    //   id: "85",
    //   country: "Grenada",
    //   country_iso2: "GD",
    //   country_iso3: "GRD",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/85/states",
    //       "#text": "/countries/85/states",
    //     },
    //   },
    // },
    // {
    //   id: "86",
    //   country: "Guadeloupe",
    //   country_iso2: "GP",
    //   country_iso3: "GLP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/86/states",
    //       "#text": "/countries/86/states",
    //     },
    //   },
    // },
    // {
    //   id: "87",
    //   country: "Guam",
    //   country_iso2: "GU",
    //   country_iso3: "GUM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/87/states",
    //       "#text": "/countries/87/states",
    //     },
    //   },
    // },
    // {
    //   id: "88",
    //   country: "Guatemala",
    //   country_iso2: "GT",
    //   country_iso3: "GTM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/88/states",
    //       "#text": "/countries/88/states",
    //     },
    //   },
    // },
    // {
    //   id: "89",
    //   country: "Guinea",
    //   country_iso2: "GN",
    //   country_iso3: "GIN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/89/states",
    //       "#text": "/countries/89/states",
    //     },
    //   },
    // },
    // {
    //   id: "90",
    //   country: "Guinea-Bissau",
    //   country_iso2: "GW",
    //   country_iso3: "GNB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/90/states",
    //       "#text": "/countries/90/states",
    //     },
    //   },
    // },
    // {
    //   id: "91",
    //   country: "Guyana",
    //   country_iso2: "GY",
    //   country_iso3: "GUY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/91/states",
    //       "#text": "/countries/91/states",
    //     },
    //   },
    // },
    // {
    //   id: "92",
    //   country: "Haiti",
    //   country_iso2: "HT",
    //   country_iso3: "HTI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/92/states",
    //       "#text": "/countries/92/states",
    //     },
    //   },
    // },
    // {
    //   id: "93",
    //   country: "Heard Island and Mcdonald Islands",
    //   country_iso2: "HM",
    //   country_iso3: "",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/93/states",
    //       "#text": "/countries/93/states",
    //     },
    //   },
    // },
    // {
    //   id: "94",
    //   country: "Holy See (Vatican City State)",
    //   country_iso2: "VA",
    //   country_iso3: "VAT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/94/states",
    //       "#text": "/countries/94/states",
    //     },
    //   },
    // },
    // {
    //   id: "95",
    //   country: "Honduras",
    //   country_iso2: "HN",
    //   country_iso3: "HND",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/95/states",
    //       "#text": "/countries/95/states",
    //     },
    //   },
    // },
    // {
    //   id: "96",
    //   country: "Hong Kong",
    //   country_iso2: "HK",
    //   country_iso3: "HKG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/96/states",
    //       "#text": "/countries/96/states",
    //     },
    //   },
    // },
    // {
    //   id: "97",
    //   country: "Hungary",
    //   country_iso2: "HU",
    //   country_iso3: "HUN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/97/states",
    //       "#text": "/countries/97/states",
    //     },
    //   },
    // },
    // {
    //   id: "98",
    //   country: "Iceland",
    //   country_iso2: "IS",
    //   country_iso3: "ISL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/98/states",
    //       "#text": "/countries/98/states",
    //     },
    //   },
    // },
    // {
    //   id: "99",
    //   country: "India",
    //   country_iso2: "IN",
    //   country_iso3: "IND",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/99/states",
    //       "#text": "/countries/99/states",
    //     },
    //   },
    // },
    // {
    //   id: "100",
    //   country: "Indonesia",
    //   country_iso2: "ID",
    //   country_iso3: "IDN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/100/states",
    //       "#text": "/countries/100/states",
    //     },
    //   },
    // },
    // {
    //   id: "102",
    //   country: "Iraq",
    //   country_iso2: "IQ",
    //   country_iso3: "IRQ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/102/states",
    //       "#text": "/countries/102/states",
    //     },
    //   },
    // },
    // {
    //   id: "103",
    //   country: "Ireland",
    //   country_iso2: "IE",
    //   country_iso3: "IRL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/103/states",
    //       "#text": "/countries/103/states",
    //     },
    //   },
    // },
    // {
    //   id: "104",
    //   country: "Israel",
    //   country_iso2: "IL",
    //   country_iso3: "ISR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/104/states",
    //       "#text": "/countries/104/states",
    //     },
    //   },
    // },
    // {
    //   id: "105",
    //   country: "Italy",
    //   country_iso2: "IT",
    //   country_iso3: "ITA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/105/states",
    //       "#text": "/countries/105/states",
    //     },
    //   },
    // },
    // {
    //   id: "106",
    //   country: "Jamaica",
    //   country_iso2: "JM",
    //   country_iso3: "JAM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/106/states",
    //       "#text": "/countries/106/states",
    //     },
    //   },
    // },
    // {
    //   id: "107",
    //   country: "Japan",
    //   country_iso2: "JP",
    //   country_iso3: "JPN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/107/states",
    //       "#text": "/countries/107/states",
    //     },
    //   },
    // },
    // {
    //   id: "108",
    //   country: "Jordan",
    //   country_iso2: "JO",
    //   country_iso3: "JOR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/108/states",
    //       "#text": "/countries/108/states",
    //     },
    //   },
    // },
    // {
    //   id: "109",
    //   country: "Kazakhstan",
    //   country_iso2: "KZ",
    //   country_iso3: "KAZ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/109/states",
    //       "#text": "/countries/109/states",
    //     },
    //   },
    // },
    // {
    //   id: "110",
    //   country: "Kenya",
    //   country_iso2: "KE",
    //   country_iso3: "KEN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/110/states",
    //       "#text": "/countries/110/states",
    //     },
    //   },
    // },
    // {
    //   id: "111",
    //   country: "Kiribati",
    //   country_iso2: "KI",
    //   country_iso3: "KIR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/111/states",
    //       "#text": "/countries/111/states",
    //     },
    //   },
    // },
    // {
    //   id: "113",
    //   country: "Korea, Republic of",
    //   country_iso2: "KR",
    //   country_iso3: "KOR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/113/states",
    //       "#text": "/countries/113/states",
    //     },
    //   },
    // },
    // {
    //   id: "114",
    //   country: "Kuwait",
    //   country_iso2: "KW",
    //   country_iso3: "KWT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/114/states",
    //       "#text": "/countries/114/states",
    //     },
    //   },
    // },
    // {
    //   id: "115",
    //   country: "Kyrgyzstan",
    //   country_iso2: "KG",
    //   country_iso3: "KGZ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/115/states",
    //       "#text": "/countries/115/states",
    //     },
    //   },
    // },
    // {
    //   id: "117",
    //   country: "Latvia",
    //   country_iso2: "LV",
    //   country_iso3: "LVA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/117/states",
    //       "#text": "/countries/117/states",
    //     },
    //   },
    // },
    // {
    //   id: "118",
    //   country: "Lebanon",
    //   country_iso2: "LB",
    //   country_iso3: "LBN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/118/states",
    //       "#text": "/countries/118/states",
    //     },
    //   },
    // },
    // {
    //   id: "119",
    //   country: "Lesotho",
    //   country_iso2: "LS",
    //   country_iso3: "LSO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/119/states",
    //       "#text": "/countries/119/states",
    //     },
    //   },
    // },
    // {
    //   id: "120",
    //   country: "Liberia",
    //   country_iso2: "LR",
    //   country_iso3: "LBR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/120/states",
    //       "#text": "/countries/120/states",
    //     },
    //   },
    // },
    // {
    //   id: "121",
    //   country: "Libya",
    //   country_iso2: "LY",
    //   country_iso3: "LBY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/121/states",
    //       "#text": "/countries/121/states",
    //     },
    //   },
    // },
    // {
    //   id: "122",
    //   country: "Liechtenstein",
    //   country_iso2: "LI",
    //   country_iso3: "LIE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/122/states",
    //       "#text": "/countries/122/states",
    //     },
    //   },
    // },
    // {
    //   id: "123",
    //   country: "Lithuania",
    //   country_iso2: "LT",
    //   country_iso3: "LTU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/123/states",
    //       "#text": "/countries/123/states",
    //     },
    //   },
    // },
    // {
    //   id: "124",
    //   country: "Luxembourg",
    //   country_iso2: "LU",
    //   country_iso3: "LUX",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/124/states",
    //       "#text": "/countries/124/states",
    //     },
    //   },
    // },
    // {
    //   id: "125",
    //   country: "Macao",
    //   country_iso2: "MO",
    //   country_iso3: "MAC",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/125/states",
    //       "#text": "/countries/125/states",
    //     },
    //   },
    // },
    // {
    //   id: "126",
    //   country: "Macedonia, the Former Yugoslav Republic of",
    //   country_iso2: "MK",
    //   country_iso3: "MKD",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/126/states",
    //       "#text": "/countries/126/states",
    //     },
    //   },
    // },
    // {
    //   id: "127",
    //   country: "Madagascar",
    //   country_iso2: "MG",
    //   country_iso3: "MDG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/127/states",
    //       "#text": "/countries/127/states",
    //     },
    //   },
    // },
    // {
    //   id: "128",
    //   country: "Malawi",
    //   country_iso2: "MW",
    //   country_iso3: "MWI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/128/states",
    //       "#text": "/countries/128/states",
    //     },
    //   },
    // },
    // {
    //   id: "129",
    //   country: "Malaysia",
    //   country_iso2: "MY",
    //   country_iso3: "MYS",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/129/states",
    //       "#text": "/countries/129/states",
    //     },
    //   },
    // },
    // {
    //   id: "130",
    //   country: "Maldives",
    //   country_iso2: "MV",
    //   country_iso3: "MDV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/130/states",
    //       "#text": "/countries/130/states",
    //     },
    //   },
    // },
    // {
    //   id: "131",
    //   country: "Mali",
    //   country_iso2: "ML",
    //   country_iso3: "MLI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/131/states",
    //       "#text": "/countries/131/states",
    //     },
    //   },
    // },
    // {
    //   id: "132",
    //   country: "Malta",
    //   country_iso2: "MT",
    //   country_iso3: "MLT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/132/states",
    //       "#text": "/countries/132/states",
    //     },
    //   },
    // },
    // {
    //   id: "133",
    //   country: "Marshall Islands",
    //   country_iso2: "MH",
    //   country_iso3: "MHL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/133/states",
    //       "#text": "/countries/133/states",
    //     },
    //   },
    // },
    // {
    //   id: "134",
    //   country: "Martinique",
    //   country_iso2: "MQ",
    //   country_iso3: "MTQ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/134/states",
    //       "#text": "/countries/134/states",
    //     },
    //   },
    // },
    // {
    //   id: "135",
    //   country: "Mauritania",
    //   country_iso2: "MR",
    //   country_iso3: "MRT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/135/states",
    //       "#text": "/countries/135/states",
    //     },
    //   },
    // },
    // {
    //   id: "136",
    //   country: "Mauritius",
    //   country_iso2: "MU",
    //   country_iso3: "MUS",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/136/states",
    //       "#text": "/countries/136/states",
    //     },
    //   },
    // },
    // {
    //   id: "137",
    //   country: "Mayotte",
    //   country_iso2: "YT",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/137/states",
    //       "#text": "/countries/137/states",
    //     },
    //   },
    // },
    // {
    //   id: "138",
    //   country: "Mexico",
    //   country_iso2: "MX",
    //   country_iso3: "MEX",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/138/states",
    //       "#text": "/countries/138/states",
    //     },
    //   },
    // },
    // {
    //   id: "139",
    //   country: "Micronesia, Federated States of",
    //   country_iso2: "FM",
    //   country_iso3: "FSM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/139/states",
    //       "#text": "/countries/139/states",
    //     },
    //   },
    // },
    // {
    //   id: "140",
    //   country: "Moldova, Republic of",
    //   country_iso2: "MD",
    //   country_iso3: "MDA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/140/states",
    //       "#text": "/countries/140/states",
    //     },
    //   },
    // },
    // {
    //   id: "141",
    //   country: "Monaco",
    //   country_iso2: "MC",
    //   country_iso3: "MCO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/141/states",
    //       "#text": "/countries/141/states",
    //     },
    //   },
    // },
    // {
    //   id: "142",
    //   country: "Mongolia",
    //   country_iso2: "MN",
    //   country_iso3: "MNG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/142/states",
    //       "#text": "/countries/142/states",
    //     },
    //   },
    // },
    // {
    //   id: "143",
    //   country: "Montserrat",
    //   country_iso2: "MS",
    //   country_iso3: "MSR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/143/states",
    //       "#text": "/countries/143/states",
    //     },
    //   },
    // },
    // {
    //   id: "144",
    //   country: "Morocco",
    //   country_iso2: "MA",
    //   country_iso3: "MAR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/144/states",
    //       "#text": "/countries/144/states",
    //     },
    //   },
    // },
    // {
    //   id: "145",
    //   country: "Mozambique",
    //   country_iso2: "MZ",
    //   country_iso3: "MOZ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/145/states",
    //       "#text": "/countries/145/states",
    //     },
    //   },
    // },
    // {
    //   id: "146",
    //   country: "Myanmar",
    //   country_iso2: "MM",
    //   country_iso3: "MMR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/146/states",
    //       "#text": "/countries/146/states",
    //     },
    //   },
    // },
    // {
    //   id: "147",
    //   country: "Namibia",
    //   country_iso2: "NA",
    //   country_iso3: "NAM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/147/states",
    //       "#text": "/countries/147/states",
    //     },
    //   },
    // },
    // {
    //   id: "148",
    //   country: "Nauru",
    //   country_iso2: "NR",
    //   country_iso3: "NRU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/148/states",
    //       "#text": "/countries/148/states",
    //     },
    //   },
    // },
    // {
    //   id: "149",
    //   country: "Nepal",
    //   country_iso2: "NP",
    //   country_iso3: "NPL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/149/states",
    //       "#text": "/countries/149/states",
    //     },
    //   },
    // },
    // {
    id: "150",
    country: "Netherlands",
    country_iso2: "NL",
    country_iso3: "NLD",
    states: {
      link: {
        "@rel": "resource",
        "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/150/states",
        "#text": "/countries/150/states",
      },
    },
    // },
    // {
    //   id: "151",
    //   country: "Netherlands Antilles",
    //   country_iso2: "AN",
    //   country_iso3: "ANT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/151/states",
    //       "#text": "/countries/151/states",
    //     },
    //   },
    // },
    // {
    //   id: "152",
    //   country: "New Caledonia",
    //   country_iso2: "NC",
    //   country_iso3: "NCL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/152/states",
    //       "#text": "/countries/152/states",
    //     },
    //   },
    // },
    // {
    //   id: "153",
    //   country: "New Zealand",
    //   country_iso2: "NZ",
    //   country_iso3: "NZL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/153/states",
    //       "#text": "/countries/153/states",
    //     },
    //   },
    // },
    // {
    //   id: "154",
    //   country: "Nicaragua",
    //   country_iso2: "NI",
    //   country_iso3: "NIC",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/154/states",
    //       "#text": "/countries/154/states",
    //     },
    //   },
    // },
    // {
    //   id: "155",
    //   country: "Niger",
    //   country_iso2: "NE",
    //   country_iso3: "NER",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/155/states",
    //       "#text": "/countries/155/states",
    //     },
    //   },
    // },
    // {
    //   id: "156",
    //   country: "Nigeria",
    //   country_iso2: "NG",
    //   country_iso3: "NGA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/156/states",
    //       "#text": "/countries/156/states",
    //     },
    //   },
    // },
    // {
    //   id: "157",
    //   country: "Niue",
    //   country_iso2: "NU",
    //   country_iso3: "NIU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/157/states",
    //       "#text": "/countries/157/states",
    //     },
    //   },
    // },
    // {
    //   id: "158",
    //   country: "Norfolk Island",
    //   country_iso2: "NF",
    //   country_iso3: "NFK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/158/states",
    //       "#text": "/countries/158/states",
    //     },
    //   },
    // },
    // {
    //   id: "159",
    //   country: "Northern Mariana Islands",
    //   country_iso2: "MP",
    //   country_iso3: "MNP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/159/states",
    //       "#text": "/countries/159/states",
    //     },
    //   },
    // },
    // {
    //   id: "160",
    //   country: "Norway",
    //   country_iso2: "NO",
    //   country_iso3: "NOR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/160/states",
    //       "#text": "/countries/160/states",
    //     },
    //   },
    // },
    // {
    //   id: "161",
    //   country: "Oman",
    //   country_iso2: "OM",
    //   country_iso3: "OMN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/161/states",
    //       "#text": "/countries/161/states",
    //     },
    //   },
    // },
    // {
    //   id: "162",
    //   country: "Pakistan",
    //   country_iso2: "PK",
    //   country_iso3: "PAK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/162/states",
    //       "#text": "/countries/162/states",
    //     },
    //   },
    // },
    // {
    //   id: "163",
    //   country: "Palau",
    //   country_iso2: "PW",
    //   country_iso3: "PLW",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/163/states",
    //       "#text": "/countries/163/states",
    //     },
    //   },
    // },
    // {
    //   id: "164",
    //   country: "Palestinian Territory, Occupied",
    //   country_iso2: "PS",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/164/states",
    //       "#text": "/countries/164/states",
    //     },
    //   },
    // },
    // {
    //   id: "165",
    //   country: "Panama",
    //   country_iso2: "PA",
    //   country_iso3: "PAN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/165/states",
    //       "#text": "/countries/165/states",
    //     },
    //   },
    // },
    // {
    //   id: "166",
    //   country: "Papua New Guinea",
    //   country_iso2: "PG",
    //   country_iso3: "PNG",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/166/states",
    //       "#text": "/countries/166/states",
    //     },
    //   },
    // },
    // {
    //   id: "167",
    //   country: "Paraguay",
    //   country_iso2: "PY",
    //   country_iso3: "PRY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/167/states",
    //       "#text": "/countries/167/states",
    //     },
    //   },
    // },
    // {
    //   id: "168",
    //   country: "Peru",
    //   country_iso2: "PE",
    //   country_iso3: "PER",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/168/states",
    //       "#text": "/countries/168/states",
    //     },
    //   },
    // },
    // {
    //   id: "169",
    //   country: "Philippines",
    //   country_iso2: "PH",
    //   country_iso3: "PHL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/169/states",
    //       "#text": "/countries/169/states",
    //     },
    //   },
    // },
    // {
    //   id: "170",
    //   country: "Pitcairn",
    //   country_iso2: "PN",
    //   country_iso3: "PCN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/170/states",
    //       "#text": "/countries/170/states",
    //     },
    //   },
    // },
    // {
    //   id: "171",
    //   country: "Poland",
    //   country_iso2: "PL",
    //   country_iso3: "POL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/171/states",
    //       "#text": "/countries/171/states",
    //     },
    //   },
    // },
    // {
    //   id: "172",
    //   country: "Portugal",
    //   country_iso2: "PT",
    //   country_iso3: "PRT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/172/states",
    //       "#text": "/countries/172/states",
    //     },
    //   },
    // },
    // {
    //   id: "173",
    //   country: "Puerto Rico",
    //   country_iso2: "PR",
    //   country_iso3: "PRI",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/173/states",
    //       "#text": "/countries/173/states",
    //     },
    //   },
    // },
    // {
    //   id: "174",
    //   country: "Qatar",
    //   country_iso2: "QA",
    //   country_iso3: "QAT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/174/states",
    //       "#text": "/countries/174/states",
    //     },
    //   },
    // },
    // {
    //   id: "175",
    //   country: "Reunion",
    //   country_iso2: "RE",
    //   country_iso3: "REU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/175/states",
    //       "#text": "/countries/175/states",
    //     },
    //   },
    // },
    // {
    //   id: "176",
    //   country: "Romania",
    //   country_iso2: "RO",
    //   country_iso3: "ROM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/176/states",
    //       "#text": "/countries/176/states",
    //     },
    //   },
    // },
    // {
    //   id: "177",
    //   country: "Russian Federation",
    //   country_iso2: "RU",
    //   country_iso3: "RUS",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/177/states",
    //       "#text": "/countries/177/states",
    //     },
    //   },
    // },
    // {
    //   id: "178",
    //   country: "Rwanda",
    //   country_iso2: "RW",
    //   country_iso3: "RWA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/178/states",
    //       "#text": "/countries/178/states",
    //     },
    //   },
    // },
    // {
    //   id: "179",
    //   country: "Saint Helena",
    //   country_iso2: "SH",
    //   country_iso3: "SHN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/179/states",
    //       "#text": "/countries/179/states",
    //     },
    //   },
    // },
    // {
    //   id: "180",
    //   country: "Saint Kitts and Nevis",
    //   country_iso2: "KN",
    //   country_iso3: "KNA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/180/states",
    //       "#text": "/countries/180/states",
    //     },
    //   },
    // },
    // {
    //   id: "181",
    //   country: "Saint Lucia",
    //   country_iso2: "LC",
    //   country_iso3: "LCA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/181/states",
    //       "#text": "/countries/181/states",
    //     },
    //   },
    // },
    // {
    //   id: "182",
    //   country: "Saint Pierre and Miquelon",
    //   country_iso2: "PM",
    //   country_iso3: "SPM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/182/states",
    //       "#text": "/countries/182/states",
    //     },
    //   },
    // },
    // {
    //   id: "183",
    //   country: "Saint Vincent and the Grenadines",
    //   country_iso2: "VC",
    //   country_iso3: "VCT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/183/states",
    //       "#text": "/countries/183/states",
    //     },
    //   },
    // },
    // {
    //   id: "184",
    //   country: "Samoa",
    //   country_iso2: "WS",
    //   country_iso3: "WSM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/184/states",
    //       "#text": "/countries/184/states",
    //     },
    //   },
    // },
    // {
    //   id: "185",
    //   country: "San Marino",
    //   country_iso2: "SM",
    //   country_iso3: "SMR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/185/states",
    //       "#text": "/countries/185/states",
    //     },
    //   },
    // },
    // {
    //   id: "186",
    //   country: "Sao Tome and Principe",
    //   country_iso2: "ST",
    //   country_iso3: "STP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/186/states",
    //       "#text": "/countries/186/states",
    //     },
    //   },
    // },
    // {
    //   id: "187",
    //   country: "Saudi Arabia",
    //   country_iso2: "SA",
    //   country_iso3: "SAU",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/187/states",
    //       "#text": "/countries/187/states",
    //     },
    //   },
    // },
    // {
    //   id: "188",
    //   country: "Senegal",
    //   country_iso2: "SN",
    //   country_iso3: "SEN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/188/states",
    //       "#text": "/countries/188/states",
    //     },
    //   },
    // },
    // {
    //   id: "189",
    //   country: "Serbia",
    //   country_iso2: "RS",
    //   country_iso3: "SRB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/189/states",
    //       "#text": "/countries/189/states",
    //     },
    //   },
    // },
    // {
    //   id: "190",
    //   country: "Seychelles",
    //   country_iso2: "SC",
    //   country_iso3: "SYC",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/190/states",
    //       "#text": "/countries/190/states",
    //     },
    //   },
    // },
    // {
    //   id: "191",
    //   country: "Sierra Leone",
    //   country_iso2: "SL",
    //   country_iso3: "SLE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/191/states",
    //       "#text": "/countries/191/states",
    //     },
    //   },
    // },
    // {
    //   id: "192",
    //   country: "Singapore",
    //   country_iso2: "SG",
    //   country_iso3: "SGP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/192/states",
    //       "#text": "/countries/192/states",
    //     },
    //   },
    // },
    // {
    //   id: "193",
    //   country: "Slovakia",
    //   country_iso2: "SK",
    //   country_iso3: "SVK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/193/states",
    //       "#text": "/countries/193/states",
    //     },
    //   },
    // },
    // {
    //   id: "194",
    //   country: "Slovenia",
    //   country_iso2: "SI",
    //   country_iso3: "SVN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/194/states",
    //       "#text": "/countries/194/states",
    //     },
    //   },
    // },
    // {
    //   id: "195",
    //   country: "Solomon Islands",
    //   country_iso2: "SB",
    //   country_iso3: "SLB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/195/states",
    //       "#text": "/countries/195/states",
    //     },
    //   },
    // },
    // {
    //   id: "196",
    //   country: "Somalia",
    //   country_iso2: "SO",
    //   country_iso3: "SOM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/196/states",
    //       "#text": "/countries/196/states",
    //     },
    //   },
    // },
    // {
    //   id: "197",
    //   country: "South Africa",
    //   country_iso2: "ZA",
    //   country_iso3: "ZAF",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/197/states",
    //       "#text": "/countries/197/states",
    //     },
    //   },
    // },
    // {
    //   id: "198",
    //   country: "South Georgia and the South Sandwich Islands",
    //   country_iso2: "GS",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/198/states",
    //       "#text": "/countries/198/states",
    //     },
    //   },
    // },
    // {
    //   id: "199",
    //   country: "Spain",
    //   country_iso2: "ES",
    //   country_iso3: "ESP",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/199/states",
    //       "#text": "/countries/199/states",
    //     },
    //   },
    // },
    // {
    //   id: "200",
    //   country: "Sri Lanka",
    //   country_iso2: "LK",
    //   country_iso3: "LKA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/200/states",
    //       "#text": "/countries/200/states",
    //     },
    //   },
    // },
    // {
    //   id: "201",
    //   country: "Sudan",
    //   country_iso2: "SD",
    //   country_iso3: "SDN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/201/states",
    //       "#text": "/countries/201/states",
    //     },
    //   },
    // },
    // {
    //   id: "202",
    //   country: "Suriname",
    //   country_iso2: "SR",
    //   country_iso3: "SUR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/202/states",
    //       "#text": "/countries/202/states",
    //     },
    //   },
    // },
    // {
    //   id: "203",
    //   country: "Svalbard and Jan Mayen",
    //   country_iso2: "SJ",
    //   country_iso3: "SJM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/203/states",
    //       "#text": "/countries/203/states",
    //     },
    //   },
    // },
    // {
    //   id: "204",
    //   country: "Swaziland",
    //   country_iso2: "SZ",
    //   country_iso3: "SWZ",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/204/states",
    //       "#text": "/countries/204/states",
    //     },
    //   },
    // },
    // {
    //   id: "205",
    //   country: "Sweden",
    //   country_iso2: "SE",
    //   country_iso3: "SWE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/205/states",
    //       "#text": "/countries/205/states",
    //     },
    //   },
    // },
    // {
    //   id: "206",
    //   country: "Switzerland",
    //   country_iso2: "CH",
    //   country_iso3: "CHE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/206/states",
    //       "#text": "/countries/206/states",
    //     },
    //   },
    // },
    // {
    //   id: "208",
    //   country: "Taiwan",
    //   country_iso2: "TW",
    //   country_iso3: "TWN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/208/states",
    //       "#text": "/countries/208/states",
    //     },
    //   },
    // },
    // {
    //   id: "209",
    //   country: "Tajikistan",
    //   country_iso2: "TJ",
    //   country_iso3: "TJK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/209/states",
    //       "#text": "/countries/209/states",
    //     },
    //   },
    // },
    // {
    //   id: "210",
    //   country: "Tanzania, United Republic of",
    //   country_iso2: "TZ",
    //   country_iso3: "TZA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/210/states",
    //       "#text": "/countries/210/states",
    //     },
    //   },
    // },
    // {
    //   id: "211",
    //   country: "Thailand",
    //   country_iso2: "TH",
    //   country_iso3: "THA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/211/states",
    //       "#text": "/countries/211/states",
    //     },
    //   },
    // },
    // {
    //   id: "212",
    //   country: "Timor-Leste",
    //   country_iso2: "TL",
    //   country_iso3: "NUL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/212/states",
    //       "#text": "/countries/212/states",
    //     },
    //   },
    // },
    // {
    //   id: "213",
    //   country: "Togo",
    //   country_iso2: "TG",
    //   country_iso3: "TGO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/213/states",
    //       "#text": "/countries/213/states",
    //     },
    //   },
    // },
    // {
    //   id: "214",
    //   country: "Tokelau",
    //   country_iso2: "TK",
    //   country_iso3: "TKL",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/214/states",
    //       "#text": "/countries/214/states",
    //     },
    //   },
    // },
    // {
    //   id: "215",
    //   country: "Tonga",
    //   country_iso2: "TO",
    //   country_iso3: "TON",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/215/states",
    //       "#text": "/countries/215/states",
    //     },
    //   },
    // },
    // {
    //   id: "216",
    //   country: "Trinidad and Tobago",
    //   country_iso2: "TT",
    //   country_iso3: "TTO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/216/states",
    //       "#text": "/countries/216/states",
    //     },
    //   },
    // },
    // {
    //   id: "217",
    //   country: "Tunisia",
    //   country_iso2: "TN",
    //   country_iso3: "TUN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/217/states",
    //       "#text": "/countries/217/states",
    //     },
    //   },
    // },
    // {
    //   id: "218",
    //   country: "Turkey",
    //   country_iso2: "TR",
    //   country_iso3: "TUR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/218/states",
    //       "#text": "/countries/218/states",
    //     },
    //   },
    // },
    // {
    //   id: "219",
    //   country: "Turkmenistan",
    //   country_iso2: "TM",
    //   country_iso3: "TKM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/219/states",
    //       "#text": "/countries/219/states",
    //     },
    //   },
    // },
    // {
    //   id: "220",
    //   country: "Turks and Caicos Islands",
    //   country_iso2: "TC",
    //   country_iso3: "TCA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/220/states",
    //       "#text": "/countries/220/states",
    //     },
    //   },
    // },
    // {
    //   id: "221",
    //   country: "Tuvalu",
    //   country_iso2: "TV",
    //   country_iso3: "TUV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/221/states",
    //       "#text": "/countries/221/states",
    //     },
    //   },
    // },
    // {
    //   id: "222",
    //   country: "Uganda",
    //   country_iso2: "UG",
    //   country_iso3: "UGA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/222/states",
    //       "#text": "/countries/222/states",
    //     },
    //   },
    // },
    // {
    //   id: "223",
    //   country: "Ukraine",
    //   country_iso2: "UA",
    //   country_iso3: "UKR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/223/states",
    //       "#text": "/countries/223/states",
    //     },
    //   },
    // },
    // {
    //   id: "224",
    //   country: "United Arab Emirates",
    //   country_iso2: "AE",
    //   country_iso3: "ARE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/224/states",
    //       "#text": "/countries/224/states",
    //     },
    //   },
    // },
    // {
    //   id: "225",
    //   country: "United Kingdom",
    //   country_iso2: "GB",
    //   country_iso3: "GBR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/225/states",
    //       "#text": "/countries/225/states",
    //     },
    //   },
    // },
    // {
    //   id: "226",
    //   country: "United States",
    //   country_iso2: "US",
    //   country_iso3: "USA",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/226/states",
    //       "#text": "/countries/226/states",
    //     },
    //   },
    // },
    // {
    //   id: "227",
    //   country: "United States Minor Outlying Islands",
    //   country_iso2: "UM",
    //   country_iso3: "",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/227/states",
    //       "#text": "/countries/227/states",
    //     },
    //   },
    // },
    // {
    //   id: "228",
    //   country: "Uruguay",
    //   country_iso2: "UY",
    //   country_iso3: "URY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/228/states",
    //       "#text": "/countries/228/states",
    //     },
    //   },
    // },
    // {
    //   id: "229",
    //   country: "Uzbekistan",
    //   country_iso2: "UZ",
    //   country_iso3: "UZB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/229/states",
    //       "#text": "/countries/229/states",
    //     },
    //   },
    // },
    // {
    //   id: "230",
    //   country: "Vanuatu",
    //   country_iso2: "VU",
    //   country_iso3: "VUT",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/230/states",
    //       "#text": "/countries/230/states",
    //     },
    //   },
    // },
    // {
    //   id: "231",
    //   country: "Venezuela",
    //   country_iso2: "VE",
    //   country_iso3: "VEN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/231/states",
    //       "#text": "/countries/231/states",
    //     },
    //   },
    // },
    // {
    //   id: "232",
    //   country: "Viet Nam",
    //   country_iso2: "VN",
    //   country_iso3: "VNM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/232/states",
    //       "#text": "/countries/232/states",
    //     },
    //   },
    // },
    // {
    //   id: "233",
    //   country: "Virgin Islands, British",
    //   country_iso2: "VG",
    //   country_iso3: "VGB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/233/states",
    //       "#text": "/countries/233/states",
    //     },
    //   },
    // },
    // {
    //   id: "234",
    //   country: "Virgin Islands, U.S.",
    //   country_iso2: "VI",
    //   country_iso3: "VIR",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/234/states",
    //       "#text": "/countries/234/states",
    //     },
    //   },
    // },
    // {
    //   id: "235",
    //   country: "Wallis and Futuna",
    //   country_iso2: "WF",
    //   country_iso3: "WLF",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/235/states",
    //       "#text": "/countries/235/states",
    //     },
    //   },
    // },
    // {
    //   id: "236",
    //   country: "Western Sahara",
    //   country_iso2: "EH",
    //   country_iso3: "ESH",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/236/states",
    //       "#text": "/countries/236/states",
    //     },
    //   },
    // },
    // {
    //   id: "237",
    //   country: "Yemen",
    //   country_iso2: "YE",
    //   country_iso3: "YEM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/237/states",
    //       "#text": "/countries/237/states",
    //     },
    //   },
    // },
    // {
    //   id: "238",
    //   country: "Zambia",
    //   country_iso2: "ZM",
    //   country_iso3: "ZMB",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/238/states",
    //       "#text": "/countries/238/states",
    //     },
    //   },
    // },
    // {
    //   id: "239",
    //   country: "Zimbabwe",
    //   country_iso2: "ZW",
    //   country_iso3: "ZWE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/239/states",
    //       "#text": "/countries/239/states",
    //     },
    //   },
    // },
    // {
    //   id: "240",
    //   country: "Isle of Man",
    //   country_iso2: "IM",
    //   country_iso3: "IMN",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/240/states",
    //       "#text": "/countries/240/states",
    //     },
    //   },
    // },
    // {
    //   id: "241",
    //   country: "Jersey",
    //   country_iso2: "JE",
    //   country_iso3: "JEY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/241/states",
    //       "#text": "/countries/241/states",
    //     },
    //   },
    // },
    // {
    //   id: "242",
    //   country: "Guernsey",
    //   country_iso2: "GG",
    //   country_iso3: "GGY",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/242/states",
    //       "#text": "/countries/242/states",
    //     },
    //   },
    // },
    // {
    //   id: "243",
    //   country: "Lao People's Democratic Republic",
    //   country_iso2: "LA",
    //   country_iso3: "LAO",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/243/states",
    //       "#text": "/countries/243/states",
    //     },
    //   },
    // },
    // {
    //   id: "245",
    //   country: "Montenegro",
    //   country_iso2: "ME",
    //   country_iso3: "MNE",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/245/states",
    //       "#text": "/countries/245/states",
    //     },
    //   },
    // },
    // {
    //   id: "246",
    //   country: "Cote d'Ivoire",
    //   country_iso2: "CI",
    //   country_iso3: "CIV",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/246/states",
    //       "#text": "/countries/246/states",
    //     },
    //   },
    // },
    // {
    //   id: "247",
    //   country: "Bonaire, Sint Eustatius and Saba",
    //   country_iso2: "BQ",
    //   country_iso3: "BES",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/247/states",
    //       "#text": "/countries/247/states",
    //     },
    //   },
    // },
    // {
    //   id: "248",
    //   country: "Republic of Kosovo",
    //   country_iso2: "XK",
    //   country_iso3: "UNK",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/248/states",
    //       "#text": "/countries/248/states",
    //     },
    //   },
    // },
    // {
    //   id: "249",
    //   country: "Sint Maarten",
    //   country_iso2: "SX",
    //   country_iso3: "SXM",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/249/states",
    //       "#text": "/countries/249/states",
    //     },
    //   },
    // },
    // {
    //   id: "250",
    //   country: "Curaçao",
    //   country_iso2: "CW",
    //   country_iso3: "CUW",
    //   states: {
    //     link: {
    //       "@rel": "resource",
    //       "@href": "https://api.bigcommerce.com/stores/9va9hsw1lh/v2/countries/250/states",
    //       "#text": "/countries/250/states",
    //     },
    //   },
  },
];
