import React, { ReactNode, useEffect } from "react";
import { z } from "zod";
import { useQueryGet } from "./api/restApiQuery";
import { BrandConfig, BrandConfigSchema, BrandTheme, validateBrandConfigScheme } from "./api/schemeValidator";

type brandConfigcontext = {
    getByID: (id: string) => BrandConfigResult;
};

export type BrandConfigGroup = { [key: string]: BrandConfig };

type BrandConfigResult = {
    brandTheme?: BrandTheme;
    data?: BrandConfig;
};

const BrandConfigContext = React.createContext<brandConfigcontext | undefined>(undefined);

const requiredCreateAccountFallback = ["givenName", "familyName", "email"];

export const BrandConfigProvider = ({ children }: { children: ReactNode }) => {
    const { data, status } = useQueryGet<BrandConfigGroup>(["brand-configs"], "gateway", "/brand/public/configs");

    useEffect(() => {
        if (status === "success") validateBrandConfigScheme(z.record(BrandConfigSchema), data);
    }, [status]);

    const getByID = (id: string): BrandConfigResult => {
        if (status !== "success") return { data: undefined, brandTheme: undefined };

        const config = data[id] as BrandConfig;
        if (!config) {
            return {
                data: undefined,
                brandTheme: undefined,
            };
        }

        return {
            data: {
                ...config,
                requiredCreateAccount: config.requiredCreateAccount || requiredCreateAccountFallback,
            },
            brandTheme: config?.theme,
        };
    };

    return <BrandConfigContext.Provider value={{ getByID: getByID }}>{children}</BrandConfigContext.Provider>;
};

export const useBrandConfigProvider = () => {
    const context = React.useContext(BrandConfigContext);
    if (!context) throw new Error("You muse use useBrandConfig inside a BrandConfigProvider");
    return context;
};
