import { IconButton, Paper, Stack, Tooltip, useTheme } from "@mui/material";
import { Img } from "react-suspense-img";
import { Section, useCountdown } from "../../service/InAppNotificationService";
import { ReactNode, useEffect } from "react";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";

export default function InAppNotificationSection(props: Section & { children?: ReactNode }) {
    const queryClient = useQueryClient();
    useEffect(() => {
        const now = dayjs().unix();
        const difference = props.expires_at - now;

        const timeout = setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ["in-app-notifications"] });
        }, difference * 1000);

        return () => clearTimeout(timeout);
    }, []);

    return (
        <div>
            <SectionHeader section={props} />
            <Paper
                sx={{
                    p: 1,
                    mb: 2,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                    background: "#453F6F",
                }}
            >
                <Stack gap={1}>{props.children}</Stack>
            </Paper>
        </div>
    );
}

type SectionHeaderProps = {
    section: Section;
    primaryButton?: ReactNode;
};
export const SectionHeader = ({ section, primaryButton }: SectionHeaderProps) => {
    const { expires_at, image_url, section_name: name } = section;

    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const translatedTitle = `inAppNotifications.section.title.${name}`;

    return (
        <div
            style={{
                position: "relative",
                height: "150px",
            }}
        >
            <Img
                loading="lazy"
                alt=""
                src={image_url}
                height="150px"
                style={{
                    objectFit: "cover",
                    position: "absolute",
                    width: `calc(100% + ${theme.spacing(2)})`,
                    marginLeft: "-8px",
                    marginRight: "-8px",
                    height: "150px",
                    zIndex: -1,
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "8px",
                    paddingBottom: "12px",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        marginLeft: "auto",
                    }}
                >
                    <TimeCountdown expires_at={expires_at} section={name} />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "auto",
                        fontWeight: 700,
                        fontSize: "20px",
                        fontFamily: "Poppins",
                    }}
                >
                    {i18n.exists(translatedTitle) ? t(translatedTitle) : name}
                    {primaryButton}
                </div>
            </div>
        </div>
    );
};

const TimeCountdown = ({ expires_at, section }: { expires_at: number; section: string }) => {
    const { days, hours, minutes, seconds } = useCountdown(expires_at);
    const date = new Date(expires_at * 1000);

    const { i18n, t } = useTranslation();
    return (
        <div
            style={{
                display: "flex",
                gap: "4px",
                fontFamily: "Poppins",
                color: "#000000",
                alignItems: "center",
            }}
        >
            <TimeDisplay time={days} format={t("inAppNotifications.time.days")} />
            <TimeDisplay time={hours} format={t("inAppNotifications.time.hours")} />
            <TimeDisplay time={minutes} format={t("inAppNotifications.time.minutes")} />
            <TimeDisplay time={seconds} format={t("inAppNotifications.time.seconds")} />
            <Tooltip
                title={t("inAppNotifications.time.tooltip", {
                    date: date.toLocaleDateString(i18n.language, {
                        hourCycle: "h24",
                    }),
                    time: date.toLocaleTimeString(i18n.language, {
                        hourCycle: "h24",
                    }),
                    section: section,
                })}
                enterTouchDelay={0}
                componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: "#ffffff",
                            color: "#000000",
                            "& .MuiTooltip-tooltip": {
                                color: "#000000",
                            },
                            fontFamily: "Poppins",
                            maxWidth: "90vw",
                        },
                    },
                }}
            >
                <IconButton>
                    <FontAwesomeIcon
                        style={{
                            background: "#ffffff",
                            color: "black",
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            padding: "2px",
                            fontSize: "1px",
                        }}
                        icon={faQuestion}
                        size="xs"
                    />
                </IconButton>
            </Tooltip>
        </div>
    );
};

const TimeDisplay = ({ time, format }: { time: number; format: string }) => {
    return (
        <div
            style={{
                background: "rgba(255, 255, 255, 0.55)",
                backdropFilter: "blur(2px)",
                minWidth: "50px",
                height: "40px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "4px",
            }}
        >
            <div
                style={{
                    fontSize: "16px",
                    fontWeight: 600,
                }}
            >
                {time}
            </div>
            <div
                style={{
                    fontSize: "9px",
                    fontWeight: 400,
                }}
            >
                {format}
            </div>
        </div>
    );
};
