/**
 * Serivce for BSO API regarding parents, children events and BSO profile management
 */

import { useTheme } from "@mui/material";
import { type Event } from "react-big-calendar";
import { useTranslation } from "react-i18next";
import { UseMutationOptions, UseQueryOptions } from "react-query";
import { AppLanguage, normalizeLanguage } from "../ConfigTranslator";
import { useAuthProvider } from "../auth/AuthProvider";
import { areSameDate } from "../dateService";
import { FetchOptions, useQueryGet, useQueryPost, useQueryPut } from "./restApiQuery";

// mock data
let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 6);
let tomorrow1 = new Date();
tomorrow1.setDate(tomorrow.getDate() + 12);

// extend react-big-calendar interface
export interface IEvent extends Event {
    _id: string;
    entity?: BsoCalendarEvent;
    isAggregate?: boolean;
    aggregates?: IEvent[];
}

export type Bso = {
    id: string;
    name: string;
    school_ids: string[];
    telephone?: string;
    street?: string;
    number?: string;
    post_code?: string;
    city?: string;
};

type BsoSchool = {
    id: string;
    name: string;
    telephone?: string;
    street?: string;
    number?: string;
    post_code?: string;
    city?: string;
};

export type BsoChild = {
    id: string;
    school_id: string;
    first_name: string;
    bso_ids: string[];
};

export type BsoParent = {
    user_id: string;
    children: BsoChild[];
};

export type CreateBsoChildRequest = Omit<BsoChild, "id">;

export type CreateParentRequest = {
    user_id: string;
    children: CreateBsoChildRequest[];
};

export function useCreateBsoParent(options?: UseMutationOptions<CreateParentRequest, unknown, FetchOptions, unknown>) {
    return useQueryPost<CreateParentRequest>(["bso", "parent"], "gateway", "/bso/parent", options);
}
export function useUpdateBsoParent(options?: UseMutationOptions<CreateParentRequest, unknown, FetchOptions, unknown>) {
    return useQueryPut<CreateParentRequest>(["bso", "parent"], "gateway", "/bso/parent", options);
}

export function useGetBsoParent(options?: UseQueryOptions<BsoParent>) {
    return useQueryGet<BsoParent>(["bso", "parent"], "gateway", "/bso/parent", {
        retry: (failureCount, error: any) => {
            return error?.response?.status !== 404;
        },
        useErrorBoundary: false,
        ...options,
    });
}

export function isBsoFormValid(values: Partial<CreateParentRequest | BsoParent>): boolean {
    if (!values) return false;
    if (!values.children) return false;
    for (let i = 0; i < values.children.length; i++) {
        const child = values.children[i];

        if (
            !child.first_name ||
            child.first_name.trim() === "" ||
            !child.school_id ||
            child.school_id === "" ||
            child.bso_ids.length === 0
        )
            return false;
    }

    return true;
}

export function useGetBsoList(brandId?: string, options?: UseQueryOptions<Bso[]>) {
    const { authBody } = useAuthProvider();

    return useQueryGet<Bso[]>(["bso", "bsos"], "gateway", `/bso/bsos?brand_id=${brandId}`, {
        enabled: !!brandId && !!authBody?.accessToken,
        ...options,
    });
}

export function useGetSchoolList(bsoIds: string[], options?: UseQueryOptions<BsoSchool[]>) {
    const { authBody } = useAuthProvider();

    return useQueryGet<BsoSchool[]>(
        ["bso", "schools", bsoIds],
        "gateway",
        `/bso/bso/schools?bso_id=${bsoIds.join(",")}`,
        {
            enabled: bsoIds.length > 0 && !!authBody?.accessToken,
            ...options,
        },
    );
}

export type BsoCalendarEvent = {
    bso_id: string;
    school_id: string;
    children: string[];
    start_date: number;
    end_date: number;
    type: "study-day" | "trip" | "vacation"; // don't know these values are final

    allDay: boolean;

    title: string;
    description: string;
    location: string;
};

type BsoCalendarEventResponse = BsoCalendarEvent & {
    language: {
        en: {
            title: string;
            description: string;
        };
        nl: {
            title: string;
            description: string;
        };
    };
};

type CalendarEventResponse = Record<string, BsoCalendarEventResponse>;

export function useGetChildrenCalendarEvent(options?: UseQueryOptions<CalendarEventResponse, unknown, IEvent[]>) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");

    return useQueryGet<CalendarEventResponse, IEvent[]>(
        ["bso", "events", lang],
        "gateway",
        `/bso/calendar?lang=${lang}`,
        {
            select: (data) => {
                const sameDateList: BsoCalendarEventResponse[] = [];
                const resultList: IEvent[] = [];

                Object.keys(data).forEach((key) => {
                    const date = data[key];
                    if (areSameDate(date.start_date, data[key].end_date)) {
                        sameDateList.push(date);
                    } else {
                        resultList.push(convertBsoCalendarEventToIEvent(date, key, lang));
                    }
                });

                const groups = new Map<string, BsoCalendarEventResponse[]>();
                sameDateList.forEach((item) => {
                    const date = new Date(item.start_date * 1000);
                    const dateString = date.toISOString().split("T")[0];

                    // Check if this date is already a key in the groups map
                    if (!groups.has(dateString)) {
                        groups.set(dateString, []);
                    }

                    // Add the current item to the correct group
                    groups.get(dateString)?.push(item);
                });

                let index = 0;
                groups.forEach((value, key) => {
                    const aggregatedCalendarEvent: IEvent = {
                        ...convertBsoCalendarEventToIEvent(value[0], key, lang),
                        isAggregate: true,
                        aggregates: value.map((item) => {
                            return convertBsoCalendarEventToIEvent(item, key + "-" + index, lang);
                        }),
                    };

                    resultList.push(aggregatedCalendarEvent);
                    index++;
                });

                return resultList;
            },
            retry: (failureCount, error: any) => {
                return error?.response?.status !== 404;
            },
            refetchOnWindowFocus: false,
            useErrorBoundary: false,
            ...options,
        },
    );
}

export function useChildColorLegend() {
    const theme = useTheme();
    return (event?: BsoCalendarEvent) => {
        if (event?.bso_id && event?.bso_id !== "") return theme.palette.info.main;
        else if (event?.school_id && event?.school_id !== "") return theme.palette.tertiair.main;

        return theme.palette.tertiair.main;
    };
}

function convertBsoCalendarEventToIEvent(event: BsoCalendarEventResponse, id: string, lang: AppLanguage): IEvent {
    const languageEntity = lang === "en" ? event.language.en : event.language.nl;

    const parsedEntity: BsoCalendarEvent = {
        ...event,
        title: languageEntity.title,
        description: languageEntity.description,
    };

    return {
        _id: id,
        isAggregate: false,
        start: new Date(event.start_date * 1000),
        end: new Date(event.end_date * 1000),
        entity: parsedEntity,
        title: languageEntity.title,
    };
}

/**
 * Filters events to find those active on a given date.
 * @param date - The date to check events against.
 * @param events - An array of events to filter.
 * @returns An array of events active on the given date.
 */
export function getEventsForDate(date: Date, events: IEvent[]): IEvent[] {
    return events.filter((event) => {
        if (!event.start || !event.end) {
            return false;
        }

        // Create date objects for comparison that ignore time components
        const startOfDay = new Date(date.setHours(0, 0, 0, 0));
        const endOfDay = new Date(date.setHours(23, 59, 59, 999));
        const eventStartDay = new Date(event.start.setHours(0, 0, 0, 0));
        const eventEndDay = new Date(event.end.setHours(23, 59, 59, 999));

        // Check if the event's date range overlaps with the given date, ignoring time components
        return startOfDay <= eventEndDay && endOfDay >= eventStartDay;
    });
}

export type UsefulLink = {
    id: string;
    logo_url: string;
    title: string;
    description: string;
    link: string;
    highlighted: boolean;
};

export function useGetUsefulLinks(brandId: string, highlighted = false) {
    const { i18n } = useTranslation();
    const lang = normalizeLanguage(i18n.language, "nl");

    return useQueryGet<UsefulLink[]>(
        ["bso", "links", lang, highlighted],
        "gateway",
        `/bso/bso/links?lang=${lang}&highlighted=${highlighted}&brand_id=${brandId}`,
        { enabled: !!brandId, suspense: true },
    );
}
