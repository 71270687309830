import { faChevronRight, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import ClubBsoCalendarDrawer from "../../../pages/club/bso/calendarPage/components/ClubBsoCalendarDrawer";
import { useBrandConfigProvider } from "../../../service/BrandConfigProvider";
import { IEvent, useGetChildrenCalendarEvent } from "../../../service/api/bsoService";
import { formatUnixTimestampSimple } from "../../../service/dateService";
import { useTranslation } from "react-i18next";

function findClosestEvent(events: IEvent[]): IEvent | undefined {
    const now = new Date();

    let closestEvent: IEvent | undefined = undefined;
    let smallestDiff: number | undefined = undefined;

    events.forEach((event) => {
        if (!event.start) return;

        const eventDate = new Date(event.start);
        const diff = eventDate.getTime() - now.getTime();

        // Check if the event is in the future and if it's the closest one so far
        if (diff > 0 && (smallestDiff === undefined || diff < smallestDiff)) {
            smallestDiff = diff;
            closestEvent = event;
        }
    });

    if (!closestEvent) {
        smallestDiff = undefined;
        events.forEach((event) => {
            if (!event.end) return;

            const eventDate = new Date(event.end);
            const diff = eventDate.getTime() - now.getTime();

            // Check if the event is in the future and if it's the closest one so far
            if (diff > 0 && (smallestDiff === undefined || diff < smallestDiff)) {
                smallestDiff = diff;
                closestEvent = event;
            }
        });
    }
    return closestEvent;
}

type Props = {
    brandId: string;
    showCalendarDrawer?: boolean;
};
export default function BsoCalendarEventAlert({ brandId, showCalendarDrawer = true }: Props) {
    const { data: events, isLoading } = useGetChildrenCalendarEvent({
        suspense: true,
    });

    const [eventIsOpen, setEventIsOpen] = useState<boolean>(false);

    const { i18n } = useTranslation();
    const { brandTheme: theme } = useBrandConfigProvider().getByID(brandId);

    const closestCalendarEvent = useMemo(() => {
        if (events) return findClosestEvent(events);
    }, [isLoading, events]);

    if ((!isLoading && !closestCalendarEvent) || !theme) return null;

    const formattedStartDate = formatUnixTimestampSimple(closestCalendarEvent?.entity?.start_date ?? 0, i18n.language);

    return (
        <>
            <ButtonBase onClick={() => setEventIsOpen(true)} sx={{ flex: 1 }}>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: 1,
                        justifyContent: "space-around",
                        alignItems: "center",
                        borderRadius: 0.5,
                        bgcolor: theme.palette.tertiair,
                        color: theme.palette.tertiairTextColor,
                        py: 1.5,
                        px: 2,
                    }}
                >
                    <FontAwesomeIcon icon={faCircleExclamation} fontSize="24px" />
                    <Box
                        sx={{
                            flex: 1,
                            ml: 1,
                        }}
                    >
                        <Typography
                            variant="h7"
                            sx={{
                                color: theme.palette.tertiairTextColor,
                                fontWeight: 700,
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textAlign: "left",
                            }}
                        >
                            {`${formattedStartDate.day} ${formattedStartDate.month} - ${closestCalendarEvent?.entity?.title}`}
                        </Typography>
                        <Typography
                            variant="h8"
                            sx={{
                                color: "#FFC7EC",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textAlign: "left",
                            }}
                        >
                            {closestCalendarEvent?.entity?.location}
                        </Typography>
                    </Box>
                    <FontAwesomeIcon icon={faChevronRight} fontSize="18px" />
                </Box>
            </ButtonBase>

            {showCalendarDrawer && closestCalendarEvent?.entity && (
                <ClubBsoCalendarDrawer
                    open={eventIsOpen}
                    toggleOpen={() => setEventIsOpen(!eventIsOpen)}
                    events={[closestCalendarEvent.entity]}
                />
            )}
        </>
    );
}
