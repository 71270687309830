import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useArticlesByBrandId } from "../../../service/ClubService";

type BlogListProps = {
    brand_id: string;
    bsos: string;
};

export function BsoArticleList({ brand_id, bsos }: BlogListProps) {
    const navigate = useNavigate();
    const theme = useTheme();

    const { data: articles, status } = useArticlesByBrandId(bsos + `,${brand_id}`, {
        select: (data) => {
            return data ? data.sort((a, b) => b.created_at - a.created_at).slice(0, 3) : [];
        },
        suspense: true,
    });

    if (status !== "success" || !articles) {
        return <></>;
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 1,
                }}
            >
                <Typography
                    variant="h5"
                    style={{
                        fontWeight: 700,
                        letterSpacing: "normal",
                    }}
                >
                    {t("club.bso.home.articleHeader")}
                </Typography>

                {articles.map((article) => {
                    return (
                        <ButtonBase
                            key={article.id}
                            onClick={() => navigate(`/club/nieuws/${brand_id}/artikel/${article.id}`)}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    backgroundColor: "#FFF",
                                    width: "100%",
                                    alignItems: "center",
                                    overflow: "hidden",
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundImage: `url(${article.banner_image_url})`,
                                        width: "80px",
                                        height: "80px",
                                        aspectRatio: 1,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "9999px",
                                    }}
                                />
                                <Box
                                    sx={{
                                        color: "#000000",
                                        overflow: "hidden",
                                        px: 1.5,
                                        textAlign: "left",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        flexGrow: 1, // Add this line
                                    }}
                                >
                                    <Typography
                                        variant="h7"
                                        sx={{
                                            fontWeight: 400,
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {article.header}
                                    </Typography>

                                    <Typography
                                        variant="h8"
                                        sx={{
                                            fontWeight: 300,
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            color: "#000000",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        {article.sub_header}
                                    </Typography>
                                </Box>

                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    size="xs"
                                    style={{
                                        fontSize: "17px",
                                        fontWeight: 400,
                                        color: theme.palette.primary.main,
                                    }}
                                />
                            </Box>
                        </ButtonBase>
                    );
                })}
            </Box>
        </Box>
    );
}
