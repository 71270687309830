import { Box, Typography, Skeleton, useTheme } from "@mui/material";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { ReactNode, useMemo } from "react";
import { ImageWithFallback } from "../Image/ImageWithFallback";

type Props = {
    id: string;
    title: string;
    heroImgUrl?: string;
    showLogo?: boolean;
    gradient?: boolean;
    rightAdornment?: ReactNode;
    titleAndLogoOverride?: ReactNode;
    maxHeight?: string;
};

export default function BrandDetailHeader({
    title,
    heroImgUrl: _heroImgUrl,
    id,
    showLogo = true,
    gradient = true,
    rightAdornment,
    titleAndLogoOverride,
    maxHeight,
}: Props) {
    const theme = useTheme();

    const heroImgUrl = useMemo(() => {
        return _heroImgUrl;
    }, [_heroImgUrl]);

    return (
        <>
            <Box
                sx={{
                    margin: "0 auto",
                    minHeight: "185px",
                }}
            >
                <ImageWithFallback
                    src={heroImgUrl}
                    width="100%"
                    height="270px"
                    containerSx={{
                        maxHeight: maxHeight ?? "200px",
                        WebkitMaskImage: gradient ? "linear-gradient(to top, transparent, black 30%)" : "none",
                        maskImage: gradient ? "linear-gradient(to top, transparent, black 30%)" : "none",
                    }}
                    type="rectangular"
                />

                {titleAndLogoOverride ? (
                    titleAndLogoOverride
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            width: `calc(100vw - ${theme.spacing(4)})`,
                            alignItems: "center",
                            justifyContent: "space-between",
                            pb: gradient ? 0 : 4,
                            mx: 2,
                            mt: "-40px",
                        }}
                    >
                        {showLogo && <BrandIcon width="53px" sx={{ mr: 2 }} brandId={id} />}

                        {title ? (
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    zIndex: 1,
                                    lineHeight: "90%",
                                    fontSize: "max(6vw + 5px, 22px)",
                                    [theme.breakpoints.up("sm")]: {
                                        fontSize: "min(4vw, 2.25rem)",
                                    },
                                }}
                                variant="h2"
                            >
                                {title}
                            </Typography>
                        ) : (
                            <Skeleton width="60%" />
                        )}

                        {rightAdornment}
                    </Box>
                )}
            </Box>
        </>
    );
}
