import { Avatar, Box, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import FullScreenContainerCenter from "../../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../../components/Container/FullScreenRootContainer";
import PageAnalytics from "../../../components/Analytics/PageAnalytics";
import { TopNav } from "../../../components/nav/TopNavigation";
import { Numpad, NumpadClearCacheHandle } from "../../../components/Numpad/Numpad";
import { PinForm } from "../../../components/PinForm/PinForm";

import { useAuthProvider } from "../../../service/auth/AuthProvider";
import { useCapacitorProvider } from "../../../service/capacitor/CapacitorProvider";
import SecureStorageService from "../../../service/capacitor/SecureStorageService";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const blockWrongPincode = false;

type Props = {
    useBiometric: () => void;
};

export default function PincodeAuthenticationPage({ useBiometric }: Props) {
    const [wrongPincode, setWrongPincode] = useState(false);
    const [pincode, setPincode] = useState("");
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    const numPadRef = useRef<NumpadClearCacheHandle>(null);

    const { t } = useTranslation();
    const authProvider = useAuthProvider();
    const capacitorProvider = useCapacitorProvider();
    const { authBody, setAuthBioVerified } = authProvider;
    const { authPreferences } = capacitorProvider;
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        if (remainingSeconds > 0) {
            const timer = setInterval(() => {
                setRemainingSeconds(remainingSeconds - 1);

                if (remainingSeconds === 1) {
                    resetPincode();
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [remainingSeconds]);

    const handleNumpadChange = useCallback(function (newPincode: string) {
        setPincode(newPincode);
        wrongPincode && setWrongPincode(false);

        if (newPincode.length === 5) {
            SecureStorageService.verifyPincode(authProvider, newPincode).then(async (result) => {
                if (result.result === true) {
                    navigate(window.location.pathname + window.location.search || "/home", {
                        state: location.state,
                    });
                    setAuthBioVerified(true);
                } else {
                    setWrongPincode(true);
                }

                if (blockWrongPincode && result.blockedUntil) {
                    const unblockAfterSeconds = (result.blockedUntil.getTime() - Date.now()) / 1000;
                    setRemainingSeconds(Math.round(unblockAfterSeconds));
                } else {
                    setTimeout(() => {
                        resetPincode();
                    }, 1000);
                }
            });
        }
    }, []);

    function resetPincode() {
        setPincode("");
        setWrongPincode(false);
        // reset numpad internal cache
        numPadRef.current?.clearCache();
    }

    return (
        <FullScreenRootContainer
            sx={{
                [theme.breakpoints.down("sm")]: {
                    height: "100vh",
                },
            }}
        >
            <TopNav showBackButton={false} showLogoutButton height={"116px"} />
            <title>{t("pincodeAuthentication.tabTitle")}</title>
            <FullScreenContainerCenter maxWidth="sm">
                <Box
                    sx={{
                        textAlign: "center",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                    }}
                >
                    <Box>
                        <Avatar
                            sx={{
                                width: "6.25rem",
                                height: "6.25rem",
                                backgroundColor: theme.palette.card.light,
                                margin: "auto",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={solid("user")}
                                style={{ color: theme.palette.icon.main, height: "50px" }}
                            />
                        </Avatar>
                        <Box my={2}>
                            <Typography variant="h2" sx={{ fontSize: "20px" }}>
                                {authBody?.jwt.name}
                            </Typography>
                        </Box>
                    </Box>

                    <Card
                        sx={{
                            width: "100%",
                            boxShadow: "none",
                            padding: 2,
                        }}
                    >
                        <PinForm
                            pincode={pincode}
                            wrongPincode={wrongPincode}
                            sx={{ marginY: 2, marginX: 0 }}
                            emptyStyle={{
                                bgcolor:
                                    theme.palette.mode === "dark"
                                        ? theme.palette.card.dark
                                        : theme.palette.sandMedium.main,
                            }}
                        />
                        <Typography variant="subtitle1" color="neutral">
                            {remainingSeconds > 0
                                ? `${t("pincodeAuthentication.secondsLeft1")} ${remainingSeconds} ${t(
                                      "pincodeAuthentication.secondsLeft2",
                                  )}`
                                : t("pincodeAuthentication.confirmPincode")}
                        </Typography>
                    </Card>
                </Box>
            </FullScreenContainerCenter>
            <Box sx={{ width: "100%" }}>
                <Numpad
                    disabled={remainingSeconds > 0}
                    actionIsDisabled={!authPreferences?.bioEnabled}
                    handleChange={handleNumpadChange}
                    performAction={useBiometric}
                    ref={numPadRef}
                />
            </Box>
            <PageAnalytics />
        </FullScreenRootContainer>
    );
}
