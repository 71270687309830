import { ReactNode, createContext, useContext, useState } from "react";

interface BrandSearchContextInterface {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}

const BrandSearchContext = createContext<BrandSearchContextInterface | undefined>(undefined);

export function BrandSearchProvider({ children }: { children: ReactNode }) {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <BrandSearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
      }}
    >
      {children}
    </BrandSearchContext.Provider>
  );
}

export function useBrandSearchProvider() {
  const context = useContext(BrandSearchContext);

  if (!context) throw new Error("You must include inside BrandSearchProvider provider");

  return context;
}
