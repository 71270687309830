import { Box, CardActionArea, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { env } from "../../env.mjs";
import { GPFooter } from "../../gp-components/src";
import { BrandCategory, categories } from "../../service/api/categoryService";
import PageAnalytics from "../Analytics/PageAnalytics";
import ContentContainer from "../Container/ContentContainer";
import PageContainer from "../Container/PageContainer";
import RootContainer from "../Container/RootContainer";
import { SideNavigation } from "../nav/SideNavigation";
import { TopNav } from "../nav/TopNavigation";

export default function BrandCategoriesPage() {
    const { t } = useTranslation();

    return (
        <RootContainer>
            <TopNav showBackButton={true} loading={false} />
            <title>{t("category.tabTitle")}</title>
            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    <BrandCategoriesContent />
                </ContentContainer>
            </PageContainer>
            <PageAnalytics />
            <GPFooter />
        </RootContainer>
    );
}

export function BrandCategorySection() {
    const { t } = useTranslation();

    return (
        <div>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant={"h6"}>{t("category.lookingFor")}</Typography>
            </Box>

            <BrandCategories categories={categories.slice(0, 4)} />
        </div>
    );
}

export function BrandCategories({ categories }: { categories: BrandCategory[] }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    function handleOnClick(categoryId: string) {
        navigate(`/aangesloten-merken?category=${categoryId}`);
    }

    return (
        <Grid container mt={0} mb={2} spacing={2}>
            {categories.map((item) => {
                return (
                    <Grid item xs={6} key={item.Name}>
                        <CardActionArea
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                height: "118px",
                                p: 2,
                                borderRadius: "8px",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                overflow: "hidden",
                            }}
                            onClick={() => {
                                handleOnClick(item.Id);
                            }}
                        >
                            <img
                                src={`${env.cdnUrl}/category/${item.Id}_1_5x.webp`}
                                srcSet={`${env.cdnUrl}/category/${item.Id}_1_5x.webp 300w, ${env.cdnUrl}/category/${item.Id}_3x.webp 512w`}
                                sizes="(max-width: 600px) 250px, 500px"
                                width="100%"
                                height="100%"
                                style={{
                                    position: "absolute",
                                    zIndex: -1,
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }}
                            />
                            <Typography variant="h4">{t("category." + item.Name)}</Typography>
                        </CardActionArea>
                    </Grid>
                );
            })}
        </Grid>
    );
}

function BrandCategoriesContent() {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant={"h6"}>{t("category.lookingFor")}</Typography>
            </Box>

            <BrandCategories categories={categories} />
        </Box>
    );
}
