import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Button, Dialog, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface ErrorMessage {
    title: string;
}

interface Props {
    open: boolean;
    title?: string;
    description?: string;
    action: "home" | "retry";
}

export function ErrorDialog({ open, title, description, action }: Props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Dialog
            open={open}
            sx={{
                borderRadius: theme.shape.borderRadius,
            }}
            PaperProps={{
                style: {
                    padding: theme.spacing(2),
                },
            }}
            fullWidth
        >
            <Avatar
                sx={{
                    height: "6.25rem",
                    width: "6.25rem",
                    margin: theme.spacing(1) + " auto",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <FontAwesomeIcon
                    icon={solid("circle-exclamation")}
                    style={{
                        height: "64px",
                        color: theme.palette.primary.main,
                    }}
                />
            </Avatar>
            <Box my={2} sx={{ textAlign: "center" }}>
                <Typography variant="h3">{title || t("errorDialog.popupTitle")}</Typography>
                {description && <Typography>{description}</Typography>}
            </Box>
            {action === "home" && (
                <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    {t("errorDialog.actionHome")}
                </Button>
            )}
            {action === "retry" && (
                <Button
                    sx={{ width: "100%" }}
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    {t("errorDialog.actionRetry")}
                </Button>
            )}
        </Dialog>
    );
}
