import { Typography } from "@mui/material";
import i18next from "i18next";
import React, { ReactNode } from "react";
import { AnyButton } from "../../gp-components/src";
import { NoConnectionPage } from "../../pages/error/NoConnection";
import FullScreenRootContainer from "../Container/FullScreenRootContainer";
import SafeAreaContainer from "../Container/SafeAreaContainer";
import { BackButton } from "../nav/BackButton";

export class ErrorFallback extends React.Component<
    { children?: ReactNode; hasError?: boolean; isOnline?: boolean },
    any
> {
    constructor(props: { children?: ReactNode; hasError?: boolean; isOnline?: boolean }) {
        super(props);
        this.state = { hasError: false, isOnline: navigator.onLine };
    }

    componentDidMount(): void {
        window.addEventListener("offline", () => {
            this.setState({ isOnline: false });
        });
        window.addEventListener("online", () => this.setState({ isOnline: true }));
    }

    componentWillUnmount(): void {
        window.removeEventListener("offline", () => this.setState({ isOnline: false }));
        window.removeEventListener("online", () => this.setState({ isOnline: true }));
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    render() {
        const { children } = this.props;
        const { hasError, isOnline } = this.state;

        if (!isOnline) {
            return <NoConnectionPage />;
        }

        if (hasError)
            return (
                <SafeAreaContainer
                    sx={{
                        position: "relative",
                        ["@media (min-width:600px)"]: {
                            maxWidth: "none",
                        },
                    }}
                >
                    <FullScreenRootContainer sx={{ m: 2 }}>
                        <div
                            style={{
                                position: "absolute",
                                top: "8px",
                                left: "16px",
                            }}
                        >
                            {/* the page will go back in history and reload */}
                            <BackButton replace reload />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                height: "100%",
                                textAlign: "center",
                            }}
                        >
                            <Typography mb={1} variant="h1">
                                {i18next.t("errors.error")}
                            </Typography>
                            <Typography mb={2} variant="subtitle1">
                                {i18next.t("errors.message")}
                            </Typography>
                            <AnyButton anytype="green" onClick={() => window.location.reload()}>
                                {i18next.t("errors.refresh")}
                            </AnyButton>
                        </div>
                    </FullScreenRootContainer>
                </SafeAreaContainer>
            );
        return children;
    }
}

export default Error;
