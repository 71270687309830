import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Params, useNavigate, useParams } from "react-router-dom";
import { brandNavigationRouter } from "../components/Brand/ActionRouter";
import { normalizeLanguage } from "./ConfigTranslator";
import { fetchGet, fetchMutation } from "./api/restApiQuery";
import { Action } from "./api/schemeValidator";
import { useAuthProvider } from "./auth/AuthProvider";
import { isPollActive } from "./ClubService";

export const AddURLParams = (url: string, params: Readonly<Params<string>>) => {
    Object.keys(params).forEach((key) => {
        url = url.replace(":" + key, params[key] ?? "");
    });
    return url;
};

export const useAction = (brandId: string) => {
    const [toggleState, setToggleState] = useState<Record<string, boolean>>({});

    const navigate = useNavigate();

    const params = useParams();
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();

    const { i18n } = useTranslation();

    return {
        state: toggleState,
        getAction: (action: Action) => {
            if (action.type === "brand_action") {
                return () =>
                    new Promise(async (resolve, reject) => {
                        const res = await fetchMutation(
                            "post",
                            authProvider,
                            "gateway",
                            "/brand-action/action/" + action.key,
                            {
                                headers: {
                                    BrandId: brandId,
                                    "Target-Brand-ID": action.brand_id ?? "",
                                },
                            },
                        ).catch((err) => reject(err));

                        if (action.callback) action.callback();
                        brandNavigationRouter(res?.data.redirect_url, action.brand_id ?? brandId, navigate)
                            .then(() => resolve(""))
                            .catch((err) => reject(err));
                    });
            }

            if (action.type === "navigate") {
                const url = AddURLParams(action.url, params);
                return () => {
                    if (action.callback) action.callback();
                    navigate(url);
                };
            }

            if (action.type === "external-navigate") {
                return () => window.open(action.url, "_blank");
            }

            if (action.type === "follow") {
                // TODO: unfollow account
                return () => console.log("follow");
            }

            if (action.type === "toggle") {
                return () =>
                    setToggleState({
                        ...toggleState,
                        [action.key]: !toggleState[action.key],
                    });
            }

            if (action.type === "notification") {
                // TODO: toggle notifcications
                return () => console.log("notification");
            }

            if (action.type === "coupon") {
                return () => {
                    return new Promise(async (resolve, reject) => {
                        const res = await fetchGet(
                            authProvider,
                            "gateway",
                            "/coupon/coupon/byId?ids=" +
                                action.coupon_id +
                                "&lang=" +
                                normalizeLanguage(i18n.language, "nl"),
                            undefined,
                            {
                                headers: {
                                    BrandId: brandId,
                                },
                            },
                        ).catch((err) => reject(err));

                        if (!res) {
                            navigate("/gesloten?brand_id=" + action.brand_id);
                            return;
                        }

                        // set in the react-query cache, so we avoid to fetch it again
                        await queryClient.setQueryData(["coupon", action.coupon_id], res);

                        if (action.callback) action.callback();

                        navigate(action.url);
                    });
                };
            }

            if (action.type === "mvp_poll") {
                return () => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            // we don't use react query, so each time user clicks we get te latest data version
                            const res = await fetchGet(
                                authProvider,
                                "gateway",
                                "/sport/poll?id=" + action.poll_id,
                                undefined,
                                {
                                    headers: {
                                        BrandId: brandId,
                                    },
                                },
                            );

                            //todo: #2149 tech-debt: need to check if match is about to start AND show the event is about to start. Not that the event is expired
                            if (!res || !isPollActive(res)) {
                                navigate(`/gesloten?brand_id=${action.brand_id}&event_type=mvp_poll`);
                                return;
                            }

                            // set in the react-query cache, so we avoid to fetch it again
                            await queryClient.setQueryData(["club", "poll", action.poll_id], res);

                            if (action.callback) action.callback();

                            navigate(action.url);
                        } catch (e) {
                            navigate(`/gesloten?brand_id=${action.brand_id}&event_type=mvp_poll`);
                        }
                    });
                };
            }

            if (action.type === "poll") {
                return () => {
                    return new Promise(async (resolve, reject) => {
                        const res = await fetchGet(
                            authProvider,
                            "gateway",
                            "/sport/poll?id=" + action.poll_id,
                            undefined,
                            {
                                headers: {
                                    BrandId: brandId,
                                },
                            },
                        ).catch((err) => reject(err));

                        if (!res) {
                            navigate(`/gesloten?brand_id=${action.brand_id}&event_type=poll`);
                            return;
                        }
                        if (action.callback) action.callback();

                        navigate(action.url);
                    });
                };
            }

            if (action.type === "none") {
                return () => {};
            }

            action satisfies never;
            return () => console.error("action not found");
        },
    };
};
