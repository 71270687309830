import { createTheme } from "@mui/material";

export const anyPalette = {
  primary: "#025497",
  secondary: "#54AB9A",
  lightGray: "#ececec",
  green: "#54AB9A",
  orange: "#F7A34F",
  purple: "#7E65A3",
  lightBlue: "#497fb5",
  lightYellow: "#FFFBCC",
  lightRed: "#EC3323",
  darkYellow: "#c2bc76",
  darkRed: "#8b0000",
  darkGray: "#222323",
  sandDark: "#A7A39A",
  sandMedium: "#DDDBD7",
  sandLight: "#EFEDE8",
  background: "#efede8",
  softRed: "#FF7878",
};

export const gpTheme = createTheme({
  typography: {
    h1: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "24px",
    },
    h2: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
    },
    h3: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
    },
    h4: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
      fontWeight: "bold",
    },
    h5: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
    },
    h6: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      color: anyPalette.primary,
    },
    fontFamily: "Roboto",
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
        },
      },
    },
  },
  palette: {
    primary: {
      main: anyPalette.primary,
    },
    secondary: {
      main: anyPalette.secondary,
    },
  },
});

export const dtddPallette = {
  lightYellow: "#fff8e9",
  darkYellow: "#fcc00a",
  black: "#1d1e1c",
  lightRed: "#ea7a56",
  pink: "#fab4b4",
  lightGray: "#c1c1c1",
};

export const dtddTheme = createTheme({});
