import { AppUpdate } from "@capawesome/capacitor-app-update";
import { Card, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import NotConnectedCircle from "../../assets/img/illustrations/not-updated-circle.svg";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import DescriptionContainer from "../../components/Container/DescriptionContainer";
import FullScreenContainerCenter from "../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../components/Container/FullScreenRootContainer";
import TitleContainer from "../../components/Container/TitleContainer";
import { TopNav } from "../../components/nav/TopNavigation";
import { AnyButton } from "../../gp-components/src";

type Props = {};

export function NotUpdatedPage(props: Props) {
  const { t } = useTranslation();

  return (
    <FullScreenRootContainer>
      <title>{t("notUpdated.tabTitle")}</title>
      <TopNav showBackButton={false} />
      <FullScreenContainerCenter maxWidth="sm">
        <TitleContainer>
          <Typography variant="h1">{t("notUpdated.title")}</Typography>
        </TitleContainer>
        <DescriptionContainer>
          <Typography>{t("notUpdated.description")}</Typography>
        </DescriptionContainer>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            sx={{
              height: "112px",
              width: "112px",
              marginY: 3,
              marginX: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={NotConnectedCircle} width="100px" alt="not-connected-circle" />
          </Box>
          <AnyButton
            onClick={async () => {
              AppUpdate.openAppStore({ country: "NL" });
            }}
            sx={{ marginTop: 2, width: "100%" }}
            anytype="green"
          >
            <Typography variant="button1">{t("notUpdated.retryButton")}</Typography>
          </AnyButton>
        </Card>
      </FullScreenContainerCenter>
      <PageAnalytics />
    </FullScreenRootContainer>
  );
}
