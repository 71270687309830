import { Variants, AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

export const fadeVariant: Variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

export default function FadeContainer({ short = false, children }: { short?: boolean; children: ReactNode }) {
    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                variants={fadeVariant}
                initial="initial"
                animate="enter"
                exit="exit"
                transition={{ duration: short ? 0.2 : undefined }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
}
