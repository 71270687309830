import { Box, Card, CardActionArea, Dialog, Paper, Stack, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useClubProvider } from "../../service/ClubService";
import { wrapAroundIndex } from "../Brand/BrandCardMediaCarousel";
import { SeasonTicketType } from "../../service/api/CardService";
import { QRCodeCanvas } from "qrcode.react";
import { useBackground } from "../Container/MainContent";
import { BackButton } from "./BackButton";
import { faArrowUpRightAndArrowDownLeftFromCenter, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";

export default function ClubSeasonTicket() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <TicketPreview onClick={() => setOpen((prev) => !prev)} />
            <SeasonTicketSection open={open} handleOnClose={() => setOpen(false)} />
        </>
    );
}

function TicketPreview({ onClick }: JSX.IntrinsicElements["div"]) {
    const { brand_config, userCards } = useClubProvider();

    if (!userCards) return <></>;

    const firstTicketUlr = Object.keys(userCards[Object.keys(userCards)[0]])[0];

    return (
        <Card
            sx={{
                boxShadow: "0px 0px 9px 0px #FFF",
                background: `linear-gradient(90deg, ${brand_config.environment?.colors.secondary} 0%, ${brand_config.environment?.colors.primary} 100%)`,
            }}
            onClick={onClick}
        >
            <CardActionArea sx={{ display: "flex", alignItems: "center", gap: 2, p: 1, py: 2, pr: 2 }}>
                <FontAwesomeIcon icon={faArrowUpRightAndArrowDownLeftFromCenter} style={{ flex: 0 }} />
                <Header style={{ fontSize: "clamp(1em, 1.4em + 1vw, 24px)", textAlign: "center" }} />
                <QRCode value={firstTicketUlr} variant="preview" />
            </CardActionArea>
        </Card>
    );
}

type SeasonTicketSectionProps = {
    open: boolean;
    handleOnClose: () => void;
};

function SeasonTicketSection({ open, handleOnClose }: SeasonTicketSectionProps) {
    const { userCards } = useClubProvider();

    const { insets } = useCapacitorProvider();
    const theme = useTheme();

    if (!userCards) return <></>;

    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            sx={{ height: "100vh" }}
            PaperComponent={(PaperProps) => <div style={{ margin: 0 }} {...PaperProps} />}
        >
            <BackButton
                icon={faXmark}
                sx={{
                    position: "fixed",
                    top: insets?.top ? insets?.top + 1 + "px" : 0,
                    right: theme.spacing(2),
                    zIndex: "1",
                }}
                action={handleOnClose}
            />
            <SeasonTicketCarousel
                tickets={Object.keys(userCards).map((key) => {
                    return { ...userCards[key], wallet_uuid: "" };
                })}
            >
                {Object.keys(userCards).map((key) => {
                    return Object.keys(userCards[key]).map((ticketurl) => {
                        return (
                            <SeasonTicket key={key} ticketUrl={ticketurl} ticketMetadata={userCards[key][ticketurl]} />
                        );
                    });
                })}
            </SeasonTicketCarousel>
        </Dialog>
    );
}

const cardVariants = {
    enter: (props: { page: number; direction: number }) => {
        return {
            x: props.direction > 0 ? 10000 : -10000,
            opacity: 1,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (props: { page: number; direction: number }) => {
        return {
            zIndex: 0,
            x: props.direction < 0 ? 10000 : -10000,
            opacity: 1,
        };
    },
};

const swipeConfidenceThreshold = 1;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};

type SeasonTicketCarouselProps = {
    tickets: SeasonTicketType[];
    children: React.ReactNode;
};

function SeasonTicketCarousel({ children, tickets }: SeasonTicketCarouselProps) {
    const [[page, direction], setPage] = useState([0, 0]);

    const theme = useTheme();

    const paginate = (newDirection: number) => {
        const newPageIndex = wrapAroundIndex(page + newDirection, tickets.length);

        setPage([newPageIndex, newDirection]);
    };

    const swipeOffset = 100 / tickets.length;

    return (
        <>
            <div style={{ display: "flex", overflow: "hidden" }}>
                <motion.div
                    custom={{ page, direction }}
                    variants={cardVariants}
                    exit="exit"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 },
                    }}
                    drag={tickets.length > 1 ? "x" : undefined}
                    dragConstraints={{ left: 0, right: 0 }}
                    animate={{ x: -page * swipeOffset + "%" }}
                    dragElastic={1}
                    dragSnapToOrigin={false}
                    dragMomentum={false}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                    style={{
                        display: "flex",
                    }}
                >
                    {children}
                </motion.div>
            </div>

            {tickets.length > 1 && (
                <Stack mt={1.5} direction={"row"} width={"50%"} mx={"auto"} gap={1}>
                    {tickets.map((_, index) => {
                        return (
                            <span
                                key={index}
                                onClick={() => setPage([index, 0])}
                                style={{
                                    height: "10px",
                                    width: "100%",
                                    borderRadius: theme.spacing(1),
                                    transition: "all 0.5s ease",
                                    backgroundColor:
                                        index === page ? "rgba(255, 255, 255, 0.9)" : "rgba(255, 255, 255, 0.30)",
                                }}
                            />
                        );
                    })}
                </Stack>
            )}
        </>
    );
}

type SeasonTicketProps = {
    ticketMetadata?: SeasonTicketType;
    ticketUrl: string;
};

function SeasonTicket({ ticketMetadata, ticketUrl }: SeasonTicketProps) {
    const { brand_id, brand_config } = useClubProvider();
    const Gradient = useBackground(brand_id, { position: "absolute", top: "-120%", zIndex: 0 });

    const theme = useTheme();

    const seats = ticketMetadata?.seat?.split(",");

    const { t } = useTranslation();

    return (
        <div
            style={{
                width: `calc(100vw - ${theme.spacing(0)} * 2)`,
            }}
        >
            <Paper
                sx={{
                    position: "relative",
                    p: 3,
                    px: 2,
                    borderRadius: 1.5,
                    width: "90%",
                    mx: "auto",
                    height: "100%",
                    overflow: "hidden",
                    textAlign: "center",
                }}
            >
                {Gradient}
                <Stack
                    gap={2}
                    sx={{
                        position: "relative",
                        zIndex: 1,
                    }}
                >
                    <Header />

                    <Box px={4}>
                        <QRCode value={ticketUrl} />
                    </Box>

                    {/* detail metadata */}
                    {ticketMetadata?.owner && (
                        <Typography
                            sx={{
                                fontSize: "22.726px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                textTransform: "uppercase",
                            }}
                        >
                            {ticketMetadata.owner}
                        </Typography>
                    )}
                    {/* seaset etc. */}
                    {seats && (
                        <Stack direction={"row"} gap={1.5} justifyContent={"center"}>
                            <Typography>
                                {t("club.nav.seasonTicket.section")}{" "}
                                <span style={{ fontWeight: "700" }}>{seats[0]}</span>
                            </Typography>
                            <Typography>
                                {t("club.nav.seasonTicket.row")} <span style={{ fontWeight: "700" }}>{seats[1]}</span>
                            </Typography>
                            <Typography>
                                {t("club.nav.seasonTicket.seat")} <span style={{ fontWeight: "700" }}>{seats[2]}</span>
                            </Typography>
                        </Stack>
                    )}

                    <Typography
                        sx={{
                            opacity: 0.6,
                            userSelect: "auto",
                        }}
                    >
                        {ticketUrl}
                    </Typography>
                </Stack>
            </Paper>
        </div>
    );
}

function Header({ style }: JSX.IntrinsicElements["div"]) {
    const { brand_config } = useClubProvider();

    const { t } = useTranslation();

    return (
        <div
            style={{
                fontSize: "48px",
                fontFamily: brand_config.environment?.fonts.primary,
                lineHeight: "0.9em",
                ...style,
            }}
        >
            <div className="outline-text text-fade">{t("club.nav.seasonTicket.title").toUpperCase()}</div>
            <div>{t("club.nav.seasonTicket.title2").toUpperCase()}</div>
        </div>
    );
}

type QRCodeProps = {
    value: string;
    variant?: "preview" | "detailed";
};

function QRCode({ value, variant = "detailed" }: QRCodeProps) {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                p: variant === "preview" ? 0.5 : 1,
                mx: "auto",
                height: "fit-content",
                width: "clamp(150px, 220px, 100%)",

                backgroundColor: "#fff",
            }}
        >
            <QRCodeCanvas
                value={value}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                onClick={() => {}}
            />
            <div
                style={{
                    position: "absolute",
                    border: "1px solid #ffffff",
                    top: "10%",
                    bottom: "10%",
                    right: "-10%",
                    left: "-10%",
                    zIndex: -1,
                }}
            />
        </Box>
    );
}
