import { ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { getDynamicTheme } from "../../service/theme/AnyTheme";
import { useBackground } from "../Container/MainContent";
import { useTranslation } from "react-i18next";
import { CSSProperties, ReactNode, useEffect, useMemo } from "react";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";
import { colorIsDark } from "../../service/theme/themeService";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

/** Use this wrapper to apply Club environment theming which is a more aggressive theming than the ThemedPage wrapper */
function ClubThemedPage({ children, gradientStyle }: { children?: ReactNode; gradientStyle?: CSSProperties }) {
    const { brandId } = useParams<{ brandId: string }>();
    const { data: brand_config } = useBrandConfigProvider().getByID(brandId ?? "");

    const Gradient = useBackground(brandId, gradientStyle);
    const { i18n } = useTranslation();

    const clubTheme = useMemo(
        () => getDynamicTheme("dark", i18n, brandId, "club", brand_config),
        [brandId, brand_config, i18n],
    );

    // set StatusBar
    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            if (!colorIsDark(clubTheme.palette.background.default)) {
                StatusBar.setStyle({ style: Style.Light });
            }
        }
        return () => {
            if (Capacitor.isNativePlatform()) {
                // set back to anyID theme's style (which is dark mode by default)
                StatusBar.setStyle({ style: Style.Dark });
            }
        };
    }, [clubTheme]);

    if (!brand_config?.environment) return <></>;

    return (
        <ThemeProvider theme={clubTheme}>
            {Gradient}
            {children}
        </ThemeProvider>
    );
}

export default ClubThemedPage;
