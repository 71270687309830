import { Box, Paper, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Brand, ContentComponent, ContentComponentType } from "../../service/api/brandService";
import { Carousel } from "../Carousel/Carousel";
import { t } from "i18next";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { useEffect, useState } from "react";
import { BrandCardContent, BrandCardContentSkeleton, contentMediaHeight } from "./BrandCardContent";
import UniqueBrandCard from "./UniqueBrandCard";
import { useCouponByIds } from "../../service/api/couponService";
import BrandCardMediaCarousel from "./BrandCardMediaCarousel";
import { useArticlesByBrandId } from "../../service/ClubService";
import { BrandCardHeader } from "./BrandCardHeader";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";

export type DynamicContentComponentType = {
    commonAttributes: {
        name: string;
        image: string;
    };
    entity: unknown;
    contentComponent: ContentComponent;
};

type Props = {
    margin?: string;
};

export default function BrandCard(brand: Props & Brand) {
    const [dynamicContentComponent, setdynamicContentComponent] = useState<DynamicContentComponentType[]>([]);

    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);

    const isDesktop = useMediaQuery("(min-width:600px)");
    const theme = useTheme();
    const navigate = useNavigate();

    const { data: brandConfig } = useBrandConfigProvider().getByID(brand.id);

    const {
        data: couponsByContentComponents,
        isLoading: couponsByContentComponentsLoading,
        refetch: couponsByContentComponentsRefetch,
    } = useCouponByIds(
        brand.content_components?.filter((content) => content.type === "coupon").map((content) => content.content_id) ??
            [],
        {
            onSuccess: (data: any) => {
                if (data === null)
                    console.warn(
                        `The brand "${brand.name}" ${brand.id} doesn't have coupons for ids: ${brand.content_components
                            ?.map((i) => i.content_id)
                            .join(",")}`,
                    );
            },
            onError: (e: any) => {
                setAlertError({
                    id: "coupon",
                    message: t("couponDetail.error"),
                    code: e.response.status,
                    icon: "retry",
                    handleAction: couponsByContentComponentsRefetch,
                });
            },
        },
    );

    // TODO: #1610 get articles by content_components list (not currently used)
    const { data: brandArticles, isLoading: brandArticlesLoading } = useArticlesByBrandId(brand.id);

    const contentComponentEntitiesLoading = couponsByContentComponentsLoading || brandArticlesLoading;

    const hasContent = (): boolean => {
        if (contentComponentEntitiesLoading) return false;

        if (couponsByContentComponents && couponsByContentComponents?.length > 0) return true;

        if (brandArticles && brandArticles.length > 0) return true;

        return false;
    };

    useEffect(() => {
        if (contentComponentEntitiesLoading) return;
        let contentComponentWithEntities: DynamicContentComponentType[] = [];

        if (brand.content_components && couponsByContentComponents)
            contentComponentWithEntities = brand.content_components.map((content, index) => {
                const counponEntity = couponsByContentComponents.find((i: any) => i.id === content.content_id);

                return {
                    contentComponent: content,
                    commonAttributes: {
                        image: counponEntity?.image ?? "",
                        name: counponEntity?.name ?? "",
                    },
                    entity: counponEntity,
                };
            });

        // concat articles
        if (brandArticles) {
            contentComponentWithEntities = contentComponentWithEntities.concat(
                brandArticles.map((article: any) => {
                    return {
                        contentComponent: {
                            type: "article",
                            highlighted: false,
                            content_id: article.id,
                        },
                        commonAttributes: {
                            image: article?.banner_image_url ?? "",
                            name: article?.header ?? "",
                        },
                        entity: article,
                    };
                }),
            );
        }

        setdynamicContentComponent(contentComponentWithEntities);
    }, [couponsByContentComponentsLoading, brandArticlesLoading]);

    function handleClickContent(type: ContentComponentType, entityId: number | string) {
        if (type === "coupon") {
            navigate("/coupon/" + entityId);
            return;
        }
        if (type === "article") {
            navigate(`/nieuws/${brand.id}/artikel/${entityId}`);
            return;
        }
    }

    if (!brandConfig) {
        return <BrandCardSkeleton />;
    }

    // non webshop brands
    if (brandConfig.theme?.uniqueBrandCard) {
        return <UniqueBrandCard {...brand} margin={brand.margin} />;
    }

    if (contentComponentEntitiesLoading) {
        return <BrandCardSkeleton />;
    }

    return (
        // neutralize parent padding
        <Box mx={brand.margin ?? -2}>
            {alertError && (
                <Box m={brand.margin ?? 2} mb={2}>
                    <AnyAlert error={alertError} handleClose={setAlertError} />
                </Box>
            )}

            <Paper
                sx={{
                    position: "relative",
                    borderRadius: isDesktop ? "8px" : 0,
                    backgroundColor: brandConfig.theme?.palette.backgroundColor ?? theme.palette.primary.main,
                }}
            >
                <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 1 }}>
                    <BrandCardHeader {...brand} {...brandConfig} />
                </Box>

                {/* media */}
                <BrandCardMediaCarousel id={brand.id} />

                {hasContent() && (
                    <Box
                        sx={{
                            position: "relative",
                            height: `calc((${contentMediaHeight} + ${theme.spacing(2)}) + 30px )`,
                            mb: "60px",
                        }}
                    >
                        <Box
                            my={2}
                            sx={{
                                position: "absolute",
                                top: 0,
                                width: `calc(100% - ${theme.spacing(0)})`,
                            }}
                        >
                            <Carousel>
                                {contentComponentEntitiesLoading ? (
                                    <BrandCardContentSkeleton />
                                ) : (
                                    dynamicContentComponent
                                        ?.sort((a) => (a.contentComponent.highlighted ? -1 : 1))
                                        .map((contentComponent, index) => {
                                            if (contentComponent.entity !== undefined) {
                                                return (
                                                    <Box key={contentComponent.contentComponent.content_id}>
                                                        <BrandCardContent
                                                            brand_config={brandConfig}
                                                            content_component={contentComponent.contentComponent}
                                                            entity={contentComponent.entity}
                                                            title={contentComponent.commonAttributes.name}
                                                            imgUrl={contentComponent.commonAttributes.image}
                                                            handleClick={() =>
                                                                handleClickContent(
                                                                    contentComponent.contentComponent.type,
                                                                    contentComponent.contentComponent.content_id,
                                                                )
                                                            }
                                                            margin={
                                                                index === dynamicContentComponent.length - 1
                                                                    ? `0 ${theme.spacing(2)}`
                                                                    : undefined
                                                            }
                                                        />
                                                    </Box>
                                                );
                                            }
                                        })
                                )}
                            </Carousel>
                        </Box>
                    </Box>
                )}
            </Paper>
        </Box>
    );
}

export function BrandCardSkeleton() {
    return <Skeleton height={479} sx={{ transform: "none", transformOrigin: "0", mb: 2 }}></Skeleton>;
}
