import { Capacitor } from "@capacitor/core";
import axios, { AxiosRequestHeaders } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { env } from "../../env.mjs";
import { AuthContextInterface, useAuthProvider } from "../../service/auth/AuthProvider";
import { refreshToken } from "../auth/OAuthService";
import { FetchMutateResponse } from "./fetchService.js";
import { Semaphore } from "./Semaphore";
import { deepMergeObjects } from "../theme/themeService";

type Host = "gateway" | "keycloak";

export const defaultStaleTime = 1000 * 60 * 5; // 5 minutes

function getHost(host: Host) {
    if (host === "gateway") return env.gatewayUrl;
    if (host === "keycloak") return env.keycloakUrl;
    return "undefined";
}

export function useQueryGet<T, R = T>(
    key: any[],
    host: Host,
    path: string,
    options?: UseQueryOptions<T, unknown, R>,
    fetchOptions?: FetchOptions,
) {
    const authProvider = useAuthProvider();

    return useQuery<T, unknown, R>(key, () => fetchGet(authProvider, host, path, undefined, fetchOptions), options);
}

/**
 * If you're lucky enough, you may know enough about what your users will do to be able to prefetch the data they need before it's needed!
 * Note: prefetch doesn't use a `options` parameter, so if the cached data uses a `select` option, the useQuery hook will run using the cached data.
 * @returns the fetch promise. But doesn't make sense to wait for it
 */
export function usePrefetchQuery<T>(
    key: any[],
    host: Host,
    path: string,
    fetchOptions?: FetchOptions,
    options?: { enabled?: boolean },
) {
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();

    if (options?.enabled) {
        return queryClient.prefetchQuery({
            queryKey: key,
            queryFn: () => fetchGet(authProvider, host, path, undefined, fetchOptions),
        });
    } else return undefined;
}

export async function fetchGet(
    authProvider: AuthContextInterface,
    host: Host,
    path: string,
    newAccessToken?: string,
    options?: FetchOptions,
): Promise<any> {
    const { authBody, emptyCache } = authProvider;

    const headers = {
        "Content-Type": "application/json",
        ...options?.headers,
    } as AxiosRequestHeaders;

    if (!isPublicEndpoint(path) && authBody?.accessToken) {
        headers.Authorization = `Bearer ${newAccessToken || authBody.accessToken}`;
    }

    const res = await axios({
        method: "get",
        url: getHost(host) + path + (options?.queryParams || ""),
        headers,
    }).catch(async (error) => {
        return handleUnauthorizedError(path, error, authProvider, emptyCache, (accessToken) =>
            fetchGet(authProvider, host, path, accessToken),
        );
    });

    if (res.status === 204) return undefined;

    if (newAccessToken) {
        return res;
    }

    return res?.data;
}

export type FetchOptions = {
    queryParams?: string;
    body?: Object;
    headers?: AxiosRequestHeaders;
    context?: Object;
};

export function useQueryPut<T = FetchMutateResponse>(
    key: any[],
    host: Host,
    path: string,
    queryOptions?: UseMutationOptions<T, unknown, FetchOptions, unknown>,
    fetchOptions?: FetchOptions,
) {
    const authProvider = useAuthProvider();

    return useMutation(
        key,
        (_fetchOptions?: FetchOptions) =>
            fetchMutation("put", authProvider, host, path, deepMergeObjects(fetchOptions, _fetchOptions)),
        queryOptions,
    );
}

export function useQueryPost<T = FetchMutateResponse>(
    key: any[],
    host: Host,
    path: string,
    queryOptions?: UseMutationOptions<T, unknown, FetchOptions, unknown>,
    fetchOptions?: FetchOptions,
) {
    const authProvider = useAuthProvider();

    return useMutation(
        key,
        (_fetchOptions?: FetchOptions) =>
            fetchMutation<T>("post", authProvider, host, path, deepMergeObjects(fetchOptions, _fetchOptions)),
        queryOptions,
    );
}

export function useQueryDelete<T = FetchMutateResponse>(
    key: any[],
    host: Host,
    path: string,
    queryOptions?: UseMutationOptions<T, unknown, FetchOptions, unknown>,
    fetchOptions?: FetchOptions,
) {
    const authProvider = useAuthProvider();

    return useMutation(
        key,
        (_fetchOptions?: FetchOptions) =>
            fetchMutation("delete", authProvider, host, path, deepMergeObjects(fetchOptions, _fetchOptions)),
        queryOptions,
    );
}

export async function fetchMutation<T = any>(
    method: "post" | "put" | "delete",
    authProvider: AuthContextInterface,
    host: Host,
    path: string,
    options: FetchOptions,
    newAccessToken?: string,
): Promise<T> {
    const { authBody, emptyCache } = authProvider;

    const headers = {
        "Content-Type": "application/json",
        ...options.headers,
    } as AxiosRequestHeaders;

    if (!isPublicEndpoint(path) && authBody?.accessToken) {
        headers.Authorization = `Bearer ${newAccessToken || authBody.accessToken}`;
    }

    const res = await axios({
        method,
        url: getHost(host) + path + (options.queryParams || ""),
        headers,
        data: options.body,
    }).catch(async (error: Error) => {
        return handleUnauthorizedError(path, error, authProvider, emptyCache, (accessToken) =>
            fetchMutation(method, authProvider, host, path, options, accessToken),
        );
    });

    return res;
}

const tokenRefreshSemaphore = new Semaphore(1);

export async function handleUnauthorizedError(
    path: string,
    error: any,
    authProvider: AuthContextInterface,
    emptyCache: () => void,
    callbackFunction: (accessToken: string | undefined) => Promise<any>,
) {
    if (error.response.status !== 401 || isPublicEndpoint(path)) {
        return Promise.reject(error);
    }
    if (!Capacitor.isNativePlatform()) {
        emptyCache();
        return;
    }

    await tokenRefreshSemaphore.acquire();

    const newAuthBody = await refreshToken(authProvider)
        .catch(() => {
            emptyCache();
            return;
        })
        .finally(() => {
            tokenRefreshSemaphore.release();
        });

    if (newAuthBody && newAuthBody.accessToken) {
        return callbackFunction(newAuthBody?.accessToken);
    }
}

function isPublicEndpoint(path: string) {
    return path.includes("public");
}
