import { Capacitor } from "@capacitor/core";
import { Stack, Typography, useTheme } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { ReactNode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-suspense-img";
import ContentContainer from "../../components/Container/ContentContainer";
import PageContainer from "../../components/Container/PageContainer";
import RootContainer from "../../components/Container/RootContainer";
import InAppNotificationItem, {
    InAppNotificationItemEnvironmentType,
} from "../../components/InAppNotification/InAppNotificationItem";
import InAppNotificationOptionsButton from "../../components/InAppNotification/InAppNotificationOptionsButton";
import { SideNavigation } from "../../components/nav/SideNavigation";
import { TopNav } from "../../components/nav/TopNavigation";
import { env } from "../../env.mjs";
import { GPFooter } from "../../gp-components/src";
import { useInAppNotifications } from "../../service/InAppNotificationProvider";
import { InAppNotification, Section, useSetSeenInAppNotificationItems } from "../../service/InAppNotificationService";
import { useBrandMediaContentProvider } from "../../service/mediaContentService";
import BlackFridayFooter from "./../../assets/img/in-app-notification/BlackFridayFooter.webp";
import InAppNotificationSection from "./InAppNotificationSection";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";
import { normalizeLanguage } from "../../service/ConfigTranslator";
import { useQueryClient } from "react-query";

const InAppNotificationPage = () => {
    const { sections } = useInAppNotifications();

    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <RootContainer>
            <TopNav showBackButton={false} />
            <PageContainer sx={{ px: 1 }}>
                <title>{t("inAppNotifications.tabTitle")}</title>
                <SideNavigation />
                <ContentContainer>
                    <div
                        style={{
                            display: "flex",
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        <Typography variant="h3">{t("inAppNotifications.title")}</Typography>
                        {Capacitor.isNativePlatform() && <InAppNotificationOptionsButton />}
                    </div>
                    <InAppNotificationCenter sections={sections} />
                </ContentContainer>
            </PageContainer>
            <GPFooter />
        </RootContainer>
    );
};

export default InAppNotificationPage;

type InAppNotificationCenterProps = {
    sections: Section[];
    clickable?: boolean;
    noContentFallback?: ReactNode;
    environmentVariant?: InAppNotificationItemEnvironmentType;
};
export const InAppNotificationCenter = ({
    sections,
    clickable = true,
    noContentFallback,
    environmentVariant,
}: InAppNotificationCenterProps) => {
    const unseenNotifications = useMemo(
        () =>
            sections
                .map((x) => x.notifications)
                .flat()
                .filter((x) => x.status === "first_loaded")
                .map((x) => x.id),
        [sections],
    );

    const defaultSection = useMemo(() => sections.find((x) => x.section_id === "default"), [sections]);

    const { mutateAsync: seeIANs } = useSetSeenInAppNotificationItems(unseenNotifications);

    const noContentCondition =
        sections.map((x) => x.notifications).flat().length === 0 && defaultSection?.notifications.length === 0;

    const queryClient = useQueryClient();

    useEffect(() => {
        if (unseenNotifications?.length > 0) {
            seeIANs({}).then(() => {
                if (environmentVariant === "bso") {
                    queryClient.invalidateQueries({ queryKey: ["in-app-notifications"] });
                }
            });
        }
    }, [sections]);

    return (
        <div>
            {noContentCondition && noContentFallback ? (
                noContentFallback
            ) : (
                <>
                    {sections
                        .filter((x) => x.expires_at * 1000 > new Date().getTime())
                        .filter((x) => x.section_id !== "default")
                        .sort((a, b) => a.expires_at - b.expires_at)
                        .map((section, i) => {
                            return (
                                <div key={"section." + i}>
                                    <InAppNotificationSection {...section}>
                                        <SectionNotifications
                                            notifications={section.notifications}
                                            section={section.section_name}
                                            clickable={clickable}
                                        />
                                    </InAppNotificationSection>
                                </div>
                            );
                        })}
                    {defaultSection && (
                        <>
                            <Stack gap={1}>
                                <AnimatePresence>
                                    {defaultSection.notifications
                                        .filter((x) => x.status !== "deleted")
                                        .sort((a, b) => b.created_at - a.created_at)
                                        .map((notification) => {
                                            return (
                                                <InAppNotificationItem
                                                    key={notification.id}
                                                    notification={notification}
                                                    clickable={clickable}
                                                    environmentVariant={environmentVariant}
                                                />
                                            );
                                        })}
                                </AnimatePresence>
                            </Stack>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

const SectionNotifications = ({
    notifications,
    section,
    clickable,
    environmentVariant,
}: {
    notifications: InAppNotification[];
    section: string;
    clickable?: boolean;
    environmentVariant?: InAppNotificationItemEnvironmentType;
}) => {
    const notification_by_parent = useMemo(() => {
        const groupedData: Record<string, InAppNotification[]> = {};

        notifications.forEach((item) => {
            const parentId = item.parent_id || "default";
            if (!groupedData[parentId]) {
                groupedData[parentId] = [];
            }
            groupedData[parentId].push(item);
        });
        return groupedData;
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
            }}
        >
            {Object.keys(notification_by_parent)
                .filter((x) => x !== "default")
                .map((key) => {
                    return (
                        <BrandSection
                            brand_id={key}
                            notifications={notification_by_parent[key]}
                            key={key}
                            section={section}
                            clickable={clickable}
                        />
                    );
                })}

            {notification_by_parent["default"] && (
                <div>
                    <Stack gap={1}>
                        <AnimatePresence>
                            {notification_by_parent["default"]
                                ?.sort((a, b) => b.created_at - a.created_at)
                                .filter((x) => x.status !== "deleted")
                                .map((notification) => {
                                    return (
                                        <InAppNotificationItem
                                            key={notification.id}
                                            notification={notification}
                                            clickable={clickable}
                                            environmentVariant={environmentVariant}
                                        />
                                    );
                                })}
                        </AnimatePresence>
                    </Stack>

                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Img
                            src={BlackFridayFooter}
                            alt="black-friday-footer"
                            style={{
                                width: "45%",
                                marginLeft: "auto",
                                marginTop: "16px",
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const BrandSection = ({
    brand_id,
    notifications,
    section,
    clickable,
    environmentVariant,
}: {
    brand_id: string;
    notifications: InAppNotification[];
    section: string;
    clickable?: boolean;
    environmentVariant?: InAppNotificationItemEnvironmentType;
}) => {
    const { data: brand_config } = useBrandConfigProvider().getByID(brand_id);
    const { GetBrandMediaContent } = useBrandMediaContentProvider();
    const { i18n } = useTranslation();

    const media = GetBrandMediaContent({
        brandId: brand_id,
        directory: "logo",
    });

    return (
        <div
            style={{
                backgroundColor: brand_config?.theme?.palette.primary,
                borderRadius: "16px",
                padding: "8px",
                marginBottom: "30px",
            }}
        >
            <div
                style={{
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "end",
                        height: "100%",
                    }}
                >
                    <img
                        src={`${env.cdnUrl}/brand/other/${brand_id}/${section}_${normalizeLanguage(
                            i18n.language,
                            "nl",
                        )}.webp`}
                        style={{
                            height: "100%",
                            width: "100%",
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {media?.Url && (
                        <Img
                            src={media.Url}
                            style={{
                                objectFit: "contain",
                                height: "90px",
                            }}
                        />
                    )}
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <Stack gap={1}>
                    <AnimatePresence>
                        {notifications
                            .filter((x) => x.status !== "deleted")
                            .sort((a, b) => b.created_at - a.created_at)
                            .map((notification) => {
                                return (
                                    <InAppNotificationItem
                                        key={notification.id}
                                        notification={notification}
                                        clickable={clickable}
                                        environmentVariant={environmentVariant}
                                    />
                                );
                            })}
                    </AnimatePresence>
                </Stack>
            </div>
            <div
                style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <Img
                    src={`${env.cdnUrl}/brand/other/${brand_id}/SectionFooter.webp`}
                    style={{
                        height: "140px",
                        width: "auto",
                        marginBottom: "16px",
                    }}
                />
            </div>
        </div>
    );
};
