import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Skeleton, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Brand } from "../../service/api/brandService";
import VipIcon from "../../gp-components/icons/VipIcon";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { ReactNode } from "react";
import { useBrandConfigProvider } from "../../service/BrandConfigProvider";

const showVipIconFeatureFlag = false;

type BrandCardHeaderProps = {
    showBackground?: boolean;
    showArrowIcon?: boolean;
    pt?: number;
    mt?: number;
    rightAdornment?: ReactNode;
    clickable?: boolean;
    onClick?: () => void;
};

export function BrandCardHeader(brand: BrandCardHeaderProps & Brand) {
    const {
        showBackground = true,
        showArrowIcon = true,
        pt = 2,
        mt = 0,
        clickable = true,
        rightAdornment = undefined,
        onClick,
    } = brand;
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery("(min-width:600px)");

    const { data: brandConfig } = useBrandConfigProvider().getByID(brand.id);

    if (!brandConfig) return <></>;

    // TODO: #1661 create a navigation class to reuse in the app
    function navigateToBrand() {
        if (onClick) {
            onClick();
        } else if (clickable) {
            if (brandConfig?.environment) {
                navigate("/club/" + brand.id, { state: { from: brand.id } });
                return;
            }

            navigate(brand.exclusive ? "/member/" + brand.id : "/brand/" + brand.id);
        }
    }

    return (
        <Box
            p={2}
            pt={pt}
            mt={mt}
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
                borderRadius: showBackground && isDesktop ? "8px 8px 0 0" : 0,
                background: showBackground
                    ? "linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.60) 50%, rgba(0, 0, 0, 0.00) 100%)"
                    : "",
                cursor: clickable ? "pointer" : "default",
            }}
            onClick={navigateToBrand}
        >
            <BrandIcon brandId={brand.id} />

            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <Typography color="primary.contrastText" sx={{ fontSize: "1rem" }} variant="h4">
                        {brand.name}
                    </Typography>
                    {showArrowIcon && <FontAwesomeIcon icon={solid("chevron-right")} size="xs" />}
                    {showVipIconFeatureFlag && brand.visibility === "private" && brand.exclusive && (
                        <VipIcon width={37} fill={theme.palette.text.primary} />
                    )}
                    {rightAdornment}
                </Box>
                <Typography
                    variant="subtitle1"
                    color="primary.contrastText"
                    sx={{ fontSize: "12px", maxWidth: "30ch" }}
                >
                    {brand?.tags?.join(" | ").toLowerCase()}
                </Typography>
            </Box>
        </Box>
    );
}

export const BrandCardSkeleton = styled(Skeleton)({
    height: 85,
    transform: "none",
    transformOrigin: "0",
});
