export class Semaphore {
  count: number;
  isAcquired: boolean;

  constructor(count = 1) {
    this.count = count;
    this.isAcquired = false;
  }

  async acquire(): Promise<void> {
    if (this.count > 0 && !this.isAcquired) {
      this.count--;
      this.isAcquired = true;
    } else {
      throw new Error("Semaphore is acquired");
    }
  }

  release(): void {
    this.isAcquired = false;
    if (this.count < 1) {
      this.count++;
    }
  }
}
