import { useClubProvider } from "../../service/ClubService";
import ClubBsoHomePage from "./bso/ClubBsoHomePage";
import ClubSportHomePage from "./sport/ClubSportHomePage";

export const ClubHomePage = () => {
    const { brand_config } = useClubProvider();

    switch (brand_config.type) {
        case "sport":
            return <ClubSportHomePage />;
        case "bso":
            return <ClubBsoHomePage />;

        default:
            return <ClubSportHomePage />;
    }
};

export default ClubHomePage;
