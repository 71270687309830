import { Container, styled } from "@mui/material";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";

const SafeAreaContainer = styled(Container)(({ theme }) => {
    const { insets } = useCapacitorProvider();

    return {
        height: "100%",
        // mobile
        [theme.breakpoints.down("sm")]: {
            marginTop: insets?.top ? insets.top + "px" : theme.spacing(2),
            marginBottom: insets?.bottom ? insets.bottom + "px" : theme.spacing(2),
        },
        // desktop
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            maxWidth: theme.breakpoints.values.sm,
        },
    };
});

export default SafeAreaContainer;
