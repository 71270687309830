import { CardActionArea, CardActionAreaProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function SideButton(props: CardActionAreaProps) {
    const { children, ...otherProps } = props;
    const theme = useTheme();

    return (
        <CardActionArea
            {...otherProps}
            sx={{
                backgroundColor: theme.palette.card.main,
                ...otherProps.sx,
                borderRadius: 1,
                padding: "4px 8px",
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                color: theme.palette.text.tertiair,
            }}
        >
            {children}
        </CardActionArea>
    );
}
