import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import { env } from "../env.mjs";

const excludePages = ["/login-link", "/welkom"];

function isExcludedPage() {
  const currentPath = window.location.pathname;
  return excludePages.includes(currentPath) || excludePages.some((page) => currentPath.startsWith(page));
}

export function ScriptProvider() {
  const [cookiesAreShown, setCookiesAreShown] = useState(false);

  function createScript(src: string, type: string = "text/javascript", dataDomainScript?: string) {
    const script = document.createElement("script");
    script.src = src;
    script.type = type;
    dataDomainScript && script.setAttribute("data-domain-script", dataDomainScript);

    return script;
  }

  // function addCookieBannerScript() {
  //   //CookiePro Cookies Consent Notice start for mijn.any-id.nl
  //   const script1 = createScript(
  //     `https://cookie-cdn.cookiepro.com/consent/${env.cookieCode}/OtAutoBlock.js`,
  //     "text/javascript"
  //   );
  //   const script2 = createScript(
  //     "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js",
  //     "text/javascript",
  //     env.cookieCode
  //   );

  //   document.body.appendChild(script1);
  //   document.body.appendChild(script2);
  //   setCookiesAreShown(true);

  //   return () => {
  //     document.body.removeChild(script1);
  //     document.body.removeChild(script2);
  //   };
  // }

  useEffect(() => {
    if (
      cookiesAreShown === false &&
      !Capacitor.isNativePlatform() &&
      !["development", "release"].includes(env.devEnv) &&
      !isExcludedPage()
    ) {
      //addCookieBannerScript();
    }
  }, []);

  return <></>;
}
