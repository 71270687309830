import { useTranslation } from "react-i18next";
import { z } from "zod";

const Languages = ["en", "nl"] as const;

export type AppLanguage = (typeof Languages)[number];

export type TranslatableConfigText = Record<AppLanguage, string>;

//TODO: make this dynamic
export const TranslatableConfigTextSchema = z.object({
    en: z.string().min(0),
    nl: z.string().min(0),
});

//TODO: use this for the entire config
export const useConfigTranslator = () => {
    const { i18n } = useTranslation();

    return {
        translateConfig: (input: TranslatableConfigText) => {
            return input[normalizeLanguage(i18n.language, i18n.options.fallbackLng as AppLanguage)];
        },
    };
};

// catches edgecases of e.g. en-US and changes it to en
// and fallbacks into en
export const normalizeLanguage = (userLanguage: string, fallbackLanguage: AppLanguage) => {
    let language = userLanguage;
    let foundLanguage = false;

    Languages.forEach((l) => {
        if (userLanguage.startsWith(l)) {
            language = l;
            foundLanguage = true;
        }
    });

    return (foundLanguage ? language : fallbackLanguage) as AppLanguage;
};
