import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DynamicPopup } from "../../gp-components/src/components/Dialog/DynamicPopup";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Preferences } from "@capacitor/preferences";
import { useQuery, useQueryClient } from "react-query";
import { env } from "../../env.mjs";

type Step = {
    image: string;
    title: string;
    description: string;
};

const steps: Step[] = [
    {
        image: env.cdnUrl + "/anyid/introduction/0.png",
        title: "introduction.0.title",
        description: "introduction.0.description",
    },
    {
        image: env.cdnUrl + "/anyid/introduction/1.png",
        title: "introduction.1.title",
        description: "introduction.1.description",
    },
    {
        image: env.cdnUrl + "/anyid/introduction/2.png",
        title: "introduction.2.title",
        description: "introduction.2.description",
    },
    {
        image: env.cdnUrl + "/anyid/introduction/3.png",
        title: "introduction.3.title",
        description: "introduction.3.description",
    },
    {
        image: env.cdnUrl + "/anyid/introduction/4.png",
        title: "introduction.4.title",
        description: "introduction.4.description",
    },
];

export const WhatIsAnyIDIntroduction = ({ open }: { open: boolean }) => {
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const CloseDrawer = () => {
        Preferences.set({
            key: "seen_anyid_welcome_intro",
            value: "true",
        });
        queryClient.invalidateQueries({ queryKey: "seen_anyid_welcome_intro" });
    };

    const { data: seen_intro, isLoading } = useQuery({
        queryKey: ["seen_anyid_welcome_intro"],
        queryFn: () => Preferences.get({ key: "seen_anyid_welcome_intro" }),
    });

    const NextStep = () => {
        if (index === steps.length - 1) {
            CloseDrawer();
            return;
        }
        setIndex(index + 1);
    };

    const PreviousStep = () => {
        if (index === 0) {
            navigate(-1);
            return;
        }
        setIndex(index - 1);
    };

    return (
        <DynamicPopup
            open={!isLoading && open && seen_intro?.value !== "true"}
            handleClose={() => CloseDrawer()}
            onOpen={() => {}}
        >
            <div
                style={{
                    position: "absolute",
                    right: 16,
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                    fontFamily: "poppins",
                }}
                onClick={() => CloseDrawer()}
            >
                Skip
                <FontAwesomeIcon icon={faArrowRight} />
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    textAlign: "center",
                    maxHeight: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <img
                        src={steps[index].image}
                        style={{
                            marginTop: "20px",
                            height: "min(400px, 35vh)",
                            objectFit: "contain",
                        }}
                    />
                </div>

                <Typography
                    sx={{
                        fontWeight: "600",
                        fontSize: "20px",
                        fontFamily: "Poppins",
                    }}
                >
                    {t(steps[index].title)}
                </Typography>

                <Typography
                    sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        minHeight: "50px",
                    }}
                >
                    {t(steps[index].description)}
                </Typography>

                <div
                    style={{
                        display: "flex",
                        gap: "4px",
                        justifyContent: "center",
                    }}
                >
                    {steps.map((_, i) => {
                        return (
                            <StepIndicatorDot key={i} status={index === i ? "current" : i < index ? "old" : "new"} />
                        );
                    })}
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <Button
                        onClick={() => PreviousStep()}
                        sx={{
                            backgroundColor: "transparent",
                            border: "2px solid #ffffff",
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }}
                    >
                        Back
                    </Button>
                    <Button onClick={() => NextStep()} sx={{ width: "100%" }}>
                        Continue
                    </Button>
                </div>
            </div>
        </DynamicPopup>
    );
};

const StepIndicatorDot = ({ status }: { status: "old" | "current" | "new" }) => {
    const theme = useTheme();
    return (
        <div
            style={{
                height: "8px",
                width: "8px",
                borderRadius: "100%",
                backgroundColor:
                    status === "old" ? "#ffffff" : status === "current" ? theme.palette.rose.main : "#ffffff80",
            }}
        ></div>
    );
};
