import { Capacitor } from "@capacitor/core";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PageAnalytics from "../../../components/Analytics/PageAnalytics";
import FullScreenContainerCenter from "../../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../../components/Container/FullScreenRootContainer";
import TitleContainer from "../../../components/Container/TitleContainer";
import { TopNav } from "../../../components/nav/TopNavigation";
import { AnyButton } from "../../../gp-components/src";

import { FullScreenBottomSpacer } from "../../../components/Container/FullScreenBottomSpacer";
import { useAuthProvider } from "../../../service/auth/AuthProvider";
import { useCapacitorProvider } from "../../../service/capacitor/CapacitorProvider";
import { getBioTypeIcon, performBiometricVerification } from "../../../service/capacitor/CapacitorService";
import { anyPalette, anyPaletteFilter } from "../../../theme";

type Props = {
    usePincode: () => void;
};

export default function BioAuthenticationPage({ usePincode }: Props) {
    const { t } = useTranslation();
    const authProvider = useAuthProvider();
    const { setAuthBioVerified } = authProvider;
    const { bio, authPreferences } = useCapacitorProvider();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            loginWithBiometrics();
        }
    }, []);

    function loginWithBiometrics() {
        performBiometricVerification().then(async (verified) => {
            if (verified) {
                navigate(window.location.pathname + window.location.search || "/home", { state: location.state });
                setAuthBioVerified(true);
            }
        });
    }

    return (
        <FullScreenRootContainer>
            <title>{t("welcome.tabTitle")}</title>
            <TopNav showBackButton={false} showLogoutButton disableIconButtons />
            <FullScreenContainerCenter
                maxWidth="sm"
                sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
            >
                <TitleContainer>
                    <Typography variant="h1">
                        {authProvider.authBody?.jwt.firstName && t("setup.title") + authProvider.authBody.jwt.firstName}
                    </Typography>
                </TitleContainer>
                <Card
                    sx={{
                        padding: theme.spacing(2),
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            height: "112px",
                            margin: theme.spacing(2),
                            width: "112px",
                            borderRadius: "50%",
                            backgroundColor: anyPalette.sandLight,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Avatar
                            sx={{
                                height: "112px",
                                width: "112px",
                                backgroundColor:
                                    theme.palette.mode === "dark"
                                        ? theme.palette.card.light
                                        : theme.palette.sandLight.main,
                            }}
                        >
                            <img
                                alt="biometric type"
                                style={{
                                    filter:
                                        theme.palette.mode === "dark"
                                            ? anyPaletteFilter.darkMode
                                            : anyPaletteFilter.primary,
                                }}
                                width="64px"
                                src={getBioTypeIcon(bio?.type)}
                            />
                        </Avatar>
                    </Box>
                    <Box mt={2} width="100%">
                        <AnyButton sx={{ width: "100%" }} anytype="green" onClick={loginWithBiometrics}>
                            <Typography variant="button1">{t("bioAuthentication.bioButton")}</Typography>
                        </AnyButton>
                    </Box>
                </Card>

                {authPreferences?.pincodeSet && (
                    <AnyButton
                        onClick={usePincode}
                        sx={{ position: "absolute", width: "calc(100% - 32px)", bottom: "50px" }}
                        anytype="transparent"
                    >
                        <Typography variant="button1" color={theme.palette.text.primary}>
                            {t("bioAuthentication.loginWithPincode")}
                        </Typography>
                    </AnyButton>
                )}
            </FullScreenContainerCenter>
            <PageAnalytics />
            <FullScreenBottomSpacer />
        </FullScreenRootContainer>
    );
}
