import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// The container for the description beneath the title
const DescriptionContainer = styled(Box)(({ theme }) => {
  return {
    // desktop
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2),
    },
    // mobile
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  };
});

export default DescriptionContainer;
