import { useState } from "react";
import { useCapacitorProvider } from "../../../service/capacitor/CapacitorProvider";
import BioAuthenticationPage from "./BioAuthentication";
import PincodeAuthenticationPage from "./PincodeAuthentication";

type Props = {};

export function MobileAuthenticationPage(props: Props) {
  const [useBiometric, setUseBiometric] = useState(true);
  const { authPreferences } = useCapacitorProvider();

  if (useBiometric && authPreferences?.bioEnabled) {
    return <BioAuthenticationPage usePincode={() => setUseBiometric(false)} />;
  }

  return <PincodeAuthenticationPage useBiometric={() => setUseBiometric(true)} />;
}
