import axios, { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QueryCache, UseMutationOptions, UseMutationResult, UseQueryOptions, useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import SafeAreaContainer from "../components/Container/SafeAreaContainer";
import ClubBottomNav from "../components/nav/ClubBottomNav";
import ClubThemedPage from "../components/Theme/ClubThemedPage";
import { env } from "../env.mjs";
import { useBrandById, usePrefetchBrandById } from "./api/brandService";
import { useGetBsoParent } from "./api/bsoService";
import { useGetUserCard, UserCard } from "./api/CardService";
import { usePrefetchCouponsByBrandId } from "./api/couponService";
import { FetchOptions, usePrefetchQuery, useQueryGet, useQueryPost } from "./api/restApiQuery";
import { BrandConfig } from "./api/schemeValidator";
import { useBrandConfigProvider } from "./BrandConfigProvider";
import { useAuthProvider } from "./auth/AuthProvider";
import FontAwesomeIconWrapper from "./theme/fontAwesomeIconWrapper";

export type Article = {
    id: string;
    brand_id: string;
    created_at: number;
    updated_at: number;
    header: string;
    sub_header?: string;
    banner_image_url: string;
    sponsor_brand_id?: string;
};

export type ContentArticle = Article & {
    content: string;
};

type ClubContext = {
    brand_config: BrandConfig;
    brand_id: string;
    articles: Article[];
    userCards?: UserCard;
};

const clubContext = createContext<ClubContext | undefined>(undefined);
const style = { height: "150%" };

export const ClubProvider = ({ children }: { children?: ReactNode }) => {
    const { brandId: brand_id } = useParams();
    const { data: brand_config } = useBrandConfigProvider().getByID(brand_id!);

    const navigate = useNavigate();

    const {
        data: brandEntity,
        isLoading: brandLoading,
        isFetching: brandFetching,
    } = useBrandById(brand_id, {
        suspense: true,
    });

    const { data: articles } = useArticlesByBrandId(brand_id, {
        select: (data) => {
            return data ? data.sort((a, b) => b.created_at - a.created_at) : null;
        },
        suspense: true,
    });

    const { data: userCards, refetch: refetchUserCards } = useGetUserCard(brand_id, {
        enabled: brand_config?.type === "sport" || brand_config?.type === "club",
        onError(e: any) {
            if (e.response.status !== 404) {
                refetchUserCards();
            }
        },
    });

    const {
        data: bsoParent,
        isLoading: bsoParentLoading,
        status: bsoParentStatus,
    } = useGetBsoParent({
        enabled: brand_config?.type === "bso",
        suspense: brand_config?.type === "bso",
        staleTime: 30000, // dirty fix to refetch when query fails with error
        refetchOnWindowFocus: true,
    });

    // prefetch data for calendar, partners pages, which are often visited
    usePrefetchClubMatches(brand_id);
    usePrefetchBrandById(brand_config?.environment?.partner.brand_id);
    usePrefetchCouponsByBrandId(brand_id);

    useEffect(() => {
        if (brandLoading || brandFetching) return;

        if (!brandEntity?.connected) {
            // user is not connected to Club brand
            navigate("/aangesloten-merken");
        } else {
            // user is connected to BSO brand and tries to go to the bso env without parent object
            // so we resume the onboarding
            if (brand_config?.type === "bso" && bsoParentStatus !== "loading") {
                const bsoChildren = bsoParent?.children.filter((child) => !!child.id);

                if (bsoParentStatus === "error" || !bsoParent || bsoChildren?.length === 0) {
                    navigate(`/bso/mijn-gegevens/${brand_id}`, { replace: true });
                    return;
                } else {
                    // happy flow: user is going to BSO environment with parent object.
                    // preload lazy loaded pages to improve app responsiveness, namely the bottom nav pages
                    import("../pages/club/bso/ClubBsoHomePage");
                    import("../pages/club/bso/calendarPage/ClubBsoCalendarPage");
                    import("../pages/club/bso/BsoInAppNotificationPage");
                }
            }
        }
    }, [brandLoading, brandEntity, bsoParentLoading]);

    if (!brand_id) {
        navigate("/");
        return <></>;
    }

    return (
        <FontAwesomeIconWrapper>
            <clubContext.Provider
                value={{
                    brand_config: brand_config!,
                    brand_id: brand_id,
                    articles: articles ?? [],
                    userCards: userCards,
                }}
            >
                <SafeAreaContainer
                    sx={{
                        // bottom nav height
                        paddingBottom: "90px",
                    }}
                >
                    <ClubThemedPage gradientStyle={style}>
                        <div
                            style={{
                                fontFamily: brand_config?.environment?.fonts.primary,
                                color: brand_config?.environment?.colors.text,
                                userSelect: "none",
                            }}
                        >
                            {children}
                        </div>
                        <ClubBottomNav />
                    </ClubThemedPage>
                </SafeAreaContainer>
            </clubContext.Provider>
        </FontAwesomeIconWrapper>
    );
};

export const useClubProvider = () => {
    const context = useContext(clubContext);
    if (!context) {
        throw Error("must use useClubData in club context provider");
    }
    return context;
};

export const useArticle = (article_id?: string, options?: UseQueryOptions<ContentArticle, unknown, ContentArticle>) => {
    const { i18n } = useTranslation();

    return useQueryGet<ContentArticle>(
        ["article", article_id],
        "gateway",
        `/article/article?id=${article_id}&lang=${i18n.language.split("-")[0]}`,
        {
            enabled: !!article_id,
            ...options,
        },
    );
};

type ArticleResponse = Article[] | null;
export function useArticlesByBrandId(
    brandId?: string,
    options?: UseQueryOptions<ArticleResponse, unknown, ArticleResponse>,
) {
    const { i18n } = useTranslation();

    return useQueryGet<ArticleResponse>(
        ["article", brandId],
        "gateway",
        `/article/article/brand?id=${brandId}&lang=${i18n.language.split("-")[0]}`,
        options,
    );
}

type Partner = {
    brand_id: string;
    coupon_id: string;
    video_url: string;
    logo_url: string;
};

type Location = {
    name: string;
};

type MatchResult = {
    team_score: number;
    opponent_score: number;
};

export type MatchDisplay = {
    id: string;
    team_id: string;
    poll_id: string;
    date: number;
    ticket_shop_url: string;
    home: boolean;
    partner: Partner;
    location: Location;
    opponent: Team;
    match_result: MatchResult;
};

export function useClubMatches(brandId?: string, options?: UseQueryOptions<MatchDisplay[], unknown, MatchDisplay[]>) {
    return useQueryGet<MatchDisplay[]>(
        ["club", brandId, "matches"],
        "gateway",
        "/sport/sport/team/match?id=" + brandId,
        {
            enabled: !!brandId,
            ...options,
        },
    );
}

export function usePrefetchClubMatches(brandId?: string) {
    usePrefetchQuery(["club", brandId, "matches"], "gateway", "/sport/sport/team/match?id=" + brandId);
}

export function closestMatch(matches: MatchDisplay[]) {
    const currentTimeUnix = new Date().getTime() / 1000;

    const closestMatch = matches.reduce((closest: MatchDisplay | null, event: MatchDisplay) => {
        const eventTimeUnix = event.date;

        if (eventTimeUnix > currentTimeUnix && (!closest || eventTimeUnix < closest.date)) {
            return event;
        }

        return closest;
    }, null);
    return closestMatch ? closestMatch : matches[0];
}

export type Team = {
    id: string;
    name: string;
    logo_url: string;
};

export type TeamWithPlayers = {
    rival_id: string;
    players: Player[] | null;
} & Team;

export type Player = {
    id: string;
    team_id: string;
    mvp_count: number;
    name: string;
    number: string;
    portrait_url: string;
    role: string;
    birth_date: number;
};

export function useClubTeamById(teamId?: string, options?: UseQueryOptions<TeamWithPlayers, unknown, TeamWithPlayers>) {
    return useQueryGet<TeamWithPlayers>(["club", "team", teamId], "gateway", "/sport/sport/team?id=" + teamId, {
        enabled: !!teamId,
        ...options,
    });
}

export interface Poll {
    id: string;
    brand_id: string;
    start_date: number;
    // duration in hours
    duration: number;
    metadata: Metadata;
    active: boolean;
}

export type PollResult = {
    count: number;
    item_id: string;
    percentage: number;
};

export type PlayerItem = Pick<Player, "name" | "number" | "role" | "portrait_url">;

export interface Metadata {
    items: Record<string, PlayerItem>;
    styling: Styling;
    type: string;
    result?: PollResult[];
    voting_winner?: VotingWinner;
    banner_image_url?: string;
    user_voted: boolean;
}

export type VotingWinner = {
    family_name: string;
    given_name: string;
};

export interface Styling {}

export function useGetClosestPoll(brandId?: string, options?: UseQueryOptions<Poll[], unknown, Poll[]>) {
    return useQueryGet<Poll | null>(["club", "polls", brandId], "gateway", "/sport/poll/brand?id=" + brandId, {
        select: (data: unknown) => {
            const polls = data as Poll[];
            const currentTimeUnix = new Date().getTime() / 1000;

            // get closest poll by start_date
            const closestPoll = polls.reduce((closest: Poll | null, event: Poll) => {
                if (
                    !closest ||
                    Math.abs(event.start_date - currentTimeUnix) < Math.abs(closest.start_date - currentTimeUnix)
                ) {
                    return event;
                }
                return closest;
            }, null);

            if (!closestPoll?.active) return null;

            if (!isPollActive(closestPoll)) return null;

            return closestPoll;
        },
    });
}

// check if poll is active AND is between the start and closing time of the MVP poll
export function isPollActive(closestPoll: Poll): boolean {
    if (!closestPoll?.active) return false;

    const currentTimeUnix = Math.floor(new Date().getTime() / 1000);

    const finish_date = closestPoll?.start_date + (closestPoll?.duration ? closestPoll?.duration * 3600 : 0);

    return closestPoll?.start_date < currentTimeUnix && currentTimeUnix < finish_date;
}

// check if poll is active AND before the start time of the MVPpoll
export function isPollAboutToStart(closestPoll: Poll): boolean {
    if (!closestPoll?.active) return false;

    const currentTimeUnix = Math.floor(new Date().getTime() / 1000);

    const finish_date = closestPoll?.start_date + (closestPoll?.duration ? closestPoll?.duration * 3600 : 0);

    return currentTimeUnix < finish_date;
}

export function useGetPollById(pollId?: string, options?: UseQueryOptions<Poll, unknown, Poll>) {
    const { authBody } = useAuthProvider();
    return useQueryGet<Poll>(["club", "poll", pollId], "gateway", "/sport/poll?id=" + pollId, {
        enabled: !!pollId && !!authBody?.accessToken,
        ...options,
    });
}

export function useGetPublicPollById(pollId?: string, options?: UseQueryOptions<Poll, unknown, Poll>) {
    const queryKey = ["club", "public", "poll", pollId];
    const queryFn = () => axios.get(env.gatewayUrl + "/sport/public/onboarding?id=" + pollId).then((res) => res.data);
    return useQuery<Poll, unknown, Poll>(queryKey, queryFn, {
        enabled: !!pollId,
        ...options,
    });
}

export function useGetActivePollByBrandId(brand_id?: string, options?: UseQueryOptions<Poll, unknown, Poll>) {
    return useQueryGet<Poll>(["brand", brand_id], "gateway", "/sport/poll/brand?id=" + brand_id, {
        enabled: !!brand_id,
        ...options,
    });
}

export function usePollByBrandConfiguration(brand_config: BrandConfig, brand_id: string) {
    const { data: closestPoll } = useGetClosestPoll(brand_id, {
        enabled: !brand_config.environment?.poll?.currentActivePollId,
    });

    return useGetPollById(brand_config.environment?.poll?.currentActivePollId ?? closestPoll?.id);
}
// mvp-poll-image-banner-url-fallback
const MvpPollBannerImgUrlFallback = env.cdnUrl + "/quiz/mvp-poll-image-banner-url-fallback.jpg";
// used to show the poll in the homepage main article section
export function convertPollToArticle(poll: Poll, t: TFunction): Article {
    const hasPollStarted = () => {
        if (!poll?.active) return false;

        const currentTimeUnix = Math.floor(new Date().getTime() / 1000);

        return currentTimeUnix < poll?.start_date;
    };

    return {
        id: poll.id,
        banner_image_url:
            !poll.metadata?.banner_image_url || poll.metadata?.banner_image_url === ""
                ? MvpPollBannerImgUrlFallback
                : poll.metadata?.banner_image_url,
        header: hasPollStarted()
            ? t("club.mvp.mvpIsAboutToBegin").toString()
            : t("club.mvp.highlightHeader").toString(),
        created_at: poll.start_date,
        updated_at: poll.start_date,
        brand_id: "",
    };
}

export function useCastMvpVote(
    pollId?: string,
    itemId?: string,
    options?: UseMutationOptions<AxiosResponse<void>, unknown, FetchOptions, unknown>,
) {
    return useQueryPost(["club", "poll", pollId], "gateway", "/sport/poll?id=" + pollId, options, {
        body: {
            mvp: itemId,
        },
    });
}

export function useCastPollVote(
    pollId?: string,
    options?: UseMutationOptions<AxiosResponse<void>, unknown, FetchOptions, unknown>,
) {
    return useQueryPost(["club", "poll", pollId], "gateway", "/sport/poll?id=" + pollId, options);
}

export function useToggleUserPollSubmissionNativePlatformFlag(
    options?: UseMutationOptions<AxiosResponse<void>, Error, { pollId: string }>,
): UseMutationResult<AxiosResponse<void>, Error, { pollId: string }> {
    const authProvider = useAuthProvider();

    return useMutation<AxiosResponse<void>, Error, { pollId: string }>(async ({ pollId }) => {
        return await axios
            .post(
                env.gatewayUrl + "/sport/poll/toggle/native?id=" + pollId,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + authProvider.authBody?.accessToken,
                    },
                },
            )
            .then((res) => res.data);
    });
}

export default ClubProvider;
