import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Card, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageAnalytics from "../../../components/Analytics/PageAnalytics";
import FullScreenContainerCenter from "../../../components/Container/FullScreenContainerCenter";
import FullScreenRootContainer from "../../../components/Container/FullScreenRootContainer";
import { TopNav } from "../../../components/nav/TopNavigation";
import { AnyButton } from "../../../gp-components/src";
import { useAuthProvider } from "../../../service/auth/AuthProvider";
import { loginKeycloakNative } from "../../../service/auth/OAuthService";
import { FullScreenBottomSpacer } from "../../../components/Container/FullScreenBottomSpacer";
import { Preferences } from "@capacitor/preferences";
import { useState } from "react";
import { AlertError, AnyAlert } from "../../../gp-components/src/components/Alert/AnyAlert";

type Props = {};

export default function LoginPage(props: Props) {
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);

    const theme = useTheme();
    const authProvider = useAuthProvider();
    const { t } = useTranslation();
    const { setAuthBioVerified } = useAuthProvider();

    const navigate = useNavigate();

    async function authenticate(startPage: "login" | "register") {
        await loginKeycloakNative(authProvider, startPage)
            .then(async () => {
                setAuthBioVerified(true);

                const result = await Preferences.get({ key: "callbackRedirectPath" });

                if (result.value) {
                    await Preferences.remove({ key: "callbackRedirectPath" });
                    const targetPageUrl =
                        result.value && new URLSearchParams(result.value.split("?")[1]).get("target-url");
                    navigate(targetPageUrl || result.value || "/home");
                }
            })
            .catch((e) => {
                setAlertError({
                    id: "brand",
                    message: t("redirectIdp.errorMessage") + e,
                    code: 401,
                    icon: "retry",
                    handleAction: () => authenticate(startPage),
                });
            });
    }

    return (
        <FullScreenRootContainer>
            <title>{t("login.tabTitle")}</title>
            <TopNav showBackButton={false} disableIconButtons />
            <FullScreenContainerCenter
                maxWidth="sm"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Card
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box p={2} textAlign={"center"}>
                        <Box mt={3} mb={4}>
                            <Avatar
                                sx={{
                                    width: "6.25rem",
                                    height: "6.25rem",
                                    backgroundColor: theme.palette.card.light,
                                    margin: "auto",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={solid("user")}
                                    style={{ color: theme.palette.icon.main, height: "50px" }}
                                />
                            </Avatar>
                        </Box>
                        <Typography variant="h2" fontWeight={"600"} mb={3}>
                            {t("login.welcomeByAnyID")}
                        </Typography>
                        <Box mb={3}>
                            <AnyButton
                                anytype="green"
                                sx={{ width: "100%" }}
                                onClick={() => {
                                    authenticate("login");
                                }}
                            >
                                {t("login.loginButton")}
                            </AnyButton>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Typography variant="subtitle2" sx={{ marginRight: "4px" }}>
                                {t("login.noAccountYet")}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                display="inline"
                                sx={{
                                    color: theme.palette.text.primary,
                                    textDecorationColor: theme.palette.text.primary,
                                    textDecoration: "underline",
                                }}
                                onClick={() => {
                                    authenticate("register");
                                }}
                            >
                                {t("login.signUp")}
                            </Typography>
                        </Box>
                    </Box>
                </Card>

                {/* error box */}
                {alertError && (
                    <Box my={2}>
                        <AnyAlert error={alertError} handleClose={setAlertError} />
                    </Box>
                )}
            </FullScreenContainerCenter>
            <PageAnalytics />
            <FullScreenBottomSpacer />
        </FullScreenRootContainer>
    );
}
