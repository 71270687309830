import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ListItemIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type Props = {
    children: any;
    icon?: IconDefinition;
};

export function BulletList({ children, icon }: Props) {
    const theme = useTheme();

    return (
        <Box>
            {children?.map((item: JSX.Element, index: number) => {
                const lastIndex = children.length === index + 1;

                return (
                    <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", marginBottom: !lastIndex ? theme.spacing(1) : 0 }}
                    >
                        <ListItemIcon
                            key={index}
                            sx={{
                                alignItems: "center",
                                verticalAlign: "center",
                                minWidth: "auto",
                            }}
                        >
                            <FontAwesomeIcon
                                style={{
                                    paddingRight: theme.spacing(1),
                                    width: icon ? "16px" : "8px",
                                    color: theme.palette.secondary.main,
                                }}
                                icon={icon || solid("circle")}
                            />
                        </ListItemIcon>
                        {item}
                    </Box>
                );
            })}
        </Box>
    );
}
