import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonBase, CircularProgress, Typography, styled, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Suspense, lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { BrandIcon } from "../../gp-components/src/components/Brand/BrandIcon";
import { useAction } from "../../service/ActionProvider";
import { InAppNotification, useClickInAppNotificationItem } from "../../service/InAppNotificationService";
import { useBrandById } from "../../service/api/brandService";
import { Action } from "../../service/api/schemeValidator";
import { ImageWithFallback } from "../Image/ImageWithFallback";
import AnyIDIcon from "./../../../src/assets/img/icons/anyid-icon.png";
import "./InAppNotificationItem.css";
import { useParams } from "react-router-dom";

const LinkDataPopup = lazy(() => import("../../components/Brand/LinkDataPopup"));

const DELETE_BTN_WIDTH = 70;

const MESSAGE_DELETE_ANIMATION = { height: 0, opacity: 0 };
const MESSAGE_DELETE_TRANSITION = {
    opacity: {
        transition: {
            duration: 0,
        },
    },
};
export type InAppNotificationItemEnvironmentType = "anyid" | "bso";
type InAppNotificationItemProps = {
    notification: InAppNotification;
    clickable?: boolean;
    environmentVariant?: InAppNotificationItemEnvironmentType;
};
export default function InAppNotificationItem({
    notification,
    clickable,
    environmentVariant = "anyid",
}: InAppNotificationItemProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [linkAccountPopupOpen, setLinkAccountPopupOpen] = useState(false);

    const { brandId } = useParams();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const { getAction } = useAction(notification.brand_id);
    const { data: brand } = useBrandById(notification.brand_id, {
        enabled: notification.category === "mvp_poll",
    });
    const { mutate: clickInAppNotification } = useClickInAppNotificationItem(notification.id, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["in-app-notifications"] });
        },
    });

    const action = () => {
        setIsLoading(true);
        if (["seen", "first_loaded"].includes(notification.status)) {
            clickInAppNotification({
                body: {
                    in_app_notification_id: notification.id,
                },
            });
        }

        return getAction(
            getNotificationAction(notification, () => {
                setIsLoading(false);
            }),
        );
    };

    return (
        <>
            <motion.div
                key={notification.id}
                exit={MESSAGE_DELETE_ANIMATION}
                transition={MESSAGE_DELETE_TRANSITION}
                whileTap={{ scale: 0.98 }}
                style={{ backgroundColor: clickable ? "inherit" : "none" }}
            >
                <motion.div dragConstraints={{ left: 0, right: -DELETE_BTN_WIDTH }} className="msg-container">
                    <NotificationButton className="notification-button" disabled={isLoading}>
                        <Container
                            style={{
                                backgroundColor: theme.palette.inAppNotificationCard.main,
                            }}
                            onClick={() => {
                                if (!clickable) {
                                    return;
                                }

                                if (notification.category === "mvp_poll" && !brand?.connected) {
                                    setLinkAccountPopupOpen(true);
                                    return;
                                }

                                action()();
                            }}
                        >
                            <Header>
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "6px",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                                        {notification.variant === "brand" ? (
                                            <BrandIcon
                                                width="28px"
                                                brandId={environmentVariant === "bso" ? brandId : notification.brand_id}
                                            />
                                        ) : (
                                            <ImageWithFallback
                                                src={AnyIDIcon}
                                                containerSx={{
                                                    width: "28px",
                                                    height: "28px",
                                                }}
                                                alt="AnyID icon"
                                            />
                                        )}
                                        <BrandName variant="h8">{notification.brand_name}</BrandName>
                                    </div>

                                    <Time time={notification.created_at} />
                                </div>
                            </Header>
                            <Divider />
                            <Content>
                                <div>
                                    <Title variant="h7">{notification.title}</Title>
                                    <Description variant="h8">{notification.description}</Description>
                                </div>

                                {clickable ? (
                                    !isLoading ? (
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    ) : (
                                        <CircularProgress size={16} sx={{ color: "#000000" }} />
                                    )
                                ) : undefined}
                            </Content>
                        </Container>
                    </NotificationButton>
                </motion.div>
            </motion.div>

            {notification.category === "mvp_poll" && brand && !brand?.connected && (
                <Suspense fallback={null}>
                    <LinkDataPopup
                        open={linkAccountPopupOpen}
                        brand={brand}
                        handleCompleted={() => {
                            setLinkAccountPopupOpen(false);
                            action()();
                        }}
                        toggleOpen={() => {
                            setLinkAccountPopupOpen((prev) => !prev);
                        }}
                        handleMissingData={() => {
                            setLinkAccountPopupOpen(false);
                        }}
                    />
                </Suspense>
            )}
        </>
    );
}

const getNotificationAction = (notification: InAppNotification, onSuccess: () => void): Action => {
    switch (notification.category) {
        case "mvp_poll":
            return {
                type: "mvp_poll",
                url: notification.navigate_url,
                poll_id: notification.resource_id,
                brand_id: notification.brand_id,
                callback: onSuccess,
            };
        case "poll":
            return {
                type: "poll",
                url: notification.navigate_url,
                poll_id: notification.resource_id,
                brand_id: notification.brand_id,
                callback: onSuccess,
            };
        case "coupon":
            return {
                type: "coupon",
                url: notification.navigate_url,
                coupon_id: notification.resource_id,
                brand_id: notification.brand_id,
                callback: onSuccess,
            };
        case "brand_action":
            return {
                type: "brand_action",
                key: notification.resource_id,
            };
        case "article":
            return {
                type: "navigate",
                url: notification.navigate_url,
                callback: onSuccess,
            };
    }

    // catch all other categories like "navigate"
    return {
        type: "navigate",
        url: notification.navigate_url,
    };
};

const Time = ({ time }: { time: number }) => {
    const display_value = useRelativeTimeDisplay(time);

    return (
        <Typography
            variant="h9"
            style={{
                textAlign: "right",
                fontWeight: 500,
                opacity: 0.7,
            }}
        >
            {display_value}
        </Typography>
    );
};

function useRelativeTimeDisplay(created_at: number): string {
    const { t } = useTranslation();

    const now = new Date().getTime();
    const millis = now - created_at * 1000;

    // Less than 1 hour ago
    if (millis < 60 * 60 * 1000) {
        const minutes = Math.floor(millis / (60 * 1000));
        return `${
            minutes === 1
                ? t("inAppNotifications.time.display.minute", {
                      time: minutes,
                  })
                : t("inAppNotifications.time.display.minutes", {
                      time: minutes,
                  })
        }`;
    }

    // Less than 24 hours ago
    if (millis < 24 * 60 * 60 * 1000) {
        const hours = Math.floor(millis / (60 * 60 * 1000));
        return hours === 1
            ? t("inAppNotifications.time.display.hour", {
                  time: hours,
              })
            : t("inAppNotifications.time.display.hours", { time: hours });
    }

    // Less than 7 days ago
    if (millis < 7 * 24 * 60 * 60 * 1000) {
        const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const dayOfWeekIndex = new Date(created_at).getDay();
        return `${t("time.days." + daysOfWeek[dayOfWeekIndex])}`;
    }

    // More than 7 days ago (last week)
    const createdDate = new Date(created_at * 1000);
    const day = createdDate.getDate();
    const month = createdDate.getMonth();

    return `${day} ${t("time.months." + month)}`;
}

const NotificationButton = styled(ButtonBase)({
    width: "100%",
    borderRadius: "6px",
    backgeoundColor: "transparent !important",
    "& .MuiTouchRipple-child": {
        backgroundColor: "rgba(0, 0, 0, 0.50) !important",
    },
});

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    minHeight: "103px",
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.black.main,
    flex: 1,
}));

const BrandName = styled(Typography)({
    opacity: 0.7,
    fontWeight: 500,
    textTransform: "uppercase",
});

const Header = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
});

const Divider = styled(Box)(({ theme }) => ({
    width: `calc(100% + ${theme.spacing(3)})`,
    margin: theme.spacing("auto", -1.5),
    height: "1px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
}));

const Content = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "space-between",
}));

/** No rows restrictions since user has no way to read the entire content */
const Title = styled(Typography)({
    textAlign: "left",
    fontWeight: 500,
});
const Description = styled(Typography)({
    textAlign: "left",
    fontWeight: 300,
});
