import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import QR from "../assets/img/download_app_qr.png";
import ID from "../assets/img/icons/ID.png";
import Appstore from "../assets/img/storeButtons/appstore-en.svg";
import Playstore from "../assets/img/storeButtons/playstore-en.svg";
import ContentContainer from "../components/Container/ContentContainer";
import PageContainer from "../components/Container/PageContainer";
import RootContainer from "../components/Container/RootContainer";
import { SideNavigation } from "../components/nav/SideNavigation";
import { TopNav } from "../components/nav/TopNavigation";
import { getMobileOperatingSystem } from "../service/capacitor/CapacitorService";

export const DownloadAppPage = () => {
    const isDesktop = useMediaQuery("(min-width:600px)");

    return isDesktop ? <Desktop /> : <Mobile />;
};
const Desktop = () => {
    const { t } = useTranslation();

    return (
        <RootContainer>
            <TopNav showBackButton={false} />

            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#363264",
                                padding: "32px",
                                borderRadius: "16px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "32px",
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: "350px",
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "24px",
                                }}
                            >
                                {t("download.desktop")}
                            </div>

                            <img
                                src={QR}
                                style={{
                                    borderRadius: "8px",
                                    width: "350px",
                                }}
                            />
                        </div>
                    </div>
                </ContentContainer>
            </PageContainer>
        </RootContainer>
    );
};

const Mobile = () => {
    const deviceOS = getMobileOperatingSystem(window);
    const { t } = useTranslation();

    return (
        <div
            style={{
                overflow: "hidden",
                height: "calc(100vh - 60px)",
            }}
        >
            <TopNav showBackButton={false} hideHeader />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "calc(100% - 60px)",
                    gap: "24px",
                }}
            >
                <img src={ID} alt="logo" />
                <div
                    style={{
                        fontFamily: "Poppins",
                        fontSize: "24px",
                        fontWeight: 600,
                    }}
                >
                    {t("download.mobile")}
                </div>
                {deviceOS === "iOS" ? (
                    <img src={Appstore} alt="appstore" style={{ width: "200px" }} />
                ) : (
                    <img src={Playstore} alt="playstore" style={{ width: "200px" }} />
                )}
            </div>
        </div>
    );
};
