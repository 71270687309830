import { Box } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import FadeContainer from "./FadeContainer";
import { ReactNode } from "react";
import { Capacitor } from "@capacitor/core";
import { useCapacitorProvider } from "../../service/capacitor/CapacitorProvider";

// helps with the navigation margins

const _RootContainer = styled(Box)(({ theme }) => ({
    // mobile
    [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(8),
    },
    // desktop
    [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
    },
}));

export default function ({ children, sx }: { children: ReactNode; sx?: SxProps }) {
    const { insets } = useCapacitorProvider();

    return (
        <FadeContainer short>
            <_RootContainer
                sx={{
                    paddingBottom: insets?.bottom && Capacitor.getPlatform() === "ios" ? 1 : 0,
                    ...sx,
                }}
            >
                {children}
            </_RootContainer>
        </FadeContainer>
    );
}
