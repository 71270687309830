import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageAnalytics from "../../components/Analytics/PageAnalytics";
import ContentContainer from "../../components/Container/ContentContainer";
import PageContainer from "../../components/Container/PageContainer";
import RootContainer from "../../components/Container/RootContainer";
import TitleContainer from "../../components/Container/TitleContainer";
import { BackButton } from "../../components/nav/BackButton";
import { SideNavigation } from "../../components/nav/SideNavigation";
import { TopNav } from "../../components/nav/TopNavigation";
import { Desktop, DynamicForm, GPFooter } from "../../gp-components/src";
import { AlertError, AnyAlert } from "../../gp-components/src/components/Alert/AnyAlert";
import { FormItem } from "../../gp-components/src/components/Form/Dynamic/DynamicForm";
import { logEvent } from "../../service/AnalyticsService";
import { useQueryGet, useQueryPut } from "../../service/api/restApiQuery";
import { useAuthProvider } from "../../service/auth/AuthProvider";
import { getUpdatedJwt as updateTokenContent } from "../../service/auth/AuthStorageService";
import { countries } from "../../service/countryService";
import { nameIsCorrect, phoneNumberIsCorrect } from "../../service/formService";

export const updateUserForm: FormItem[] = [
    {
        title: "profileInfo.givenName",
        name: "given_name",
        validator: nameIsCorrect,
        required: true,
        autoComplete: "given-name",
    },
    {
        title: "profileInfo.familyName",
        name: "family_name",
        validator: nameIsCorrect,
        required: true,
        autoComplete: "family-name",
    },
    {
        title: "profileInfo.email",
        name: "email",
        readOnly: true,
        validator: (value) =>
            value?.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ),
        required: true,
        type: "email",
    },
    {
        title: "profileInfo.birthDate",
        type: "date",
        name: "birth_date",
        maxDate: new Date(),
    },
    {
        title: "profileInfo.telephone",
        name: "telephone",
        validator: phoneNumberIsCorrect,
        autoComplete: "tel",
    },
    {
        title: "profileInfo.company",
        name: "company",
        autoComplete: "organization",
    },
    {
        title: "profileInfo.postalCode",
        name: "postal_code1",
        validator: (value) => value?.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i),
        required: true,
        autoComplete: "postal-code",
    },
    {
        title: "profileInfo.houseNumber",
        name: "house_number1",
        required: true,
        autoComplete: "address-line1",
    },
    {
        title: "profileInfo.street",
        name: "street1",
        required: true,
        autoComplete: "address-line2",
    },

    {
        title: "profileInfo.city",
        name: "city1",
        validator: (value) => value.length >= 0 && value.length <= 100,
        required: true,
        autoComplete: "address-level2",
    },
    {
        title: "profileInfo.countryCode",
        name: "country_code1",
        type: "select",
        options: countries.map((c) => ({
            value: c.country_iso2,
            label: c.country,
        })),
        defaultValue: "NL",
        autoComplete: "country",
        required: true,
    },
];

export default function MyDataEditPage() {
    const [formErrorMessage, setFormErrorMessage] = useState("");
    const [alertError, setAlertError] = useState<AlertError | undefined>(undefined);

    const navigate = useNavigate();
    const authProvider = useAuthProvider();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const theme = useTheme();

    const {
        isLoading: profileLoading,
        data: myData,
        refetch: profileRefetch,
    } = useQueryGet(["profile"], "gateway", "/vault/user/profile", {
        onError: (e: any) => {
            setAlertError({
                id: "profile",
                message: t("errors.getDataFailed"),
                code: e.response.code,
                icon: "retry",
                handleAction: profileRefetch,
            });
        },
    });

    const { mutate: updateUser, isLoading: requestLoading } = useQueryPut(
        ["profile"],
        "gateway",
        "/vault/user/profile",
        {
            onSuccess: (result, data) => {
                const newData = data.body as { [key: string]: string };
                authProvider.setAuthBody(
                    updateTokenContent(authProvider, {
                        firstName: newData.given_name,
                        name: newData.given_name + " " + newData.family_name,
                    }),
                );

                queryClient.invalidateQueries({ queryKey: ["profile"] });

                logEvent("edit_data");

                const redirectUri = searchParams.get("redirect_uri");
                if (redirectUri !== null) window.location.replace(redirectUri);
                else {
                    navigate(-1);
                }
            },
            onError: () => {
                setFormErrorMessage(t("errors.updateDataFailed"));
            },
        },
    );

    return (
        <RootContainer>
            <TopNav loading={profileLoading} />
            <title>{t("editData.tabTitle")}</title>
            <PageContainer>
                <SideNavigation />
                <ContentContainer>
                    <TitleContainer>
                        <Typography variant="h1">{t("editData.title")}</Typography>
                    </TitleContainer>
                    <Desktop sx={{ display: "flex", width: "auto", paddingBottom: "5px" }}>
                        <BackButton />
                    </Desktop>
                    {alertError && (
                        <Box mb={2}>
                            <AnyAlert error={alertError} handleClose={setAlertError} />
                        </Box>
                    )}
                    {!alertError && (
                        <Box
                            borderRadius={1}
                            sx={{
                                padding: 2,
                                paddingTop: 0.5,
                                backgroundColor: theme.palette.card.main,
                                marginBottom: 1,
                            }}
                        >
                            <DynamicForm
                                items={updateUserForm}
                                primaryButtonProps={{
                                    text: t("editData.saveButton"),
                                }}
                                handleSubmit={(data) => {
                                    updateUser({ body: data });
                                }}
                                errorMessage={formErrorMessage}
                                formValues={myData}
                                loading={profileLoading}
                                requestLoading={requestLoading}
                            />
                        </Box>
                    )}
                </ContentContainer>
            </PageContainer>
            <GPFooter />
            <PageAnalytics />
        </RootContainer>
    );
}
