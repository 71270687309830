import { Button, SxProps } from "@mui/material";
import { useClubProvider } from "../../service/ClubService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

type BackButtonProps = {
    icon?: IconDefinition;
    sx?: SxProps;
    action?: () => void;
};

export const BackButton = ({ icon, sx, action }: BackButtonProps) => {
    const theme = useTheme();
    const { brand_config } = useClubProvider();
    const navigate = useNavigate();

    return (
        <Button
            sx={{
                backgroundColor: brand_config.environment?.colors.text,
                margin: theme.spacing(2, 0),
                width: "40px",
                height: "40px",
                minWidth: "unset",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 0,
                ...sx,
            }}
            onClick={() => {
                if (action) {
                    action();
                    return;
                }
                navigate(-1);
            }}
        >
            <FontAwesomeIcon
                icon={icon ?? faAngleLeft}
                style={{
                    color: brand_config.environment?.colors.primary,
                    fontSize: "1.5em",
                }}
            />
        </Button>
    );
};
