import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Alert,
    Box,
    Button,
    CardActionArea,
    CircularProgress,
    Grid,
    InputAdornment,
    MenuItem,
    Skeleton,
    TextField,
    Typography,
    TypographyProps,
} from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";
import axios from "axios";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Desktop, Mobile } from "../..";
import { PasswordRequirements } from "../../../../../components/Password/PasswordRequirements";
import SapMarketingConsent from "../../../../../components/WizardForm/SapMarketingConsent";
import { env } from "../../../../../env.mjs";
import { anyPalette } from "../../../theme";
import {
    emailIsCorrect,
    nameIsCorrect,
    passwordIsCorrect,
    phoneNumberIsCorrect,
    postalCodeIsCorrect,
} from "../../../../../service/formService";

interface SelectOption {
    value: string;
    label: string;
}

export type ValidatorFN = (value: any, form: any) => boolean;

export interface FormItem {
    title?: string;
    type?: "text" | "password" | "select" | "date" | "email" | "sapMarketingConsent";
    name: string;
    required?: boolean;
    validator?: ValidatorFN | string;
    errorText?: string;
    options?: SelectOption[];
    defaultValue?: string;
    excludeFromSubmit?: boolean;
    maxDate?: Date;
    afterElement?: any;
    readOnly?: boolean;
    hidden?: boolean;
    hideAsterisk?: boolean;
    showPasswordRequirements?: boolean;
    textColor?: string;
    autoComplete?: string;
    placeholder?: string;
}

type Values = { [key: string]: string };

interface ButtonProps {
    text: string;
    style?: Object;
    onClick?: () => void;
    anyType?: string;
}

type Props = {
    items: FormItem[];
    primaryButtonProps?: ButtonProps;
    secondaryButtonProps?: ButtonProps;
    handleSubmit: (submitValues: any, allValues?: any) => void;
    errorMessage?: JSX.Element | string;
    size?: number;
    xs?: number;
    sm?: number;
    formValues?: any;
    style?: any;
    margin?: "normal" | "large";
    requiredFieldMessage?: string;
    loading?: boolean;
    requestLoading?: boolean;
    headerProps?: TypographyProps;
    inputStyling?: SxProps;
    menuItemStyling?: SxProps;
};

const ValidatorMap: { [key: string]: ValidatorFN } = {
    nameIsCorrect: nameIsCorrect,
    emailIsCorrect: emailIsCorrect,
    passwordIsCorrect: passwordIsCorrect,
    postalCodeIsCorrect: postalCodeIsCorrect,
    phoneNumberIsCorrect: phoneNumberIsCorrect,
};

export function getFormHeaderText(t: TFunction, item: FormItem, type: "title") {
    if (!item.title) return "";
    let title = t(item.title);
    if (item.required && !item.hideAsterisk && type === "title") title += " *";

    return title;
}

export function checkFormErrors(values: Values, item: FormItem, validator?: ValidatorFN) {
    const valFN = typeof item.validator === "string" ? ValidatorMap[item.validator] : item.validator;

    const validatorToUse = validator || valFN;

    if (item.required && !values[item.name]) {
        return true;
    }

    if (validatorToUse && !validatorToUse(values[item.name], values)) {
        return true;
    }
    return false;
}

export default function DynamicForm(props: Props) {
    const {
        items,
        primaryButtonProps,
        secondaryButtonProps,
        handleSubmit,
        errorMessage,
        xs,
        sm,
        formValues,
        requiredFieldMessage,
        loading = false,
        requestLoading = false,
        headerProps,
        inputStyling,
        menuItemStyling,
    } = props;
    const [values, setValues] = useState<Values>({});
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
    const [blurred, setBlurred] = useState<{ [key: string]: boolean }>({});
    const [scrolledToError, setScrolledToError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const { t } = useTranslation();
    const theme = useTheme();

    // Add Already set values to values
    useEffect(() => {
        if (formValues) {
            const defaultValues: { [key: string]: string } = {};
            Object.keys(formValues).forEach((key) => {
                if (!values[key] || values[key] === "") {
                    defaultValues[key] = formValues[key];
                }
            });
            setValues(defaultValues);
        }
    }, [formValues]);

    // Add default values to values
    useEffect(() => {
        if (Object.keys(values).length > 0) {
            items
                .filter((x) => x.required && x.defaultValue)
                .forEach((item) => {
                    if (!values[item.name] || values[item.name] === "") {
                        setValues({ ...values, [item.name]: item.defaultValue! });
                    }
                });
        }
    }, [values, items]);

    function submit(e: any) {
        e?.preventDefault();

        const newErrors: { [key: string]: boolean } = {};
        for (const item of items) {
            if (checkFormErrors(values, item)) {
                newErrors[item.name] = true;
                scrollToError(undefined, item.name);
            }
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const formattedValues = {} as Values;
            for (const item of items) {
                const value = values[item.name] ?? item.defaultValue ?? "";
                const trimmedValue = value.trim();
                let formattedValue = trimmedValue;

                if (item.name === "birth_date") {
                    const parts = trimmedValue.split("-");
                    if (parts.length === 3 && parts[2].length === 4) {
                        formattedValue = `${parts[2]}-${parts[1]}-${parts[0]}`;
                    }
                }

                formattedValues[item.name] = formattedValue;
            }

            const submitValues = {} as Values;
            for (const item of items) {
                if (!item.excludeFromSubmit) {
                    submitValues[item.name] = formattedValues[item.name];
                }
            }

            handleSubmit(submitValues, formattedValues);
        }
    }

    function getDateString(dateString: string) {
        let parts = dateString?.split("-");
        if (parts && parts[0].length === 2) {
            return dateString.split("-").reverse().join("-");
        }

        return dateString;
    }

    function getErrorText(item: FormItem) {
        if (errors.hasOwnProperty(item.name)) {
            if (!item.required && !values[item.name]) return;
            if (item.required && !values[item.name]) {
                return requiredFieldMessage || t("editData.requiredField");
            }

            const valFN = typeof item.validator === "string" ? ValidatorMap[item.validator] : item.validator;

            if (valFN && !valFN(values[item.name], values)) {
                return item.errorText ? t(item.errorText) : t(item.title!) + t("editData.incorrectValue");
            }
        }
    }

    function tryPostalCodeAutofill() {
        if (
            values.postal_code1?.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i) &&
            values.house_number1 &&
            !values.street1 &&
            !values.city1
        ) {
            axios({
                method: "get",
                url: env.postalCodeUrl + `/postcode?postcode=${values.postal_code1}&number=${values.house_number1}`,
                headers: {
                    Authorization: "Bearer " + env.postalCodeToken,
                },
            })
                .then((res) => {
                    if (res.data.city) {
                        values.city1 = res.data.city;
                    }
                    if (res.data.street) {
                        values.street1 = res.data.street;
                    }
                    setValues({ ...values });
                })
                .catch(() => {});
        }
    }

    function scrollToError(e: any, name?: string) {
        if (scrolledToError) return;

        const element = document.querySelector("#" + (e?.target.name || name));
        const coordinates = element?.getBoundingClientRect();

        window.scrollBy(0, (coordinates?.y || 0) - 106 || 0);
        setScrolledToError(true);
    }

    return (
        <div
            style={{
                scrollBehavior: "smooth",
            }}
        >
            <form onInvalidCapture={scrollToError} onSubmit={submit} autoComplete="off" style={{ width: "100%" }}>
                <Grid container spacing={1}>
                    {items.map((item, index) => {
                        if (item.hidden) return false;
                        switch (item.type) {
                            case "select":
                                return (
                                    <Grid item xs={xs || 12} sm={sm || 6} key={item.name}>
                                        <Typography
                                            pt={1}
                                            sx={{
                                                paddingBottom: 1,
                                                ...headerProps,
                                            }}
                                            variant="formHeader"
                                        >
                                            {getFormHeaderText(t, item, "title")}
                                        </Typography>
                                        {!loading ? (
                                            <TextField
                                                id={item.name}
                                                name={item.name}
                                                fullWidth
                                                select
                                                autoComplete={item.autoComplete}
                                                placeholder={item.placeholder}
                                                onChange={(e) => {
                                                    setValues({ ...values, [item.name]: e.target.value });
                                                }}
                                                value={values[item.name] || item.defaultValue || ""}
                                                onFocus={() => {
                                                    setScrolledToError(false);
                                                }}
                                                onBlur={() => {
                                                    setBlurred({ ...blurred, [item.name]: true });
                                                    setErrors({
                                                        ...errors,
                                                        [item.name]: getErrorText(item) ? true : false,
                                                    });
                                                }}
                                                sx={{ textAlign: "left", ...inputStyling }}
                                                error={getErrorText(item) ? true : false}
                                                helperText={getErrorText(item)}
                                                required={item.required}
                                                disabled={item.readOnly}
                                                onInvalid={() => {
                                                    setErrors({
                                                        ...errors,
                                                        [item.name]: getErrorText(item) ? true : false,
                                                    });
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        MenuListProps: {
                                                            sx: {
                                                                ...menuItemStyling,
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {item.options &&
                                                    item.options.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {t(option.label) || option.value}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                        ) : (
                                            <Skeleton height="40px" />
                                        )}
                                        {item.afterElement}
                                    </Grid>
                                );
                            case "sapMarketingConsent": {
                                return (
                                    <Grid item xs={xs || 12} sm={sm || 6} key={item.name} mt={2} ml={1}>
                                        <SapMarketingConsent
                                            key={item.name}
                                            required={item.required}
                                            // convert string into boolean to avoid to type check all other form input types
                                            marketingConsent={values[item.name] === "true"}
                                            setMarketingConsent={() => {
                                                setValues({
                                                    ...values,
                                                    [item.name]: values[item.name] === "true" ? "false" : "true",
                                                });
                                            }}
                                        />
                                    </Grid>
                                );
                            }
                            default:
                                return (
                                    <Grid item xs={xs || 12} sm={sm || 6} key={item.name}>
                                        <Typography
                                            pt={1}
                                            sx={{
                                                paddingBottom: 1,
                                                ...headerProps,
                                            }}
                                            variant="formHeader"
                                        >
                                            {getFormHeaderText(t, item, "title")}
                                        </Typography>
                                        {!loading ? (
                                            <TextField
                                                type={item.type === "password" && showPassword ? "text" : item.type}
                                                id={item.name}
                                                name={item.name}
                                                autoComplete={item.autoComplete}
                                                value={
                                                    item.type === "date" && values[item.name]
                                                        ? getDateString(values[item.name])
                                                        : values[item.name] || item.defaultValue || ""
                                                }
                                                sx={{
                                                    "& .MuiFormHelperText-root": {
                                                        color:
                                                            theme.palette.mode === "dark"
                                                                ? "#ffffff !important"
                                                                : "inherit",
                                                    },
                                                    "& legend": { display: !item.title ? "none" : "" },
                                                    "& fieldset": { top: !item.title ? 0 : "" },
                                                    ...inputStyling,
                                                }}
                                                onChange={(e) => {
                                                    setValues({ ...values, [item.name]: e.target.value });
                                                }}
                                                InputLabelProps={item.type === "date" ? { shrink: true } : {}}
                                                error={getErrorText(item) ? true : false}
                                                helperText={getErrorText(item)}
                                                placeholder={item.placeholder}
                                                fullWidth
                                                onFocus={() => {
                                                    setScrolledToError(false);
                                                    setBlurred({ ...blurred, [item.name]: false });
                                                }}
                                                disabled={item.readOnly}
                                                InputProps={{
                                                    inputProps: {
                                                        readOnly: item.readOnly,
                                                        max: item.maxDate && item.maxDate.toISOString().split("T")[0],
                                                    },
                                                    endAdornment: item.type === "password" &&
                                                        values.password?.length > 0 && (
                                                            <InputAdornment position="end" tabIndex={undefined}>
                                                                <CardActionArea
                                                                    tabIndex={999}
                                                                    sx={{
                                                                        height: "32px",
                                                                        paddingY: 0,
                                                                        paddingX: 1,
                                                                        borderRadius: "6px",
                                                                        display: "flex",
                                                                    }}
                                                                    onClick={() => {
                                                                        setShowPassword((prev) => !prev);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        style={{
                                                                            fontSize: "24px",
                                                                            float: "left",
                                                                            justifyContent: "center",
                                                                            verticalAlign: "middle",
                                                                            display: "flex",
                                                                            marginTop: "auto",
                                                                            marginBottom: "auto",
                                                                            width: ".85em",
                                                                        }}
                                                                        icon={
                                                                            showPassword
                                                                                ? solid("eye-slash")
                                                                                : solid("eye")
                                                                        }
                                                                        color={anyPalette.sandDark}
                                                                    />
                                                                </CardActionArea>
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onBlur={() => {
                                                    setBlurred({ ...blurred, [item.name]: true });
                                                    setErrors({
                                                        ...errors,
                                                        [item.name]: getErrorText(item) ? true : false,
                                                    });
                                                    tryPostalCodeAutofill();
                                                }}
                                                required={item.required}
                                                onInvalid={() => {
                                                    setErrors({
                                                        ...errors,
                                                        [item.name]: getErrorText(item) ? true : false,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <Skeleton height="40px" />
                                        )}
                                        {item.showPasswordRequirements && (
                                            <PasswordRequirements
                                                password={values[item.name]}
                                                focussed={blurred[item.name] === false || values[item.name] !== ""}
                                                error={getErrorText(item) ? true : false}
                                                textColor={item.textColor}
                                            />
                                        )}
                                        {item.afterElement}
                                    </Grid>
                                );
                        }
                    })}
                </Grid>
                <Box sx={{ marginTop: 3 }}>
                    <Mobile>
                        <Button
                            disabled={requestLoading || loading}
                            sx={{
                                width: "100%",
                                ...primaryButtonProps?.style,
                            }}
                            variant="contained"
                            type="submit"
                        >
                            {requestLoading ? (
                                <CircularProgress size={28} sx={{ color: theme.palette.primary.contrastText }} />
                            ) : (
                                primaryButtonProps?.text || "Bevestigen"
                            )}
                        </Button>
                        {secondaryButtonProps && (
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={requestLoading || loading}
                                sx={{ width: "100%", ...secondaryButtonProps?.style }}
                                onClick={secondaryButtonProps.onClick}
                            >
                                {secondaryButtonProps?.text}
                            </Button>
                        )}
                    </Mobile>
                    <Desktop>
                        <Button
                            variant="contained"
                            disabled={requestLoading || loading}
                            sx={{
                                padding: 1,
                                ...primaryButtonProps?.style,
                            }}
                            type="submit"
                        >
                            {requestLoading ? (
                                <CircularProgress size={28} sx={{ color: theme.palette.primary.contrastText }} />
                            ) : (
                                primaryButtonProps?.text || "Bevestigen"
                            )}
                        </Button>
                        {secondaryButtonProps && (
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={requestLoading || loading}
                                sx={{
                                    padding: 1,
                                    ...secondaryButtonProps?.style,
                                }}
                                onClick={secondaryButtonProps.onClick}
                            >
                                {secondaryButtonProps?.text}
                            </Button>
                        )}
                    </Desktop>
                </Box>
            </form>
            {errorMessage && (
                <Alert sx={{ width: "inherit", marginTop: 2 }} severity="error">
                    {errorMessage}
                </Alert>
            )}
        </div>
    );
}
